import CheckEligibility from "../../../jsx/components/Dashboard/CheckEligibility/checkeligibility";

const MyEligibilityWrapper = () => {
  return <CheckEligibility />;
};

export const allowedRoles = [
  _enum.ROUTE_PERSONAS.admin,
  _enum.ROUTE_PERSONAS.connector,
  _enum.ROUTE_PERSONAS.manager,
];

export default constore(MyEligibilityWrapper);
