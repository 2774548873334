export default {
    // apiGetMokeEmployeeData() {
    //     let data = [
    //         {
    //             employeeName: "Test Employee",
    //             mobileNumber: "9876543210",
    //             emailAddress: "test@example.com",
    //             joiningDate: "2023-11-24T09:43:35.466Z",
    //             department: "sales",
    //             locationId: "1",
    //             status: "active",
    //         },
    //     ]
    //     return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    // },
    apiGetEmployeeMokeById(urldata = {}) {
        let data =
        {
            employeeName: "Test Employee",
            mobileNumber: "9876543210",
            emailAddress: "test@example.com",
            joiningDate: "2023-11-24T09:43:35.466Z",
            department: "sales",
            locationId: "1",
            status: "active",
        }

        return this.mokeapi(() => (data));
    },
    apiGetMokeDashboardData() {
        let data = {
            total_digirep: 20,
            total_manager: 10,
            total_registered_user: 20,
            total_leads: 60
        }
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    apiGetKycMyRequestDataMoke() {
        let data = [
            {
                name: "Suresh Vadla",
                mobileNumber: "9988556644",
                requestedAmount: "200",
                date: new Date().toISOString(),
                status: "Processed",
                remarks: "Lorem Ipsum",
            },
            {
                name: "Abhay Kumar",
                mobileNumber: "9988556644",
                requestedAmount: "200",
                date: new Date().toISOString(),
                status: "Pending",
                remarks: "Lorem Ipsum",
            },
            {
                name: "Suresh Vadla",
                mobileNumber: "9988556644",
                requestedAmount: "200",
                date: new Date().toISOString(),
                status: "Rejected",
                remarks: "Lorem Ipsum",
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    apiGetKycTransactionHistoryDataMoke() {
        let data = [
            {
                transactionType: "Debit",
                transactionAmount: "200",
                date: new Date(),
                details: "Amount debited for using PAN verification on RAVINDRA CHARY...",
            },
            {
                transactionType: "Debit",
                transactionAmount: "200",
                date: new Date(),
                details: "Amount debited for using PAN verification on RAVINDRA CHARY...",
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    apiGetKycUtilizationDataMoke() {
        let data = [
            {
                name: "Suresh Vadla",
                mobileNumber: "9988556644",
                pan: "10/15",
                aadhar: "10/30",
                bsa: "2/10",
                cibil: "-",
                lastActiveDate: new Date(),
            },
            {
                name: "Abhay Kumar",
                mobileNumber: "9988556644",
                pan: "10/15",
                aadhar: "10/30",
                bsa: "2/10",
                cibil: "-",
                lastActiveDate: new Date(),
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    apiGetKycConfigurationDataMoke() {
        let data = [
            {
                serviceName: "PAN",
                role: "Connector",
                monthlyLimit: "30",
                costPerHit: "25",
            },
            {
                serviceName: "Aadhar",
                role: "Manager",
                monthlyLimit: "30",
                costPerHit: "25",
            },
            {
                serviceName: "Cibil",
                role: "Admin",
                monthlyLimit: "30",
                costPerHit: "25",
            },
            {
                serviceName: "Cibil",
                role: "Admin",
                monthlyLimit: "30",
                costPerHit: "25",
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
}