import React, { Component } from "react";

export class DigitalLeadsModal extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.ApplicationModal = this;
  }

  render() {
    let { props } = this;
    let {
      id,
      title,
      show,
      onClose,
      modalSize,
      disbData,
      // logsColumns,
      errorMessage,
      viewmode,
      adminPersona,
    } = props;
    let attrs = Object.except(props, ["title", "type", "onClose"]);
    let adminAttrs = Object.only(props, [""]);
    let disbAttrs = Object.except(props, ["type", "onClose"]);

    return (
      <AppCanvas
        id={id}
        title={title}
        show={show}
        onClose={onClose}
        modalSize={modalSize}
      >
        {!adminPersona ? (
          <AppFormData
            {...attrs}
            onClose={onClose}
            errorMessage={errorMessage}
            viewmode={viewmode}
          ></AppFormData>
        ) : (
          <>
            <AppFormData
              {...attrs}
              // {...adminAttrs}
              onClose={onClose}
              errorMessage={errorMessage}
              viewmode={viewmode}
            ></AppFormData>
            {/* <h5 class="modal-title mb-3">Disbursement Details</h5>
          <AppFormData
          {...disbAttrs}
          onClose={onClose}
          errorMessage={errorMessage}
          viewmode={viewmode}
        ></AppFormData> */}
          </>
        )}
      </AppCanvas>
    );
  }
}

export default DigitalLeadsModal;
