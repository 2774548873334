export default {
    apiGetPendingDataMoke(urldata = {}) {
        let data =
            [{
                customerName: "Test Employee",
                applicationNo: "App123",
                bankName: "SBI",
                disbAmt: "2,00,000",
                disbDate: "2023-11-24T09:43:35.466Z",
                payoutType: "monthly",
                paymentStatus: "Pending",
            }]

        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    apiGetMyEarningCardsMoke() {
        let data = {
            leads_entered: {
                number_of_leads: 100,
                amount: 800000
            },
            approved_leads: {
                number_of_leads: 10,
                amount: 500000
            },
            disbursement_leads: {
                number_of_leads: 6,
                amount: 80000
            },
        }
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },

    // 
    apiGetPayoutDashboard(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_PAYOUT_DASHBOARD, payload)
            .exec().log()
            .mapresults((response) => {
                return response
            })
            .get();
    },
    apiGetAdminPayouts(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_ADMIN_PAYOUTS, payload)
            .exec().log()
            .mapresults((response) => {
                return response
            })
            .get();
    },

    apiSendAdminLeadsForPayment(data) {
        return this.request(this.api().SEND_ADMIN_LEADS_FOR_PAYMENT, data)
            .exec()
            .log()
            .get();
    },

    apiAdminChangePaymentStatusToProcessed(data) {
        return this.request(this.api().ADMIN_CHANGE_PAYMENT_STATUS_TO_PROCESSED, data)
            .exec()
            .log()
            .get();
    },
    // 
    apiGetConnectorPayouts(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_CONNECTOR_PAYOUTS, payload)
            .exec().log()
            .mapresults((response) => {
                return response
            })
            .get();
    },

    apiSendConnectorLeadsForPayment(data) {
        return this.request(this.api().SEND_CONNECTOR_LEADS_FOR_PAYMENT, data)
            .exec()
            .log()
            .get();
    },
    apiDownloadAdminPayout(data) {
        return this.request(this.api().DOWNLOAD_CONNECTOR_PAYOUT, data)
            .exec()
            .log()
            .get();
    },
    apiGetUploadedBankPayouts(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_UPLOADED_BANK_PAYOUTS, payload)
            .exec().log()
            .mapresults((response) => {
                return {
                    data: response.data,
                    total: response.count
                }
            })
            .get();
    },
    apiManualTriggerToMatchRecords() {
        return this.request(this.api().MANUAL_TRIGGER_TO_MATCH_RECORDS)
            .exec().log()
            .get();
    },
    apiGetPreviouslyRunnedeBankPayoutJobs(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_PREVIOUSLY_RUNNED_BANK_PAYOUT_JOBS, payload)
            .exec().log()
            .mapresults((response) => {
                return {
                    data: response.data,
                    total: response.count
                }
            })
            .get();
    },
    apiBulkUploadBankPayout(data, urldata = mixins.params()) {
        console.log(data, urldata);
        return this.request(this.api().BULK_UPLOAD_BANK_PAYOUT, data)
            .urltransform(urldata)
            .multipart()
            .exec()
            .log()
            .get();
    },

    apiDownloadBankPayoutTemplate() {
        return this.request(this.api().DOWNLOAD_BANK_PAYOUT_TEMPLATE)
            .exec()
            .log()
            .get();
    },
    // 
    apiGetPaymentInfo(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_PAYMENT_INFO, payload)
            .exec().log()
            .mapresults((response) => {
                return {
                    data: response.data,
                    count: response.count
                }
            })
            .get();
    },

    apiGetPaymentInfoById(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().GET_PAYMENT_INFO_BY_ID)
            .urltransform(urldata)
            .exec().log()
            .mapresults((res) => {
                return {
                    data: res,
                }
            })
            .get();
    },

    apiDownloadAdminPaymentInfo(data) {
        return this.request(this.api().DOWNLOAD_LEADS_PAYMENT, data)
            .exec()
            .log()
            .get();
    },
}