import React, { useState } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import MainPagetitle from "../../../layouts/MainPagetitle";
import MyEarningCards from "./myEarningCards";
import Pending from "./pending/pending";
import Processed from "./processed/processed";
import PaymentDetails from "./paymentDetails/paymentDetails";

const MyEarnings = (props) => {
  const isAdmin = props?.$store?.user?.role === _enum.ROUTE_PERSONAS.admin;

  const [activeKey, setActiveKey] = useState("pending");
  const tabData = [
    {
      key: "pending",
      name: "Pending",
    },
    {
      key: "processed",
      name: "Processed",
    },
    {
      key: "paymentdetails",
      name: "Payment details",
    },
  ];

  const handleSelect = (key) => {
    setActiveKey(key);
  };

  const getBackLink = () => {
    return "/app/businesstobusiness?activetab=overallleads";
  };

  return (
    <>
      <MainPagetitle
        mainTitle={isAdmin ? "Payout Details" : "My Earnings"}
        backLink={getBackLink()}
        pageIcon={imagepaths.WalletIconActive}
      />
      <div className="container-fluid">
        <div className="header_box">
          <MyEarningCards />
        </div>
        <Tab.Container>
          <Card name="custom-tab" className="dz-card min-h-[600px]">
            <Tab.Content>
              <Card.Body>
                {/* <!-- Nav tabs --> */}
                <div className="custom-tab-1 myearnings">
                  <Tab.Container
                    defaultActiveKey={activeKey}
                    activeKey={activeKey}
                    onSelect={handleSelect}
                  >
                    <Nav as="ul" className="nav-tabs">
                      {!isAdmin
                        ? tabData
                            .filter((v) => v.key !== "processed")
                            .map((data, i) => (
                              <Nav.Item as="li" key={i}>
                                <Nav.Link eventKey={data.key}>
                                  {data.name}
                                </Nav.Link>
                              </Nav.Item>
                            ))
                        : tabData.map((data, i) => (
                            <Nav.Item as="li" key={i}>
                              <Nav.Link eventKey={data.key}>
                                {data.name}
                              </Nav.Link>
                            </Nav.Item>
                          ))}
                    </Nav>
                    <Tab.Content className="pt-4">
                      {activeKey === "pending" && (
                        <Tab.Pane eventKey="pending">
                          <Pending />
                        </Tab.Pane>
                      )}
                      {activeKey === "processed" && (
                        <Tab.Pane eventKey="processed">
                          <Processed />
                        </Tab.Pane>
                      )}
                      {activeKey === "paymentdetails" && (
                        <Tab.Pane eventKey="paymentdetails">
                          <PaymentDetails />
                        </Tab.Pane>
                      )}
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </Card.Body>
            </Tab.Content>
          </Card>
        </Tab.Container>
      </div>
    </>
  );
};
export default constore(MyEarnings);
