import React, { useState } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import MainPagetitle from "../../../../layouts/MainPagetitle";
import MyRequest from "./MyRequest/MyRequest";
import TransactionHistory from "./TransactionHistory/TransactionHistory";

const TransactionDetails = (props) => {
  const frompath = props?.urlparams?.params?.frompath;
  const [search, setSearch] = useState({
    limit: 10,
    order_by: "updatedAt",
    order_direction: "DESC",
    page: 1,
  });

  const [activeKey, setActiveKey] = useState("myrequest");
  const tabData = [
    {
      key: "myrequest",
      name: "My Request",
    },
    {
      key: "transactionHistory",
      name: "Transaction History",
    },
  ];

  const handleSelect = (key) => {
    setActiveKey(key);
  };

  const getBreadCums = () => {
    let pagetitle = "Transaction Details";
    return pagetitle;
  };

  const getBackLink = () => {
    let link;
    if (frompath === "vas") {
      link = `/app/valueaddedservices`;
    }
    if (frompath === "kyc") {
      link = `/app/kyc`;
    }
    return link;
  };
  return (
    <>
      <MainPagetitle
        mainTitle={"KYC"}
        back={getBreadCums()}
        backLink={getBackLink()}
        pageTitle="Transaction Details"
        parentTitle="Home"
        pageIcon={imagepaths.PersonSearchIconActive}
      />

      <div className="container-fluid">
        <Tab.Container>
          <Card name="custom-tab" className="dz-card min-h-[600px]">
            <Tab.Content>
              <Card.Body>
                {/* <!-- Nav tabs --> */}
                <div className="custom-tab-1">
                  <Tab.Container
                    defaultActiveKey={activeKey}
                    activeKey={activeKey}
                    onSelect={handleSelect}
                  >
                    <Nav as="ul" className="nav-tabs">
                      {tabData.map((data, i) => (
                        <Nav.Item as="li" key={i}>
                          <Nav.Link eventKey={data.key}>{data.name}</Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                    <Tab.Content className="pt-4">
                      {activeKey === "myrequest" && (
                        <Tab.Pane eventKey="myrequest">
                          <MyRequest />
                        </Tab.Pane>
                      )}
                      {activeKey === "transactionHistory" && (
                        <Tab.Pane eventKey="transactionHistory">
                          <TransactionHistory />
                        </Tab.Pane>
                      )}
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </Card.Body>
            </Tab.Content>
          </Card>
        </Tab.Container>
      </div>
    </>
  );
};

export default constore(TransactionDetails);
