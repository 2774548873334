import React from "react";
import ProductCard from "../../elements/ProductCard";

const DigitalLeads = (props) => {
  const digitalLeads = [
    {
      title: "L&T Finance",
      image: imagepaths.ltIcon,
      path: "/app/digitalleads",
    },
    {
      id: "tata_capital",
      title: "TATA Capital",
      image: imagepaths.tataCapitalIcon,
      path: "/app/tatacapital",
    },
    {
      id: "incred_finance",
      title: "InCred Finance",
      image: imagepaths.incredFinanceIcon,
      path: "/app/incredfinance",
    },
    {
      id: "aditya_birla_capital",
      title: "Aditya Birla Capital",
      image: imagepaths.adityaBirlaCapitalIcon,
      path: "https://creditlink.finbox.in/?partnerCode=SP_GUHTTB",
      // buttonText: "link",
      isExternalPath: true,
    },
    {
      id: "zype",
      title: "Zype",
      image: imagepaths.zypeIcon,
      path: "/app/zype",
    },
    {
      id: "livlong",
      title: "Livlong Insurance",
      image: imagepaths.livLongIcon,
      isDefaultBtn: true,
      buttonText: "Apply Now",
    },
  ];

  const handleDefaultBtn = (e, item) => {
    e.preventDefault();
    if (item?.id === "livlong") {
      props.apis.apiGetLivlongInsuranceRedirectUrl().then(({ data }) => {
        console.log({ data });

        if (data.statusCode === 200) {
          var link = document.createElement("a");
          link.setAttribute("target", "_blank");
          link.href = data?.redirectUrl;
          link.click();
        }
      });
    }
  };

  return (
    <div className="row g-3 mx-auto py-3 px-4">
      {digitalLeads?.map((item, i) => {
        return (
          <ProductCard
            title={item.title}
            image={item.image}
            path={item.path}
            key={i}
            isExternalPath={item.isExternalPath}
            isDefaultBtn={item.isDefaultBtn}
            {...(item.buttonText
              ? {
                  buttonText: item.buttonText,
                }
              : {})}
            handleBtn={(...args) => handleDefaultBtn(...args, item)}
          />
        );
      })}
    </div>
  );
};

export default constore(DigitalLeads);
