import React, { useEffect, useState } from "react";
import DigiModal from "../../Digirep/DigiModal";
import DigiSearch from "../../Digirep/DigiSearch";

const TeamDetails = (props) => {
  const isAdmin = props?.$store?.user?.role === _enum.ROUTE_PERSONAS.admin;
  const isManager = props?.$store?.user?.role === _enum.ROUTE_PERSONAS.manager;
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [totalPageChange, setTotalPageChange] = useState({
    page: 1,
    limit: 10,
    // order_by: "updatedAt",
    // order_direction: "DESC",
  });
  const [rowcount, setRowcount] = useState(0);
  const [loader, setLoader] = useState(false);
  const columns = [
    {
      dataIndex: "name",
      title: "Name",
      render: (text, record) => {
        return (
          <>
            <span className="table-primary-text">{text}</span>
            <p className="table_text_sm">({record?.profileId})</p>
          </>
        );
      },
    },
    {
      dataIndex: "mobileNumber",
      title: "Mobile No.",
    },
    {
      dataIndex: "designation",
      title: "Designation",
    },
    {
      dataIndex: "supervisorId",
      title: "Reporting Manager",
      render: (text, record) => {
        return <span>{record?.supervisor?.name || "-"}</span>;
      },
    },
    {
      dataIndex: "state",
      title: "State",
    },
    {
      dataIndex: "city",
      title: "City",
    },
    {
      dataIndex: "accountHolderBankName",
      title: "Bank Name",
    },
  ];
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState({ name: "", message: "" });
  const [stateCity, setStateCity] = useState({ state: "", city: "" });
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const [modalFields, setModalFields] = useState([
    {
      label: "Name",
      name: "name",
      type: "text",
      placeholder: "Please Enter",
      onChange: (value) => handleDigiName(value),
    },
    {
      label: "Role",
      name: "role",
      type: "select",
      options: [],
      onChange: (value) => handleRole(value),
      required: true,
    },
    {
      label: "Mobile Number",
      name: "mobileNumber",
      type: "text",
      placeholder: "Please Enter",
      onChange: (value) => handleMobileNo(value),
    },
    {
      label: "Email ID",
      name: "emailAddress",
      type: "email",
      placeholder: "Please Enter",
      onChange: (value) => handleEmailId(value),
    },
    {
      label: "Account Holder Name",
      name: "accountHolderName",
      type: "text",
      placeholder: "Please Enter",
      onChange: (value) => handleActHolderName(value),
    },
    {
      label: "Account No.",
      name: "accountNumber",
      type: "text",
      placeholder: "Please Enter",
      onChange: (value) => handleActNo(value),
    },
    {
      label: "Bank Name",
      name: "accountHolderBankName",
      type: "select",
      options: [],
      placeholder: "Please Select",
    },
    {
      label: "IFSC Code",
      name: "accountHolderBankIFSC",
      type: "text",
      placeholder: "Please Enter",
      onChange: (value) => handleIFSC(value),
    },
    {
      label: "Work Location",
      name: "workLocation",
      type: "select",
      options: [],
      onChange: (value) => {
        setLocation(value);
      },
      placeholder: "Please Select",
    },
    {
      label: "State",
      name: "state",
      type: "text",
      id: "disableModalField",
      placeholder: "Auto populated",
      disabled: true,
    },
    {
      label: "City",
      name: "city",
      type: "text",
      id: "disableModalField",
      placeholder: "Auto populated",
      disabled: true,
    },
    // {
    //   label: "PAN No.",
    //   name: "panCardId",
    //   type: "text",
    //   placeholder: "Please Enter",
    //   onChange: (value) => handlePanCard(value),
    // },
    {
      label: "Digi Team Member Designation",
      name: "designation",
      type: "select",
      options: [],
      // onChange: (...args) => handleDesignation(...args),
      placeholder: "Please Select",
      required: true,
    },
    {
      label: "Reporting Manager",
      name: "supervisorId",
      type: "select",
      options: [],
      placeholder: "Please Select",
      required: true,
    },
  ]);
  const [editData, setEditData] = useState({});
  const [editmode, setEditmode] = useState(false);
  const [viewmode, setViewmode] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [popupfields, setPopupfields] = useState({});
  const [popupdata, setPopupdata] = useState({});

  const [roles, setRoles] = useState([]);
  const [locations, setLocations] = useState([]);
  const [cities, setCities] = useState([]);
  const [banknames, setBanknames] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [supervisorOptions, setSupervisorOptions] = useState([]);

  const onPageChange = (pageData) => {
    // console.log({ onPageChange: pageData });
    setTotalPageChange({
      ...search,
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const openModal = () => {
    setOpenCanvas(true);
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };

  const handleDigiName = (e) => {
    const stringReg = _enum?.REGEX?.onlystring;
    if (stringReg.test(e.target.value)) {
      setErrorMessage("");
    } else {
      setErrorMessage({
        name: "name",
        message: "Special characters are not allowed",
      });
    }
  };

  const handleMobileNo = (e) => {
    const numberReg = _enum?.REGEX?.number;
    const fieldValue = e.target.value;
    if (numberReg.test(fieldValue) && fieldValue.length === 10) {
      setErrorMessage("");
    } else {
      setErrorMessage({
        name: "mobileNumber",
        message: "Please enter a valid Mobile Number",
      });
    }
  };

  const handleEmailId = (e) => {
    const emailReg = _enum?.REGEX?.email;
    const fieldValue = e.target.value;
    if (emailReg.test(fieldValue)) {
      setErrorMessage("");
    } else {
      setErrorMessage({
        name: "emailAddress",
        message: "Please enter a valid Email Address",
      });
    }
  };

  const handleActHolderName = (e) => {
    const stringReg = _enum?.REGEX?.onlystring;
    if (stringReg.test(e.target.value)) {
      setErrorMessage("");
    } else {
      setErrorMessage({
        name: "accountHolderName",
        message: "Special characters are not allowed",
      });
    }
  };

  const handleActNo = (e) => {
    const numberReg = _enum?.REGEX?.number;
    const fieldValue = e.target.value;
    if (numberReg.test(fieldValue)) {
      setErrorMessage("");
    } else {
      setErrorMessage({
        name: "accountNumber",
        message: "Please enter a valid Account Number",
      });
    }
  };

  const handleIFSC = (e) => {
    const ifscRegex = _enum?.REGEX?.ifsc;
    if (ifscRegex.test(e.target.value)) {
      setErrorMessage("");
    } else {
      setErrorMessage({
        name: "accountHolderBankIFSC",
        message: "Invalid IFSC code",
      });
    }
  };

  const handleDesignation = (value) => {
    console.log({ value });
    let searchValue = value[0]?.designation;

    // clear selected option
    let supervisor = document.querySelector(
      "[name='appinput-supervisorId'].form-group"
    );
    let { values: supervisorNames = [] } =
      supervisor?.vnode?.refs?.select?.state;
    if (supervisorNames.length) {
      supervisor.vnode.refs.select.clearAll();
    }

    // calling digirep's by designation
    // getSupervisors(searchValue);
  };

  const handleRole = (val) => {
    let value = val[0].key;
    console.log({ value });

    // clear selected option
    let supervisor = document.querySelector(
      "[name='appinput-supervisorId'].form-group"
    );
    let { values: supervisorNames = [] } =
      supervisor?.vnode?.refs?.select?.state;
    if (supervisorNames.length) {
      supervisor.vnode.refs.select.clearAll();
    }
    getSupervisors(value);
  };

  const getSupervisors = (role) => {
    console.log("getSupervisors role: ", role);
    console.log({ role });

    let filteredData = [
      {
        profileId: props?.$store?.user?.profileId,
        name: props?.$store?.user?.name,
      },
    ];
    let supervisorIdField = modalFields.find((o) => o.name == "supervisorId");
    let supervisoroptions = filteredData.options("profileId", "name");
    supervisorIdField.options = supervisoroptions;
    console.log({ filteredData, supervisoroptions });
    setModalFields([...modalFields]);
  };

  const getLocations = () => {
    let payload = {
      ...search,
    };
    props.apis.apiGetMasterLocations(payload).then(({ data }) => {
      // console.log({ apiGetMasterLocations: data });
      let location = modalFields.find((o) => o.name == "workLocation");
      location.options = data.options("locationName");
      setLocations(data);
      setOpenCanvas(false);
    });
  };

  const setLocation = (record) => {
    console.log({ setLocation: record[0] });
    let statefield = modalFields.find((o) => o.name == "state");
    statefield.defaultValue = record[0]?.state;

    let cityfield = modalFields.find((o) => o.name == "city");
    cityfield.defaultValue = record[0]?.city;

    setStateCity({ state: record[0]?.state, city: record[0]?.city });
    setModalFields([...modalFields]);
  };

  const getDigirep = (params = {}, reset) => {
    console.log({ params, reset });
    let payload = {};
    if (reset) {
      let defaultSearchData = Object.only(search, [
        "limit",
        "page",
        // "order_by",
        // "order_direction",
      ]);

      setSearch(defaultSearchData);
      payload = {
        ...defaultSearchData,
        ...params,
      };
      // console.log({ defaultSearchData: payload });
    } else {
      payload = {
        ...search,
        ...params,
      };
    }
    props.apis.apiGetDigirep(payload).then(({ data, total }) => {
      console.log({ apiGetDigirep: data, total });
      data = data?.map((obj) => {
        let newObj = { ...obj };
        newObj.joiningDate = new Date(obj.joiningDate).toDate();
        return newObj;
      });
      setTableData(data);
      setIsFetching(false);
      setRowcount(total);
    });
  };

  const handleSubmit = (e, data, form) => {
    e.preventDefault();
    console.log("formdata: ", data);
    let payload = {
      // ...Object.except(data, ["locationId"]),
      ...data,
    };
    payload.state = stateCity.state;
    payload.city = stateCity.city;

    console.log({ payload });

    if (editmode) {
      let updateData = {
        ...payload,
        profileId: editData?.profileId,
      };
      updateData.locationId = Number(editData.locationId);
      // console.log({ updateData });
      props.apis.apiUpdateEmployee(updateData).then((res) => {
        // console.log({ apiUpdateEmployee: res });
        swal2.fire({
          title: "Digi Team Member updated successfully.",
          icon: "success",
          iconColor: "#234C9B",
          timer: 4000,
        });
        closeModal();
        getDigirep();
      });
    } else {
      props.apis.apiAddDigirep(payload).then((res) => {
        // console.log({ apiAddDigirep: res });
        swal2.fire({
          title: "Digi Team Member added successfully.",
          icon: "success",
          iconColor: "#234C9B",
          timer: 4000,
        });
        closeModal();
        getDigirep();
      });
    }
  };

  const onSearch = (data, reset) => {
    console.log("onSearch on digirep", data, reset);
    if (Object.keys(data).length === 0) {
      setSearch({
        ...data,
        ...search,
      });
      getDigirep(data, reset);
    } else {
      setSearch({
        ...data,
        ...search,
      });
      getDigirep(data, reset);
    }
  };

  const addModalFields = (values) => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    console.log("formdata: ", fields, formdata);
    setPopupfields(fields);
    setPopupdata(formdata);
    setOpenCanvas(true);
  };

  const onModalClose = () => {
    console.log("onModalClose");
    closeModal();
    setPopupfields({});
    setViewmode(false);
    setEditmode(false);
    setEditData({});
  };

  const getRoles = () => {
    props.apis.apiGetMasterRoles().then((data) => {
      let rolefield = modalFields.find((o) => o.name == "role");
      let roles;
      roles = data.filter((v) => v.role === _enum.ROUTE_PERSONAS.connector);
      console.log({ roles });

      let opts = roles.options("role");
      let newArray = opts?.map((obj) => {
        if (obj.key === "DIGIREP-MANAGER") {
          return { ...obj, label: "DIGI TEAM MANAGER" };
        }
        if (obj.key === "CONNECTOR") {
          return { ...obj, label: "DIGI TEAM MEMBER" };
        }
        return obj;
      });
      rolefield.options = newArray;
      setRoles(data);
    });
  };

  const getBankNames = () => {
    let payload = {
      page: 1,
    };
    props.apis.apiGetBanknames(payload).then(({ data, count }) => {
      console.log({ apiGetBanknames: data });
      let bankName = modalFields.find((o) => o.name == "accountHolderBankName");
      bankName.options = data?.options("bankName");
      setModalFields([...modalFields]);
      setBanknames(data);
    });
  };

  const getDesignations = () => {
    props.apis.apiGetMasterDesignations().then((res) => {
      console.log(modalFields, res);
      let designation = modalFields.find((o) => o.name == "designation");
      // designation.options = res.options("designation");

      let opts = res.options("designation");
      let newArray = opts?.map((obj) => {
        if (obj.designation === "EXECUTIVE") {
          return { ...obj, label: "Digi Team Member" };
        }
        return obj;
      });
      designation.options = newArray;

      setDesignations(res);
    });
  };

  const getTeamDetails = () => {
    let payload = {
      ...search,
    };

    props.apis.apiGetDigirep(payload).then(({ data, count }) => {
      setData(data);
      setRowcount(count);
      setIsFetching(false);
    });
  };

  useEffect(() => {
    console.log({ Digirep: props });
    getDigirep();
    getDesignations();
    getRoles();
    // getStates();
    getLocations();
    getBankNames();
  }, []);

  useEffect(() => {
    console.log(search);
    getDigirep(totalPageChange);
  }, [totalPageChange]);

  useEffect(() => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    setPopupfields(fields);
  }, [editData]);

  useEffect(() => {
    console.log({ editmode, viewmode });
    // if (!!viewmode && editmode) {
    //   let emailAddressField = modalFields.find((o) => o.name == "emailAddress");
    //   emailAddressField.disabled = false;
    // }
    let emailAddressField = modalFields.find((o) => o.name == "emailAddress");
    let mobileNumberField = modalFields.find((o) => o.name == "mobileNumber");
    if (editmode === false) {
      emailAddressField.disabled = false;
      mobileNumberField.disabled = false;
    } else {
      emailAddressField.disabled = true;
      mobileNumberField.disabled = true;
    }
  }, [openCanvas]);

  useEffect(() => {
    getTeamDetails();
  }, []);
  return (
    <>
      {/* <Link
        to={"#"}
        className="btn btn-primary mb-3 floating_button"
        onClick={(...args) => addModalFields(...args)}
      >
        + Add Digi Team Member
      </Link> */}
      <div className="tbl-caption-container">
        <DigiSearch
          inactive={props.inactive}
          onSearch={(...arg) => onSearch(...arg)}
          onAddModalFields={(...args) => addModalFields(...args)}
        />
      </div>
      <AppTable
        data={tableData}
        columns={columns}
        onChange={(...arg) => onPageChange(...arg)}
        total={rowcount}
        reorder={true}
        viewable={false}
        editable={false}
        deletable={false}
        loading={isFetching}
      ></AppTable>
      <DigiModal
        id="employeeCanvas"
        title={`${
          editmode
            ? "Edit Digi Team Member"
            : viewmode
            ? "View Digi Team Member Details"
            : "Add New Digi Team Member"
        }`}
        show={openCanvas}
        onClose={() => onModalClose()}
        data={popupdata}
        editdata={editData}
        fields={popupfields}
        editmode={editmode}
        onSubmit={(...args) => handleSubmit(...args)}
        submitText={`${editmode ? "Update" : "Submit"}`}
        disableallfields={viewmode ? true : false}
        loader={loader}
        viewmode={viewmode}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default constore(TeamDetails);
