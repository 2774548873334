import React, { Component } from "react";

export class MyRequestSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        limit: 10,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
    };
    this.adminfields = [
      {
        name: "name",
        label: "Connector Name",
        placeholder: "Connector Name",
      },
      {
        name: "monthYear",
        label: "Month",
        type: "month",
        placeholder: "Month",
      },
    ];
    this.overallfields = [
      {
        name: "status",
        label: "Status",
        type: "select",
        options: _enum.ADMIN_KYC_REQUEST_STATUS,
        placeholder: "Status",
      },
      {
        name: "date",
        label: "Date",
        type: "date",
        placeholder: "Date",
      },
    ];
  }

  componentDidMount() {}

  onSearch = (reset = false) => {
    let data = this.refs.form.form.current.getData();
    // console.log("onSearch formData", data, reset);
    data = Object.filter(data, (val) => val);

    const months = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    if (data?.monthYear) {
      let inputVal = data?.monthYear;
      const [month, year] = inputVal.split("-");
      const output = `${year}-${months[month]}`;
      data = {
        ...data,
        monthYear: output,
      };
    }
    this.props.onSearch && this.props.onSearch(data, reset);
  };

  resetSearchForm() {
    this.refs.form.form.current.reset();
    this.onSearch(true);
  }

  render() {
    let { isAdmin } = this.props;
    return (
      <>
        <AppForm
          className="filter-row flex-auto mx-auto kycrequest_search_wrapper"
          ref="form"
        >
          {isAdmin
            ? this.adminfields.map((field, i) => (
                <div
                  className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 input_column"
                  key={i}
                >
                  <AppInputFocus ref="inputname" {...field}></AppInputFocus>
                </div>
              ))
            : this.overallfields.map((field, i) => (
                <div
                  className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 input_column"
                  key={i}
                >
                  <AppInputFocus ref="inputname" {...field}></AppInputFocus>
                </div>
              ))}

          <div className="col-6 col-md-1 col-lg-1 col-xl-1 d-flex align-items-start justify-content-center mw-lg-75">
            <button
              className="btn btn-primary search_btn"
              onClick={() => this.onSearch()}
            >
              <i className="fa fa-search"></i>
              <span>Search</span>
            </button>
          </div>
          <div className="col-6 col-md-1 col-lg-1 col-xl-1 d-flex align-items-start justify-content-center mw-75">
            <button
              type="button"
              className="btn btn-primary clear_filter_btn"
              onClick={(...args) => this.resetSearchForm(...args)}
            >
              <i class="fa-solid fa-filter-circle-xmark"></i>
            </button>
          </div>
        </AppForm>
      </>
    );
  }
}

export default constore(MyRequestSearch);
