import React from 'react';
import { SVGICON } from '../../constant/theme';

function LogoutPage() {

  function onLogout() {
    // app?.props?.apis.apiLogout().then(() => {
    //   app?.props?.apis?.applogout();
    // })
    const resolve = () => {
      console.log('logout', "DESTROY_SESSION");
      app?.props?.store("auth", false);
      app?.props?.store("user", false);
      app?.props?.store("persona", "");
      localStorage.clear();
      window.navigate('/open/login')
    }
    resolve();
    // app?.props.apis.apiLogout().then(res => {
    // }).catch((error) => {
    //   resolve();
    //   console.error(error)
    // });
  }
  return (
    <>
      <button className="dropdown-item ai-icon ms-1 logout-btn" onClick={onLogout}>
        {SVGICON.Logout} {" "}
        <span className="ms-2">Logout </span>
      </button>
    </>
  )
}

export default constore(LogoutPage);