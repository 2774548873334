import React from "react";

const IncomeCard = ({ data }) => {
  return (
    <div className="card profile_card">
      <div className="card-header p-0 border-0">
        <div className="d-flex align-items-center mb-1">
          <img
            src={imagepaths.walletMoneyIcon}
            alt="walletMoneyIcon"
            className="card_icon"
          />
          <h5 className="title ms-2 mb-0">Income Details</h5>
        </div>
        {/* <div className="action_box d-flex align-items-center">
          <button
            className=""
            onClick={(...args) => props.onEdit(...args, "aadhar")}
          >
            <i className="fa fa-pencil"></i>
          </button>
          <div className="separator"></div>
          <i className="fa fa-check check_icon"></i>
        </div> */}
      </div>
      <hr />
      <div className="card-body p-0">
        <div className="content_box_container">
          <ul className="content_box">
            <li>
              <span className="label">Company Name:</span>
              <span>{data?.companyname || "-"}</span>
            </li>
            <li>
              <span className="label">Mode of Salary:</span>
              <span>{data?.salaryMode || "-"}</span>
            </li>
            <li>
              <span className="label">Monthly Salary:</span>
              <span>{data?.monthlySalary?.cur() || "-"}</span>
            </li>
            <li>
              <span className="label">Office Email:</span>
              <span>{data?.officialEmail || "-"}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default constore(IncomeCard);
