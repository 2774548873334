import Viewbankoffer from "../../../jsx/components/Dashboard/BankwiseOffer/viewbankoffer";

const AddBankOfferWrapper = () => {
  return <Viewbankoffer />;
};

export const allowedRoles = [
  _enum.ROUTE_PERSONAS.admin,
  _enum.ROUTE_PERSONAS.connector,
];

export default constore(AddBankOfferWrapper);
