import React, { Component } from "react";

export class TataCapitalSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        limit: 10,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
    };
    this.fields = [
      {
        name: "APPLICANT_NAME",
        label: "Applicant Name",
        placeholder: "Application Name",
      },
      {
        name: "PHONE-NUMBER",
        label: "Mobile No.",
        placeholder: "Mobile No.",
      },
      {
        name: "startDate",
        label: "Start Date",
        type: "date",
        placeholder: "Start Date",
      },
      {
        name: "endDate",
        label: "End Date",
        type: "date",
        placeholder: "End Date",
      },
      {
        name: "retStatus",
        label: "Loan Status",
        type: "select",
        options: _enum.LOAN_STATUS,
        placeholder: "Loan Status",
      },
    ];
  }

  componentDidMount() {
    this.getProductTypes();
  }

  getProductTypes() {
    // this.props.apis.apiGetProductTypes().then((res) => {
    //   let productType = this.fields.find((o) => o.name == "productType");
    //   productType.options = res.options("productType");
    // });
  }

  onSearch = (reset = false) => {
    let data = this.refs.form.form.current.getData();
    console.log("onSearch formData", data, reset);
    data = Object.filter(data, (val) => val);
    this.props.onSearch && this.props.onSearch(data, reset);
  };

  resetSearchForm() {
    this.refs.form.form.current.reset();
    this.onSearch(true);
  }

  render() {
    const adminPersona =
      this.props?.$store?.user?.role === _enum.ROUTE_PERSONAS.admin;
    return (
      <>
        <AppForm
          className="filter-row flex-auto mx-auto justify-content-end"
          ref="form"
        >
          {this.fields.map((field, i) => (
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" key={i}>
              <AppInputFocus ref="inputname" {...field}></AppInputFocus>
            </div>
          ))}
          <div
            className={Object.className({
              "col-3 col-md-1 col-lg-1 col-xl-1 d-flex align-items-start justify-content-center mw-lg-75": true,
              "mw-lg-50": !adminPersona,
            })}
          >
            <button
              className="btn btn-primary search_btn"
              onClick={() => this.onSearch()}
            >
              <i className="fa fa-search"></i>
              <span>Search</span>
            </button>
          </div>
          <div className="col-5 col-md-1 col-lg-1 col-xl-1 d-flex align-items-start justify-content-center mw-75 mw-md-50">
            <button
              type="button"
              className="btn btn-primary clear_filter_btn"
              onClick={(...args) => this.resetSearchForm(...args)}
            >
              <i class="fa-solid fa-filter-circle-xmark"></i>
            </button>
          </div>
        </AppForm>
      </>
    );
  }
}

export default constore(TataCapitalSearch);
