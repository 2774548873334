export default {
    GENDER: [
        { key: 'Male', label: "Male" },
        { key: 'Female', label: "Female" },
    ],
    ROUTE_PERSONAS: {
        admin: "ADMIN",
        manager: "DIGIREP-MANAGER",
        connector: "CONNECTOR",
        accounts: "ACCOUNTS",
        sales: "SALES",
        ops: "OPERATIONS"
    },
    ROUTE_PERSONAS_REVERSE_MAP: {
        "ADMIN": "ADMIN",
        "DIGIREP-MANAGER": "DIGIREP-MANAGER",
        "CONNECTOR": "CONNECTOR",
        "ACCOUNTS": "ACCOUNTS",
        "SALES": "SALES",
        "OPERATIONS": "OPERATIONS",
    },
    STATUS: [
        { key: 'active', label: "Active" },
        { key: 'inactive', label: "In Active" },
    ],
    ADMIN_LEADS_STATUS: [
        { key: 'OPEN', label: "Open" },
        { key: 'INTERESTED', label: "Interested" },
        { key: 'NOT_INTERESTED', label: "Not Interested" },
        { key: 'NOT_REACHABLE', label: "Not Reachable" },
        { key: 'CALL_NOT_ANSWERED', label: "Call Not Answered" },
    ],
    LEADS_ALLOWED_STATUS: [
        "OPEN",
        "INTERESTED",
        "NOT_INTERESTED",
        "NOT_REACHABLE",
        "CALL_NOT_ANSWERED",
    ],
    OPS_LEADS_STATUS: [
        { key: 'OPEN', label: "Open" },
        { key: 'NOT_INTERESTED', label: "Not Interested" },
        { key: 'NOT_ELIGIBLE', label: "Not Eligible" },
        { key: 'PENDING', label: "Pending" },
    ],
    APPLICATION_STATUS: [
        { key: 'disbursed', label: "Disbursed" },
        { key: 'rejected', label: "Rejected" },
        { key: 'logged_in', label: "Logged In", }
    ],
    SOURCES: [
        { key: 'SELF', label: "Self" },
        { key: 'ENQUIRY', label: "Enquiry" },
        { key: 'EMPLOYEE', label: "Employee" },
        { key: 'CUSTOMER', label: "Customer" },
        { key: 'BULK', label: "Bulk" },
    ],
    LOGIN_STATUS: [
        { key: 'pending', label: "Pending" },
    ],
    STATUS_COLOR_MAP: {
        // success
        'interested': 'success',
        'disbursed': 'success',
        'processed': 'success',
        'open': 'success',
        // error
        'not_interested': 'error',
        'call_not_answered': 'error',
        'rejected': 'error',
        'reject': 'error',
        'unpaid': 'error',
        'failed': 'error',
        'call_back': 'error',
        // warning
        'not_reachable': 'warning',
        'pending': 'warning',
        'logged_in': 'warning',
        'applied': 'warning',
        // approved
        'approved': 'approved',
    },
    EMP_ROLE_MAP: {
        "DigiRep": ["Manager DigiRep", "Admin"],
        "Manager DigiRep": ["Admin"],
        "Admin": ["Admin"],
    },
    LOAN_TYPES: [
        { key: 'PERSONAL_LOAN', label: "Personal loan" },
        { key: 'BUSINESS_LOAN', label: 'Business loan' },
        { key: 'HOME_LOAN', label: "Home loan", }
    ],
    LOAN_TYPES_MAP: {
        'PERSONAL_LOAN': 'Personal loan',
        'BUSINESS_LOAN': 'Business loan',
        'HOME_LOAN': 'Home loan',
    },
    INTEREST_TYPES: [
        { key: 'Fixed', label: 'Fixed' },
        { key: 'Floating', label: 'Floating' },
    ],
    LEADS_STATUS: [
        { key: 'interested', label: "Interested" },
        { key: 'not_interested', label: "Not Interested" },
        { key: 'not_reachable', label: "Not Reachable" },
        { key: 'call_not_answered', label: "Call Not Answered" },
    ],
    LOAN_STATUS: [
        { key: 'APPLIED', label: "Applied" },
        { key: 'APPROVED', label: "Approved" },
        { key: 'DISBURSED', label: "Disbursed" },
    ],
    LOAN_STATUS_MAP: {
        applied: "APPLIED",
        approved: "APPROVED",
        disbursed: "DISBURSED",
    },
    LEAD_TITLE_MAP: {
        "lt_finance": "L&T Finance",
        "tata_capital": "Tata Capital",
        "incred_finance": "InCred Finance",
        "zype": "Zype",
    },
    CHANNEL_TYPES: [
        { key: "ONLINE", label: "Online" },
        { key: "OFFLINE", label: "Offline" },
    ],
    // OFFLINE_TITLE_MAP: {
    //     "personal_loan":"Personal Loan",
    // },
    REGEX: {
        email: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        number: /^[0-9]+$/,
        onlystring: /^[A-Za-z\s]*$/,
        pan: /^[A-Z]{5}[0-9]{4}[A-Z]$/,
        degrees: /^[0-9-+.]*$/,
        float: /^[0-9.]*$/,
        ifsc: /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/,
    },
    EMPLOYEE_TYPE: [
        { key: 'Salaried', label: "Salaried" },
        { key: 'Self Employed', label: "Self Employed" },
    ],
    BANKWISE_EMPLOYEE_TYPE: [
        { key: 'SALARIED', label: "Salaried" },
        { key: 'SELF_EMPLOYED', label: "Self Employed" },
    ],
    TENURE: [
        { key: 6, label: "6 Months" },
        { key: 12, label: "12 Months" },
        { key: 18, label: "18 Months" },
        { key: 24, label: "24 Months" },
        { key: 30, label: "30 Months" },
        { key: 36, label: "36 Months" },
        { key: 42, label: "42 Months" },
        { key: 48, label: "48 Months" },
        { key: 54, label: "54 Months" },
        { key: 60, label: "60 Months" },
    ],
    BANK_WISE_TENURE: [
        { key: 6, label: "6 Months" },
        { key: 12, label: "12 Months" },
        { key: 18, label: "18 Months" },
        { key: 24, label: "24 Months" },
        { key: 30, label: "30 Months" },
        { key: 36, label: "36 Months" },
        { key: 42, label: "42 Months" },
        { key: 48, label: "48 Months" },
        { key: 54, label: "54 Months" },
        { key: 60, label: "60 Months" },
        { key: 66, label: "66 Months" },
        { key: 72, label: "72 Months" },
        { key: 78, label: "78 Months" },
        { key: 84, label: "84 Months" },
    ],
    BANK_WISE_PROCESS: [
        { key: 'Digital', label: "Digital" },
        { key: 'Offline', label: "Offline" },

    ],
    PAYOUT_TYPES: [
        { key: "monthly", label: "Monthly" },
        { key: "spot", label: "Spot" },
    ],
    ADMIN_PAYOUT_STATUS: [
        { key: "PENDING", label: "Pending" },
        { key: "PROCESSED", label: "Processed" },
    ],
    PAYOUT_STATUS: [
        { key: "PENDING", label: "Pending" },
        { key: "PROCESSED", label: "Processed" },
        { key: "UNPAID", label: "Unpaid" },
        { key: "PAID", label: "Paid" },
    ],
    ADMIN_KYC_REQUEST_STATUS: [
        { key: "PROCESSED", label: "Processed" },
        { key: "PENDING", label: "Pending" },
        { key: "REJECT", label: "Reject" },
    ],
    ALL_PAYOUT_STATUS: [
        { key: "PENDING", label: "Pending" },
        { key: "PROCESSED", label: "Processed" },
        { key: "UNPAID", label: "Unpaid" },
        { key: "PAID", label: "Paid" },
    ],
    SALARY_RANGE: [
        { key: "Less than Rs.3Lac", label: "Less than Rs.3Lac" },
        { key: "Rs. 3 to 5 Lac", label: "Rs. 3 to 5 Lac" },
        { key: "Rs. 5 to 10 Lac", label: "Rs. 5 to 10 Lac" },
        { key: "Rs. 10 to 15 Lac", label: "Rs. 10 to 15 Lac" },
        { key: "Rs. 15 to 20 Lac", label: "Rs. 15 to 20 Lac" },
        { key: "Greater than 25 Lac", label: "Greater than 25 Lac" },
    ],
    RESIDENCE_OWNERSHIP: [
        { key: "rented", label: "Rented" },
        { key: "owned", label: "Owned" }
    ],
    EDUCATION_QUALIFICATION: [
        { key: "doctor", label: "Doctor" },
        { key: "CA", label: "CA" },
        { key: "master", label: "Masters" },
        { key: "graduate", label: "Graduate" },
        { key: "non-graduate", label: "Non-graduate" },
    ],
    PAYOUT_TYPE: [
        { key: "monthly", label: "Monthly" },
        { key: "spot", label: "Spot" }
    ],
    LEADS_DETAIL: [
        { key: "today", label: "Today" },
        { key: "last_six_month", label: "Last 6 months" }
    ],
    TOTAL_WORK_EXP: [
        { key: "<1 year", label: "<1 year" },
        { key: "1-3 years", label: "1-3 years" },
        { key: "3-7 years", label: "3-7 years" },
        { key: ">7 Years", label: ">7 Years" },
    ],
    ACCOUNT_TYPE: [
        { key: "Current", label: "Current" },
        { key: "Savings", label: "Savings" }
    ],
    PRODUCT_TYPES: {
        'PERSONAL LOAN': 'PERSONAL LOAN',
        'BUSINESS LOAN': 'BUSINESS LOAN',
        'HOME LOAN': 'HOME LOAN',
        'CAR LOAN': 'CAR LOAN',
        'GOLD LOAN': 'GOLD LOAN',
        'INSURANCE LOANS': 'INSURANCE LOANS',
        'CREDIT CARD LOANS': 'CREDIT CARD LOANS',
        'EDUCATION LOANS': 'EDUCATION LOANS',
        'CORPORATE LOANS': 'CORPORATE LOANS'
    },
    RECHARGE_AMOUNTS: [
        { key: 100, label: "₹ 100" },
        { key: 1000, label: "₹ 1,000" },
        // { key: 500, label: "₹ 500" },
        // { key: 'others', label: "Others" },
    ],
    MARITAL_STATUS: [
        { key: "Single", label: "Single" },
        { key: "Married", label: "Married" },
        { key: "Divorced", label: "Divorced" }
    ],
    YES_OR_NO: [
        { key: true, label: "Yes" },
        { key: false, label: "No" },
    ],
    INCRED_MARITAL_STATUS: [
        { key: "Single", label: "Single" },
        { key: "Married", label: "Married" },
        { key: "Divorced", label: "Divorced" },
        { key: "Widowed", label: "Widowed" },
    ],
    INCRED_RESIDENCE_TYPES: [
        { key: "Hostel", label: "Hostel" },
        { key: "Owned", label: "Owned" },
        { key: "Rented", label: "Rented" },
    ],
    INCRED_PRUPOSE_OF_LOANS: [
        { key: "BUSINESS_GROWTH", label: "Business Growth" },
        { key: "BUY_CONSUMER_DURABLES", label: "Buy Consumer Durables" },
        { key: "CHILD_EDUCATION", label: "Child Education" },
        { key: "CHILD_WEDDING", label: "Child Wedding" },
        { key: "EMERGENCY", label: "Emergency" },
        { key: "FOR_VACATION", label: "For Vacation" },
        { key: "HOME RENOVATION", label: "Home Renovation" },
        { key: "INVENTORY_PURCHASE", label: "Inventory Purchase" },
        { key: "LP_OTHERS", label: "LP Others" },
        { key: "MEDICAL_EXPENSES", label: "Medical Expensed" },
        { key: "OWN_WEDDING", label: "Own Wedding" },
        { key: "PRE_PAYMENT", label: "Pre Payment" },
    ],
    INCRED_GENDERS: [
        { key: 'MALE', label: "Male" },
        { key: 'FEMALE', label: "Female" },
    ],
    INCRED_EMPLOYMENT_TYPES: [
        { key: "SALARIED", label: "Salaried" },
        { key: "SELFEMP", label: "Self employed" },
        { key: "NOTEARNING", label: "Not Working" },
    ],
    INCRED_MODE_OF_SALARY: [
        { key: "cash", label: "Cash" },
        { key: "banktransfer", label: "Bank Transfer" },
    ],

}