import React, { useState } from "react";

const PersonalDetails = (props) => {
  const [fields, setFields] = useState(props?.fields);

  return (
    <>
      <AppForm onSubmit={props.onPersonalDetailsSubmit}>
        {fields?.map((field, i) => {
          return (
            <div
              className={`col-12 col-md-${field?.customcolspan || 4} col-xl-3`}
              key={i}
            >
              <AppInput
                name={field?.name}
                label={field?.label}
                type={field?.type}
                defaultValue={field?.defaultValue || ""}
                options={field?.options || []}
              ></AppInput>
            </div>
          );
        })}
        <div className="col-12 col-md-12 mt-4">
          <label htmlFor="acknowledgement" className="cursor-pointer">
            <input
              className="form-check-input mt-0 me-2"
              //   onChange={(e) => handleAcknowledge(e)}
              type="checkbox"
              name="acknowledgement"
            />
            By clicking on submit I/We have read & understood the
            <a href="#" className="ms-1 table-primary-text">
              “Terms & Conditions”
            </a>
            & confirm to the
            <a href="#" className="ms-1 table-primary-text">
              “declaration”
            </a>
            provided in the link
            <a href="#" className="ms-1 table-primary-text">
              click here
            </a>
          </label>
        </div>
        <div className="nav_container">
          <button
            className="formdata-cancel-btn btn"
            onClick={(...args) => props.hideStepper(...args)}
          >
            Back
          </button>
          <button
            type="submit"
            className="btn btn-primary ms-3"
            onClick={(...args) => props.onPersonalDetailsSubmit}
          >
            Save & Next
          </button>
        </div>
      </AppForm>
    </>
  );
};

export default constore(PersonalDetails);
