import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const NavHader = (props) => {
  const sideMenu = props.$store.sideMenu;
  const handleToogle = () => {
    props.store("sideMenu", !sideMenu);
    console.log("handleToogle");
    // dispatch(navtoggle());
  };
  return (
    <div className="nav-header">
      <Link to="/app/home" className="brand-logo">
        <div className="loginWhiteBg_img_box">
          <img
            src={imagepaths.loginWhiteBg}
            className="loginWhiteBg_img img-fluid"
            alt="loginWhiteBg"
            style={{ width: "100px" }}
          />
          <p className="tag_line">Starpowerz Digital Technologies Pvt. Ltd.</p>
        </div>
        <img
          src={imagepaths.FavIcon}
          className="logoHome_img img-fluid"
          alt="logoHome"
          width="75px"
          height="25px"
        />
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          handleToogle();
        }}
      >
        <div className={`hamburger ${sideMenu ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default constore(NavHader);
