import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Features = (props) => {
  const isEditMode = props?.urlparams?.params?.bankId?.length > 0;
  const [errorMessage, setErrorMessage] = useState({ name: "", message: "" });
  const [editdata, setEditdata] = useState({});

  const handleSubmit = (e, data) => {
    let payload = {
      ...data,
      bankId: props?.selectedBank?.bankId,
      bankName: props?.selectedBank?.bankName,
      tenure: Number(data?.tenure),
      minLoanAmount: Number(data?.minLoanAmount),
      maxLoanAmount: Number(data?.maxLoanAmount),
      lowestROI: Number(data?.lowestROI),
      processingFee: Number(data?.processingFee),
    };
    console.log({ payload });

    if (isEditMode) {
      props.apis
        .apiUpdateBankOffer(payload, {
          bankId: props?.urlparams?.params?.bankId,
        })
        .then((res) => {
          if (res) {
            props.changeTab(e, "eligibility");
          }
        });
    } else {
      props.apis.apiAddBankOffer(payload).then((res) => {
        if (res) {
          props.changeTab(e, "eligibility");
        }
      });
    }
  };

  const handleMaxLoan = (e) => {
    let maxloanvalue = e.target.value;

    let minloanvalue = document.querySelector("[name='minLoanAmount']").value;

    if (minloanvalue && minloanvalue.length > 0) {
      // console.log(Number(maxloanvalue) < Number(minloanvalue));

      if (Number(maxloanvalue) <= Number(minloanvalue)) {
        setErrorMessage({
          name: "maxLoanAmount",
          message: "Max loan amount should be greater than min loan amount",
        });
      } else {
        setErrorMessage({ name: "", message: "" });
      }
    }
  };

  useEffect(() => {
    if (isEditMode) {
      let payload = {
        bankId: props?.urlparams?.params?.bankId,
      };
      props.apis.apiGetBankOfferById(payload).then((res) => {
        if (res) {
          setEditdata(res);
        }
      });
    }
  }, []);

  return (
    <>
      <AppForm className="container" onSubmit={handleSubmit}>
        <div className="col-12 col-md-4">
          <AppInput
            name="minLoanAmount"
            label="Min. Loan Amount"
            type="number"
            defaultValue={editdata?.minLoanAmount || ""}
            required={true}
          ></AppInput>
        </div>
        <div className="col-12 col-md-4">
          <AppInput
            name="maxLoanAmount"
            label="Max. Loan Amount"
            type="number"
            onChange={(...args) => handleMaxLoan(...args)}
            defaultValue={editdata?.maxLoanAmount || ""}
            required="true"
          ></AppInput>
          {errorMessage.name === "maxLoanAmount" && (
            <p className="text-danger">{errorMessage.message}</p>
          )}
        </div>

        <div className="col-12 col-md-4">
          <AppInput
            name="lowestROI"
            label="ROI Starting From"
            type="number"
            step=".01"
            defaultValue={editdata?.lowestROI || ""}
            required="true"
          ></AppInput>
        </div>

        <div className="col-12 col-md-4">
          <AppInput
            name="processingFee"
            label="Processing Fee"
            type="number"
            step=".01"
            defaultValue={editdata?.processingFee || ""}
            required="true"
          ></AppInput>
        </div>

        <div className="col-12 col-md-4">
          <AppInput
            name="tenure"
            label="Tenure"
            type="select"
            options={_enum.BANK_WISE_TENURE}
            defaultValue={editdata?.tenure || ""}
            required="true"
          ></AppInput>
        </div>

        <div className="col-12 col-md-4">
          <AppInput
            name="process"
            label="Process"
            type="select"
            options={_enum.BANK_WISE_PROCESS}
            defaultValue={editdata?.process || ""}
            required="true"
          ></AppInput>
        </div>

        <div className="nav_container text-end mt-4">
          <Link
            to={"/app/masterdata?activetab=bankwiseoffer"}
            type="button"
            className="formdata-cancel-btn btn"
          >
            Cancel
          </Link>
          <button
            type="submit"
            className="btn btn-primary ms-3"
            disabled={errorMessage.message.length > 0 ? true : false}
          >
            {isEditMode ? "Update" : "Submit"}
          </button>
        </div>
      </AppForm>
    </>
  );
};

export default constore(Features);
