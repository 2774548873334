import React from "react";

const AccountInfoCard = (props) => {
  return (
    <div className="card profile_card">
      <div className="card-header py-0 border-0">
        <div className="d-flex align-items-start">
          <img src={imagepaths.accountIcon} alt="aadhar" />
          <h5 className="title ms-2">Account Info</h5>
        </div>
        <div className="action_box d-flex align-items-center">
          <button
            className=""
            onClick={(...args) => props.onEdit(...args, "accountinfo")}
          >
            <i className="fa fa-pencil"></i>
          </button>
          <div className="separator"></div>
          <i className="fa fa-check check_icon"></i>
        </div>
      </div>
      <hr />
      <div className="card-body py-0">
        <h5>Account holder name</h5>
        <p className="text-primary">
          {props?.accountinfodata?.accountHolderName || "-"}
        </p>
        <div className="row">
          <div className="col">
            <h5>Account No.</h5>
            <p className="text-primary">
              {props?.accountinfodata?.accountNumber || "-"}
            </p>
          </div>
          <div className="col">
            <h5>Account Type</h5>
            <p className="text-primary">
              {props?.accountinfodata?.accountType || "-"}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h5>Bank name</h5>
            <p className="text-primary">
              {props?.accountinfodata?.accountHolderBankName || "-"}
            </p>
          </div>
          <div className="col">
            <h5>IFSC Code</h5>
            <p className="text-primary">
              {props?.accountinfodata?.accountHolderBankIFSC || "-"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default constore(AccountInfoCard);
