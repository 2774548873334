/// Menu
import React, { useContext, useEffect, useReducer, useState } from "react";
import Collapse from 'react-bootstrap/Collapse';
import { Link } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { AdminMenuList } from './Menulist/AdminMenu';
import { ConnectorMenuList } from "./Menulist/ConnectorMenu";
import { SalesMenuList } from "./Menulist/SalesMenu";
import { OpsMenuList } from "./Menulist/OpsMenu";
import { ManagerMenuList } from "./Menulist/ManagerMenu";


const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
}

const SideBar = (props) => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);

  const [state, setState] = useReducer(reducer, initialState);
  const [sidemenuData, setSidemenuData] = useState([]);

  // Function to find and update the title based on ID
  function updateTitleById(array, id, newTitle) {
    const updatedArray = array.map(obj => {
      if (obj.id === id) {
        return { ...obj, title: newTitle };
      }
      return obj;
    });
    return updatedArray;
  }

  // Function to find and update titles based on an object mapping IDs to titles
  function updateColumns(col, idMap) {
    // 1.filter out the columns
    let targetId = "id";
    // let filteredColumns = columns.only(col, targetId);

    // 2.change columns title
    const updatedArray = col.map((obj) => {
      if (idMap.hasOwnProperty(obj[targetId])) {
        return { ...obj, title: idMap[obj[targetId]] };
      }
      return obj;
    });
    return updatedArray;
  }

  useEffect(() => {
    const userRole = props?.$store?.user?.role;

    if (userRole === _enum.ROUTE_PERSONAS.connector) {
      setSidemenuData(ConnectorMenuList);
    } else if (userRole === _enum.ROUTE_PERSONAS.manager) {
      setSidemenuData(ManagerMenuList);
    }
    else {
      if (userRole !== _enum.ROUTE_PERSONAS.admin) {

        let updatedArray;
        if (userRole === _enum.ROUTE_PERSONAS.sales) {
          updatedArray = SalesMenuList
        } else if (userRole === _enum.ROUTE_PERSONAS.ops) {
          updatedArray = OpsMenuList
        }
        else {
          updatedArray = AdminMenuList
        }
        setSidemenuData(updatedArray);
      } else {
        setSidemenuData(AdminMenuList);
      }
    }

  }, []);

  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true)
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll]
  )


  const handleMenuActive = status => {
    console.log({ handleMenuActive: status });
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  }
  const handleSubmenuActive = (status) => {
    console.log({ handleSubmenuActive: status });

    setState({ activeSubmenu: status })
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" })

    }

  }

  const checkActive = (data, param) => {
    // console.log({ data, param });
    let bool;
    if (param === 'outer' && data?.content && data?.content?.length > 0) {
      let ids = data.id
      bool = ids.includes(getPath())
    } else if (param === 'outer') {
      let ids = data.id
      bool = ids.includes(getPath())
    }
    else if (param === 'inner' && data?.id && data?.id?.length > 0) {
      let ids = data.id
      bool = ids.includes(getPath())
    } else {
      (getPath() === data?.id?.replace(/\s/g, '').toLowerCase()) ? bool = true : bool = false;
    }
    return bool;
  };


  const rbiPolicy = () => {
    window.open('https://sachet.rbi.org.in/', '_blank')
  }
  // Menu dropdown list End

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  const getPath = () => {
    // return window.location.pathname.replace(/\s/g, "").replace(/[\s/]/g, "").toLowerCase()
    return window.location.pathname.replace("/app/", "").toLowerCase()
  };
  return (
    <div
      className={`deznav  border-right ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? hideOnScroll > 120
          ? "fixed"
          : ""
        : ""
        }`}
    >
      <div className="deznav-scroll sidebar-content">
        <ul className="metismenu" id="menu">
          {sidemenuData.map((data, index) => {
            let menuClass = data.classChange;
            if (menuClass === "menu-title") {
              return (
                <li className={menuClass} key={index} >{data.title}</li>
              )
            } else if (menuClass === "menu-popup") {
              return (
                <li className={menuClass} key={index}>
                  <a href="#" data-bs-toggle="modal" data-bs-target={`#${data.id}`} >
                    <div className="menu-icon">
                      <img src={data.iconStyle} className={`menu-icon-img ${data.imgclass}`} width="15px" alt="" />
                      <img src={data.iconActive} className="menu-icon-img active" width="15px" alt="" />
                    </div>
                    <span className="hide-title sidebar-title">{data.title}</span>
                  </a>
                </li>
              )
            } else if (menuClass === "menu-link") {
              return (
                <li className={menuClass} key={index} onClick={() => rbiPolicy()}>
                  <a href="#">
                    <div className="menu-icon">
                      <img src={data.iconStyle} className={`menu-icon-img ${data.imgclass}`} width="15px" alt="" />
                      <img src={data.iconActive} className="menu-icon-img active" width="15px" alt="" />
                    </div>
                    <span className="hide-title sidebar-title">{data.title}</span>
                  </a>
                </li>
              )
            } else {
              return (
                <li
                  className={`${checkActive(data, 'outer') ? 'mm-active' : ''}`}
                  key={index}
                >

                  {data.content && data.content.length > 0 ?
                    <>
                      <Link to={"#"}
                        className="has-arrow dropdown_link"
                        onClick={() => { handleMenuActive(data.id) }}
                      >
                        <div>
                          <div className="menu-icon">
                            <img src={data.iconStyle} className={`menu-icon-img ${data.imgclass}`} width="15px" alt="" />
                            <img src={data.iconActive} className="menu-icon-img active" width="15px" alt="" />
                          </div>
                          <span className="nav-text sidebar-title">{data.title}
                            {
                              data.update && data.update.length > 0 ?
                                <span className="badge badge-xs badge-danger ms-2">{data.update}</span>
                                :
                                ''
                            }
                          </span>
                        </div>
                        <i className={`fa fa-angle-${state.active === data.id ? 'up' : 'down'}`}></i>
                      </Link>
                      <Collapse in={state.active === data.id ? true : false}>
                        <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""} dropdown_ul`}>
                          {data.content && data.content.map((innerdata, index) => {
                            if (innerdata?.classChange === "menu-popup") {
                              return (
                                <li
                                  className={`${innerdata?.id} ${checkActive(innerdata, 'inner') ? 'mm-active' : ''} menu-popup`}
                                  key={index}>
                                  <a href="#" className="d-flex" data-bs-toggle="modal" data-bs-target={`#${innerdata.id}`} >
                                    <div className="menu-icon">
                                      <img src={innerdata.iconStyle} className={`nested-menu-icon-img`} width="15px" alt="" />
                                      <img src={innerdata.iconActive} className="nested-menu-icon-img active" width="15px" alt="" />
                                    </div>
                                    <span className="hide-title sidebar-title">{innerdata.title}</span>
                                  </a>
                                </li>
                              )
                            } else if (innerdata?.classChange === "menu-link") {
                              return (
                                <li
                                  className={`${innerdata?.id} ${checkActive(innerdata, 'inner') ? 'mm-active' : ''} menu-link`}
                                  key={index} onClick={() => rbiPolicy()}>
                                  <a href="#" className="d-flex">
                                    <div className="menu-icon">
                                      <img src={innerdata.iconStyle} className={`nested-menu-icon-img`} width="15px" alt="" />
                                      <img src={innerdata.iconActive} className="nested-menu-icon-img active" width="15px" alt="" />
                                    </div>
                                    <span className="hide-title sidebar-title">{innerdata.title}</span>
                                  </a>
                                </li>
                              )
                            } else {
                              return (
                                <li key={index}
                                  className={`${innerdata?.id} ${checkActive(innerdata, 'inner') ? 'mm-active' : ''}`}
                                  onClick={() => { handleSubmenuActive(innerdata.id) }}
                                >
                                  {innerdata.content && innerdata.content.length > 0 ?
                                    <>
                                      <Link to={innerdata.to} className="d-flex" >
                                        {innerdata.title}
                                      </Link>
                                    </>
                                    :
                                    <Link to={innerdata.to} className="d-flex" >
                                      <div className="menu-icon level_2">
                                        <img src={innerdata.iconStyle} className={`menu-icon-img ${innerdata.imgclass}`} width="15px" alt="" />
                                        <img src={innerdata.iconActive} className="menu-icon-img active" width="15px" alt="" />
                                      </div>
                                      <span className="nav-text sidebar-title">
                                        {innerdata.title}
                                      </span>
                                    </Link>
                                  }

                                </li>
                              )
                            }
                          })}
                        </ul>
                      </Collapse>
                    </>
                    :
                    <Link to={data.to} onClick={() => { handleMenuActive(data.id) }}>
                      <div className={`menu-icon ${data.imgclass}`}>
                        <img src={data.iconStyle} className={`menu-icon-img`} width="15px" alt="" />
                        <img src={data.iconActive} className="menu-icon-img active" width="15px" alt="" />
                      </div>
                      {" "}<span className="nav-text sidebar-title">{data.title}</span>
                      {
                        data.update && data.update.length > 0 ?
                          <span className="badge badge-xs badge-danger ms-2">{data.update}</span>
                          :
                          ''
                      }
                    </Link>
                  }

                </li>
              )
            }
          })}
        </ul>
      </div>
    </div>
  );
};

export default constore(SideBar);