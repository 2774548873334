import React, { Component } from "react";
import { Divider, Radio, Table } from "antd";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

export class AppTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          "selectedRows: ",
          selectedRows
        );
      },
    };
  }

  componentDidMount() {
    window.AppTableComponent = this;
  }

  render() {
    let { props } = this;
    const itemRender = function (current, type, originalElement) {
      // console.log("props: ", props);
      if (type === "prev") {
        return (
          <div
            onClick={() => props.onPrev && props.onPrev()}
            className={Object.className({
              "nav-prev": true,
              "pe-none": props.page <= 0,
            })}
          >
            <i className="la la-angle-left"></i>
          </div>
        );
      } else if (type === "next") {
        return (
          <div
            onClick={() => props.onNext && props.onNext()}
            className={Object.className({
              "nav-next": true,
              "pe-none": props.page === props.pageCount,
            })}
          >
            <i className="la la-angle-right"></i>
          </div>
        );
      } else if (type === "page") {
        return originalElement;
      }
    };
    const getColumns = function () {
      if (props.columns instanceof Array && props.columns.length) {
        return props.columns;
      } else if (data.length) {
        return Object.keys(data[0]).map((key) => ({
          type: "text",
          title: key.toTitleCase(),
          dataIndex: key,
          key: key,
        }));
      } else {
        return [];
      }
    };
    let {
      data = [],
      columns = [],
      attrs = {},
      editTraget = "#edit_employee",
      deleteTarget = "#delete_employee",
      viewTarget = "#view_employee",
      copyTarget = "#copy_employee",
      disableTarget = "#disable_datacollection",
      targetType = "tap",
      copyLinkAction = () => "",
      viewAction = () => "",
      editAction = () => "",
      deleteAction = () => "",
      applyLinkAction = () => "",
      processLinkAction = () => "",
      rejectLinkAction = () => "",
      editable = true,
      deletable = true,
      viewable = true,
      applyLink = false,
      processLink = false,
      rejectLink = false,
      copyable = false,
      reorder = false,
      checkbox = false,
      renderAction = () => true,
      actionList = [],
    } = props;
    columns = getColumns();
    let editTragetFun =
      typeof editTraget == "function" ? editTraget : () => editTraget;
    let deleteTargetFun =
      typeof deleteTarget == "function" ? deleteTarget : () => deleteTarget;
    let viewTragetFun =
      typeof viewTarget == "function" ? viewTarget : () => viewTarget;
    let copyTargetFun =
      typeof copyTarget == "function" ? copyTarget : () => copyTarget;
    let disableTargetFun =
      typeof disableTarget == "function" ? disableTarget : () => disableTarget;
    let visiblecolumns = columns.filter((o) => o.display !== false);
    let incolumns = data
      .maxObjKeys()
      .map((key) => ({
        title: key.toTitleCase(),
        dataIndex: key,
        key: key,
        sorter: (a, b) => {
          let isString = typeof a[key] == "string";
          let isNumber = typeof a[key] == "number";
          let flag = isNumber && a[key] - b[key];
          flag =
            flag === false ? isString && a[key]?.length - b[key]?.length : flag;
          //console.log({ a, b, key, flag, isNumber, isString });
          return flag;
        },
        display: !reorder,
        ...(columns.find((o) => o.dataIndex == key) || {}),
      }))
      .filter((o) => o.display !== false);
    let extra = columns
      .filter((o) => !incolumns.map((o) => o.dataIndex).includes(o.dataIndex))
      .filter((o) => o.display !== false);
    extra.map((o) => incolumns.push(o));

    if (reorder) {
      let sortedcolumns = visiblecolumns.reduce((o, n) => {
        let obj = incolumns.find((o) => o.dataIndex == n.dataIndex);
        obj &&
          o.push({
            ...obj,
            ...n,
          });
        return o;
      }, []);
      let unsorted = incolumns.filter(
        (o) => !sortedcolumns.map((o) => o.dataIndex).includes(o.dataIndex)
      );
      sortedcolumns = sortedcolumns.concat(unsorted);
      incolumns = sortedcolumns;
    }

    if (
      editable ||
      deletable ||
      viewable ||
      copyable ||
      applyLink ||
      processLink ||
      rejectLink
    ) {
      incolumns.push({
        title: "Action",
        render: (text, record) => (
          <Dropdown>
            {renderAction(record) && (
              <Dropdown.Toggle
                variant="secondary"
                className="light sharp i-false pt-2 pb-1 apptable_action_dropdown"
              >
                <i className="fa fa-ellipsis-v fs-4"></i>
              </Dropdown.Toggle>
            )}

            <Dropdown.Menu>
              {applyLink && (
                <>
                  {/* {targetType === "modal" && (
                    <a
                      className="dropdown-item"
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target={copyTargetFun(record)}
                      onClick={() => copyLinkAction()}
                    >
                      <i className="fa fa-copy" /> Copy Link
                    </a>
                  )} */}
                  {targetType === "tap" && (
                    <Dropdown.Item
                      className="dropdown-item cursor-pointer"
                      onClick={() => applyLinkAction(record)}
                    >
                      <i className="fa fa-pen" />
                      Apply
                    </Dropdown.Item>
                  )}
                </>
              )}
              {processLink && (
                <>
                  {targetType === "tap" && (
                    <Dropdown.Item
                      className="dropdown-item cursor-pointer"
                      onClick={() => processLinkAction(record)}
                    >
                      <i className="fa fa-check" />
                      Process
                    </Dropdown.Item>
                  )}
                </>
              )}
              {rejectLink && (
                <>
                  {targetType === "tap" && (
                    <Dropdown.Item
                      className="dropdown-item cursor-pointer"
                      onClick={() => rejectLinkAction(record)}
                    >
                      <i className="fa fa-xmark-circle" />
                      Reject
                    </Dropdown.Item>
                  )}
                </>
              )}
              {copyable && (
                <>
                  {/* {targetType === "modal" && (
                    <a
                      className="dropdown-item"
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target={copyTargetFun(record)}
                      onClick={() => copyLinkAction()}
                    >
                      <i className="fa fa-copy" /> Copy Link
                    </a>
                  )} */}
                  {targetType === "tap" && (
                    <Dropdown.Item
                      className="dropdown-item cursor-pointer"
                      onClick={() => copyLinkAction(record)}
                    >
                      <i className="fa fa-copy" />
                      Copy Link
                    </Dropdown.Item>
                  )}
                </>
              )}
              {viewable && (
                <>
                  {/* {targetType == "modal" && (
                    <a
                      className="dropdown-item"
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target={viewTragetFun(record)}
                      onClick={() => viewAction()}
                    >
                      <i className="fa fa-eye" /> View
                    </a>
                  )} */}
                  {targetType == "tap" && (
                    <Dropdown.Item
                      className="dropdown-item cursor-pointer"
                      onClick={() => viewAction(record)}
                    >
                      <i className="fa fa-eye" />
                      <span>View</span>
                    </Dropdown.Item>
                  )}
                </>
              )}
              {editable && (
                <>
                  {/* {targetType == "link" && (
                    <Link to={editTragetFun(record)} className="dropdown-item">
                      <i className="fa fa-pencil" /> Edit
                    </Link>
                  )}
                  {targetType == "modal" && (
                    <a
                      className="dropdown-item"
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target={editTragetFun(record)}
                      onClick={() => editAction()}
                    >
                      <i className="fa fa-pencil" /> Edit
                    </a>
                  )} */}
                  {targetType == "tap" && (
                    <Dropdown.Item
                      className="dropdown-item cursor-pointer"
                      onClick={() => editAction(record)}
                    >
                      <i className="fa fa-pencil" />
                      <span>Edit</span>
                    </Dropdown.Item>
                  )}
                </>
              )}
              {deletable && (
                <>
                  {/* {targetType == "modal" && (
                    <a
                      className="dropdown-item"
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target={deleteTargetFun(record)}
                      onClick={() => deleteAction()}
                    >
                      <i className="fa-regular fa-trash-can-o" /> Delete
                    </a>
                  )} */}
                  {targetType == "tap" && (
                    <Dropdown.Item
                      className="dropdown-item cursor-pointer"
                      onClick={() => deleteAction(record)}
                    >
                      <i className="fa-regular fa-trash-can" />
                      <span>Delete</span>
                    </Dropdown.Item>
                  )}
                </>
              )}
            </Dropdown.Menu>
          </Dropdown>
        ),
      });
    }

    return (
      <div className="table-responsive AppTable-Component">
        <Table
          className="table-striped"
          // rowSelection={
          //   checkbox
          //     ? {
          //         type: "checkbox",
          //         // ...this.rowSelection,
          //         onChange: (selectedRowKeys, selectedRows) => {
          //           console.log(
          //             `selectedRowKeys: ${selectedRowKeys}`,
          //             "selectedRows: ",
          //             selectedRows
          //           );
          //         },
          //         onSelectAll: (selected, selectedRows, changeRows) => {
          //           console.log({ selected, selectedRows, changeRows });
          //         },
          //       }
          //     : false
          // }
          rowSelection={props.rowSelection}
          pagination={
            this.props.showPagination === false
              ? false
              : {
                  total: props.total,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  itemRender: itemRender,
                  // defaultPageSize: props.pageSize || data?.length,
                  showSizeChanger: true,
                }
          }
          style={{ overflowX: "auto" }}
          columns={incolumns}
          // bordered
          dataSource={data}
          rowKey={(record) => record.id || record.leadId}
          onChange={props.onChange}
          onRow={props.onRowClick}
          loading={props.loading || false}
          {...attrs}
        />
      </div>
    );
  }
}

export default AppTable;
