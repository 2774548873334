export default {

    apiGetRegisteredUsers(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().CUSTOMER_INFORMATION, payload)
            .exec().log()
            .mapresults((response) => {
                return {
                    data: response?.data,
                    total: response?.count
                }
            })
            .get();
    },

    apiUploadDocs(data) {
        return this.request(this.api().PROFILE_UPLOAD_DOCS, data)
            .exec().log().mapresults((res) => {
                return res.data
            }).get();
    },

    apiUploadCustomerDocs(data) {
        return this.request(this.api().CUSTOMER_UPLOAD_DOCS, data)
            .exec().log().mapresults((res) => {
                return res.data
            }).get();
    },

    apiSendAgreementForEsign(urldata = mixins.params()) {

        return this.request(this.api().SEND_AGREEMENT_FOR_ESIGN)
            .urltransform(urldata)
            .exec().log().get();
    },
}