import React, { useEffect, useState } from "react";
import PayoutModal from "../PayoutModal";
import moment from "moment";

const PaymentDetails = (props) => {
  const isAdmin = props?.$store?.user?.role === _enum.ROUTE_PERSONAS.admin;
  const [isFetching, setIsFetching] = useState(true);
  const [search, setSearch] = useState({
    page: 1,
    // limit: 10,
  });
  const [rowcount, setRowcount] = useState(0);
  const [data, setData] = useState([]);
  const columns = [
    {
      dataIndex: "connectorName",
      title: "Digi Team Member Name",
      render: (text, record) => {
        return (
          <a
            href="#"
            onClick={(e) => handleRowClick(record)}
            className="table-primary-text link_btn"
          >
            {record?.name || "-"}
          </a>
        );
      },
      display: isAdmin ? true : false,
    },
    {
      dataIndex: "connectorName",
      title: "Name",
      render: (text, record) => {
        return (
          <a
            href="#"
            onClick={(e) => handleRowClick(record)}
            className="table-primary-text link_btn"
          >
            {record?.name || "-"}
          </a>
        );
      },
      display: isAdmin ? false : true,
    },
    {
      dataIndex: "leadIds",
      title: "No. of Leads",
      render: (text, record) => {
        return <>{record?.leadIds?.length || "-"}</>;
      },
    },
    {
      dataIndex: "digirepPayoutAmt",
      title: "Total Payout Amt.",
      render: (text) => {
        return <>{text?.cur() || "-"}</>;
      },
    },
    {
      dataIndex: "disbursedAmt",
      title: "Total Disbursed Amt.",
      render: (text) => {
        return <>{text?.cur() || "-"}</>;
      },
    },
    {
      dataIndex: "tdsAmt",
      title: "TDS",
      render: (text, record) => {
        return <>{text?.cur() || "-"}</>;
      },
    },
    {
      dataIndex: "netAmt",
      title: "Net Payout Amount",
      render: (text) => {
        return <>{text?.cur() || "-"}</>;
      },
    },
    {
      dataIndex: "createdAt",
      title: "Payment Date",
      render: (text, record) => {
        let date = new Date(record?.createdAt).dateToDDMMYYYY(
          new Date(record?.createdAt)
        );
        return date || "-";
      },
    },
    {
      dataIndex: "accountNumber",
      title: "Account No.",
    },
    {
      dataIndex: "accountHolderBankName",
      title: "Bank Name",
    },
    {
      dataIndex: "accountHolderBankIFSC",
      title: "IFSC Code",
    },
  ];
  //
  const [showinnerTable, setShowinnerTable] = useState(false);
  const [innerData, setInnerData] = useState([]);
  const [innerRowCount, setInnerRowCount] = useState(0);
  const innerColumns = [
    {
      dataIndex: "applicantName",
      title: "Customer Name",
      render: (text) => {
        return <span className="table-primary-text">{text || "-"}</span>;
      },
    },
    {
      dataIndex: "applicationNo",
      title: "Application No.",
      render: (text) => {
        return <>{text || "-"}</>;
      },
    },
    {
      dataIndex: "bankName",
      title: "Bank Name",
      render: (text, record) => {
        return <>{record?.bankName || "-"}</>;
      },
    },
    {
      dataIndex: "disbursedAmt",
      title: "Disb. Amt.",
      render: (text) => {
        return <>{text?.cur() || "-"}</>;
      },
    },
    {
      dataIndex: "digirepPayoutAmt",
      title: "Digi Team Member Payout Amt.",
      width: "12%",
      render: (text) => {
        return <>{text?.cur() || "-"}</>;
      },
    },
    {
      dataIndex: "netAmt",
      title: "Net Payout Amt.",
      render: (text) => {
        return <>{text?.cur() || "-"}</>;
      },
    },
    {
      dataIndex: "tdsAmt",
      title: "TDS Amt.",
      render: (text) => {
        return <>{text?.cur() || "-"}</>;
      },
    },
    {
      dataIndex: "paymentDate",
      title: "Payment Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
  ];

  // ----------------------------------------------------------------
  //   PAYOUT MODAL

  const [modalFields, setModalFields] = useState([
    {
      label: "Loan Info:",
      name: "loanInfo",
      type: "textarea",
      customcolspan: 12,
      outerclass: "form-title",
    },
    {
      label: "Digi Team Member Name",
      name: "connectorName",
      type: "text",
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Digi Team Member Mobile No.",
      name: "mobileNo",
      type: "number",
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Customer Name (as per PAN)",
      name: "applicantName",
      type: "text",
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Mobile No.",
      name: "mobileNo",
      type: "number",
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Product Type",
      name: "productType",
      type: "text",
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Bank Name",
      name: "bankName",
      type: "text",
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Loan Amount",
      name: "loanAmt",
      type: "number",
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Loan Status",
      name: "loanStatus",
      type: "text",
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Application No. ",
      name: "applicationNo",
      type: "text",
      placeholder: "Please Enter",
    },
    {
      label: "Application Date ",
      name: "createdAt",
      type: "date",
      disabledDate: (current) => {
        return current && current < moment().startOf("day");
      },
      placeholder: "Please Enter",
    },
    {
      label: "Disb. Amount",
      name: "disbursedAmt",
      type: "number",
      placeholder: "Please Enter",
    },
    {
      label: "Disb. Date",
      name: "disbursedDate",
      type: "date",
      disabledDate: (current) => {
        return current && current < moment().startOf("day");
      },
      placeholder: "Please Enter",
    },
    {
      label: "Payout Type",
      name: "payoutType",
      type: "select",
      options: _enum.PAYOUT_TYPES,
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "LAN",
      name: "lan",
      type: "number",
      placeholder: "Please Enter",
    },
    // PAYOUT INFO FIELDS
    {
      label: "Digi Team Member Payout %",
      name: "digirepPayoutPct",
      type: "number",
      step: ".01",
      placeholder: "Please Enter",
      onChange: (...args) => handleDigirepPayoutPct(...args),
    },
    {
      label: "Digi Team Member Payout Amt.",
      name: "digirepPayoutAmt",
      type: "number",
      step: ".01",
      placeholder: "Please Enter",
    },
    {
      label: "TDS %",
      name: "tdsPct",
      type: "number",
      step: ".01",
      placeholder: "Please Enter",
      onChange: (...args) => handleTDSPct(...args),
    },
    {
      label: "TDS Amt.",
      name: "tdsAmt",
      type: "number",
      step: ".01",
      placeholder: "Please Enter",
    },
    {
      label: "Net Payout Amt.",
      name: "netAmt",
      type: "number",
      step: ".01",
      placeholder: "Please Enter",
    },
    {
      label: "Payment Status",
      name: "paymentStatus",
      type: "select",
      onChange: (...args) => handlePaymentStatus(...args),
      options: isAdmin ? _enum.ADMIN_PAYOUT_STATUS : _enum.PAYOUT_STATUS,
      placeholder: "Please Enter",
    },
    {
      label: "Bank Payout %",
      name: "bankPayoutPct",
      type: "number",
      step: ".01",
      placeholder: "Please Enter",
      onChange: (...args) => handleBankPayoutPct(...args),
    },
    {
      label: "Bank Payout Amt.",
      name: "bankPayoutAmt",
      type: "number",
      step: ".01",
      placeholder: "Please Enter",
    },
    {
      label: "Payment Date",
      name: "paymentDate",
      type: "date",
      disabledDate: (current) => {
        return current && current < moment().startOf("day");
      },
      placeholder: "Please Enter",
    },
    {
      label: "Bank Payout Received Date",
      name: "bankPayoutReceiveDate",
      type: "date",
      placeholder: "Please Enter",
    },
    {
      label: "Remarks",
      name: "remarks",
      type: "textarea",
      customcolspan: 12,
    },
    {
      label: "Payout Info:",
      name: "payoutInfo",
      type: "textarea",
      customcolspan: 12,
      outerclass: "form-title my-3",
    },
  ]);
  const [editData, setEditData] = useState({});
  const [editmode, setEditmode] = useState(false);
  const [viewmode, setViewmode] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [popupfields, setPopupfields] = useState({});
  const [popupdata, setPopupdata] = useState({});
  const [disabledFields, setDisabledFields] = useState([]);
  // ----------------------------------------------------------------
  const handleRowClick = (record) => {
    console.log({ handleRowClick: record });
    setShowinnerTable(true);
    getInnerData(record?.digiRepPaidAppId);
  };

  const getPaymentDetails = (params = {}, reset) => {
    let payload = {
      ...search,
    };
    // if (reset) {
    //   payload = {
    //     ...search,
    //     ...params,
    //   };
    // } else {
    //   payload = {
    //     ...search,
    //     ...params,
    //   };
    // }
    props.apis.apiGetPaymentInfo(payload).then(({ data, count }) => {
      console.log({ apiGetPaymentInfo: data });
      setData(data);
      setRowcount(count);
      setIsFetching(false);
    });
  };

  const onPageChange = (pageData) => {
    // console.log({ onPageChange: pageData });
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const getInnerData = (id) => {
    let payload = {
      digiRepPaidAppId: id,
    };
    props.apis.apiGetPaymentInfoById(payload).then(({ data, count }) => {
      console.log({ apiGetPaymentInfoById: data });
      setInnerData(data);
      setInnerRowCount(count);
      setIsFetching(false);
    });
  };

  const handleBack = (e) => {
    setInnerData([]);
    setShowinnerTable(false);
  };
  // ----------------------------------------------------------------

  const view = (record) => {
    setEditmode(false);
    setViewmode(true);

    let payload = {
      leadId: record.leadId,
    };
    props.apis.apiGetConsolidatedAppsById(payload).then(({ data }) => {
      let loanInfoFields = [
        "loanInfo",
        "applicationNo",
        "applicantName",
        "mobileNo",
        "productType",
        "bankName",
        "loanAmt",
        "loanStatus",
        "disbursedAmt",
        "disbursedDate",
        "payoutType",
        "application_date",
        "payoutInfo",
      ];

      let editdata = {
        // LOAN INFO DATA --------------------------------
        loanInfo: "",
        digi_team_member_name: data?.createdBy?.name,
        digi_team_member_mobile_no: data?.createdBy?.mobileNumber,
        ...Object.only(data, loanInfoFields),
        application_date: new Date(data?.applicationDate).dateToDDMMYYYY(
          new Date(data?.applicationDate)
        ),
        payoutInfo: "",
        // PAYOUT INFO DATA --------------------------------
        digirepPayoutPct: data?.digirepPayoutPct,
        digirepPayoutAmt: data?.digirepPayoutAmt,
        tdsPct: data?.tdsPct,
        tdsAmt: data?.tdsAmt,
        netAmt: data?.netAmt,
        paymentDate: data?.paymentDate,
        paymentStatus: data?.paymentStatus,
        ...(isAdmin
          ? {
              bankPayoutPct: data?.bankPayoutPct,
              bankPayoutAmt: data?.bankPayoutAmt,
              bankPayoutReceiveDate: data?.bankPayoutReceiveDate,
            }
          : {}),
        remarks: data?.remarks,
      };

      let fields = (modalFields || []).reduce(
        (o, n) => ({ ...o, [n.name]: n }),
        {}
      );
      console.log({
        modalFields,
        editdata,
        fields,
      });
      setPopupfields(fields);

      if (isAdmin) {
        setPopupdata(editdata);
        updatefields("bankPayoutAmt", data?.bankPayoutAmt);
        updatefields("digirepPayoutAmt", data?.digirepPayoutAmt);
        updatefields("tdsAmt", data?.tdsAmt);
      } else {
        setPopupdata(
          Object.except(editdata, [
            "digi_team_member_name",
            "digi_team_member_mobile_no",
          ])
        );
      }
      setEditData(data);
      // ----------------------------------------------------------------
      let disabledFields = [
        "digi_team_member_name",
        "digi_team_member_mobile_no",
        "digirepPayoutAmt",
        "bankPayoutAmt",
        "tdsAmt",
        "application_date",
        ...modalFields.map((field) => field.name),
      ];
      setDisabledFields(disabledFields);
      openModal();
    });
  };

  // ----------------------------------------------------------------

  const onModalClose = () => {
    console.log("onModalClose");
    closeModal();
    setPopupfields({});
    setViewmode(false);
    setEditmode(false);
    setEditData({});
    let bankPayoutAmtField = modalFields.find(
      (o) => o.name === "bankPayoutAmt"
    );
    let digirepPayoutAmtField = modalFields.find(
      (o) => o.name === "digirepPayoutAmt"
    );
    let tdsAmtField = modalFields.find((o) => o.name === "tdsAmt");
    digirepPayoutAmtField.defaultValue = "";
    digirepPayoutAmtField.value = "";

    bankPayoutAmtField.defaultValue = "";
    bankPayoutAmtField.value = "";

    tdsAmtField.defaultValue = "";
    tdsAmtField.value = "";

    // let netAmtField = modalFields.find((o) => o.name === "netAmt");
    // netAmtField.defaultValue = "";

    setModalFields([...modalFields]);
    // setEditData2({});
  };

  //   PAYOUT MODAL
  const openModal = () => {
    setOpenCanvas(true);
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };

  // ----------------------------------------------------------------
  const updatefields = (field, data) => {
    let fieldElem = modalFields.find((o) => o.name === field);
    fieldElem.defaultValue = data;
  };
  // ----------------------------------------------------------------
  const handleBulkDownload = (e) => {
    let ids = data?.map((v) => JSON.stringify(v.digiRepPaidAppId));
    var payload = {};
    if (isAdmin) {
      payload.digiRepPaidAppIds = ids;
      console.log({ ids, payload });
      props.apis.apiDownloadAdminPayout(payload).then((res) => {
        console.log({ res });
        if (res) {
          let url = `${
            props.apis.api().api_base
          }/payout/admin/download?digiRepPaidAppIds=${ids}`;
          var downloadURL = url;
          var link = document.createElement("a");
          link.setAttribute(
            "download",
            `digi_rep_payout_${new Date().toLocaleDateString()}.xlsx`
          );
          link.href = downloadURL;
          link.click();
        }
      });
    } else {
      payload.consolidatedLeadIds = ids;
      console.log({ ids, payload });
      props.apis.apiDownloadAdminPaymentInfo(payload).then((res) => {
        console.log({ res });
        if (res) {
          let url = `${
            props.apis.api().api_base
          }/payment-info/admin/download?consolidatedLeadIds=${ids}`;
          var downloadURL = url;
          var link = document.createElement("a");
          link.setAttribute(
            "download",
            `digi_rep_payout_${new Date().toLocaleDateString()}.xlsx`
          );
          link.href = downloadURL;
          link.click();
        }
      });
    }
  };
  useEffect(() => {
    getPaymentDetails();
  }, []);
  return (
    <>
      {!showinnerTable && (
        <>
          <div className="bulk_download_btn_outer">
            <button
              className="bulk_download_btn"
              onClick={(...args) => handleBulkDownload(...args)}
            >
              <i className="fa fa-download me-2"></i>
              Bulk Download
            </button>
          </div>
          <div className="paymentdetails_table_outer">
            <AppTable
              data={data}
              columns={columns}
              onChange={(...arg) => onPageChange(...arg)}
              total={rowcount}
              reorder={true}
              editable={false}
              viewable={false}
              deletable={false}
              loading={isFetching}
              // onRowClick={(record, rowIndex) => {
              //   return {
              //     onClick: (event) => handleRowClick(record),
              //   };
              // }}
            ></AppTable>
          </div>
        </>
      )}

      {showinnerTable && (
        <>
          <button
            type="button"
            className="btn btn-sm btn-primary mb-3"
            onClick={(...args) => handleBack(...args)}
          >
            Back
          </button>
          <AppTable
            data={innerData}
            columns={innerColumns}
            // onChange={(...arg) => onPageChange(...arg)}
            total={innerRowCount}
            reorder={true}
            editable={false}
            viewAction={(...args) => view(...args)}
            deletable={false}
          ></AppTable>
        </>
      )}
      <PayoutModal
        id="payoutModal"
        title={`${
          editmode
            ? "Edit Lead"
            : viewmode
            ? "View Payment Details Info."
            : "Add"
        }`}
        show={openCanvas}
        onClose={() => onModalClose()}
        data={popupdata}
        // editdata={editData}
        fields={popupfields}
        editmode={editmode}
        viewmode={viewmode}
        onSubmit={(...args) => handleSubmit(...args)}
        submitText={`${editmode ? "Update" : "Submit"}`}
        disableallfields={viewmode ? true : false}
        disablefields={disabledFields}
      />
    </>
  );
};

export default constore(PaymentDetails);
