import React, { useEffect, useState } from "react";

const InfoCard = (props) => {
  const [customerinfo, setCustomerinfo] = useState({});
  useEffect(() => {
    setCustomerinfo({
      name: props?.$store?.user?.customerName,
      mobile: props?.$store?.user?.mobileNumber,
      email: props?.$store?.user?.emailAddress,
    });
  }, []);

  return (
    <div className="info_card">
      <h4 className="title text-white mb-3">My Info</h4>
      <div className="content mb-3">
        <div className="mini_box">
          <img src={imagepaths.whitePersonIcon} alt="whitePersonIcon" />
          <p>{props?.infodata?.name || "-"}</p>
        </div>
        <div className="mini_box">
          <img src={imagepaths.whiteMobileIcon} alt="whiteMobileIcon" />
          <p>{props?.infodata?.mobileNumber || "-"}</p>
        </div>
        <div className="mini_box end">
          <img src={imagepaths.whiteEnvelopeIcon} alt="whiteEnvelopeIcon" />
          <p>{props?.infodata?.emailAddress || "-"}</p>
        </div>
        <button
          className={Object.className({
            edit_btn: true,
            // "d-none": props.$store?.user?.isAadharVerified,
          })}
          onClick={(...args) => props.onEdit(...args)}
        >
          <i className="fa fa-pencil"></i>
        </button>
      </div>
    </div>
  );
};

export default constore(InfoCard);
