import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { Dropdown } from "react-bootstrap";
import ApplicationModal from "./ApplicationModal";
import ApplicationSearch from "./ApplicationSearch/ApplicationSearch";
import MainPagetitle from "../../../../layouts/MainPagetitle";
import moment from "moment";

const Applications = (props) => {
  const { role } = props?.$store?.user || {};
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
    order_by: "updatedAt",
    order_direction: "DESC",
  });
  const [tableData, setTableData] = useState([]);
  const [isadmin, setIsadmin] = useState(false);
  const [istelecaller, setIstelecaller] = useState(false);
  const [isOps, setIsOps] = useState(false);
  const [logsData, setLogsData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [modalFields, setModalFields] = useState([
    {
      label: "Customer Name (as per PAN)",
      name: "leadName",
      type: "text",
      placeholder: "Please Enter",
    },
    {
      label: "Mobile No.",
      name: "mobileNumber",
      type: "number",
      placeholder: "Please Enter",
    },
    // {
    //   label: "Leads",
    //   name: "leadId",
    //   type: "select",
    //   options: [],
    //   placeholder: "Please Enter",
    // },
    {
      label: "Source",
      name: "source",
      type: "select",
      options: _enum.SOURCES,
      placeholder: "Please Enter",
    },
    {
      label: "Product Type",
      name: "productType",
      type: "select",
      options: [],
      placeholder: "Please Enter",
    },
    {
      label: "Bank Name",
      name: "bankName",
      type: "select",
      options: [],
      placeholder: "Please Enter",
    },
    {
      label: "Application No.",
      name: "applicationNumber",
      type: "text",
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Application Date",
      name: "applicationDate",
      type: "date",
      disabledDate: (current) => {
        return current && current < moment().startOf("day");
      },
      placeholder: "Please Enter",
    },
    {
      label: "Loan Amt",
      name: "loanAmount",
      type: "number",
      placeholder: "Please Enter",
    },
    {
      label: "Status",
      name: "status",
      type: "select",
      options: _enum.APPLICATION_LOAN_STATUS,
      placeholder: "Please Enter",
    },
    {
      label: "Remarks",
      name: "remarks",
      type: "textarea",
      customcolspan: "12",
    },
  ]);
  const [editData, setEditData] = useState({});
  const [editmode, setEditmode] = useState(false);
  const [viewmode, setViewmode] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [popupfields, setPopupfields] = useState({});
  const [popupdata, setPopupdata] = useState({});

  const [rowcount, setRowcount] = useState(0);
  const [columns, setColumns] = useState([
    {
      dataIndex: "leadName",
      title: "Customer Name",
    },
    {
      dataIndex: "mobileNumber",
      title: "Mobile No.",
    },
    {
      dataIndex: "productType",
      title: "Product Type",
      render: (text, record) => {
        return <>{text || "-"}</>;
      },
    },
    {
      dataIndex: "bankName",
      title: "Bank Name",
    },
    {
      dataIndex: "loanAmount",
      title: "Loan Amt",
      render: (text) => {
        return <>{text?.cur()}</>;
      },
    },
    {
      dataIndex: "applicationNumber",
      title: "Application No.",
    },
    {
      dataIndex: "applicationDate",
      title: "Application Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "assignedTo",
      title: "Assigned To",
    },
    {
      dataIndex: "ops_mobileNumber",
      title: "Opps Mobile No.",
    },
    {
      dataIndex: "status",
      title: "Status",
      render: (text, record) => {
        let mappedStatus =
          _enum.STATUS_COLOR_MAP[record?.status?.toLowerCase()] || "";
        return (
          <Dropdown
            className={Object.className({
              "status-dropdown": true,
              "no-arrow pe-none": true,
            })}
          >
            <Dropdown.Toggle as="div" className={mappedStatus}>
              <span>{record.status.toTitleCase()}</span>
              <i class="fa-solid fa-chevron-down ms-2"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="status-drop-menu">
              <Dropdown.Item
                onClick={() =>
                  handleAction(record?.applicationNumber, "disbursed")
                }
              >
                Disbursed
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  handleAction(record?.applicationNumber, "rejected")
                }
              >
                Rejected
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  handleAction(record?.applicationNumber, "logged_in")
                }
              >
                Logged In
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ]);
  const [salesColumns, setSalesColumns] = useState([
    {
      dataIndex: "leadName",
      title: "Customer Name",
      render: (text, record) => {
        return <>{record?.assignedLead?.leadName}</>;
      },
    },
    {
      dataIndex: "mobileNumber",
      title: "Mobile No.",
      render: (text, record) => {
        return <>{record?.assignedLead?.mobileNumber}</>;
      },
    },
    {
      dataIndex: "productType",
      title: "Product Type",
      render: (text, record) => {
        return <>{record?.assignedLead?.productType}</>;
      },
    },
    {
      dataIndex: "bankName",
      title: "Bank Name",
    },
    {
      dataIndex: "loanAmount",
      title: "Loan Amt",
      render: (text) => {
        return <>{text?.cur()}</>;
      },
    },
    {
      dataIndex: "applicationNumber",
      title: "Application No.",
    },
    {
      dataIndex: "applicationDate",
      title: "Application Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "assignedTo",
      title: "Assigned To",
      render: (text, record) => {
        return <>{record?.assignedLead?.assignedEmployee?.name}</>;
      },
    },
    {
      dataIndex: "ops_mobileNumber",
      title: "Opps Mobile No.",
      render: (text, record) => {
        return <>{record?.assignedLead?.assignedEmployee?.mobileNumber}</>;
      },
    },
    {
      dataIndex: "status",
      title: "Status",
      render: (text, record) => {
        let status = record?.assignedLead?.Applications[0]?.status;
        let applicationNumber =
          record?.assignedLead?.Applications[0]?.applicationNumber;
        let mappedStatus = _enum.STATUS_COLOR_MAP[status] || "";

        return (
          <Dropdown
            className={Object.className({
              "status-dropdown": true,
              "no-arrow pe-none": true,
            })}
          >
            <Dropdown.Toggle as="div" className={mappedStatus}>
              <span>{status?.toTitleCase() || "-"}</span>
              <i class="fa-solid fa-chevron-down ms-2"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="status-drop-menu">
              <Dropdown.Item
                onClick={() => handleAction(applicationNumber, "disbursed")}
              >
                Disbursed
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleAction(applicationNumber, "rejected")}
              >
                Rejected
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleAction(applicationNumber, "logged_in")}
              >
                Logged In
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ]);
  const logsColumns = [
    {
      dataIndex: "date",
      title: "Transaction Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "status",
      title: "Status",
      render: (text, record) => {
        return (
          <span className={`badge light badge-${_enum.STATUS_COLOR_MAP[text]}`}>
            {text.toTitleCase()}
          </span>
        );
      },
    },
    {
      dataIndex: "updatedById",
      title: "Updated By",
    },
    {
      dataIndex: "remarks",
      title: "Remarks",
    },
  ];
  const [leadHistory, setLeadHistory] = useState([]);
  const [loggedinUser, setLoggedinUser] = useState("");
  const handleAction = (id, value) => {
    console.log({ handleAction: id, value });
    let payload = {
      status: value,
    };
    if (id) {
      props.apis
        .apiUpdateApplication(payload, {
          applicationNumber: id,
        })
        .then((res) => {
          // console.log({ apiUpdateApplication: res });
          getApplications();
        });
    }
  };

  const onPageChange = (pageData) => {
    // console.log({ onPageChange: pageData });
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const openModal = () => {
    setOpenCanvas(true);
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };

  const getApplications = (params = {}, reset) => {
    let payload = {};
    if (reset) {
      let defaultSearchData = Object.only(search, [
        "page",
        "limit",
        "order_by",
        "order_direction",
      ]);
      setSearch(defaultSearchData);
      payload = {
        ...defaultSearchData,
        ...params,
      };
    } else {
      payload = {
        ...search,
        ...params,
      };
    }

    props.apis.apiGetApplications(payload).then(({ data, total }) => {
      // console.log({ apiGetApplications: data });
      setTableData(data);
      setIsFetching(false);
      setRowcount(total);
    });
  };

  const getBankNames = () => {
    let payload = {
      page: 1,
    };
    props.apis.apiGetBanknames(payload).then(({ data }) => {
      // console.log({apiGetBanknames: data});
      let bankNameField = modalFields.find((o) => o.name == "bankName");
      bankNameField.options = data.options("bankName");
      setModalFields([...modalFields]);
    });
  };

  const getProductTypes = () => {
    props.apis.apiGetProductTypes().then((res) => {
      let productType = modalFields.find((o) => o.name == "productType");
      productType.options = res.options("productType");
      setModalFields([...modalFields]);
    });
  };

  const getLeads = () => {
    let payload = {
      ...search,
    };
    props.apis.apiGetLeads(payload).then(({ data }) => {
      let bankNameField = modalFields.find((o) => o.name == "leadId");
      bankNameField.options = data.options("leadId", "leadName");
      console.log({ bankNameField });
      setModalFields([...modalFields]);
    });
  };

  const getLeadsHistory = () => {
    props.apis.apiGetLeadHistory().then((res) => {
      console.log({ apiGetLeadHistory: res });
      setLeadHistory(res);
    });
    setIsFetching(false);
  };

  const viewApplication = (record) => {
    console.log({ record });
    let applnum;
    if (role === _enum.ROUTE_PERSONAS.sales) {
      applnum = record?.assignedLead?.Applications[0]?.applicationNumber;
      if (
        record?.assignedLead?.Applications[0]?.applicationNumber === undefined
      ) {
        swal2.fire({
          title: "No Application Number Found",
          timer: 3000,
        });
        return;
      }
    } else {
      applnum = record?.applicationNumber;
    }
    setEditmode(false);
    setViewmode(true);
    // GET APPLICATION BY ID
    props.apis
      .apiGetApplicationById({ applicationNumber: applnum })
      .then((data) => {
        console.log({ apiGetApplicationById: data });
        let filteredData = Object.only(
          data,
          modalFields.map((v) => v.name)
        );
        console.log({ filteredData });
        let sortedData = {
          applicationNumber: filteredData?.applicationNumber,
          leadName: filteredData?.leadName,
          loanAmount: filteredData?.loanAmount,
          mobileNumber: filteredData?.mobileNumber,
          productType: filteredData?.productType,
          status: filteredData?.status,
          bankName: filteredData?.bankName,
          applicationDate: filteredData?.applicationDate,
          remarks: filteredData?.remarks,
        };
        setPopupdata(sortedData);
        setEditData(data);
        setLogsData(data.ApplicationUpdateLogs);
        openModal();
      });
  };

  const editApplication = (record) => {
    setEditmode(true);
    setViewmode(false);
    // GET APPLICATION BY ID
    props.apis
      .apiGetApplicationById({ applicationNumber: record.applicationNumber })
      .then((data) => {
        console.log({ apiGetApplicationById: data });
        let filteredData = Object.only(
          data,
          modalFields.map((v) => v.name)
        );
        console.log({ filteredData });
        let sortedData = {
          applicationNumber: filteredData?.applicationNumber,
          leadName: filteredData?.leadName,
          loanAmount: filteredData?.loanAmount,
          mobileNumber: filteredData?.mobileNumber,
          productType: filteredData?.productType,
          status: filteredData?.status,
          bankName: filteredData?.bankName,
          applicationDate: filteredData?.applicationDate,
          remarks: filteredData?.remarks,
        };
        setPopupdata(sortedData);
        setEditData(data);
        openModal();
      });
  };

  const deleteApplication = (record) => {
    console.log({ deleteApplication: record.applicationNumber });
    swal2
      .fire({
        title: "Are you sure you want to delete this application?",
        icon: "info",
        iconColor: "#234C9B",
        showCancelButton: true,
        confirmButtonColor: "#234C9B",
        cancelButtonColor: "##D6DBE7",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
      .then((result) => {
        if (result.isConfirmed) {
          props.apis
            .apiDeleteApplication({
              applicationNumber: record.applicationNumber,
            })
            .then((res) => {
              console.log({ apiDeleteApplication: res });
              swal2.fire({
                title: "Application Deleted",
                text: "Application deleted successfully !!",
                icon: "success",
                iconColor: "#234C9B",
                timer: 4000,
              });
              getApplications();
            });
        }
      });
  };

  const handleSubmit = (e, data, form) => {
    e.preventDefault();
    console.log("formdata: ", data);
    let formdata = data;
    let payload = {
      ...formdata,
    };
    payload.leadId = Number(formdata.leadId);
    payload.loanAmount = Number(formdata.loanAmount);
    payload.applicationDate = new Date(formdata.applicationDate).toISOString();
    if (editmode) {
      props.apis
        .apiUpdateApplication(payload, {
          applicationNumber: editData?.applicationNumber,
        })
        .then((res) => {
          console.log({ apiUpdateApplication: res });
          swal2.fire({
            title: "Application updated successfully.",
            icon: "success",
            iconColor: "#234C9B",
            timer: 4000,
          });
          // closeModal();
          onModalClose();
          getApplications();
        });
    } else {
      payload.assignedById = props?.$store?.user?.profileId;
      props.apis.apiAddApplication(payload).then((res) => {
        console.log({ apiAddApplication: res });
        swal2.fire({
          title: "Application added successfully.",
          icon: "success",
          iconColor: "#234C9B",
          timer: 4000,
        });
        closeModal();
        getApplications();
      });
    }
  };

  const onSearch = (data, reset) => {
    console.log("onSearch on leads", data, reset);
    if (Object.keys(data).length === 0) {
      setSearch({
        ...data,
        ...search,
      });
      getApplications(data, reset);
    } else {
      setSearch({
        ...data,
        ...search,
      });
      getApplications(data, reset);
    }
  };

  const addModalFields = (values) => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    setPopupfields(fields);
    setPopupdata(formdata);
    setOpenCanvas(true);
  };

  const onModalClose = () => {
    console.log("onModalClose");
    setPopupfields({});
    setViewmode(false);
    setEditmode(false);
    setEditData({});
    closeModal();
  };

  const checkLoggedIn = () => {
    switch (role) {
      case _enum.ROUTE_PERSONAS.admin:
        setIsadmin(true);
        setIstelecaller(false);
        setIsOps(false);
        break;
      case _enum.ROUTE_PERSONAS.sales:
        setIsadmin(false);
        setIstelecaller(true);
        setIsOps(false);
        break;
      case _enum.ROUTE_PERSONAS.ops:
        setIsadmin(false);
        setIstelecaller(false);
        setIsOps(true);
        break;
      default:
        setIsadmin(false);
        setIstelecaller(false);
        setIsOps(false);
        break;
    }
  };

  useEffect(() => {
    if (role === _enum.ROUTE_PERSONAS.ops) {
      getApplications();
    }
  }, [search]);

  useEffect(() => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    // console.log({ updatedfields: fields });
    setPopupfields(fields);
  }, [editData]);

  useEffect(() => {
    setLoggedinUser(role);

    switch (role) {
      case _enum.ROUTE_PERSONAS.admin:
        getApplications();
        // getLeads();
        getProductTypes();
        getBankNames();
        checkLoggedIn();
        break;
      case _enum.ROUTE_PERSONAS.sales:
        getLeadsHistory();
        // getLeads();
        getProductTypes();
        break;
      case _enum.ROUTE_PERSONAS.ops:
        getLeadsHistory();
        getApplications();
        getLeadsHistory();
        // getLeads();
        getBankNames();
        getProductTypes();
        break;

      default:
        break;
    }

    // ----------------------------------------------------------------
    // Altering table columns based on logged-in user role
    console.log("APPLICATIONS: ", role);

    let allowedColumns = {
      admin: [
        "leadName",
        "mobileNumber",
        "productType",
        "bankName",
        "loanAmount",
        "applicationNumber",
        "applicationDate",
        "status",
      ],
      sales: [
        "leadName",
        "mobileNumber",
        "productType",
        "assignedTo",
        "ops_mobileNumber",
        "status",
      ],
      ops: [
        "leadName",
        "mobileNumber",
        "productType",
        "bankName",
        "loanAmount",
        "applicationNumber",
        "status",
      ],
    };
    switch (role) {
      case _enum.ROUTE_PERSONAS.admin:
        updateColumns(allowedColumns.admin, "Login Status");
        break;
      case _enum.ROUTE_PERSONAS.sales:
        updateColumns(allowedColumns.sales, "Opps Status");
        break;
      case _enum.ROUTE_PERSONAS.ops:
        updateColumns(allowedColumns.ops, "Loan Status");
        break;

      default:
        updateColumns(allowedColumns.admin, "Login Status");
        break;
    }
  }, []);

  const updateColumns = (col, title) => {
    // filter out the columns
    let filteredColumns;
    if (role === _enum.ROUTE_PERSONAS.sales) {
      filteredColumns = salesColumns.only(col, "dataIndex");
    } else {
      filteredColumns = columns.only(col, "dataIndex");
    }
    console.log({ filteredColumns });

    // change columns title
    let alteredColumns = filteredColumns?.map((obj) => {
      if (obj.dataIndex === "status") {
        return {
          ...obj,
          title,
        };
      }
      return obj;
    });
    // console.log({ alteredColumns });
    setColumns(alteredColumns);
    setSalesColumns(alteredColumns);
  };

  props.$setNode({
    props,
    search,
    editData,
  });
  return (
    <>
      {!isadmin && (
        <MainPagetitle
          mainTitle={`${
            role === _enum.ROUTE_PERSONAS.sales
              ? "Assigned to Opps"
              : "Applications"
          }`}
          pageTitle="My Applications"
          parentTitle={"Home"}
        />
      )}
      <div
        className={Object.className({
          "container-fluid": !isadmin,
        })}
      >
        <div className="row">
          <div className="col-xl-12">
            <div
              className={Object.className({
                card: !isadmin,
              })}
            >
              <div
                className={Object.className({
                  "card-body p-0": !isadmin,
                })}
              >
                <div
                  className={Object.className({
                    "tbl-caption-container application_wrapper": true,
                    fixed_tbl_container_lg: isadmin,
                  })}
                >
                  {!isadmin && (
                    <h4 className="heading mb-0">My Applications</h4>
                  )}
                  <ApplicationSearch onSearch={(...arg) => onSearch(...arg)} />
                  <div
                    className={Object.className({
                      "separator ms-4": true,
                      "d-none":
                        role === _enum.ROUTE_PERSONAS.sales ||
                        role === _enum.ROUTE_PERSONAS.admin,
                    })}
                  ></div>
                  <div className="header_box">
                    <Link
                      to={"#"}
                      className={Object.className({
                        "btn btn-primary ms-3": true,
                        "d-none":
                          role === _enum.ROUTE_PERSONAS.sales ||
                          role === _enum.ROUTE_PERSONAS.admin,
                      })}
                      onClick={(...args) => addModalFields(...args)}
                    >
                      + Add Application
                    </Link>
                  </div>
                </div>
                <AppTable
                  data={
                    role === _enum.ROUTE_PERSONAS.sales
                      ? leadHistory
                      : tableData
                  }
                  columns={
                    role === _enum.ROUTE_PERSONAS.sales ? salesColumns : columns
                  }
                  onChange={(...arg) => onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  {...(role === _enum.ROUTE_PERSONAS.sales
                    ? {
                        viewAction: (e) => viewApplication(e),
                        editable: false,
                        deletable: false,
                      }
                    : role === _enum.ROUTE_PERSONAS.ops
                    ? {
                        viewAction: (e) => viewApplication(e),
                        editAction: (e) => editApplication(e),
                        // editable: false,
                        deletable: false,
                      }
                    : {
                        editAction: (e) => editApplication(e),
                        viewAction: (e) => viewApplication(e),
                        deleteAction: (e) => deleteApplication(e),
                      })}
                  loading={isFetching}
                ></AppTable>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ApplicationModal
        id="applicationCanvas"
        title={`${
          editmode
            ? "Edit Application"
            : viewmode
            ? "View Application"
            : "Add Application"
        }`}
        show={openCanvas}
        onClose={() => onModalClose()}
        data={popupdata}
        editdata={editData}
        fields={popupfields}
        editmode={editmode}
        viewmode={viewmode}
        logsData={logsData}
        logsColumns={logsColumns}
        showLogsTable={viewmode}
        onSubmit={(...args) => handleSubmit(...args)}
        submitText={`${editmode ? "Update" : "Add"}`}
        disableallfields={viewmode ? true : false}
      />
    </>
  );
};

export default constore(Applications);
