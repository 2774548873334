import React from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import "./bootstrap";
import mixins from "./utils/mixins/mixin";
mixins.setInitials();
import router from "./routes";
import { store } from "./utils/redux/store";
import { Provider } from "react-redux";
import "./jsx/components/partials";


window.mixins = mixins;
// window.CURRENT_PERSONA = store?.getState()?.store?.persona;

const container = document.getElementById("root");
const root = createRoot(container);


root.render(
	<Provider store={store}>
		<RouterProvider router={router} />
	</Provider>
);

setTimeout(() => {
	Object.entries(imagepaths).map(([key, val]) => {
		document.body.style.setProperty(`--Image-${key.snakeCase()}`, `url(${val})`);
	});
});