import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export class AppFormData extends Component {
  render() {
    let { props } = this;
    let {
      id,
      data,
      size = 2,
      fields = {},
      submitText = "SUBMIT",
      onClose,
      disableallfields = false,
      disableSubmit = false,
      disablefields = [],
      editdata,
      customcolspan = "6",
      modaltype = "",
      errorMessage,
      hidecancelbtn,
      centerchangebtn,
      viewmode = false,
      swapfields = {},
      inputlinkbtns = [],
      verifyBtnAction = () => "",
    } = props;
    let list = Object.entries(data).chunk(size);
    let getField = (label) => fields[label] || {};
    let getColClass = (c) => fields[c]?.customcolspan || "6";
    let formAttrs = Object.except(props, [
      "data",
      "fields",
      "size",
      "submitText",
    ]);
    if (Object.keys(swapfields).length > 0) {
      // Find the index of the subarray containing "status" using a loop
      let indexOfStatusSubarray = -1;

      for (let i = 0; i < list.length; i++) {
        const subarray = list[i];
        const hasStatus = subarray.some(([key]) => key === swapfields[0]);

        if (hasStatus) {
          indexOfStatusSubarray = i;
          break;
        }
      }

      // If "status" is found, find the indexes of "status" and "state" within their subarrays
      if (indexOfStatusSubarray !== -1) {
        const indexOfStatus = list[indexOfStatusSubarray].findIndex(
          ([key]) => key === swapfields[0]
        );
        const indexOfState = list[1].findIndex(
          ([key]) => key === swapfields[1]
        );

        // If "status" and "state" are found, swap their positions within their subarrays
        if (indexOfStatus !== -1 && indexOfState !== -1) {
          [list[indexOfStatusSubarray][indexOfStatus], list[1][indexOfState]] =
            [list[1][indexOfState], list[indexOfStatusSubarray][indexOfStatus]];
        }
      }
    }

    // console.log({ list, data });
    return (
      <AppForm {...formAttrs}>
        {list.map((row, i) => (
          <Row className="mx-0" key={i}>
            {row.map(([label, value, options], j) => (
              <Col lg={getColClass(label)} key={j}>
                <AppInput
                  name={label}
                  label={label.toTitleCase()}
                  defaultValue={value}
                  disabled={disableallfields}
                  {...(disablefields.includes(label)
                    ? // Object.keys(editdata).length > 0
                      {
                        readOnly: true,
                      }
                    : { readOnly: false })}
                  {...getField(label)}
                  errorMessage={errorMessage}
                ></AppInput>
                {inputlinkbtns.includes(label) && (
                  <button
                    type="button"
                    className="input_link_btn"
                    onClick={(...args) => verifyBtnAction(...args, label)}
                  >
                    verify
                  </button>
                )}
              </Col>
            ))}
          </Row>
        ))}

        {viewmode === true && (
          <div className="row mx-0 mt-4">
            <div className="col-12 col-lg-6"></div>
            <div
              className={Object.className({
                "col-12 col-lg-6 mb-3 text-end d-flex justify-content-end": true,
              })}
            >
              <Link
                to={"#"}
                onClick={onClose}
                className={Object.className({
                  "btn-primary btn me-2 w-50": true,
                  "m-auto": disableallfields,
                  "d-none": hidecancelbtn,
                })}
              >
                Close
              </Link>
            </div>
          </div>
        )}
        {viewmode === false && (
          <div className="row mx-0 mt-4 change-password-section">
            {centerchangebtn ? (
              ""
            ) : (
              <div className="col-lg-6 col-xxl-4 col-xl-5 mb-3 d-xxl-none"></div>
            )}
            <div
              className={Object.className({
                "col-lg-6 col-xxl-8 col-xl-7 mb-3 text-end d-flex justify-content-end":
                  !centerchangebtn,
                "text-center": centerchangebtn,
                hidden: disableallfields,
              })}
            >
              {!viewmode ? (
                <Link
                  to={"#"}
                  onClick={onClose}
                  className={Object.className({
                    "formdata-cancel-btn btn me-2 w-50": true,
                    "m-auto": disableallfields,
                    "d-none": hidecancelbtn,
                  })}
                >
                  Cancel
                </Link>
              ) : (
                <Link
                  to={"#"}
                  onClick={onClose}
                  className={Object.className({
                    "btn-primary btn me-2 w-50": true,
                    "m-auto": disableallfields,
                    "d-none": hidecancelbtn,
                  })}
                >
                  Close
                </Link>
              )}
              {!viewmode && (
                <button
                  type="submit"
                  className={Object.className({
                    "btn btn-primary w-50 submitBtn": true,
                    "text-center w-100": centerchangebtn,
                  })}
                  disabled={disableSubmit}
                  data-submitbutton={id}
                >
                  {submitText}
                </button>
              )}
            </div>
          </div>
        )}
      </AppForm>
    );
  }
}

export default AppFormData;
