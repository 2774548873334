import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import OpenRouteModel from "./OpenRouteModal";
// image

function Register(props) {
  const [email, setEmail] = useState("");
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const openRouteModalRef = useRef(0);

  function onSignUp(e) {
    // e.preventDefault();
    // let error = false;
    // const errorObj = { ...errorsObj };
    // if (email === "") {
    //   errorObj.email = "Email is Required";
    //   error = true;
    // }
    // if (password === "") {
    //   errorObj.password = "Password is Required";
    //   error = true;
    // }
    // setErrors(errorObj);
    // if (error) return;

    navigate("/open/otp");
  }

  const closeModal = () => {
    document.querySelector("#openRouteModel #bsmodalclose").click();
  };

  return (
    <>
      <div className="page-wraper">
        <div className="browse-job login-style3">
          <div
            className="bg-img-fix overflow-hidden"
            style={{ backgroundImage: "url(" + imagepaths.loginBg + ")" }}
          >
            <div className="parent-container row gx-0">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="logo_header pt-5 ps-5">
                  <Link to={"/open/login"} className="logo">
                    <img src={imagepaths.logoWithTagline} alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                <div className="login-form style-2">
                  <div className="card-body">
                    {/* <div className="logo-header"></div> */}
                    <nav className="nav nav-tabs border-bottom-0">
                      <div className="tab-content w-100" id="nav-tabContent">
                        <div className="tab-pane active show fade">
                          {props.errorMessage && (
                            <div className="">{props.errorMessage}</div>
                          )}
                          {props.successMessage && (
                            <div className="">{props.successMessage}</div>
                          )}
                          <form className="dz-form py-2" onSubmit={onSignUp}>
                            <h3 className="form-title mb-5">Sign Up</h3>
                            <div className="dz-separator-outer m-b5">
                              <div className="dz-separator bg-primary style-liner"></div>
                            </div>
                            <div className="form-group mt-3">
                              <label htmlFor="dzName">Full Name</label>
                              <input
                                name="dzName"
                                required=""
                                className="form-control"
                                placeholder="Please Enter"
                                type="text"
                              />
                            </div>
                            <div className="form-group mt-3">
                              <label htmlFor="dzName">Mobile Number</label>
                              <input
                                name="dzName2"
                                required=""
                                className="form-control"
                                placeholder="Please Enter"
                                type="text"
                              />
                            </div>
                            <div className="form-group mt-3">
                              <label htmlFor="">Email ID</label>
                              <input
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="form-control"
                                placeholder="Please Enter"
                              />
                              {errors.email && (
                                <div className="text-danger fs-12">
                                  {errors.email}
                                </div>
                              )}
                            </div>

                            <div className="form-group mt-3">
                              <label htmlFor="">Password</label>
                              <input
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="form-control"
                                placeholder="Please Enter"
                              />
                              {errors.password && (
                                <div className="text-danger fs-12">
                                  {errors.password}
                                </div>
                              )}
                            </div>
                            <div className="mb-3 mt-3">
                              <span className="form-check float-start me-2">
                                <input
                                  type="checkbox"
                                  className="form-check-input mt-1"
                                  id="check2"
                                  name="example1"
                                />
                                <label
                                  className="form-check-label d-unset"
                                  htmlFor="check2"
                                >
                                  I agree to the
                                </label>
                              </span>
                              <button
                                type="button"
                                ref={openRouteModalRef}
                                data-bs-toggle="modal"
                                data-bs-target="#openRouteModel"
                                className="text-primary border-0 bg-transparent pt-1"
                              >
                                Terms of Services & Privacy Policy{" "}
                              </button>
                            </div>
                            <div className="form-group clearfix text-left">
                              {/* <NavLink
                                    to="/open/login"
                                    className="btn btn-primary outline gray"
                                    type="button"
                                  >
                                    Back
                                  </NavLink> */}
                              <button
                                type="submit"
                                className="btn btn-primary button-md btn-block mb-2"
                              >
                                Sign Up
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OpenRouteModel
        id="openRouteModel"
        className="modal-lg openRouteModel"
        modalSize="default"
        title="Terms of Service and Privacy Policy"
        submitText="Understood"
        modaltype="centeredmodal"
        onClose={() => closeModal()}
        footer={true}
        okText="Understood"
      />
    </>
  );
}

export default Register;
