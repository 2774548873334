import devapis from "./dev.endpoints";
import stagingapis from "./staging.endpoints";
import prodapis from "./prod.endpoints";
const localtodev = "http://localhost:3000,http://localhost:3001,";
const localtostage = "";
const localtoprod = "";

export const endpoints = {
    "dev": {
        "base": `${localtodev}https://strprevampapi.impetusaisolutions.com`,
        "api_base": "https://strprevampapi.impetusaisolutions.com/api/v1",
        "static_token": "",
        ...devapis,
        "open/login": {
            "base": `${localtodev}https://strprevampapi.impetusaisolutions.com`,
            "api_base": "https://strprevampapi.impetusaisolutions.com/api/v1",
            ...devapis,
        },
    },
    "staging": {
        "base": `${localtostage}https://lmsstage.impetusaisolutions.com`,
        "api_base": "https://lmsapistage.impetusaisolutions.com/api/v1",
        "static_token": "",
        ...stagingapis,

        "open/login": {
            "base": "https://lmsstage.impetusaisolutions.com",
            "api_base": "https://lmsapistage.impetusaisolutions.com/api/v1",
            ...stagingapis,
        },
    },
    "production": {
        "base": `${localtoprod}https://portal.starpowerzdigiloans.com`,
        "api_base": "https://apiprtl.starpowerzdigiloans.com/api/v1",
        "static_token": "",
        ...prodapis,

        "open/login": {
            "base": "https://portal.starpowerzdigiloans.com",
            "api_base": "https://apiprtl.starpowerzdigiloans.com/api/v1",
            ...prodapis,
        },
    }
}

export default endpoints;