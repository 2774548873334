import React, { useEffect, useState } from "react";
import UtilizationSearch from "./UtilizationSearch";

const Utilization = (props) => {
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [rowcount, setRowcount] = useState(0);
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const columns = [
    {
      dataIndex: "name",
      title: "Connector Name",
    },
    {
      dataIndex: "mobileNumber",
      title: "Mobile No.",
    },
    {
      dataIndex: "PANCARD",
      title: "PAN",
    },
    {
      dataIndex: "AADHAAR",
      title: "Aadhar",
    },
    {
      dataIndex: "BSA",
      title: "BSA",
    },
    {
      dataIndex: "CIBIL",
      title: "Cibil",
    },
    {
      dataIndex: "lastActiveDate",
      title: "Last Active Date",
      render: (text, record) => {
        if (text) {
          let date = new Date(text).dateToDDMMYYYY(new Date(text));
          return date;
        } else {
          return "-";
        }
      },
    },
  ];

  const onPageChange = (pageData) => {
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const onSearch = (data, reset) => {
    console.log("onSearch", data, reset);
    // if (Object.keys(data).length === 0) {
    //   setSearch({
    //     ...data,
    //     ...search,
    //   });
    //   getMyRequestData(data, reset);
    // } else {
    //   setSearch({
    //     ...data,
    //     ...search,
    //   });
    //   getMyRequestData(data, reset);
    // }
  };

  const getKycUtilizationData = (requestData) => {
    props.apis.apiGetServiceUtilization().then((response) => {
      setIsFetching(false);
      setData(response);
    });
  };

  useEffect(() => {
    getKycUtilizationData();
  }, []);

  return (
    <>
      <UtilizationSearch onSearch={(...arg) => onSearch(...arg)} />
      <AppTable
        data={data}
        columns={columns}
        onChange={(...arg) => onPageChange(...arg)}
        total={rowcount}
        reorder={true}
        viewable={false}
        editable={false}
        deletable={false}
        loading={isFetching}
      ></AppTable>
    </>
  );
};

export default constore(Utilization);
