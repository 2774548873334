import React, { useEffect, useState } from "react";

import MainPagetitle from "../../../layouts/MainPagetitle";
import DigitalLeadCard from "../DigitalLeads/DigitalLeadCard";
import InCredFinanceSearch from "./InCredFinanceSearch/InCredFinanceSearch";
import IncredModal from "./IncredModal";
import { Modal } from "react-bootstrap";
import OTPInput from "react-otp-input";
import { toast } from "react-toastify";
import moment from "moment";
// import IncredStepModal from "./IncredStepModal";

const InCredFinance = (props) => {
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const adminPersona = props?.$store?.user?.role === _enum.ROUTE_PERSONAS.admin;
  const pageID = props?.urlparams?.params?.leadname;
  const isPanVerified = props.$store?.user?.isPanVerified;
  const isAadharVerified = props.$store?.user?.isAadharVerified;
  const [isAccountVerified, setIsAccountVerified] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [incredManagers, setIncredManagers] = useState([]);

  const [rowcount, setRowcount] = useState(0);
  const columns = [
    {
      dataIndex: "connectorName",
      title: "Applicant Name",
      render: (text, record) => {
        return (
          <span className="table-primary-text">
            {`${record?.applicant_first_name} ${record?.applicant_last_name}` ||
              "-"}
          </span>
        );
      },
      // display: adminPersona ? true : false,
    },
    {
      dataIndex: "application_id",
      title: "Application Number",
      render: (text) => {
        return <>{text || "-"}</>;
      },
    },
    {
      dataIndex: "mobileNo",
      title: "Mobile No.",
      display: adminPersona ? true : false,
    },
    {
      dataIndex: "createdAt",
      title: "Application Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "status",
      title: "Application Status",
      sorter: false,
      render: (text, record) => {
        return (
          <span
            className={`badge light border-0 ${
              text === "REJECTED" ? "badge-danger" : "badge-success"
            } `}
          >
            {text?.toTitleCase()}
          </span>
        );
      },
    },
    {
      dataIndex: "reject_reason",
      title: "Rejected Reason",
      render: (text) => {
        return <>{text || "-"}</>;
      },
    },
    {
      dataIndex: "irr",
      title: "ROI",
      sorter: false,
      render: (text, record) => {
        return <>{text || "-"}</>;
      },
    },
    {
      dataIndex: "maxEligibility",
      title: "Max Eligibility",
      render: (text) => {
        return <>{text?.cur() || "-"}</>;
      },
      // display: adminPersona ? true : false,
    },
  ];

  // const [openCanvas, setOpenCanvas] = useState(false);
  const [goSteps, setGoSteps] = useState(0);
  const [myinfo, setMyinfo] = useState({});

  const toastConfig = {
    autoClose: 3000,
    closeButton: false,
    hideProgressBar: true,
  };

  const onSearch = (data, reset) => {
    console.log("onSearch on leads", data, reset);
    if (Object.keys(data).length === 0) {
      setSearch({
        ...data,
        ...search,
      });
      getInCredFinance(data, reset);
    } else {
      setSearch({
        // ...data,
        ...search,
      });
      getInCredFinance(data, reset);
    }
  };

  const onPageChange = (pageData) => {
    // console.log({ onPageChange: pageData });
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const viewApplication = (record) => {
    setEditmode(false);
    setViewmode(true);

    let payload = {
      application_id: record.application_id,
    };
    props.apis.apiGetIncredApplicantById(payload).then((response) => {
      let filteredData = Object.only(
        response.data,
        modalFields.map((v) => v.name)
      );

      let relationalManagerField = modalFields.find(
        (o) => o.name === "relational_manager"
      );
      relationalManagerField.options = incredManagers.options("value");

      setPopupdata({
        connector_name: props?.$store?.user?.name,
        relational_manager: response.data?.partner_reference,
        ...filteredData,
      });
      setEditData(response.data);
      openModal();
    });
  };

  const getBreadCums = () => {
    let pagetitle = _enum?.LEAD_TITLE_MAP?.incred_finance;
    return pagetitle;
  };

  const getBackLink = () => {
    return "/app/businesstobusiness?activetab=digitalleads";
  };

  const handleBulkDownload = (e) => {
    // let ids = tableData?.map((v) => v.applicationId);
    // var payload = {
    //   consolidatedLeadIds: ids,
    // };
    // console.log({ ids, payload });
    // if (typeof ids[0] !== undefined) {
    //   props.apis.apiDownloadAdminPaymentInfo(payload).then((res) => {
    //     console.log({ res });
    //     if (res) {
    //       let url = `${
    //         props.apis.api().api_base
    //       }/payment-info/admin/download?consolidatedLeadIds=${ids}`;
    //       var downloadURL = url;
    //       var link = document.createElement("a");
    //       link.setAttribute(
    //         "download",
    //         `digi_rep_payout_${new Date().toLocaleDateString()}.xlsx`
    //       );
    //       link.href = downloadURL;
    //       link.click();
    //     }
    //   });
    // }
  };

  // TATA CAPITAL FLOW
  const getInCredFinance = (params = {}, reset) => {
    let payload = {};
    if (reset) {
      let defaultSearchData = Object.only(search, ["limit", "page"]);
      setSearch(defaultSearchData);
      payload = {
        ...defaultSearchData,
        ...params,
      };
    } else {
      payload = {
        ...search,
        ...params,
      };
    }

    props.apis
      .apiGetIncredApplicants(payload)
      .then(({ data, total }) => {
        console.log({ apiGetIncredApplicants: data });
        setTableData(data);
        setIsFetching(false);
        setRowcount(total);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // const handleApply = () => {
  //   openModal();
  // };

  const openModal = () => {
    setOpenCanvas(true);
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };

  // const onModalClose = () => {
  //   closeModal();
  //   setGoSteps(0);
  // };

  // const handleSubmit = (e) => {
  //   closeModal();
  // };

  // ----------------------------------------------------------------

  const [otpValue, setOtpValue] = useState("");
  const [isOtpDisabled, setIsOtpDisabled] = useState(true);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [appId, setAppId] = useState("");

  const closeOTPModal = () => {
    setShowOTPModal(false);
    setOtpValue("");
  };

  const handleOTPChange = (otp) => {
    console.log(otp, "otp");
    // setOTP(otp);
    setOtpValue(otp);
    if (otp.length === 6) {
      setIsOtpDisabled(false);
    } else {
      setIsOtpDisabled(true);
    }
  };

  // ----------------------------------------------------------------

  const [editData, setEditData] = useState({});
  const [editmode, setEditmode] = useState(false);
  const [viewmode, setViewmode] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [popupfields, setPopupfields] = useState({});
  const [popupdata, setPopupdata] = useState({});
  const [errorMessage, setErrorMessage] = useState({ name: "", message: "" });
  const [modalFields, setModalFields] = useState([
    {
      name: "connector_name",
      label: "Digirep Name",
      type: "text",
      placeholder: "Please enter",
      readOnly: true,
      required: true,
    },
    {
      name: "relational_manager",
      label: "Relationship Manager",
      type: "select",
      placeholder: "Please enter",
      // required: true,
    },
    {
      name: "applicant_first_name",
      label: "Applicant First Name (As per PAN)",
      type: "text",
      placeholder: "Please enter",
      required: true,
    },
    {
      name: "applicant_middle_name",
      label: "Applicant Middle Name",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "applicant_last_name",
      label: "Applicant Last Name (As per PAN)",
      type: "text",
      placeholder: "Please enter",
      required: true,
    },
    {
      name: "mobileNo",
      label: "Applicant Mobile No.",
      type: "text",
      placeholder: "Applicant Mobile No.",
      onChange: (value) => handleMobileNo(value),
      required: true,
    },
    {
      name: "panNo",
      label: "Applicant PAN No.",
      type: "text",
      placeholder: "Applicant PAN No.",
      onChange: (value) => handlePanCard(value),
      required: true,
    },
    {
      name: "dob",
      label: "DOB",
      type: "date",
      disabledDate: (current) => {
        const today = moment();
        const maxDate = today.subtract(18, "years");
        return current && current > maxDate.endOf("day");
      },
      defaultValue: new Date().setAge(18),
      required: true,
    },
    {
      name: "gender",
      label: "Gender",
      type: "select",
      placeholder: "Please select",
      options: _enum.INCRED_GENDERS,
      required: true,
    },
    {
      name: "employment_type",
      label: "Employment Type",
      type: "select",
      options: _enum.INCRED_EMPLOYMENT_TYPES,
      required: true,
    },
    {
      name: "monthlySalary",
      label: "Net Monthly Income",
      type: "number",
    },
    {
      name: "pincode",
      label: "Pin code",
      type: "text",
      placeholder: "Please enter",
      onChange: (value) => handlePincode(value),
      required: true,
    },
    {
      name: "address",
      label: "Address",
      type: "textarea",
      placeholder: "Please enter",
      customcolspan: "12",
      required: true,
    },
    {
      name: "acknowledgement",
      label:
        "Digirep acknowledges the necessity of obtaining customer consent prior to submitting a loan application with Incred Finance.",
      type: "checkbox",
      placeholder: "Please select",
      onChange: (value) => handleAcknowledge(value),
      customcolspan: "12",
    },
  ]);

  const [loader, setLoader] = useState(false);
  const [applicantFormData, setApplicantFormData] = useState({});
  const [pandetails, setPandetails] = useState(null);
  const [showPanModal, setShowPanModal] = useState(false);

  const handleAcknowledge = (e) => {
    let submitbtn = document.querySelector(
      'button[data-submitbutton="applicationCanvas"]'
    );
    if (submitbtn) submitbtn.disabled = !e.target.checked;
  };

  const handleMobileNo = (e) => {
    const numberReg = _enum?.REGEX?.number;
    const fieldValue = e.target.value;
    if (numberReg.test(fieldValue) && fieldValue.length === 10)
      setErrorMessage("");
    else
      setErrorMessage({
        name: "mobileNo",
        message: "Please enter a valid Mobile Number",
      });
  };

  const handlePanCard = (e) => {
    const panRegex = _enum?.REGEX?.pan;
    if (panRegex.test(e.target.value)) setErrorMessage("");
    else setErrorMessage({ name: "panNo", message: "Invalid PAN Number" });
  };

  const handlePincode = (e) => {
    const numberReg = _enum?.REGEX?.number;
    const fieldValue = e.target.value;

    if (numberReg.test(fieldValue) && fieldValue.length === 6) {
      setErrorMessage("");
    } else {
      setErrorMessage({
        name: "pincode",
        message: "Please enter a valid pincode",
      });
    }
  };

  const closePanModal = () => {
    setShowPanModal(false);
  };

  const handlePanVerify = (e, name) => {
    console.log("pan verification", e, name);
    let panNumberVal = document.querySelector('[name="panNo"]').value;
    if (panNumberVal.length > 0) {
      let payload = {
        pan: panNumberVal,
      };
      props.apis.apiGetVASPanDetails(payload).then((response) => {
        console.log({ response });

        let { panInfo } = response;
        setPandetails(panInfo);
        setShowPanModal(true);
      });
    } else {
      swal2.fire({
        title: "Applicant PAN No. is Required",
        icon: "error",
        iconColor: "#234C9B",
        timer: 4000,
      });
    }
  };

  const handlePanSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    closePanModal();
  };

  const addModalFields = (values) => {
    setEditmode(false);
    setViewmode(false);

    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );

    const { name } = props?.$store?.user || {};
    let tdsAmtField = modalFields.find((o) => o.name === "connector_name");
    tdsAmtField.defaultValue = name;

    let relationalManagerField = modalFields.find(
      (o) => o.name === "relational_manager"
    );
    relationalManagerField.options = incredManagers.options("value");

    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);

    setPopupfields(fields);
    setPopupdata(formdata);
    setOpenCanvas(true);
  };

  const onModalClose = () => {
    setPopupfields({});
    setViewmode(false);
    setEditmode(false);
    setEditData({});
    setErrorMessage("");
    closeModal();
  };

  useEffect(() => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    setPopupfields(fields);
  }, [editData]);

  const handleSubmit = (e, data) => {
    e.preventDefault();
    console.log("formdata: ", data);
    let formdata = data;
    setApplicantFormData(formdata);

    // 1. send consent otp request
    setLoader(true);

    // open otp modal
    setShowOTPModal(true);
    let otpPayload = {
      mobileNo: data?.mobileNo,
    };

    // call consent OTP API
    props.apis
      .apiIncredSendConsentOTP(otpPayload)
      .then((res) => {
        console.log({ apiSendConsentOTP: res });
        // closeModal();
        setLoader(false);
        setAppId(res?.data?.applicationId);
        toast.success("OTP sent successfully!!", toastConfig);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const resendConsentOTP = (e) => {
    e.preventDefault();
    let payload = {
      applicationId: appId,
    };
    props.apis
      .apiIncredResendConsentOTP(payload)
      .then((res) => {
        console.log({ apiIncredResendConsentOTP: res });
        toast.success("OTP sent successfully!!", toastConfig);
      })
      .catch((err) => console.error(err));
  };

  const handleConsentOtpVerification = (e, otpcode) => {
    e.preventDefault();
    let payload = {
      applicationId: appId,
      consentCode: otpcode,
    };
    props.apis
      .apiIncredVerifyConsentOTP(payload)
      .then((res) => {
        console.log({ apiIncredVerifyConsentOTP: res });
        setLoader(true);
        setShowOTPModal(false);

        let applicantPayload = {
          ...Object.except(applicantFormData, [
            "acknowledgement",
            "connector_name",
          ]),
          dob: new Date(applicantFormData?.dob).toISOString(),
        };
        // applicantPayload.applicationId = appId;
        if (res?.isVerified === true) {
          props.apis
            .apiAddIncredApplicant(applicantPayload)
            .then((res) => {
              console.log({ apiAddIncredApplicant: res });
              closeOTPModal();
              closeModal();
              setLoader(false);
              getInCredFinance();
              swal2.fire({
                title: "Application submitted successfully.",
                icon: "success",
                iconColor: "#234C9B",
                timer: 4000,
                confirmButtonText: "ok",
              });
            })
            .catch((err) => {
              setLoader(false);
              console.log(err);
            });
        } else {
          swal2.fire({
            title: "Consent not verfied.",
            icon: "error",
            iconColor: "#234C9B",
            timer: 4000,
            confirmButtonText: "ok",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("OTP mismatched, Please enter valid OTP!!", toastConfig);
      });
  };

  const getIncredManagers = () => {
    props.apis.apiGetIncredManagers().then(({ data, total }) => {
      console.log({ data });
      setIncredManagers(data);
    });
  };
  // ----------------------------------------------------------------

  useEffect(() => {
    console.log({ pageID });

    // getInCredFinance();
    getIncredManagers();

    if (isPanVerified && isAadharVerified) {
      setIsAccountVerified(true);
    } else {
      setIsAccountVerified(false);
    }
  }, []);
  useEffect(() => {
    getInCredFinance();
  }, [search]);

  props.$setNode({
    props,
    search,
  });
  return (
    <>
      <MainPagetitle
        mainTitle={
          adminPersona
            ? "B2B Leads | Digital Leads "
            : "My Leads | Digital Leads "
        }
        back={getBreadCums()}
        backLink={getBackLink()}
        pageIcon={imagepaths.AdminLeadsIcon}
      />
      <div className="container-fluid d-flex flex-column">
        <DigitalLeadCard />
        {!adminPersona && (
          <div className="d-flex flex-row-reverse">
            <button
              type="button"
              className={Object.className({
                "apply-now-btn btn btn-primary ms-3 me-3": true,
              })}
              onClick={(...args) => addModalFields(...args)}
            >
              Apply Now
            </button>
            <button
              type="button"
              className={Object.className({
                "mt-3 border-0 bg-transparent": true,
              })}
              onClick={(...args) => handleBulkDownload(...args)}
            >
              <i className="mr-1">
                <img
                  src={imagepaths.DownloadIcon}
                  alt="download icon"
                  width="18px"
                />
              </i>
              Bulk Download
            </button>
          </div>
        )}
      </div>
      <div className="container-fluid pb-5">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="tbl-caption-container">
                  <InCredFinanceSearch
                    onSearch={(...arg) => onSearch(...arg)}
                  />
                </div>
                <AppTable
                  data={tableData}
                  columns={columns}
                  onChange={(...arg) => onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  viewAction={(...args) => viewApplication(...args)}
                  editable={false}
                  deletable={false}
                  loading={isFetching}
                ></AppTable>
              </div>
            </div>
          </div>
        </div>
      </div>

      <IncredModal
        id="applicationCanvas"
        title={`${
          editmode ? "Apply Now" : viewmode ? "Apply Now" : "Apply Now"
        }`}
        show={openCanvas}
        onClose={() => onModalClose()}
        data={popupdata}
        editdata={editData}
        fields={popupfields}
        viewmode={viewmode}
        editmode={editmode}
        errorMessage={errorMessage}
        // noValidate={true}
        onSubmit={(...args) => handleSubmit(...args)}
        submitText={`${editmode ? "Update" : "Save & Submit"}`}
        disableSubmit={true}
        loader={loader}
        disableallfields={viewmode ? true : false}
        inputlinkbtns={["panNo"]}
        verifyBtnAction={(...args) => handlePanVerify(...args)}
      />

      {/* CONSENT OTP MODAL */}
      <Modal
        show={showOTPModal}
        onHide={closeOTPModal}
        centered
        backdrop="static"
        className="consent_otp_modal"
      >
        <Modal.Header closeButton className="border-0 py-4"></Modal.Header>
        <Modal.Body className="text-center">
          <h2 className="mb-5">OTP to verify</h2>
          <p className="desc fs-4">We have sent an OTP to your mobile no.</p>
          <div className="row g-3 mb-4">
            <div className="col-12">
              <OTPInput
                value={otpValue}
                onChange={(e) => handleOTPChange(e)}
                separator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
                numInputs={6}
                shouldAutoFocus={true}
                isInputNum={true}
                containerStyle="otp_container"
              />
            </div>
            <div className="col-12">
              <div className="text-align-start otp_bottom">
                <span className="me-2">Not received OTP?</span>
                <button
                  className="btn px-0 text-primary fs-5"
                  onClick={(e) => resendConsentOTP(e)}
                >
                  Resend
                </button>
              </div>
            </div>
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary w-100 py-3"
                disabled={isOtpDisabled}
                onClick={(e) => handleConsentOtpVerification(e, otpValue)}
              >
                Verify
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* PAN MODAL */}
      <Modal
        show={showPanModal}
        onHide={closePanModal}
        centered
        backdrop="static"
        className="consent_otp_modal"
      >
        <Modal.Header
          closeButton
          className="border-0 py-4 d-flex align-item-start"
        >
          <h2>PAN Verification</h2>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="row g-3 mb-4">
            <div class="profile_details_container">
              <div class="profile_detail mb-3">
                <p class="m-0">Name as per PAN</p>
                <h4>{pandetails?.name || "-"}</h4>
              </div>
              {/* <div class="profile_detail mb-3">
                <p class="m-0">Date of birth as per PAN</p>
                <h4>{pandetails?.dob || "-"}</h4>
              </div> */}
              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-primary w-100 py-3 mb-3"
                  onClick={(...args) => handlePanSubmit(...args)}
                >
                  Ok
                </button>
                <button
                  type="button"
                  className="btn text-primary w-100 py-3"
                  onClick={(e) => closePanModal()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* <IncredStepModal
        id="profileCanvas"
        className="profile_canvas"
        title="Please Verify Below Information"
        show={openCanvas}
        handleSubmit={(...args) => handleSubmit(...args)}
        setGoSteps={setGoSteps}
        goSteps={goSteps}
        onClose={() => onModalClose()}
        myinfo={myinfo}
      /> */}
    </>
  );
};

export default constore(InCredFinance);
