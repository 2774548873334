import React, { useEffect, useRef } from "react";

function AppModalBs(props) {
  let {
    title = "Modal Title",
    className = "",
    closeIcon = "fa fa-close",
    contentClass = "",
    onClose = () => "",
    hidecancelbtn = false,
    hidecloseicon = false,
  } = props;
  let attrs = Object.except(props, [
    "title",
    "className",
    "role",
    "closeIcon",
    "contentClass",
  ]);
  const closebtn = useRef();
  useEffect(() => {
    const { onLoad = () => "" } = props;
    onLoad({ closebtn });
  }, []);
  let modalSize = props.modalSize || "modal-lg";
  // console.log({ modalSize });
  return (
    <div
      className={"modal custom-modal fade " + className}
      role="dialog"
      {...attrs}
      data-bs-backdrop="static"
    >
      <div
        className={`modal-dialog modal-dialog-centered ${
          modalSize === "default" ? "" : modalSize
        }`}
        role="document"
      >
        <div className={"modal-content " + contentClass}>
          <div className="modal-header border-0">
            <h5 className="modal-title m-auto text-3xl font-semibold">
              {title}
            </h5>
            {!hidecloseicon && (
              <button
                type="button"
                ref={closebtn}
                className="border-0 bg-white pb-5"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="bsmodalclose"
                onClick={onClose}
              >
                <span aria-hidden="true">
                  <i className={closeIcon}></i>
                </span>
              </button>
            )}
          </div>
          <div className="modal-body">{props.children}</div>
          {/*footer*/}
          {props?.footer && (
            <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
              {
                <button
                  className="btn bg-gray-100 border mx-2"
                  type="button"
                  onClick={() => onClose()}
                >
                  {props?.cancelText || "Close"}
                </button>
              }
              <button className="btn btn-primary" onClick={() => onClose()}>
                <span className="text-white">{props?.okText || "Submit"}</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AppModalBs;
