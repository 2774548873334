import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const ZypeSubmit = (props) => {
  const [errorMessage, setErrorMessage] = useState({ name: "", message: "" });
  const [fields, setFields] = useState([
    {
      name: "mobileNo",
      label: "Applicant Mobile No.",
      type: "number",
      onChange: (value) => handleMobileNo(value),
      readOnly: true,
      required: true,
    },
    {
      name: "panNo",
      label: "Applicant PAN No.",
      type: "text",
      onChange: (value) => handlePanCard(value),
      readOnly: true,
      required: true,
    },
    {
      name: "applicant_name",
      label: "Applicant Name (As per PAN)",
      type: "text",
      placeholder: "Please enter",
      required: true,
    },
    {
      name: "email",
      label: "Applicant Email ID",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "dob",
      label: "DOB",
      type: "date",
      disabledDate: (current) => {
        const today = moment();
        const maxDate = today.subtract(18, "years");
        return current && current > maxDate.endOf("day");
      },
      defaultValue: new Date().setAge(18),
      required: true,
    },
    {
      name: "employment_type",
      label: "Employment Type",
      type: "select",
      options: _enum.INCRED_EMPLOYMENT_TYPES,
      required: true,
    },
    {
      name: "income",
      label: "Income",
      type: "number",
      required: true,
    },
    {
      name: "org_name",
      label: "Organization Name",
      type: "text",
    },
    {
      name: "acknowledgement",
      label:
        "Digirep acknowledges the necessity of obtaining customer consent prior to submitting a loan application with Zype.",
      type: "checkbox",
      placeholder: "Please select",
      onChange: (value) => handleAcknowledge(value),
      customcolspan: 12,
    },
  ]);

  const handleMobileNo = (e) => {
    const numberReg = _enum?.REGEX?.number;
    const fieldValue = e.target.value;
    if (numberReg.test(fieldValue) && fieldValue.length === 10)
      setErrorMessage("");
    else
      setErrorMessage({
        name: "mobileNo",
        message: "Please enter a valid Mobile Number",
      });
  };

  const handlePanCard = (e) => {
    console.log(e, "pan");

    const panRegex = _enum?.REGEX?.pan;
    if (panRegex.test(e.target.value)) setErrorMessage("");
    else setErrorMessage({ name: "panNo", message: "Invalid PAN Number" });
  };

  const handleAcknowledge = (e) => {
    let submitbtn = document.querySelector("#zypeSubmitBtn");
    if (submitbtn) submitbtn.disabled = !e.target.checked;
  };

  const updateFields = (data) => {
    let allowedFields = [...fields.map((v) => v.name)];
    let updatedfields = Object.only(data, allowedFields);

    let farr = Object.keys(updatedfields);
    console.log({ updateFields: data, updatedfields, farr });

    farr.forEach((name) => {
      let tfield = fields.find((o) => o.name === name);
      tfield.defaultValue = data[name];

      if (props?.editData) {
        tfield.disabled = true;
        document.querySelector(
          '[name="appinput-acknowledgement"] input'
        ).checked = true;
        document.querySelector(
          '[name="appinput-acknowledgement"] input'
        ).disabled = true;
      }
    });
    setFields([...fields]);
  };

  const handleZypeSubmit = (e) => {
    props.onhandleZypeSubmit(e);
  };

  useEffect(() => {
    console.log(props.checkdata);
    if (Object.keys(props.checkdata).length > 0) {
      updateFields(props?.checkdata);
    } else {
      updateFields(props?.editData);
    }
  }, []);

  useEffect(() => {
    console.log(props.editData);
  }, [props.editData]);

  props.$setNode({
    props,
    errorMessage,
  });

  return (
    <>
      <AppForm className="container" onSubmit={handleZypeSubmit}>
        {fields?.map((field, i) => {
          return (
            <div
              className={`col-12 col-md-${field?.customcolspan || 12} col-lg-${
                field?.customcolspan || 6
              }`}
              key={i}
            >
              <AppInput
                name={field?.name}
                label={field?.label}
                type={field?.type}
                defaultValue={field?.defaultValue || ""}
                options={field?.options || []}
                {...field}
              ></AppInput>
              {errorMessage?.name === field?.name && (
                <div className="invalid-feedback d-block">
                  {errorMessage.message}
                </div>
              )}
            </div>
          );
        })}
        <div className="col-12 col-md-12 mt-4 d-flex justify-content-end">
          <button
            type="button"
            className="formdata-cancel-btn btn"
            onClick={props?.onClose}
          >
            Cancel
          </button>
          <button
            type="submit"
            id="zypeSubmitBtn"
            className="btn btn-primary ms-3"
            disabled={Object.keys(errorMessage).length > 0 ? true : false}
          >
            Proceed
          </button>
        </div>
      </AppForm>
    </>
  );
};

export default constore(ZypeSubmit);
