import React, { useEffect, useState } from "react";

import MainPagetitle from "../../../layouts/MainPagetitle";
import DigitalLeadCard from "../DigitalLeads/DigitalLeadCard";
import ZypeModal from "./ZypeModal";
import ZypeSearch from "./ZypeSearch/ZypeSearch";

const Zype = (props) => {
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const adminPersona = props?.$store?.user?.role === _enum.ROUTE_PERSONAS.admin;
  const pageID = props?.urlparams?.params?.leadname;
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const [rowcount, setRowcount] = useState(0);
  const columns = [
    {
      dataIndex: "applicant_name",
      title: "Applicant Name",
      render: (text, record) => {
        return <span className="table-primary-text">{`${text || "-"}`}</span>;
      },
      // display: adminPersona ? true : false,
    },
    {
      dataIndex: "application_id",
      title: "Application Number",
      render: (text) => {
        return <>{text || "-"}</>;
      },
    },
    {
      dataIndex: "mobileNo",
      title: "Mobile No.",
    },
    {
      dataIndex: "createdAt",
      title: "Application Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "status",
      title: "Application Status",
      sorter: false,
      render: (text, record) => {
        return (
          <span
            className={`badge light border-0 ${
              text === "REJECT" ? "badge-danger" : "badge-success"
            } `}
          >
            {text?.toTitleCase()}
          </span>
        );
      },
    },
    // {
    //   dataIndex: "reject_reason",
    //   title: "Rejected Reason",
    //   render: (text) => {
    //     return <>{text || "-"}</>;
    //   },
    // },
    {
      dataIndex: "irr",
      title: "ROI",
      sorter: false,
      render: (text, record) => {
        return <>{text || "-"}</>;
      },
    },
    {
      dataIndex: "loan_offers",
      title: "Max Eligibility",
      render: (text) => {
        return <>{text?.cur() || "-"}</>;
      },
      // display: adminPersona ? true : false,
    },
  ];

  const onSearch = (data, reset) => {
    console.log("onSearch on leads", data, reset);
    if (Object.keys(data).length === 0) {
      setSearch({
        ...data,
        ...search,
      });
      getZype(data, reset);
    } else {
      setSearch({
        // ...data,
        ...search,
      });
      getZype(data, reset);
    }
  };

  const onPageChange = (pageData) => {
    // console.log({ onPageChange: pageData });
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const viewApplication = (record) => {
    let payload = {
      application_id: record.application_id,
    };
    props.apis.apiGetZypeLeadById(payload).then((response) => {
      console.log({ response });

      setEditData(response.data);
      setGoSteps(1);
      openModal();
    });
  };

  const getBreadCums = () => {
    let pagetitle = _enum?.LEAD_TITLE_MAP?.zype;
    return pagetitle;
  };

  const getBackLink = () => {
    return "/app/businesstobusiness?activetab=digitalleads";
  };

  const handleBulkDownload = (e) => {
    // let ids = tableData?.map((v) => v.applicationId);
    // var payload = {
    //   consolidatedLeadIds: ids,
    // };
    // console.log({ ids, payload });
    // if (typeof ids[0] !== undefined) {
    //   props.apis.apiDownloadAdminPaymentInfo(payload).then((res) => {
    //     console.log({ res });
    //     if (res) {
    //       let url = `${
    //         props.apis.api().api_base
    //       }/payment-info/admin/download?consolidatedLeadIds=${ids}`;
    //       var downloadURL = url;
    //       var link = document.createElement("a");
    //       link.setAttribute(
    //         "download",
    //         `digi_rep_payout_${new Date().toLocaleDateString()}.xlsx`
    //       );
    //       link.href = downloadURL;
    //       link.click();
    //     }
    //   });
    // }
  };

  // ZYPE
  const getZype = (params = {}, reset) => {
    let payload = {};
    if (reset) {
      let defaultSearchData = Object.only(search, ["limit", "page"]);
      setSearch(defaultSearchData);
      payload = {
        ...defaultSearchData,
        ...params,
      };
    } else {
      payload = {
        ...search,
        ...params,
      };
    }

    props.apis
      .apiGetZypeLeads(payload)
      .then(({ data, total }) => {
        console.log({ apiGetZypeLeads: data });
        setTableData(data);
        setIsFetching(false);
        setRowcount(total);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const openModal = () => {
    setOpenCanvas(true);
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };

  // ----------------------------------------------------------------

  const [editData, setEditData] = useState({});

  const addModalFields = () => {
    setOpenCanvas(true);
  };

  // ----------------------------------------------------------------

  const [openCanvas, setOpenCanvas] = useState(false);
  const [goSteps, setGoSteps] = useState(0);

  const onModalClose = () => {
    setEditData({});
    setGoSteps(0);
    closeModal();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let formdata = e.target.getData();
    let payload = {
      ...Object.except(formdata, "acknowledgement"),
    };

    props.apis.apiAddZypeLead(payload).then((res) => {
      console.log({ res });
      getZype();
      closeModal();
      swal2.fire({
        title: "Zype Lead Created successfully",
        icon: "success",
        iconColor: "#234C9B",
        timer: 4000,
      });
    });
  };

  // ----------------------------------------------------------------
  useEffect(() => {
    console.log({ pageID });
    getZype();
  }, [search]);

  props.$setNode({
    props,
    search,
  });
  return (
    <>
      <MainPagetitle
        mainTitle={
          adminPersona
            ? "B2B Leads | Digital Leads "
            : "My Leads | Digital Leads "
        }
        back={getBreadCums()}
        backLink={getBackLink()}
        pageIcon={imagepaths.AdminLeadsIcon}
      />
      <div className="container-fluid d-flex flex-column">
        <DigitalLeadCard />
        {!adminPersona && (
          <div className="d-flex flex-row-reverse">
            <button
              type="button"
              className={Object.className({
                "apply-now-btn btn btn-primary ms-3 me-3": true,
              })}
              onClick={(...args) => addModalFields(...args)}
            >
              Apply Now
            </button>
            <button
              type="button"
              className={Object.className({
                "mt-3 border-0 bg-transparent": true,
              })}
              onClick={(...args) => handleBulkDownload(...args)}
            >
              <i className="mr-1">
                <img
                  src={imagepaths.DownloadIcon}
                  alt="download icon"
                  width="18px"
                />
              </i>
              Bulk Download
            </button>
          </div>
        )}
      </div>
      <div className="container-fluid pb-5">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="tbl-caption-container">
                  <ZypeSearch onSearch={(...arg) => onSearch(...arg)} />
                </div>
                <AppTable
                  data={tableData}
                  columns={columns}
                  onChange={(...arg) => onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  viewAction={(...args) => viewApplication(...args)}
                  editable={false}
                  deletable={false}
                  loading={isFetching}
                ></AppTable>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ZypeModal
        id="profileCanvas"
        className="profile_canvas"
        title="Apply Now"
        show={openCanvas}
        handleSubmit={(...args) => handleSubmit(...args)}
        setGoSteps={setGoSteps}
        goSteps={goSteps}
        onClose={() => onModalClose()}
        editData={editData}
      />
    </>
  );
};

export default constore(Zype);
