import React, { useEffect, useState } from "react";
import PendingSearch from "./PendingSearch";

const TeamLeads = (props) => {
  const [isFetching, setIsFetching] = useState(true);
  const [search, setSearch] = useState({
    page: 1,
    // limit: 10,
  });
  const [rowcount, setRowcount] = useState(0);
  const [data, setData] = useState([]);
  const columns = [
    {
      dataIndex: "connectorName",
      title: "Digi Team Member Name",
      render: (text, record) => {
        return (
          <span className="table-primary-text">{record?.createdBy?.name}</span>
        );
      },
    },
    {
      dataIndex: "applicantName",
      title: "Applicant Name",
    },
    {
      dataIndex: "mobileNo",
      title: "Applicant Mobile No",
    },
    {
      dataIndex: "bankName",
      title: "Bank Name",
    },
    {
      dataIndex: "productType",
      title: "Product Type",
    },
    {
      dataIndex: "channelType",
      title: "Channel",
    },
    {
      dataIndex: "loanAmt",
      title: "Loan Amt.",
    },
    {
      dataIndex: "loanStatus",
      title: "Loan Status",
      render: (text) => {
        return (
          <span
            className={`badge light badge-${
              _enum.STATUS_COLOR_MAP[text.toLowerCase()]
            }`}
          >
            {text?.toTitleCase() || "-"}
          </span>
        );
      },
    },
  ];

  const onPageChange = (pageData) => {
    // console.log({ onPageChange: pageData });
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const getTeamLeads = (params = {}, reset) => {
    let payload = {};
    if (reset) {
      let defaultSearchData = Object.only(search, ["limit", "page"]);

      setSearch(defaultSearchData);
      payload = {
        ...defaultSearchData,
      };
    } else {
      payload = {
        ...search,
        ...params,
      };
    }
    props.apis
      .apiGetOverallApplicantsForManagerTeam(payload)
      .then(({ data, count }) => {
        console.log({ apiGetOverallApplicantsForManagerTeam: data });
        setData(data);
        setRowcount(count);
        setIsFetching(false);
      });
  };

  const onSearch = (data, reset) => {
    console.log("onSearch", data, reset);
    if (Object.keys(data).length === 0) {
      setSearch({
        ...data,
        ...search,
      });
      getTeamLeads(data, reset);
    } else {
      setSearch({
        ...data,
        ...search,
      });
      getTeamLeads(data, reset);
    }
  };

  useEffect(() => {
    getTeamLeads();
  }, []);
  return (
    <>
      <PendingSearch onSearch={(...arg) => onSearch(...arg)} />
      <div className="paymentdetails_table_outer">
        <AppTable
          data={data}
          columns={columns}
          onChange={(...arg) => onPageChange(...arg)}
          total={rowcount}
          reorder={true}
          editable={false}
          viewable={false}
          deletable={false}
          loading={isFetching}
        ></AppTable>
      </div>
    </>
  );
};

export default constore(TeamLeads);
