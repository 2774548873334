export const OpsMenuList = [
    {
        title: "Assigned Leads",
        iconStyle: imagepaths.LeadsIcon,
        iconActive: imagepaths.LeadsIconActive,
        to: "/app/leads",
        id: "leads",
    },
    {
        title: "My Applications",
        iconStyle: imagepaths.applicationImg,
        iconActive: imagepaths.applicationActiveImg,
        to: "/app/applications",
        id: "applications",
    },
    {
        title: "Policies",
        iconStyle: imagepaths.shieldIcon,
        iconActive: imagepaths.shieldIconActive,
        to: "/app/connectorhome",
        id: ["dataPrivacyModal", "dataStorageModal", "rbipolicy"],
        classChange: "mm-collapse",
        content: [
            {
                title: "Data Privacy Policy",
                iconStyle: imagepaths.PrivacyPolicy,
                iconActive: imagepaths.PrivacyPolicyActive,
                to: "/app/connectorhome",
                classChange: "menu-popup",
                id: "dataPrivacyModal",
                showTitle: true
            },
            {
                title: "Data Storage Policy",
                iconStyle: imagepaths.StoragePolicy,
                iconActive: imagepaths.StoragePolicyActive,
                to: "/app/connectorhome",
                classChange: "menu-popup",
                id: "dataStorageModal",
            },
            {
                title: "RBI Policy",
                iconStyle: imagepaths.RBIPolicy,
                iconActive: imagepaths.RBIPolicyActive,
                id: "rbipolicy",
                classChange: "menu-link",
            },
        ],
    },
];
