import React, { Component } from "react";

export class AppForm extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  onSubmit(e) {
    let { props } = this;
    e.preventDefault();
    let submit = props?.onSubmit || (() => "");
    submit(e, this.form.current.getData(), this.form.current);
  }

  handleOnReset(e) {
    [...e.target.querySelectorAll("[name^='appinput']")].map((e) =>
      e.vnode.clearValues()
    );
  }

  render() {
    let { props } = this;
    return (
      <form
        id={props?.id}
        ref={this.form}
        {...props}
        onSubmit={(e) => this.onSubmit(e)}
        onReset={(e) => this.handleOnReset(e)}
        className={`AppForm ${props.className}`}
      >
        <button
          type="submit"
          disabled
          className="hidden"
          aria-hidden="true"
          style={{ opacity: 0 }}
        ></button>
        <div className="row">{props.children}</div>
      </form>
    );
  }
}

export default AppForm;
