export default {

    // apiAddMasterRole(data) {
    //     console.log({ apiCreateRole: data });
    //     return this.request(this.api().ADD_MASTER_ROLE, data).exec().log().get();
    // },

    apiGetMasterRoles(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_MASTER_ROLES, payload)
            .exec().log()
            .mapresults((response) => {
                console.log(response, 'response')
                return response
            })
            .get();
    },

    // apiDeleteMasterRole(urldata = mixins.params()) {

    //     return this.request(this.api().DELETE_MASTER_ROLE)
    //         .urltransform(urldata)
    //         .exec().log().get();
    // },

    apiAddMasterDesignations(data) {
        console.log({ apiAddMasterDesignations: data });
        return this.request(this.api().ADD_MASTER_DESIGNATION, data).exec().log().get();
    },

    apiGetMasterDesignations(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_MASTER_DESIGNATIONS, payload)
            .exec().log()
            .mapresults((response) => {
                return response?.data
            })
            .get();
    },

    apiDeleteMasterDesignation(urldata = mixins.params()) {

        return this.request(this.api().DELETE_MASTER_DESIGNATION)
            .urltransform(urldata)
            .exec().log().get();
    },

    apiAddMasterDepartments(data) {
        console.log({ apiAddMasterDepartments: data });
        return this.request(this.api().ADD_MASTER_DEPARTMENT, data).exec().log().get();
    },

    apiGetMasterDepartments(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_MASTER_DEPARTMENTS, payload)
            .exec().log()
            .mapresults((response) => {
                return response?.data
            })
            .get();
    },

    apiDeleteMasterDepartment(urldata = mixins.params()) {

        return this.request(this.api().DELETE_MASTER_DEPARTMENT)
            .urltransform(urldata)
            .exec().log().get();
    },

    apiAddMasterLocation(data) {
        console.log({ apiAddMasterLocation: data });
        return this.request(this.api().ADD_MASTER_LOCATION, data).exec().log().get();
    },

    apiGetMasterLocations(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_MASTER_LOCATIONS, payload)
            .exec().log()
            .mapresults((response) => {
                return response
            })
            .get();
    },

    apiGetLocationById(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().GET_MASTER_LOCATION_BY_ID,)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((res) => {
                return res
            })
            .get();
    },

    apiUpdateMasterLocation(data, urldata = {}) {
        console.log({ apiUpdateMasterLocation: data });
        urldata = { ...mixins.params(), ...urldata };

        let payload = {
            locationName: data?.locationName,
            city: data?.city,
            state: data?.state,
            latitude: data?.latitude,
            longitude: data?.longitude,
            status: data?.status
        }
        payload = Object.filter(payload, (v) => v);

        return this.request(this.api().UPDATE_MASTER_LOCATION, payload).urltransform(urldata).exec().log().get();
    },

    apiDeleteMasterLocation(urldata = mixins.params()) {

        return this.request(this.api().DELETE_MASTER_LOCATION)
            .urltransform(urldata)
            .exec().log().get();
    },

    // EMPLOYEE

    apiGetMasterLocations(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_MASTER_LOCATIONS, payload)
            .exec().log()
            .mapresults((response) => {
                return response
            })
            .get();
    },

    apiAddDigirep(data) {
        return this.request(this.api().CREATE_DIGIREP, data)
            .exec().log()
            .mapresults((response) => {
                console.log({ apiAddDigirep: response });
                return response.data
            })
            .get();
    },

    apiGetDigirep(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_DIGIREP, payload)
            .exec().log()
            .mapresults((res) => {
                return {
                    data: res.data,
                    total: res.count
                }
            })
            .get();
    },

    apiGetEmployeeById(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().GET_EMPLOYEE_BY_ID)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((res) => {
                return {
                    profileId: res?.profileId,
                    name: res?.name,
                    mobileNumber: res?.mobileNumber,
                    emailAddress: res?.emailAddress,
                    role: res?.role,
                    accountHolderName: res?.accountHolderName,
                    accountNumber: res?.accountNumber,
                    accountHolderBankName: res?.accountHolderBankName,
                    accountHolderBankIFSC: res?.accountHolderBankIFSC,
                    state: res?.state,
                    city: res?.city,
                    panCardId: res?.panCardId,
                    designation: res?.designation,
                    supervisorId: res?.supervisor?.profileId,
                    reportingManager: res?.supervisor?.name,
                    workLocation: res?.workLocation,
                }
            })
            .get();
    },

    apiUpdateEmployee(data) {
        console.log({ apiUpdateEmployee: data });

        let payload = {
            name: data?.name,
            mobileNumber: data?.mobileNumber,
            emailAddress: data?.emailAddress,
            role: data?.role,
            accountHolderName: data?.accountHolderName,
            accountType: data?.accountType,
            accountNumber: data?.accountNumber,
            accountHolderBankName: data?.accountHolderBankName,
            accountHolderBankIFSC: data?.accountHolderBankIFSC,
            state: data?.state,
            city: data?.city,
            panCardId: data?.panCardId,
            designation: data?.designation,
            workLocation: data?.workLocation,
            supervisorId: data?.supervisorId,
            profileId: data?.profileId,
        }
        payload = Object.filter(payload, (v) => v);

        return this.request(this.api().UPDATE_EMPLOYEE, payload).exec().log().get();
    },

    apiDeleteEmployee(urldata = mixins.params()) {

        return this.request(this.api().DELETE_EMPLOYEE)
            .urltransform(urldata)
            .exec().log().get();
    },

    apiGetMyInfo() {
        return this.request(this.api().GET_MY_INFO)
            .exec()
            .log()
            .mapresults((res) => {
                return res
            })
            .get();
    },

    apiGetSingleProfileInfo(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().GET_SINGLE_PROFILE_INFO,)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((res) => {
                return res
            })
            .get();
    },

    apiDeleteAadhar(urldata = mixins.params()) {

        return this.request(this.api().DELETE_CUSTOMER_AADHAR)
            .urltransform(urldata)
            .exec().log().get();
    },

    apiDeletePan(urldata = mixins.params()) {

        return this.request(this.api().DELETE_CUSTOMER_PAN)
            .urltransform(urldata)
            .exec().log().get();
    },
    //BANK NAMES

    apiAddBankname(data) {
        console.log({ apiAddBankname: data });
        return this.request(this.api().ADD_BANK_NAME, data).exec().log().get();
    },

    apiGetBanknames(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_BANK_NAMES, payload)
            .exec().log()
            .mapresults((response) => {
                return response
            })
            .get();
    },

    apiGetBanknameById(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().GET_BANK_BY_ID,)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((res) => {
                return res
            })
            .get();
    },

    apiUpdateBankname(data, urldata = {}) {
        console.log({ apiUpdateEmployee: data });
        urldata = { ...mixins.params(), ...urldata };

        let payload = {
            bankName: data?.bankName,
            ETag: data?.ETag,
        }
        payload = Object.filter(payload, (v) => v);

        return this.request(this.api().UPDATE_BANK_NAME, payload).urltransform(urldata).exec().log().get();
    },

    apiDeleteBankname(urldata = mixins.params()) {

        return this.request(this.api().DELETE_BANK_NAME)
            .urltransform(urldata)
            .exec().log().get();
    },

    // BANK WISE OFFER
    apiGetBankOfferDataMoke() {
        let data =
            [{
                bankId: 1,
                bank_name: "HDFC",
                minimum_salary: 250000,
                loanamount: 5000000,
                tenure: "4 Yrs",
                roi: "10%",
                tat: "5 Mins",
            }]

        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    apiGetBankOfferViewDataMoke() {
        let data = {
            "features_benefits": [
                "100% digital & paperless process",
                "Tenure: 12-48 Months",
                "Loan amount ranging from Rs. 50,000 to Rs. 10 Lakhs",
                "Processing Fee Upto 3%",],
            "eligibility_criteria": [
                "100% digital & paperless process",
                "Tenure: 12-48 Months",
                "Loan amount ranging from Rs. 50,000 to Rs. 10 Lakhs",
                "Processing Fee Upto 3%",

            ],
            "documents_required": [
                "100% digital & paperless process",
                "Tenure: 12-48 Months",
                "Loan amount ranging from Rs. 50,000 to Rs. 10 Lakhs",
                "Processing Fee Upto 3%",
            ],
            "point_of_contact": {
                name: "Suresh Vadla",
                mobileNumber: "9102938495",
            },
            "rate_card": {
                rateFieldId: "2c6825775d870b165fa2e0fe95c9f6fc"
            },
            "policy": {
                policyFieldId: "2c6825775d870b165fa2e0fe95c9f6fc"
            },
        }

        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    // ----------------------------------------------------------------
    apiAddBankOffer(data) {
        let payload = {
            ...data,
        }
        return this.request(this.api().ADD_BANK_OFFER, payload).exec().log().get();
    },

    apiGetBankOffers(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_BANK_OFFERS, payload)
            .exec().log()
            .mapresults((res) => {
                return {
                    data: res.data,
                    total: res.count
                }
            })
            .get();
    },

    apiGetBankOfferById(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().GET_BANK_OFFER_BY_ID,)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((res) => {
                return res
            })
            .get();
    },

    apiUpdateBankOffer(data, urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        let payload = {
            ...data,
        }

        return this.request(this.api().UPDATE_BANK_OFFER, payload).urltransform(urldata).exec().log().get();
    },

    apiDeleteBankOffer(urldata = mixins.params()) {

        return this.request(this.api().DELETE_BANK_OFFER)
            .urltransform(urldata)
            .exec().log().get();
    },
    // ----------------------------------------------------------------
    // INTEREST RATES

    apiAddInterestRate(data) {
        console.log({ apiAddInterestRate: data });
        return this.request(this.api().ADD_INTEREST_RATES, data).exec().log().get();
    },

    apiGetInterestRates(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_INTEREST_RATES, payload)
            .exec().log()
            .mapresults((response) => {
                return response
            })
            .get();
    },


    apiGetInterestRateById(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().GET_INTEREST_RATE_BY_ID,)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((res) => {
                return res
            })
            .get();
    },

    apiUpdateInterestRate(data, urldata = {}) {
        console.log({ apiUpdateEmployee: data });
        urldata = { ...mixins.params(), ...urldata };

        let payload = {
            bankName: data?.bankName,
            loanType: data?.loanType,
            interestRate: data?.interestRate,
            interestType: data?.interestType,
            processingFee: data?.processingFee,
            loanAmount: data?.loanAmount,
            tenure: data?.tenure,
            fileId: data?.fileId,
        }
        payload = Object.filter(payload, (v) => v);

        return this.request(this.api().UPDATE_INTEREST_RATE, payload).urltransform(urldata).exec().log().get();
    },

    apiDeleteInterestRate(urldata = mixins.params()) {

        return this.request(this.api().DELETE_INTEREST_RATE)
            .urltransform(urldata)
            .exec().log().get();
    },

    //PRODUCT TYPES

    apiAddProductType(data) {
        console.log({ apiAddProductType: data });
        return this.request(this.api().ADD_PRODUCT_TYPE, data).exec().log().get();
    },

    apiGetProductTypes(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_PRODUCT_TYPES, payload)
            .exec().log()
            .mapresults((response) => {
                return response
            })
            .get();
    },

    apiDeleteProductType(urldata = mixins.params()) {

        return this.request(this.api().DELETE_PRODUCT_TYPE)
            .urltransform(urldata)
            .exec().log().get();
    },

    // MY PROFILE

    apiGetProfile(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_PROFILE, payload)
            .exec().log()
            .mapresults((res) => {
                return {
                    data: res.data,
                    total: res.count
                }
            })
            .get();
    },

    // MASTER DATA LOCATION

    getMasterLocations(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_LOCATION, payload)
            .exec().log()
            .mapresults((res) => {
                return {
                    data: res.data,
                    total: res.count
                }
            })
            .get();
    },
}