import Applications from "../../../jsx/components/Dashboard/BusinessToCustomer/Applications/applications";

const ApplicatiionsWrapper = () => {
  return <Applications />;
};

export const allowedRoles = [
  _enum.ROUTE_PERSONAS.sales,
  _enum.ROUTE_PERSONAS.ops,
];

export default constore(ApplicatiionsWrapper);
