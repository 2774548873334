import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function ForgotPassword(props) {
  const [email, setEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [toggleForm, setToggleForm] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showForms, setShowForms] = useState(true);
  const [showEmailAlert, setShowEmailAlert] = useState(false);

  let errorsObj = { email: "" };
  let errorsResetObj = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [resetErrors, setResetErrors] = useState(errorsResetObj);

  const navigate = useNavigate();

  function onForgetPassword(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) return;
    let data = {
      email: email,
    };
    props.apis
      .apiPasswordGenerate(data)
      .then((res) => {
        console.log({ apiPasswordGenerate: res });
        setShowForms(false);
        setShowEmailAlert(true);
        setShowNotification(true);
      })
      .catch((err) => {
        console.log({ apiPasswordGenerate: err });
        const errorObj = { ...errorsObj };
        errorObj.email = "Email is Required";
      });
  }

  function onPasswordReset(e) {
    console.log({ onPasswordReset: e });
    e.preventDefault();
    let error = false;
    const errorResetObj = { ...errorsResetObj };

    if (oldPassword === "") {
      errorResetObj.oldPassword = "Old Password is Required";
      error = true;
    }

    if (newPassword === "") {
      errorResetObj.newPassword = "New Password is Required";
      error = true;
    }

    if (confirmPassword === "") {
      errorResetObj.confirmPassword = "Confirm Password is Required";
      error = true;
    }
    if (newPassword !== confirmPassword) {
      errorResetObj.confirmPassword = "Passwords don't match";
      error = true;
    }

    console.log({ oldPassword, newPassword, confirmPassword });
    setResetErrors(errorResetObj);
    if (error) return;
    let data = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    };
    props.apis
      .apiPasswordReset(data)
      .then((res) => {
        console.log({ apiPasswordReset: res });
        setShowForms(false);
        setShowNotification(true);
        setShowEmailAlert(false);
      })
      .catch((err) => {
        console.log({ apiPasswordReset: err });
        const errorObj = { ...errorsObj };
        errorObj.email = "Password is Required";
      });
  }

  function handleNotification(e, msg) {
    e.preventDefault();
    console.log(msg);
    setShowNotification(false);
    setShowForms(true);
    setToggleForm(false);
    navigate("/open/login");
  }

  return (
    <>
      <div className="page-wraper">
        <div className="browse-job login-style3">
          <div
            className="bg-img-fix overflow-hidden"
            style={{ backgroundImage: "url(" + imagepaths.loginBg + ")" }}
          >
            <div className="parent-container row gx-0">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="logo_header pt-5 ps-5">
                  <Link to={"/open/login"} className="logo">
                    <img src={imagepaths.logoWithTagline} alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12  d-flex align-items-center">
                <div className="login-form style-2 py-4">
                  <div className="card-body">
                    <nav className="nav nav-tabs border-bottom-0">
                      <div className="tab-content w-100" id="nav-tabContent">
                        <div className="tab-pane active show fade">
                          {props.errorMessage && (
                            <div className="">{props.errorMessage}</div>
                          )}
                          {props.successMessage && (
                            <div className="">{props.successMessage}</div>
                          )}
                          {showForms && (
                            <>
                              {!toggleForm && (
                                <>
                                  {/* FORGOT PASSWORD FORM */}
                                  <form
                                    className="dz-form py-2"
                                    onSubmit={onForgetPassword}
                                  >
                                    <h3 className="form-title text-center mb-1">
                                      Forgot Password
                                    </h3>
                                    <h4 className="text-center mb-5">
                                      Please enter registered email ID
                                    </h4>
                                    <div className="dz-separator-outer m-b5">
                                      <div className="dz-separator bg-primary style-liner"></div>
                                    </div>
                                    <div className="form-group my-4">
                                      <label htmlFor="">Email ID</label>
                                      <div className="position-relative">
                                        <input
                                          value={email}
                                          onChange={(e) =>
                                            setEmail(e.target.value)
                                          }
                                          className="form-control"
                                          placeholder="Please Enter"
                                        />
                                        <img
                                          className="user-icon"
                                          src={imagepaths.InputUserIcon}
                                          alt="user icon"
                                        />
                                      </div>
                                      {errors.email && (
                                        <div className="text-danger fs-12">
                                          {errors.email}
                                        </div>
                                      )}
                                    </div>
                                    <div className="form-group clearfix text-left mt-5 mb-2">
                                      <button
                                        type="submit"
                                        className="btn btn-primary button-md btn-block"
                                      >
                                        Forgot Password
                                      </button>
                                    </div>
                                  </form>
                                </>
                              )}
                              {toggleForm && (
                                <>
                                  {/* PASSWORD RESET FORM */}
                                  <form
                                    className="dz-form py-2"
                                    onSubmit={onPasswordReset}
                                  >
                                    <h3 className="form-title text-center mb-1">
                                      Reset Password
                                    </h3>
                                    <p className="text-center mb-5">
                                      Please reset your password
                                    </p>
                                    <div className="dz-separator-outer m-b5">
                                      <div className="dz-separator bg-primary style-liner"></div>
                                    </div>
                                    <div className="form-group mb-3">
                                      <label htmlFor="">Old Password</label>
                                      <div className="position-relative">
                                        <input
                                          value={oldPassword}
                                          onChange={(e) =>
                                            setOldPassword(e.target.value)
                                          }
                                          className="form-control"
                                          placeholder="Please Enter"
                                        />
                                        <img
                                          className="user-icon"
                                          src={imagepaths.LockIcon}
                                          alt="lock icon"
                                        />
                                      </div>
                                      {resetErrors.oldPassword && (
                                        <div className="text-danger fs-12">
                                          {resetErrors.oldPassword}
                                        </div>
                                      )}
                                    </div>
                                    <div className="form-group mb-3">
                                      <label htmlFor="">New Password</label>
                                      <div className="position-relative">
                                        <input
                                          value={newPassword}
                                          onChange={(e) =>
                                            setNewPassword(e.target.value)
                                          }
                                          className="form-control"
                                          placeholder="Please Enter"
                                        />
                                        <img
                                          className="user-icon"
                                          src={imagepaths.LockIcon}
                                          alt="lock icon"
                                        />
                                      </div>
                                      {resetErrors.newPassword && (
                                        <div className="text-danger fs-12">
                                          {resetErrors.newPassword}
                                        </div>
                                      )}
                                    </div>
                                    <div className="form-group mb-3">
                                      <label htmlFor="">Confirm Password</label>
                                      <div className="position-relative">
                                        <input
                                          value={confirmPassword}
                                          onChange={(e) =>
                                            setConfirmPassword(e.target.value)
                                          }
                                          className="form-control"
                                          placeholder="Please Enter"
                                        />
                                        <img
                                          className="user-icon"
                                          src={imagepaths.LockIcon}
                                          alt="lock icon"
                                        />
                                      </div>
                                      {resetErrors.confirmPassword && (
                                        <div className="text-danger fs-12">
                                          {resetErrors.confirmPassword}
                                        </div>
                                      )}
                                    </div>
                                    <div className="form-group clearfix text-left mt-5 mb-2">
                                      <button
                                        type="submit"
                                        className="btn btn-primary button-md btn-block"
                                      >
                                        Change Password
                                      </button>
                                    </div>
                                  </form>
                                </>
                              )}
                            </>
                          )}
                          {showNotification && (
                            <>
                              <img
                                src={imagepaths.checkCircle}
                                className="d-block mx-auto mb-4"
                                alt="checkCircle"
                              />

                              {showEmailAlert && (
                                <>
                                  <h3 className="form-title sm text-center">
                                    Email sent Successfully.
                                  </h3>
                                  <p className="sub-title text-center">
                                    Login with temporary password
                                  </p>
                                  <div className="form-group clearfix text-left mt-5 mb-2">
                                    <button
                                      type="submit"
                                      className="btn btn-primary button-md btn-block"
                                      onClick={(e) =>
                                        handleNotification(e, "emailsent")
                                      }
                                    >
                                      Ok
                                    </button>
                                  </div>
                                </>
                              )}
                              {!showEmailAlert && (
                                <>
                                  <h3 className="form-title sm text-center">
                                    Your Password has been reset successfully.
                                  </h3>
                                  <p className="sub-title text-center">
                                    Please login with new credentials.
                                  </p>
                                  <div className="form-group clearfix text-left mt-5 mb-2">
                                    <button
                                      type="submit"
                                      className="btn btn-primary button-md btn-block"
                                      onClick={(e) =>
                                        handleNotification(e, "passwordsent")
                                      }
                                    >
                                      Ok
                                    </button>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default constore(ForgotPassword);
