import React, { useEffect, useState } from "react";
import MainPagetitle from "../../../layouts/MainPagetitle";
import { Link } from "react-router-dom";

const ViewBankOffer = (props) => {
  const [bankdata, setBankdata] = useState({});
  var BASE_URL = `${endpoints[environment].api_base}/files/download/`;
  const isConnectorRoute =
    props?.urlparams?.params?.route === "connector" ? true : false;

  const getBreadCums = () => {
    let pagetitle = props?.urlparams?.params?.bankName;
    return pagetitle;
  };

  const getBackLink = () => {
    let backLink;
    if (isConnectorRoute) {
      backLink = `/app/bankwiseofferslist`;
    } else {
      backLink = "/app/masterdata?activetab=bankwiseoffer";
    }
    return backLink;
  };

  useEffect(() => {
    let payload = {
      bankId: props.urlparams.params.bankId,
    };
    // apiGetBankOfferById
    // apiGetBankOfferViewDataMoke
    props.apis.apiGetBankOfferById(payload).then((res) => {
      console.log({ res });
      setBankdata(res);
    });
  }, []);

  return (
    <>
      <MainPagetitle
        mainTitle={
          isConnectorRoute
            ? "Bank Wise Offers"
            : "Master Data | Bank Wise Offers"
        }
        pageTitle={"Master Data"}
        parentTitle={"Home"}
        back={getBreadCums()}
        backLink={getBackLink()}
        pageIcon={imagepaths.percentageIconActive}
      />
      <div className="container-fluid">
        <div className="card p-4">
          <div className="row g-4">
            <div className="col-12 col-md-6 col-lg-4">
              <div className="card offer_list_card">
                <div className="card_header">
                  <h2 className="title">Features & Benefits</h2>
                </div>
                <hr />
                <div className="card_body">
                  <ul className="custom_ul">
                    {/* {bankdata &&
                      bankdata?.features_benefits?.map((list, i) => {
                        return <li key={i}>{list}</li>;
                      })} */}
                    <li>
                      Get loan amt. as low as{" "}
                      {bankdata?.minLoanAmount?.cur() || ""}
                    </li>
                    <li>
                      Get max. loan amt. upto{" "}
                      {bankdata?.maxLoanAmount?.cur() || ""}
                    </li>
                    <li>
                      Rate of Interest starting from {bankdata?.lowestROI || ""}
                      %
                    </li>
                    <li>
                      Processing Fee Upto {bankdata?.processingFee || ""}%
                    </li>
                    <li>
                      Enjoy a loan tenure upto {bankdata?.tenure || ""} Months
                    </li>
                    <li>100% digital & paperless process</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="card offer_list_card">
                <div className="card_header">
                  <h2 className="title">Eligibility Criteria</h2>
                </div>
                <hr />
                <div className="card_body">
                  <ul className="custom_ul">
                    <li>
                      Age Group: Min-{bankdata?.minAge || ""} years Max-
                      {bankdata?.maxAge || ""} years
                    </li>
                    <li>Tenure: {bankdata?.tenure || ""} Months</li>
                    <li>
                      Monthly Income should be minimum Rs.
                      {bankdata?.minSalary?.cur() || ""}
                    </li>
                    <li>
                      Employment type should be{" "}
                      {bankdata?.employmentType?.toTitleCase() || ""}
                    </li>
                    <li>
                      Minimum Credit Score should be{" "}
                      {bankdata?.creditScore || ""}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
              <div className="card offer_list_card">
                <div className="card_header">
                  <h2 className="title">Documents Required</h2>
                </div>
                <hr />
                <div className="card_body">
                  <ul className="custom_ul">
                    <li>Proof of ID</li>
                    <li>Address Proof</li>
                    <li>Proof of Income</li>
                    <li>Aadhar Card</li>
                    <li>PAN Card</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
              <div className="card offer_list_card">
                <div className="card_header">
                  <h2 className="title">Point Of Contact</h2>
                </div>
                <hr />
                <div className="card_body">
                  <ul className="">
                    <li className="mb-2">
                      <span>Name: </span>
                      <span>{bankdata?.contactPersonName || "-"}</span>
                    </li>
                    <li className="mb-2">
                      <span>Contact No.: </span>
                      <span>{bankdata?.contactPersonMobile || "-"}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* DOCS CARD */}
            <div className="col-12 col-md-12 col-lg-8">
              <div className="card offer_list_card">
                <div className="card_header">
                  <h2 className="title">Docs Uploaded</h2>
                </div>
                <hr />
                <div className="card_body">
                  <div className="docs_card_container row g-0">
                    <div className="col-6 col-sm-4 col-md-6 col-lg-3">
                      <div className="docs_card">
                        <img
                          src={imagepaths.docsCardBlueIcon}
                          alt="docsCardBlueIcon"
                        />
                        <h2 className="title">Rate Card</h2>
                        {bankdata?.rateCardFileId &&
                        bankdata?.rateCardFileId?.length !== "" ? (
                          <a
                            href={`${BASE_URL}${bankdata?.rateCardFileId}`}
                            className="text-primary"
                          >
                            Download
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-6 col-lg-3">
                      <div className="docs_card">
                        <img
                          src={imagepaths.docsCardBlueIcon}
                          alt="docsCardBlueIcon"
                        />
                        <h2 className="title">Policy One Pager</h2>
                        {bankdata?.policyFileId &&
                        bankdata?.policyFileId?.length !== "" ? (
                          <a
                            href={`${BASE_URL}${bankdata?.policyFileId}`}
                            className="text-primary"
                          >
                            Download
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nav_container text-end mt-5">
            <Link
              to={
                isConnectorRoute
                  ? "/app/bankwiseofferslist"
                  : "/app/masterdata?activetab=bankwiseoffer"
              }
              className="btn btn-primary"
            >
              Close
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default constore(ViewBankOffer);
