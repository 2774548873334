import React, { useEffect, useRef, useState } from "react";
import ProcessedSearch from "./ProcessedSearch";
import PayoutModal from "../PayoutModal";
import BulkUploadModal from "../../OfflineLeads/BulkUploadModal";
import moment from "moment";

const Processed = (props) => {
  const isAdmin = props?.$store?.user?.role === _enum.ROUTE_PERSONAS.admin;
  const [isFetching, setIsFetching] = useState(true);
  const [search, setSearch] = useState({
    page: 1,
    // limit: 10,
    paymentStatus: "PROCESSED",
  });
  const [rowcount, setRowcount] = useState(0);
  const [data, setData] = useState([]);
  // ----------------------------------------------------------------
  //   PAYOUT MODAL

  const [modalFields, setModalFields] = useState([
    {
      label: "Loan Info:",
      name: "loanInfo",
      type: "textarea",
      customcolspan: 12,
      outerclass: "form-title",
    },
    {
      label: "Digi Team Member Name",
      name: "connectorName",
      type: "text",
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Digi Team Member Mobile No.",
      name: "mobileNo",
      type: "number",
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Customer Name (as per PAN)",
      name: "applicantName",
      type: "text",
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Mobile No.",
      name: "mobileNo",
      type: "number",
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Product Type",
      name: "productType",
      type: "text",
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Bank Name",
      name: "bankName",
      type: "text",
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Loan Amount",
      name: "loanAmt",
      type: "number",
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Loan Status",
      name: "loanStatus",
      type: "text",
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Application No. ",
      name: "applicationNo",
      type: "text",
      placeholder: "Please Enter",
    },
    {
      label: "Application Date ",
      name: "createdAt",
      type: "date",
      disabledDate: (current) => {
        return current && current < moment().startOf("day");
      },
      placeholder: "Please Enter",
    },
    {
      label: "Disb. Amount",
      name: "disbursedAmt",
      type: "number",
      placeholder: "Please Enter",
    },
    {
      label: "Disb. Date",
      name: "disbursedDate",
      type: "date",
      disabledDate: (current) => {
        return current && current < moment().startOf("day");
      },
      placeholder: "Please Enter",
    },
    {
      label: "Payout Type",
      name: "payoutType",
      type: "select",
      options: _enum.PAYOUT_TYPES,
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "LAN",
      name: "lan",
      type: "number",
      placeholder: "Please Enter",
    },
    // PAYOUT INFO FIELDS
    {
      label: "Digi Team Member Payout %",
      name: "digirepPayoutPct",
      type: "number",
      step: ".01",
      placeholder: "Please Enter",
      onChange: (...args) => handleDigirepPayoutPct(...args),
    },
    {
      label: "Digi Team Member Payout Amt.",
      name: "digirepPayoutAmt",
      type: "number",
      step: ".01",
      placeholder: "Please Enter",
    },
    {
      label: "TDS %",
      name: "tdsPct",
      type: "number",
      step: ".01",
      placeholder: "Please Enter",
      onChange: (...args) => handleTDSPct(...args),
    },
    {
      label: "TDS Amt.",
      name: "tdsAmt",
      type: "number",
      step: ".01",
      placeholder: "Please Enter",
    },
    {
      label: "Net Payout Amt.",
      name: "netAmt",
      type: "number",
      step: ".01",
      placeholder: "Please Enter",
    },
    {
      label: "Payment Status",
      name: "paymentStatus",
      type: "select",
      onChange: (...args) => handlePaymentStatus(...args),
      options: isAdmin ? _enum.ADMIN_PAYOUT_STATUS : _enum.PAYOUT_STATUS,
      placeholder: "Please Enter",
    },
    {
      label: "Bank Payout %",
      name: "bankPayoutPct",
      type: "number",
      step: ".01",
      placeholder: "Please Enter",
      onChange: (...args) => handleBankPayoutPct(...args),
    },
    {
      label: "Bank Payout Amt.",
      name: "bankPayoutAmt",
      type: "number",
      step: ".01",
      placeholder: "Please Enter",
    },
    {
      label: "Payment Date",
      name: "paymentDate",
      type: "date",
      disabledDate: (current) => {
        return current && current < moment().startOf("day");
      },
      placeholder: "Please Enter",
    },
    {
      label: "Bank Payout Received Date",
      name: "bankPayoutReceiveDate",
      type: "date",
      placeholder: "Please Enter",
    },
    {
      label: "Remarks",
      name: "remarks",
      type: "textarea",
      customcolspan: 12,
    },
    {
      label: "Payout Info:",
      name: "payoutInfo",
      type: "textarea",
      customcolspan: 12,
      outerclass: "form-title my-3",
    },
  ]);
  const [editData, setEditData] = useState({});
  const [editmode, setEditmode] = useState(false);
  const [viewmode, setViewmode] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [popupfields, setPopupfields] = useState({});
  const [popupdata, setPopupdata] = useState({});
  const [disabledFields, setDisabledFields] = useState([]);
  // ----------------------------------------------------------------
  const columns = [
    {
      dataIndex: "connectorName",
      title: "Digi Team Member Name",
      render: (text, record) => {
        return (
          <span className="table-primary-text">{record?.createdBy?.name}</span>
        );
      },
      display: isAdmin ? true : false,
    },
    {
      dataIndex: "applicantName",
      title: "Customer Name",
      render: (text, record) => {
        return (
          <span className="table-primary-text">{record?.applicantName}</span>
        );
      },
    },
    {
      dataIndex: "connectorMobileNo",
      title: "Digi Team Member Mobile No.",
      render: (text, record) => {
        return (
          <span className="table-primary-text">
            {record?.createdBy?.mobileNumber}
          </span>
        );
      },
      display: isAdmin ? true : false,
    },
    {
      dataIndex: "applicationNo",
      title: "Application No.",
    },
    {
      dataIndex: "bankName",
      title: "Bank Name",
    },
    {
      dataIndex: "disbursedAmt",
      title: "Disb. Amt.",
      render: (text) => {
        return <>{text?.cur() || "-"}</>;
      },
    },
    {
      dataIndex: "disbursedDate",
      title: "Disb. Date",
      render: (text, record) => {
        if (record?.disbursedDate) {
          let date = new Date(text).dateToDDMMYYYY(new Date(text));
          return date;
        } else {
          return "-";
        }
      },
    },
    {
      dataIndex: "payoutType",
      title: "Payout Type",
      display: isAdmin ? false : true,
      sorter: false,
    },
    {
      dataIndex: "paymentStatus",
      title: "Payment Status",
      render: (text) => {
        return (
          <span
            className={`badge light badge-${
              _enum.STATUS_COLOR_MAP[text.toLowerCase()]
            }`}
          >
            {text?.toTitleCase() || "-"}
          </span>
        );
      },
    },
  ];

  const getData = (params = {}, reset) => {
    let payload = {};
    if (reset) {
      let defaultSearchData = Object.only(search, [
        "page",
        "limit",
        "paymentStatus",
      ]);

      setSearch(defaultSearchData);
      payload = {
        ...defaultSearchData,
      };
      console.log({ defaultSearchData: payload });
    } else {
      payload = {
        ...search,
        ...params,
      };
    }
    if (!isAdmin) {
      props.apis.apiGetConnectorPayouts(payload).then(({ data, count }) => {
        console.log({ apiGetConnectorPayouts: data });
        setData(data);
        setRowcount(count);
        setIsFetching(false);
      });
    } else {
      props.apis.apiGetAdminPayouts(payload).then(({ data, count }) => {
        console.log({ apiGetAdminPayouts: data });
        setData(data);
        setRowcount(count);
        setIsFetching(false);
      });
    }
  };

  const view = (record) => {
    setEditmode(false);
    setViewmode(true);

    let payload = {
      leadId: record.leadId,
    };
    props.apis.apiGetConsolidatedAppsById(payload).then(({ data }) => {
      let loanInfoFields = [
        "loanInfo",
        "applicationNo",
        "applicantName",
        "mobileNo",
        "productType",
        "bankName",
        "loanAmt",
        "loanStatus",
        "disbursedAmt",
        "disbursedDate",
        "payoutType",
        "application_date",
        "payoutInfo",
      ];

      let editdata = {
        // LOAN INFO DATA --------------------------------
        loanInfo: "",
        digi_team_member_name: data?.createdBy?.name,
        digi_team_member_mobile_no: data?.createdBy?.mobileNumber,
        ...Object.only(data, loanInfoFields),
        application_date: new Date(data?.applicationDate).dateToDDMMYYYY(
          new Date(data?.applicationDate)
        ),
        payoutInfo: "",
        // PAYOUT INFO DATA --------------------------------
        digirepPayoutPct: data?.digirepPayoutPct,
        digirepPayoutAmt: data?.digirepPayoutAmt,
        tdsPct: data?.tdsPct,
        tdsAmt: data?.tdsAmt,
        netAmt: data?.netAmt,
        paymentDate: data?.paymentDate,
        paymentStatus: data?.paymentStatus,
        bankPayoutPct: data?.bankPayoutPct,
        bankPayoutAmt: data?.bankPayoutAmt,
        bankPayoutReceiveDate: data?.bankPayoutReceiveDate,
        remarks: data?.remarks,
      };

      let fields = (modalFields || []).reduce(
        (o, n) => ({ ...o, [n.name]: n }),
        {}
      );
      console.log({
        modalFields,
        editdata,
        fields,
      });
      setPopupfields(fields);

      if (isAdmin) {
        setPopupdata(editdata);
        updatefields("bankPayoutAmt", data?.bankPayoutAmt);
        updatefields("digirepPayoutAmt", data?.digirepPayoutAmt);
        updatefields("tdsAmt", data?.tdsAmt);
      } else {
        setPopupdata(
          Object.except(editdata, [
            "digi_team_member_name",
            "digi_team_member_mobile_no",
          ])
        );
      }
      setEditData(data);
      // ----------------------------------------------------------------
      let disabledFields = [
        "digi_team_member_name",
        "digi_team_member_mobile_no",
        "digirepPayoutAmt",
        "bankPayoutAmt",
        "tdsAmt",
        "application_date",
        ...modalFields.map((field) => field.name),
      ];
      setDisabledFields(disabledFields);
      openModal();
    });
  };

  const updatefields = (field, data) => {
    let fieldElem = modalFields.find((o) => o.name === field);
    fieldElem.defaultValue = data;
  };

  const onSearch = (data, reset) => {
    console.log("onSearch on pending", data, reset);
    if (Object.keys(data).length === 0) {
      setSearch({
        ...data,
        ...search,
      });
      getData(data, reset);
    } else {
      setSearch({
        ...data,
        ...search,
      });
      getData(data, reset);
    }
  };
  const onPageChange = (pageData) => {
    // console.log({ onPageChange: pageData });
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  // ----------------------------------------------------------------
  // ROW SELECTION
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [notSelected, setNotSelected] = useState(true);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => {
      if (record?.paymentStatus === "PROCESSED") {
        return { disabled: false };
      } else {
        return { disabled: true };
      }
    },
  };
  // ----------------------------------------------------------------

  const onModalClose = () => {
    console.log("onModalClose");
    closeModal();
    setPopupfields({});
    setViewmode(false);
    setEditmode(false);
    setEditData({});
    let bankPayoutAmtField = modalFields.find(
      (o) => o.name === "bankPayoutAmt"
    );
    let digirepPayoutAmtField = modalFields.find(
      (o) => o.name === "digirepPayoutAmt"
    );
    let tdsAmtField = modalFields.find((o) => o.name === "tdsAmt");
    digirepPayoutAmtField.defaultValue = "";
    digirepPayoutAmtField.value = "";

    bankPayoutAmtField.defaultValue = "";
    bankPayoutAmtField.value = "";

    tdsAmtField.defaultValue = "";
    tdsAmtField.value = "";

    // let netAmtField = modalFields.find((o) => o.name === "netAmt");
    // netAmtField.defaultValue = "";

    setModalFields([...modalFields]);
    // setEditData2({});
  };
  //   PAYOUT MODAL
  const openModal = () => {
    setOpenCanvas(true);
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };
  // ----------------------------------------------------------------
  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      console.log("has values");
      setNotSelected(false);
    } else {
      console.log("has no values");
      setNotSelected(true);
    }
  }, [selectedRowKeys]);

  const handleSendPayment = () => {
    let payload = {
      leadIds: [...selectedRowKeys],
    };
    console.log({ handleSendPayment: payload });

    props.apis.apiSendAdminLeadsForPayment(payload).then((res) => {
      console.log({ apiSendAdminLeadsForPayment: res });
      if (res) {
        swal2.fire({
          title: "Lead Assigned",
          text: "Leads sent successfully !!",
          icon: "success",
          iconColor: "#234C9B",
          timer: 4000,
        });
        setSelectedRowKeys([]);
        getData();

        if (res) {
          let url = `${
            props.apis.api().api_base
          }/payout/admin/download?digiRepPaidAppIds=${res}`;
          var downloadURL = url;
          var link = document.createElement("a");
          link.setAttribute(
            "download",
            `Leads_Data-${new Date().toLocaleDateString()}.xlsx`
          );
          link.href = downloadURL;
          link.click();
        }
      }
    });
  };
  // ----------------------------------------------------------------

  // ----------------------------------------------------------------
  const bulkUploadModalRef = useRef(0);
  const [popupBulkUploadFields, setPopupBulkUploadFields] = useState({});
  const [popupBulkUploadData, setPopupBulkUploadData] = useState({});
  const [bulkUploadModalFields, setBulkUploadModalFields] = useState([
    {
      label: "File Upload (Max limit 10,000)",
      name: "file",
      type: "filearea",
      accept: ".xlsx, .xls",
      customcolspan: "12",
      required: true,
    },
  ]);

  const downloadTemplateLeads = (e) => {
    props.apis.apiDownloadBankPayoutTemplate().then((res) => {
      console.log({ res });
      if (res) {
        let url = `${props.apis.api().api_base}/bank-payout/template`;
        var downloadURL = url;
        var link = document.createElement("a");
        link.setAttribute(
          "download",
          `bank_payout_template_${new Date().toLocaleDateString()}.xlsx`
        );
        link.href = downloadURL;
        link.click();
      }
    });
  };

  const downloadBulkLeads = (values) => {
    // let leadIds = tableData?.map((v) => JSON.parse(v.leadId));
    // let payload = {};
    // payload.leads = leadIds;
    // console.log({ downloadBulkLeads: payload });
    // props.apis.apiBulkDownloadLead(payload).then((res) => {
    //   console.log({ res });
    //   // if (res) {
    //   //   let url = `${props.apis.api().api_base}/leads/bulk-download`;
    //   //   var downloadURL = url;
    //   //   var link = document.createElement("a");
    //   //   link.setAttribute(
    //   //     "download",
    //   //     `leads_${new Date().toLocaleDateString()}.xlsx`
    //   //   );
    //   //   link.href = downloadURL;
    //   //   // link.target = "_blank";
    //   //   link.click();
    //   // }
    // });
  };

  // HANDLE BULK UPLOAD
  const openBulkUploadmodal = () => {
    bulkUploadModalRef.current.click();
  };

  const closeBulkUploadmodal = () => {
    document.querySelector("#bulkUploadModal #bsmodalclose").click();
    setPopupBulkUploadData({});
    setPopupBulkUploadFields({});
  };

  const addBulkUploadModalFields = () => {
    let fields = (bulkUploadModalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    setPopupBulkUploadFields(fields);
    setPopupBulkUploadData(formdata);
    openBulkUploadmodal();
  };

  const handleBulkUploadSubmit = (e, data, form) => {
    let payload = {
      file: data?.file,
    };
    console.log({ payload });

    props.apis.apiBulkUploadBankPayout(payload).then((res) => {
      console.log({ apiBulkUploadBankPayout: res });
      if (res) {
        swal2.fire({
          title: "Bulk Uploaded Successfully.",
          text: `Records Uploaded : ${res?.count || 0}`,
          icon: "success",
          iconColor: "#234C9B",
          timer: 4000,
        });
        closeBulkUploadmodal();
        getData();
      }
    });

    swal2.fire({
      title: "Bulk Uploaded Successfully.",
      //   text: `Records Uploaded : ${res?.count || 0}`,
      icon: "success",
      iconColor: "#234C9B",
      timer: 4000,
    });
    closeBulkUploadmodal();
  };

  // ----------------------------------------------------------------

  // ----------------------------------------------------------------

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className="bulk_download_btn_outer transparent_btns">
        <button
          className="btn btn-sm btn-outline-secondary me-2"
          onClick={(...args) => downloadTemplateLeads(...args)}
        >
          <i className="fa fa-download me-2"></i>
          <span>Download Template</span>
        </button>
        <button
          className="btn btn-sm btn-outline-secondary me-2"
          onClick={(e) => addBulkUploadModalFields(e)}
          ref={bulkUploadModalRef}
          data-bs-toggle="modal"
          data-bs-target="#bulkUploadModal"
        >
          <i className="fa fa-upload me-2"></i>
          <span>Bulk Upload</span>
        </button>
        <button
          className="btn btn-sm btn-outline-secondary me-2"
          onClick={(...args) => downloadBulkLeads(...args)}
          // disabled={disableBulkDownload}
        >
          <i className="fa fa-download me-2"></i>
          <span>Bulk Download</span>
        </button>
      </div>
      <ProcessedSearch
        onSearch={(...arg) => onSearch(...arg)}
        isAdmin={isAdmin}
      />
      <AppTable
        data={data}
        columns={columns}
        onChange={(...arg) => onPageChange(...arg)}
        total={rowcount}
        reorder={true}
        viewAction={(...args) => view(...args)}
        deletable={false}
        editable={false}
        loading={isFetching}
        {...(isAdmin
          ? {
              rowSelection: rowSelection,
              checkbox: true,
            }
          : {})}
      ></AppTable>
      <div
        className={Object.className({
          "row bottom_container": true,
          "d-none": !isAdmin,
        })}
      >
        <div className="col-12 text-end">
          <button
            className={Object.className({
              "btn btn-primary m-4": true,
            })}
            onClick={(e) => handleSendPayment(e)}
            disabled={notSelected}
          >
            Send for Payment
          </button>
        </div>
      </div>
      <PayoutModal
        id="payoutModal"
        title={`${editmode ? "Edit Lead" : viewmode ? "View Lead" : "Add"}`}
        show={openCanvas}
        onClose={() => onModalClose()}
        data={popupdata}
        // editdata={editData}
        fields={popupfields}
        editmode={editmode}
        viewmode={viewmode}
        onSubmit={(...args) => handleSubmit(...args)}
        submitText={`${editmode ? "Update" : "Submit"}`}
        disableallfields={viewmode ? true : false}
        disablefields={disabledFields}
      />

      <BulkUploadModal
        id="bulkUploadModal"
        modalSize="modal-lg"
        title="Bulk Upload"
        submitText="Upload"
        data={popupBulkUploadData}
        viewmode={false}
        fields={popupBulkUploadFields}
        onSubmit={(...args) => handleBulkUploadSubmit(...args)}
        modaltype="centeredmodal"
        onClose={() => closeBulkUploadmodal()}
      />
    </>
  );
};

export default constore(Processed);
