export default {

    // MASTER - LOCATION
    ADD_MASTER_LOCATION: {
        url: "/master-location",
        method: "post",
    },
    GET_MASTER_LOCATIONS: {
        url: "/master-location",
        method: "get",
    },
    GET_MASTER_LOCATION_BY_ID: {
        url: "/master-location/{locationId}",
        method: "get",
    },
    UPDATE_MASTER_LOCATION: {
        url: "/master-location/{locationId}",
        method: "put",
    },
    DELETE_MASTER_LOCATION: {
        url: "/master-location/{locationId}",
        method: "delete",
    },

    // MASTER - ROLES
    // ADD_MASTER_ROLE: {
    //     url: "/master-role",
    //     method: "post",
    // },
    GET_MASTER_ROLES: {
        url: "/master-role",
        method: "get",
    },
    // DELETE_MASTER_ROLE: {
    //     url: "/master-role/{role}",
    //     method: "delete",
    // },

    // MASTER DESTIGNATION
    ADD_MASTER_DESIGNATION: {
        url: "/master-designation",
        method: "post",
    },
    GET_MASTER_DESIGNATIONS: {
        url: "/master-designation",
        method: "get",
    },
    DELETE_MASTER_DESIGNATION: {
        url: "/master-designation/{designation}",
        method: "delete",
    },

    // MASTER DEPARTMENT
    ADD_MASTER_DEPARTMENT: {
        url: "/master-department",
        method: "post",
    },
    GET_MASTER_DEPARTMENTS: {
        url: "/master-department",
        method: "get",
    },
    DELETE_MASTER_DEPARTMENT: {
        url: "/master-department/{department}",
        method: "delete",
    },

    // BANK-NAMES
    ADD_BANK_NAME: {
        url: "/master-bank",
        method: "post",
    },
    GET_BANK_NAMES: {
        url: "/master-bank",
        method: "get",
    },
    GET_BANK_BY_ID: {
        url: "/master-bank/{bankId}",
        method: "get",
    },
    UPDATE_BANK_NAME: {
        url: "/master-bank/{bankId}",
        method: "put",
    },
    DELETE_BANK_NAME: {
        url: "/master-bank/{bankId}",
        method: "delete",
    },

    // MASTER - BANK WISE OFFER
    ADD_BANK_OFFER: {
        url: "/master-bank-offer",
        method: "post",
    },
    GET_BANK_OFFERS: {
        url: "/master-bank-offer",
        method: "get",
    },
    GET_BANK_OFFER_BY_ID: {
        url: "/master-bank-offer/{bankId}",
        method: "get",
    },
    UPDATE_BANK_OFFER: {
        url: "/master-bank-offer/{bankId}",
        method: "put",
    },
    DELETE_BANK_OFFER: {
        url: "/master-bank-offer/{bankId}",
        method: "delete",
    },

    // INTEREST RATE
    ADD_INTEREST_RATES: {
        url: "/master-bank-rates",
        method: "post",
    },
    GET_INTEREST_RATES: {
        url: "/master-bank-rates",
        method: "get",
    },
    GET_INTEREST_RATE_BY_ID: {
        url: "/master-bank-rates/{interestRateId}",
        method: "get",
    },
    UPDATE_INTEREST_RATE: {
        url: "/master-bank-rates/{interestRateId}",
        method: "put",
    },
    DELETE_INTEREST_RATE: {
        url: "/master-bank-rates/{interestRateId}",
        method: "delete",
    },

    // PRODUCT TYPES
    ADD_PRODUCT_TYPE: {
        url: "/master-product-type",
        method: "post",
    },
    GET_PRODUCT_TYPES: {
        url: "/master-product-type",
        method: "get",
    },
    DELETE_PRODUCT_TYPE: {
        url: "/master-product-type/{productType}",
        method: "delete",
    },
}