import React from "react";
import OfflineLeadCard from "../../elements/OfflineLeadCards";

const OfflineLeads = () => {
  const offlineLeads = [
    {
      id: "personal_loan",
      title: "PERSONAL LOAN",
      image: imagepaths.PersonalLoan,
    },
    {
      id: "business_loan",
      title: "BUSINESS LOAN",
      image: imagepaths.BusinessLoan,
    },
    {
      id: "home_loan",
      title: "HOME LOAN AGAINST PROPERTY",
      placeholder: "HOME LOAN / LOAN AGAINST PROPERTY",
      image: imagepaths.HomeLoan,
    },
    {
      id: "car_loan",
      title: "CAR LOAN",
      image: imagepaths.CarLoan,
    },
    {
      id: "gold_loan",
      title: "GOLD LOAN",
      image: imagepaths.GoldLoan,
    },
    {
      id: "insurance_loans",
      title: "INSURANCE LOANS",
      image: imagepaths.InsuranceLoans,
    },
    {
      id: "credit_card_loans",
      title: "CREDIT CARD LOANS",
      image: imagepaths.CreditCardLoans,
    },
    {
      id: "education_loans",
      title: "EDUCATION LOANS",
      image: imagepaths.EducationLoans,
    },
    {
      id: "corporate_loan",
      title: "CORPORATE LOANS",
      image: imagepaths.CorporateLoans,
    },
  ];
  return (
    <div className="row g-3 mx-auto py-3 px-4">
      {offlineLeads?.map((item, i) => {
        return (
          <OfflineLeadCard
            id={item.id}
            title={item.title}
            placeholder={item.placeholder}
            image={item.image}
            key={i}
          />
        );
      })}
    </div>
  );
};

export default OfflineLeads;
