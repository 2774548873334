import React, { Component } from "react";

export class ProcessedSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        limit: 10,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
    };
    this.adminfields = [
      {
        name: "applicantName",
        label: "Customer Name (as per PAN)",
        placeholder: "Customer Name",
      },
      {
        name: "applicationNo",
        label: "Application No.",
        type: "text",
        placeholder: "Application No.",
      },
      {
        name: "loanStatus",
        label: "Loan Status",
        type: "select",
        options: _enum.LOAN_STATUS,
        placeholder: "Loan Status",
      },
      {
        name: "bankName",
        label: "Bank Name",
        type: "select",
        render: (text, record) => {
          return <>{record?.bankDetails?.bankName}</>;
        },
        placeholder: "Bank Name",
      },
    ];

    this.overallFields = [
      {
        name: "applicantName",
        label: "Customer Name (as per PAN)",
        placeholder: "Customer Name",
      },
      {
        name: "applicationNo",
        label: "Application No.",
        type: "text",
        placeholder: "Application No.",
      },
      {
        name: "payoutType",
        label: "Payout Type",
        type: "select",
        options: _enum.PAYOUT_TYPE,
        placeholder: "Payout Type",
      },
      {
        name: "bankName",
        label: "Bank Name",
        type: "select",
        render: (text, record) => {
          return <>{record?.bankDetails?.bankName}</>;
        },
        placeholder: "Bank Name",
      },
    ];
  }

  componentDidMount() {
    this.getBankNames();
  }

  getBankNames = () => {
    let payload = {
      page: 1,
    };
    this.props.apis.apiGetBanknames(payload).then(({ data, count }) => {
      console.log({ apiGetBanknames: data });
      let textField = this.props.isAdmin
        ? this.adminfields
        : this.overallFields;
      let bankNameField = textField.find((o) => o.name === "bankName");
      bankNameField.options = data.options("bankName");
      this.setState([...textField]);
    });
  };

  onSearch = (reset = false) => {
    let data = this.refs.form.form.current.getData();
    console.log("onSearch formData", data, reset);
    data = Object.filter(data, (val) => val);
    this.props.onSearch && this.props.onSearch(data, reset);
  };

  resetSearchForm() {
    this.refs.form.form.current.reset();
    this.onSearch(true);
  }

  render() {
    let { isAdmin } = this.props;
    return (
      <>
        <AppForm className="filter-row flex-auto mx-auto" ref="form">
          <div className="d-none d-xl-flex col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"></div>
          {isAdmin
            ? this.adminfields.map((field, i) => (
                <div
                  className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"
                  key={i}
                >
                  <AppInputFocus ref="inputname" {...field}></AppInputFocus>
                </div>
              ))
            : this.overallFields.map((field, i) => (
                <div
                  className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"
                  key={i}
                >
                  <AppInputFocus ref="inputname" {...field}></AppInputFocus>
                </div>
              ))}
          <div className="col-6 col-md-1 col-lg-1 col-xl-1 p-0 d-flex align-items-start justify-content-center mw-75">
            <button
              className="btn btn-primary search_btn"
              onClick={() => this.onSearch()}
            >
              <i className="fa fa-search"></i>
              <span>Search</span>
            </button>
          </div>
          <div className="col-6 col-md-1 col-lg-1 col-xl-1 p-0 d-flex align-items-start justify-content-center mw-75">
            <button
              type="button"
              className="btn btn-primary clear_filter_btn"
              onClick={(...args) => this.resetSearchForm(...args)}
            >
              <i class="fa-solid fa-filter-circle-xmark"></i>
            </button>
          </div>
        </AppForm>
      </>
    );
  }
}

export default constore(ProcessedSearch);
