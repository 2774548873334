import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import OTPInput from "react-otp-input";
import { toast } from "react-toastify";

import moment from "moment";
import MainPagetitle from "../../../layouts/MainPagetitle";
import DigitalLeadCard from "./DigitalLeadCard";
import DigitalLeadSearch from "./DigitalLeadSearch/DigitalLeadSearch";
import DigitalLeadsModal from "./DigitalLeadsModal";
import { Link } from "react-router-dom";

const DigitalLeads = (props) => {
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
    // order_by: "updatedAt",
    // order_direction: "DESC",
  });
  const [totalPageChange, setTotalPageChange] = useState({
    page: 1,
    limit: 10,
    // order_by: "updatedAt",
    // order_direction: "DESC",
  });
  const adminPersona = props?.$store?.user?.role === _enum.ROUTE_PERSONAS.admin;
  const isPanVerified = props.$store?.user?.isPanVerified;
  const isAadharVerified = props.$store?.user?.isAadharVerified;
  const isAgreementSigned = props.$store?.user?.isAgreementSigned;
  const [isAccountVerified, setIsAccountVerified] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [modalFields, setModalFields] = useState([
    {
      name: "applicant_first_name",
      label: "Applicant First Name",
      type: "text",
      placeholder: "Please enter",
      required: true,
      onChange: (value) => handleApplicantFirstName(value),
    },
    {
      name: "applicant_middle_name",
      label: "Applicant Middle Name",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "applicant_last_name",
      label: "Applicant Last Name",
      type: "text",
      placeholder: "Please enter",
      required: true,
      onChange: (value) => handleApplicantLastName(value),
    },
    {
      name: "mother_first_name",
      label: "Mother First Name",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "mother_middle_name",
      label: "Mother Middle Name",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "mother_last_name",
      label: "Mother Last Name",
      type: "text",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "mobileNo",
      label: "Mobile No.",
      type: "text",
      placeholder: "Mobile No.",
      required: true,
      onChange: (value) => handleMobileNo(value),
    },
    {
      name: "dob",
      label: "DOB",
      type: "date",
      disabledDate: (current) => {
        const today = moment();
        const maxDate = today.subtract(18, "years");
        return current && current > maxDate.endOf("day");
      },
      defaultValue: new Date().setAge(18),
      required: true,
    },
    {
      name: "gender",
      label: "Gender",
      type: "select",
      placeholder: "Please select",
      options: _enum.GENDER,
      required: true,
    },
    {
      name: "panNo",
      label: "PAN No.",
      type: "text",
      placeholder: "PAN No.",
      required: true,
      onChange: (value) => handlePanCard(value),
    },
    {
      name: "pincode",
      label: "Pin code",
      type: "text",
      placeholder: "Please enter",
      required: true,
      onChange: (value) => handlePincode(value),
    },
    {
      name: "city",
      label: "City",
      type: "text",
      readOnly: true,
    },
    {
      name: "state",
      label: "State",
      type: "text",
      readOnly: true,
    },

    {
      name: "email",
      label: "Personal Email Id",
      type: "text",
      placeholder: "please enter",
    },
    {
      name: "residence_ownership",
      label: "Residence Ownership",
      type: "select",
      options: _enum.RESIDENCE_OWNERSHIP,
      placeholder: "please select",
      required: true,
    },
    {
      name: "education_qualification",
      label: "Education Qualification",
      type: "select",
      options: _enum.EDUCATION_QUALIFICATION,
      placeholder: "please select",
      required: true,
    },
    {
      name: "employment_type",
      label: "Employment Type",
      type: "select",
      defaultValue: "Salaried",
      placeholder: "Please select",
      required: true,
      options: _enum.EMPLOYEE_TYPE,
    },
    {
      name: "total_work_exp",
      label: "Total Work Exp",
      type: "select",
      options: _enum.TOTAL_WORK_EXP,
      placeholder: "Please select",
      required: true,
      // onChange: (value) => handleTotalEmp(value),
    },
    {
      name: "company_name",
      label: "Company Name",
      type: "select",
      options: [],
      onChange: (value) => handleCompanyName(value),
      onSearch: (value) => handleCompanyNameSearch(value),
      placeholder: "Please enter",
    },
    {
      name: "company_category",
      label: "Company Category",
      type: "text",
      placeholder: "Please enter",
      readOnly: true,
    },
    {
      name: "official_email_id",
      label: "Official Email Id",
      type: "email",
      placeholder: "Please enter",
      onChange: (value) => handleEmailId(value),
    },
    {
      name: "salaryRange",
      label: "Salary Range",
      type: "select",
      options: _enum.SALARY_RANGE,
      placeholder: "Please select",
      required: true,
    },
    {
      name: "annual_income",
      label: "Annual Income",
      type: "text",
      placeholder: "Please enter",
      onChange: (value) => handleAnnualIncome(value),
    },
    {
      name: "min_loan_amount",
      label: "Min Loan Amount",
      type: "text",
      placeholder: "Please enter",
      onChange: (value) => handleMinLoanAmt(value),
    },
    {
      name: "Max_loan_amount",
      label: "Max Loan Amount",
      type: "text",
      placeholder: "Please enter",
      onChange: (value) => handleMaxLoanAmt(value),
    },
    {
      name: "required_loan_amount",
      label: "Required Loan Amt.",
      type: "text",
      placeholder: "Please enter",
      onChange: (value) => handleRequiredLoanAmt(value),
    },
    {
      name: "tenure",
      label: "Tenure",
      type: "select",
      placeholder: "Please select",
      required: true,
      options: _enum.TENURE,
    },
    {
      name: "acknowledgement",
      label:
        "Digi Team Member acknowledges the necessity of obtaining customer consent prior to submitting a loan application with L&T Finance.",
      type: "checkbox",
      placeholder: "Please select",
      onChange: (value) => handleAcknowledge(value),
      customcolspan: "12",
    },
    {
      name: "remarks",
      label: "Remarks",
      type: "textarea",
      placeholder: "Please enter",
      customcolspan: "12",
    },
  ]);
  const [adminModalFields, setAdminModalFields] = useState([
    {
      name: "connector_name",
      label: "Digi Team Member Name",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "connector_mobile_no",
      label: "Digi Team Member Mobile No.",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "applicant_first_name",
      label: "Applicant Name",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "applicant_mobile_no",
      label: "Applicant Mobile No.",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "lead_status",
      label: "Lead Status",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "application_status",
      label: "Loan Status",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "roi",
      label: "ROI",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "max_eligibilty",
      label: "Max Eligibility",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "remarks",
      label: "Remarks",
      type: "textarea",
      placeholder: "Please enter",
      customcolspan: "12",
    },

    {
      name: "application_no",
      label: "Application No.",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "application_date",
      label: "Application Date",
      type: "text",
      disabledDate: (current) => {
        return current && current < moment().startOf("day");
      },
      placeholder: "Please enter",
    },
    {
      name: "stage",
      label: "Stage",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "sub_stage",
      label: "Sub-Stage",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "net_disbursement",
      label: "Net Disbursement",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "processing_fee",
      label: "Processing Fee",
      type: "text",
      placeholder: "Please enter",
    },
  ]);
  const [adminData, setAdminData] = useState([]);
  const [editData, setEditData] = useState({});
  const [editmode, setEditmode] = useState(false);
  const [viewmode, setViewmode] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [popupfields, setPopupfields] = useState({});
  const [popupdata, setPopupdata] = useState({});
  const [consentUid, setConsentUid] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [errorMessage, setErrorMessage] = useState({ name: "", message: "" });
  const [loader, setLoader] = useState(false);
  const [applicantFormData, setApplicantFormData] = useState({});

  const [rowcount, setRowcount] = useState(0);
  const columns = [
    {
      dataIndex: "connectorName",
      title: "Digi Team Member Name",
      render: (text, record) => {
        return (
          <span className="table-primary-text">
            {record?.createdBy?.name || "-"}
          </span>
        );
      },
      display: adminPersona ? true : false,
    },
    {
      dataIndex: "connector_mobile_no",
      title: "Digi Team Member Mobile No.",
      display: adminPersona ? true : false,
      render: (text, record) => {
        return (
          <span className="table-primary-text">
            {record?.createdBy?.mobileNumber || "-"}
          </span>
        );
      },
    },
    {
      dataIndex: "applicant_first_name",
      title: "Applicant Name",
      render: (text, record) => {
        return <span className="table-primary-text">{text}</span>;
      },
    },
    {
      dataIndex: "mobileNo",
      title: "Mobile No.",
      sorter: false,
      display: adminPersona ? false : true,
    },
    {
      dataIndex: "applicationDate",
      title: "App. Date",
      display: adminPersona ? false : true,
      render: (text, record) => {
        let date = new Date(record?.createdAt).dateToDDMMYYYY(
          new Date(record?.createdAt)
        );
        return date;
      },
    },
    {
      dataIndex: "status",
      title: "Lead Status",
      display: adminPersona ? false : true,
      sorter: false,
      render: (text, record) => {
        return (
          <span
            className={`badge light border-0 ${
              text === "REJECTED" ? "badge-danger" : "badge-success"
            } `}
          >
            {text?.toTitleCase()}
          </span>
        );
      },
    },
    {
      dataIndex: "applicationStatus",
      title: "Loan Status",
      sorter: false,
      render: (text, record) => {
        return <>{text || "-"}</>;
      },
    },
    {
      dataIndex: "irr",
      title: "ROI",
      sorter: false,
    },
    {
      dataIndex: "Max_loan_amount",
      title: "Max Eligibility",
      render: (text, record) => {
        let value = "";
        if (Object.keys(record).includes("breMaxEligibilityAmount")) {
          value = record?.breMaxEligibilityAmount;
        } else {
          value = text;
        }
        return <>{value}</>;
      },
    },
    {
      dataIndex: "remarks",
      title: "Remarks",
      // display: adminPersona ? true : false,
    },
  ];

  const [otpValue, setOtpValue] = useState("");
  const [isOtpDisabled, setIsOtpDisabled] = useState(true);
  const [showOTPModal, setShowOTPModal] = useState(false);

  const toastConfig = {
    autoClose: 3000,
    closeButton: false,
    hideProgressBar: true,
  };

  const onPageChange = (pageData) => {
    // console.log({ onPageChange: pageData });
    setTotalPageChange({
      ...search,
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const openModal = () => {
    setOpenCanvas(true);
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };

  const handleApplicantFirstName = (e) => {
    const stringReg = _enum?.REGEX?.onlystring;
    if (stringReg.test(e.target.value)) setErrorMessage("");
    else
      setErrorMessage({
        name: "applicant_first_name",
        message: "Special characters are not allowed",
      });
  };

  const handleApplicantLastName = (e) => {
    const stringReg = _enum?.REGEX?.onlystring;
    if (stringReg.test(e.target.value)) setErrorMessage("");
    else
      setErrorMessage({
        name: "applicant_last_name",
        message: "Special characters are not allowed",
      });
  };

  const handleMobileNo = (e) => {
    const numberReg = _enum?.REGEX?.number;
    const fieldValue = e.target.value;
    setMobileNo(fieldValue);
    if (numberReg.test(fieldValue) && fieldValue.length === 10)
      setErrorMessage("");
    else
      setErrorMessage({
        name: "mobileNo",
        message: "Please enter a valid Mobile Number",
      });
  };

  const handleEmailId = (e) => {
    const emailReg = _enum?.REGEX?.email;
    const fieldValue = e.target.value;
    if (emailReg.test(fieldValue)) setErrorMessage("");
    else
      setErrorMessage({
        name: "official_email_id",
        message: "Please enter a valid Email Address",
      });
  };

  const handlePanCard = (e) => {
    const panRegex = _enum?.REGEX?.pan;
    if (panRegex.test(e.target.value)) setErrorMessage("");
    else setErrorMessage({ name: "panNo", message: "Invalid PAN Number" });
  };

  const handlePincode = (e) => {
    const numberReg = _enum?.REGEX?.number;
    const fieldValue = e.target.value;
    var cityField = modalFields.find((o) => o.name == "city");
    var stateField = modalFields.find((o) => o.name == "state");

    if (numberReg.test(fieldValue) && fieldValue.length === 6) {
      setErrorMessage("");

      // HANDLE PINCODE
      let payload = {
        pincode: fieldValue,
      };
      mixins.debounce(() => {
        props.apis.apiGetIndividualMasterPincode(payload).then((res) => {
          if (res) {
            cityField.defaultValue = res?.city;
            stateField.defaultValue = res?.state;
            setModalFields([...modalFields]);
          }
        });
      }, 1000);
    } else {
      setErrorMessage({
        name: "pincode",
        message: "Please enter a valid pincode",
      });
      cityField.defaultValue = "";
      stateField.defaultValue = "";
    }
  };

  const handleMinLoanAmt = (e) => {
    const numberReg = _enum?.REGEX?.number;
    const fieldValue = e.target.value;
    if (numberReg.test(fieldValue) && fieldValue > 50000) setErrorMessage("");
    else
      setErrorMessage({
        name: "min_loan_amount",
        message: "Min amount should be greater than 50k",
      });
  };

  const handleMaxLoanAmt = (e) => {
    const numberReg = _enum?.REGEX?.number;
    const fieldValue = e.target.value;
    if (numberReg.test(fieldValue)) {
      if (fieldValue > 700001) {
        setErrorMessage({
          name: "Max_loan_amount",
          message: "Max amount should be less than 7L",
        });
      } else if (fieldValue < 50000) {
        setErrorMessage({
          name: "Max_loan_amount",
          message: "Min amount should be greater than 50k",
        });
      } else {
        setErrorMessage("");
      }
    } else
      setErrorMessage({
        name: "Max_loan_amount",
        message: "Amount must be a number",
      });
  };

  const handleRequiredLoanAmt = (e) => {
    const numberReg = _enum?.REGEX?.number;
    const fieldValue = e.target.value;
    if (numberReg.test(fieldValue)) {
      if (fieldValue >= 1500001) {
        setErrorMessage({
          name: "required_loan_amount",
          message: "Max amount should be less than 15L",
        });
      } else if (fieldValue < 50000) {
        setErrorMessage({
          name: "required_loan_amount",
          message: "Min amount should be greater than 50k",
        });
      } else {
        setErrorMessage("");
      }
    } else
      setErrorMessage({
        name: "required_loan_amount",
        message: "Amount must be a number",
      });
  };

  const handleAnnualIncome = (e) => {
    const numberReg = _enum?.REGEX?.number;
    const fieldValue = e.target.value;
    if (numberReg.test(fieldValue)) {
      setErrorMessage("");
    } else
      setErrorMessage({
        name: "annual_income",
        message: "Amount must be a number",
      });
  };

  // const handleTotalEmp = (e) => {
  //   const numberReg = _enum?.REGEX?.number;
  //   const fieldValue = e.target.value;
  //   if (numberReg.test(fieldValue)) setErrorMessage("");
  //   else
  //     setErrorMessage({
  //       name: "total_work_exp",
  //       message: "Work experience must be a number",
  //     });
  // };

  const handleAcknowledge = (e) => {
    let submitbtn = document.querySelector(
      'button[data-submitbutton="applicationCanvas"]'
    );
    if (submitbtn) submitbtn.disabled = !e.target.checked;
  };

  const handleCompanyNameSearch = (value) => {
    // console.log(value);
    let payload = {
      // page: 1,
      // limit: 10,
      companyName: value,
    };
    if (value.length >= 3) {
      mixins.debounce(() => {
        props.apis.apiGetMasterCompanies(payload).then(({ data }) => {
          if (data) {
            let companyNameInput = document.querySelector(
              "[name='appinput-company_name'].form-group"
            );
            let companySelect = companyNameInput.vnode.refs.select;
            let { searchResults } = companySelect.state;
            let datawithkey = data?.options("companyName");
            // console.log(datawithkey);
            searchResults = datawithkey;
            companySelect.setState({ searchResults });
          }
        });
      }, 1000);
    }
  };

  const handleCompanyName = (value) => {
    console.log(value);
    let category = value[0]?.category;
    let companyCategoryField = modalFields.find(
      (o) => o.name === "company_category"
    );
    companyCategoryField.defaultValue = category;
    setModalFields([...modalFields]);
  };

  const getApplications = (params = {}, reset) => {
    let payload = {};
    if (reset) {
      let defaultSearchData = Object.only(search, ["limit", "page"]);
      setSearch(defaultSearchData);
      payload = {
        ...defaultSearchData,
      };
    } else {
      payload = {
        ...search,
        ...params,
      };
    }
    props.apis
      .apiGetLntApplicants(payload)
      .then(({ data, total }) => {
        console.log({ apiGetLntApplicants: data });
        setTableData(data);
        setIsFetching(false);
        setRowcount(total);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const viewApplication = (record) => {
    setEditmode(false);
    setViewmode(true);
    // GET APPLICANTS BY ID
    let payload = {
      uid: record.uid,
    };
    props.apis
      .apiGetLntApplicantById(payload, { uid: record.uid })
      .then((response) => {
        console.log({ apiGetLntApplicantById: response });

        let filteredData = Object.only(
          response.data,
          modalFields.map((v) => v.name)
        );
        console.log({ filteredData });
        setPopupdata(filteredData);

        let updatedAdminData = Object.only(
          response.data,
          adminModalFields.map((v) => v.name)
        );
        console.log({ updatedAdminData });
        setAdminData(updatedAdminData);

        setEditData(response.data);
        openModal();
      });
  };

  const handleSubmit = (e, data) => {
    e.preventDefault();
    console.log("formdata: ", data);
    let formdata = data;
    setApplicantFormData(formdata);

    // 1. send consent otp request
    setLoader(true);

    // open otp modal
    setShowOTPModal(true);
    let otpPayload = {
      mobileNo: data?.mobileNo,
    };

    // call consent OTP API
    props.apis
      .apiSendConsentOTP(otpPayload)
      .then((res) => {
        console.log({ apiSendConsentOTP: res });
        setLoader(false);
        setConsentUid(res?.data?.uid);
        toast.success("OTP sent successfully!!", toastConfig);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const resendConsentOTP = (e) => {
    e.preventDefault();
    let payload = {
      uid: consentUid,
    };
    props.apis
      .apiResendConsentOTP(payload)
      .then((res) => {
        console.log({ apiResendConsentOTP: res });
      })
      .catch((err) => console.error(err));
  };

  const handleConsentOtpVerification = (e, otpcode) => {
    e.preventDefault();
    let payload = {
      uid: consentUid,
      consentCode: otpcode,
    };
    props.apis
      .apiVerifyConsentOTP(payload)
      .then((res) => {
        console.log({ apiVerifyConsentOTP: res });
        setLoader(true);
        setShowOTPModal(false);
        let applicantPayload = {
          ...Object.except(applicantFormData, ["acknowledgement"]),
        };
        applicantPayload.uid = consentUid;
        props.apis
          .apiAddLntApplicant(applicantPayload)
          .then((res) => {
            console.log({ apiAddLntApplicant: res });
            closeModal();
            setLoader(false);
            getApplications();
            swal2.fire({
              title: "Application submitted successfully.",
              icon: "success",
              iconColor: "#234C9B",
              timer: 4000,
              confirmButtonText: "ok",
            });
          })
          .catch((err) => {
            setLoader(false);
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        toast.error("OTP mismatched, Please enter valid OTP!!", toastConfig);
      });
  };

  const showGuidelines = () => {
    swal2.fire({
      imageUrl: imagepaths.RBIGuidelines,
      width: 1200,
      imageAlt: "RBI Guideline image",
      confirmButtonText: "Close",
    });
  };

  const onSearch = (data, reset) => {
    console.log("onSearch on leads", data, reset);
    if (Object.keys(data).length === 0) {
      setSearch({
        ...data,
        ...search,
      });
      getApplications(data, reset);
    } else {
      let searchData = {
        ...data,
        ...(data?.startDate
          ? {
              startDate: new Date(data?.startDate).toISOString(),
            }
          : {}),
        ...(data?.endDate
          ? {
              endDate: new Date(data?.endDate).toISOString(),
            }
          : {}),
      };
      console.log({ searchData });
      setSearch({
        ...searchData,
        ...search,
      });
      getApplications(searchData, reset);
    }
  };

  const addModalFields = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setEditmode(false);
    setViewmode(false);
    let fields = (modalFields.filter((v) => v.name !== "remarks") || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    // submitbtn.disabled = true;
    setPopupfields(fields);
    setPopupdata(formdata);
    setOpenCanvas(true);
  };

  const onModalClose = () => {
    console.log("onModalClose");
    setPopupfields({});
    setViewmode(false);
    setEditmode(false);
    setEditData({});
    closeModal();
  };

  const getBreadCums = () => {
    let pagetitle = _enum?.LEAD_TITLE_MAP?.lt_finance;
    return pagetitle;
  };

  const getBackLink = () => {
    return "/app/businesstobusiness?activetab=digitalleads";
  };

  const handleOTPChange = (otp) => {
    console.log(otp, "otp");
    // setOTP(otp);
    setOtpValue(otp);
    if (otp.length === 6) {
      setIsOtpDisabled(false);
    } else {
      setIsOtpDisabled(true);
    }
  };

  const handleBulkDownload = (e) => {
    let ids = tableData?.map((v) => v.uid);
    var payload = {
      consolidatedLeadIds: ids,
    };
    console.log({ ids, payload });
    if (typeof ids[0] !== undefined) {
      props.apis.apiDownloadAdminPaymentInfo(payload).then((res) => {
        console.log({ res });
        if (res) {
          let url = `${
            props.apis.api().api_base
          }/payment-info/admin/download?consolidatedLeadIds=${ids}`;
          var downloadURL = url;
          var link = document.createElement("a");
          link.setAttribute(
            "download",
            `digi_rep_payout_${new Date().toLocaleDateString()}.xlsx`
          );
          link.href = downloadURL;
          link.click();
        }
      });
    }
  };

  const closeOTPModal = () => {
    setShowOTPModal(false);
    setOtpValue("");
  };

  useEffect(() => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    setPopupfields(fields);
  }, [editData]);

  useEffect(() => {
    getApplications();
    if (isPanVerified && isAadharVerified && isAgreementSigned) {
      setIsAccountVerified(true);
    } else {
      setIsAccountVerified(false);
    }
  }, []);

  props.$setNode({
    props,
    search,
    editData,
  });
  return (
    <>
      <MainPagetitle
        mainTitle={
          adminPersona
            ? "B2B Leads | Digital Leads "
            : "My Leads | Digital Leads "
        }
        back={getBreadCums()}
        backLink={getBackLink()}
        pageIcon={imagepaths.AdminLeadsIcon}
      />
      <div className="container-fluid d-flex flex-column">
        <DigitalLeadCard />
        {!adminPersona && (
          <div className="d-flex flex-row-reverse">
            {isAccountVerified && (
              <Link
                className={Object.className({
                  "apply-now-btn btn btn-primary": true,
                })}
                onClick={(...args) => addModalFields(...args)}
              >
                Apply Now
              </Link>
            )}
            <button
              type="button"
              className={Object.className({
                "border-0 bg-transparent grey_action_btn me-2": true,
              })}
              onClick={(...args) => handleBulkDownload(...args)}
            >
              <i className="mr-1">
                <img
                  src={imagepaths.DownloadIcon}
                  alt="download icon"
                  width="18px"
                />
              </i>
              Bulk Download
            </button>
          </div>
        )}
      </div>
      <div className="container-fluid pb-5">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="tbl-caption-container">
                  {/* <h4 className="heading mb-0">Applications</h4> */}
                  <DigitalLeadSearch
                    onSearch={(...arg) => onSearch(...arg)}
                    isAccountVerified={isAccountVerified}
                    addModalFields={addModalFields}
                    handleBulkDownload={handleBulkDownload}
                  />
                </div>
                <AppTable
                  data={tableData}
                  columns={columns}
                  onChange={(...arg) => onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  viewAction={(...args) => viewApplication(...args)}
                  editable={false}
                  deletable={false}
                  loading={isFetching}
                ></AppTable>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DigitalLeadsModal
        id="applicationCanvas"
        title={`${
          // editmode
          //   ? "Edit Applicant Details"
          viewmode ? "View Applicant Details" : "Apply Now"
        }`}
        show={openCanvas}
        onClose={() => onModalClose()}
        data={adminPersona ? adminData : popupdata}
        editdata={editData}
        fields={popupfields}
        viewmode={viewmode}
        // editmode={editmode}
        errorMessage={errorMessage}
        noValidate={true}
        onSubmit={(...args) => handleSubmit(...args)}
        submitText={"Save & Submit"}
        disableallfields={viewmode ? true : false}
        disableSubmit={true}
        loader={loader}
        adminPersona={adminPersona}
      />
      {/* CONSENT OTP MODAL */}
      <Modal
        show={showOTPModal}
        onHide={closeOTPModal}
        centered
        backdrop="static"
        className="consent_otp_modal"
      >
        <Modal.Header closeButton className="border-0 py-4"></Modal.Header>
        <Modal.Body className="text-center">
          <h2 className="mb-5">OTP to verify</h2>
          <p className="desc fs-4">We have sent an OTP to your mobile no.</p>
          <div className="row g-3 mb-4">
            <div className="col-12">
              <OTPInput
                value={otpValue}
                onChange={(e) => handleOTPChange(e)}
                separator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
                numInputs={6}
                shouldAutoFocus={true}
                isInputNum={true}
                containerStyle="otp_container"
              />
            </div>
            <div className="col-12">
              <div className="text-align-start otp_bottom">
                <span className="me-2">Not received OTP?</span>
                <button
                  className="btn px-0 text-primary fs-5"
                  onClick={(e) => resendConsentOTP(e)}
                >
                  Resend
                </button>
              </div>
            </div>
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary w-100 py-3"
                disabled={isOtpDisabled}
                onClick={(e) => handleConsentOtpVerification(e, otpValue)}
              >
                Verify
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <p className="myleads-footer">
        <img src={imagepaths.footerLNTIcon} alt="LNT Icon" />
        <a
          href="https://www.ltfs.com/our-products/personal-loans/fees-charges"
          target="_blank"
          rel="noopener noreferrer"
          className="text text-primary ms-1"
        >
          <u className="me-3">L&T Fees Chargers</u>
        </a>
        | <img src={imagepaths.footerRBIIcon} alt="RBI Icon" className="ms-3" />
        <a href="#" className="text text-primary ms-1">
          <u
            onClick={() => {
              showGuidelines();
            }}
          >
            RBI Digital Guidelines
          </u>
        </a>
      </p>
    </>
  );
};

export default constore(DigitalLeads);
