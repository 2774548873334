import React, { useState } from "react";

const PanVerification = (props) => {
  const [isPanDisabled, setIsPanDisabled] = useState(true);
  const { name, number } = props.pandetails;
  const isPanVerified = props.$store?.user?.isPanVerified;

  const onChangePan = (e) => {
    let value = e.target.value;
    if (value.length > 0) {
      setIsPanDisabled(false);
    } else {
      setIsPanDisabled(true);
      props.handlePanDetailsClose();
    }
  };

  const resetField = () => {
    let panInput = document.querySelector('[name="panNumber"]');
    if (panInput) {
      panInput.value = "";
    }
    props.handlePanDetailsClose();
  };

  return (
    <>
      {!isPanVerified && (
        <>
          <AppForm onSubmit={props.onPanVerify}>
            <div className="col-12 col-md-8 mb-3">
              <label htmlFor="mobileNumber">PAN No.</label>
              <input
                type="text"
                label="PAN No"
                className="form-control"
                onChange={(e) => onChangePan(e)}
                name="panNumber"
              />
            </div>
            <div className="col-12 col-md-3 mb-3 d-flex align-items-end">
              <button
                type="submit"
                className="btn btn-primary w-100"
                disabled={isPanDisabled}
              >
                Verify
              </button>
            </div>
          </AppForm>
        </>
      )}
      {/* PAN DETAILS CONTAINER */}

      {isPanVerified ? (
        <div
          className={Object.className({
            profile_details_container: true,
          })}
        >
          <div className="profile_detail mb-3">
            <p className="m-0">Name as per PAN</p>
            <h4>
              {name?.toTitleCase() ||
                props?.myinfo?.profilePanCardInfo?.name ||
                "-"}
            </h4>
          </div>
          <div className="profile_detail mb-3">
            <p className="m-0">PAN Number</p>
            <h4>
              {number || props?.myinfo?.profilePanCardInfo?.number || "-"}
            </h4>
          </div>
          <div className="profile_detail mb-3">
            <p className="m-0">Aadhar Seeding Status</p>
            <h4>
              {props?.myinfo?.profilePanCardInfo?.aadhaarSeedingStatus ===
              "Successful"
                ? "Yes"
                : "No"}
            </h4>
          </div>
          <div className="nav_container">
            <button
              className="formdata-cancel-btn btn"
              onClick={(...args) => props.onHandleStep(...args, 1)}
            >
              Back
            </button>
            <button
              className="btn btn-primary ms-3"
              onClick={(...args) => props.onHandleStep(...args, 3)}
            >
              Next
            </button>
          </div>
        </div>
      ) : (
        <div
          className={Object.className({
            profile_details_container: true,
            hidden: !props.showPanDetails || isPanVerified,
          })}
        >
          <div className="profile_detail mb-3">
            <p className="m-0">Name as per PAN</p>
            <h4>{props?.pandetails?.name?.toTitleCase()}</h4>
          </div>
          <div className="profile_detail mb-3 d-none">
            <p className="m-0">Date of birth as per PAN</p>
            <h4></h4>
          </div>
          <button
            className="formdata-cancel-btn btn me-3"
            onClick={(...args) => resetField(...args)}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={(...args) => props.onPanSubmit(...args)}
          >
            Save & Submit
          </button>
        </div>
      )}
    </>
  );
};

export default constore(PanVerification);
