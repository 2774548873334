import React from "react";
import { Link } from "react-router-dom";

const ProductCard = ({
  title,
  image,
  path,
  buttonType,
  buttonText,
  isExternalPath = false,
  isDefaultBtn = false,
  handleBtn = () => "",
}) => {
  return (
    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-4">
      <div className="product_card">
        <div className="body d-flex">
          <img src={image} className="img-fluid me-4" alt={title} />
          <div className="content w-100">
            <h4 className="title">{title}</h4>
            {isDefaultBtn ? (
              <>
                <a href="http://" target="_blank" rel="noopener noreferrer"></a>
                <button
                  type="button"
                  className={Object.className({
                    "btn btn-outline-primary w-100": true,
                    "pe-none": buttonType === "inactive" ? true : false,
                  })}
                  onClick={(...args) => handleBtn(...args)}
                >
                  <span>{buttonText || "View Details"}</span>
                </button>
              </>
            ) : (
              <Link
                className={Object.className({
                  "btn btn-outline-primary w-100": true,
                  "pe-none": buttonType === "inactive" ? true : false,
                })}
                to={path}
                {...(isExternalPath
                  ? {
                      target: "_blank",
                    }
                  : {})}
                disabled={buttonType === "inactive" ? true : false}
              >
                <span>{buttonText || "View Details"}</span>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
