import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { InterestRateModal } from "./InterestRateModal";

const InterestRate = (props) => {
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [modalFields, setModalFields] = useState([
    {
      label: "Bank Name",
      name: "bankId",
      type: "select",
      options: [],
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Loan Type",
      name: "loanType",
      type: "select",
      options: [],
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Interest Type",
      name: "interestType",
      type: "select",
      options: _enum.INTEREST_TYPES,
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Interest Rate",
      name: "interestRate",
      type: "text",
      step: ".01",
      placeholder: "Please Enter",
      onChange: (e) => handleInterestRate(e),
      required: true,
    },
    {
      label: "Max Loan Amt",
      name: "loanAmount",
      type: "text",
      placeholder: "Please Enter",
      onChange: (e) => handleLoanAmount(e),
      required: true,
    },
    {
      label: "Processing Fee",
      name: "processingFee",
      type: "text",
      placeholder: "Please Enter",
      onChange: (e) => handleProcessingFee(e),
      required: true,
    },
    {
      label: "Max Tenure",
      name: "tenure",
      type: "text",
      placeholder: "Please Enter",
      onChange: (e) => handleTenture(e),
      required: true,
    },
  ]);
  const [editData, setEditData] = useState([]);
  const [editmode, setEditmode] = useState(false);
  const [viewmode, setViewmode] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [popupfields, setPopupfields] = useState({});
  const [popupdata, setPopupdata] = useState({});

  const [bankData, setBankData] = useState([]);
  const [targetFileId, setTargetFileId] = useState("");
  const [errorMessage, setErrorMessage] = useState({ name: "", message: "" });

  const [rowcount, setRowcount] = useState(0);
  const columns = [
    {
      dataIndex: "bankName",
      title: "Bank Name",
      render: (text, record) => {
        return (
          <span className="table-primary-text">
            {record?.bankDetails?.bankName}
          </span>
        );
      },
    },
    {
      dataIndex: "loanType",
      title: "Loan Type",
      render: (text) => {
        return <>{text.toTitleCase()}</>;
      },
    },
    {
      dataIndex: "interestRate",
      title: "Interest Rate",
    },
    {
      dataIndex: "interestType",
      title: "Interest Type",
    },
    {
      dataIndex: "processingFee",
      title: "Processing Fee",
      render: (text) => {
        return <>{text.cur()}</>;
      },
    },
    {
      dataIndex: "loanAmount",
      title: "Max Loan Amt",
      render: (text) => {
        return <>{text.cur()}</>;
      },
    },
    {
      dataIndex: "tenure",
      title: "Max Tenure",
    },
  ];

  const onPageChange = (pageData) => {
    console.log({ onPageChange: pageData });
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const openModal = () => {
    setOpenCanvas(true);
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };

  const handleInterestRate = (e) => {
    const numberReg = _enum?.REGEX?.float;
    const fieldValue = e.target.value;
    if (numberReg.test(fieldValue)) setErrorMessage("");
    else
      setErrorMessage({
        name: "interestRate",
        message: "Interest Rate must be a number",
      });
  };
  const handleLoanAmount = (e) => {
    const numberReg = _enum?.REGEX?.number;
    const fieldValue = e.target.value;
    if (numberReg.test(fieldValue)) setErrorMessage("");
    else
      setErrorMessage({
        name: "loanAmount",
        message: "Loan amount must be a number",
      });
  };
  const handleProcessingFee = (e) => {
    const numberReg = _enum?.REGEX?.number;
    const fieldValue = e.target.value;
    if (numberReg.test(fieldValue)) setErrorMessage("");
    else
      setErrorMessage({
        name: "processingFee",
        message: "Processing Fee must be a number",
      });
  };
  const handleTenture = (e) => {
    const numberReg = _enum?.REGEX?.number;
    const fieldValue = e.target.value;
    if (numberReg.test(fieldValue)) setErrorMessage("");
    else
      setErrorMessage({
        name: "tenure",
        message: "Tenure must be a number",
      });
  };

  const getProductTypes = () => {
    props.apis.apiGetProductTypes().then((res) => {
      let loanType = modalFields.find((o) => o.name == "loanType");
      loanType.options = res.options("productType");
      setModalFields([...modalFields]);
    });
  };

  const getBankNames = () => {
    let payload = {
      page: 1,
    };
    props.apis.apiGetBanknames(payload).then(({ data, count }) => {
      // console.log({apiGetBanknames: data});
      let bankNameField = modalFields.find((o) => o.name == "bankId");
      bankNameField.options = data.options("bankName");
      setModalFields([...modalFields]);
      setBankData(data);
    });
  };

  const getInterestRates = () => {
    let payload = {
      ...search,
    };
    props.apis.apiGetInterestRates(payload).then(({ data, count }) => {
      console.log({ apiGetInterestRates: data });
      setTableData(data);
      setIsFetching(false);
      setRowcount(count);
    });
  };

  const viewInterestRate = (record) => {
    setEditmode(false);
    setViewmode(true);
    console.log({ record });
    // GET INTEREST-RATES BY ID
    props.apis
      .apiGetInterestRateById({ interestRateId: record.interestRateId })
      .then((data) => {
        console.log({ apiGetInterestRateById: data });
        openModal();
        let filteredData = Object.only(
          data,
          modalFields.map((v) => v.name)
        );
        console.log({ modalFields, data, filteredData });
        let allData = {
          ...Object.except(filteredData, ["bankName"]),
        };
        setPopupdata(allData);
        setEditData(data);
      });
  };

  const editInterestRate = (record) => {
    console.log({ editInterestRate: record.interestRateId });
    setEditmode(true);
    setViewmode(false);

    // GET INTEREST RATE BY ID
    props.apis
      .apiGetInterestRateById({ interestRateId: record.interestRateId })
      .then((data) => {
        console.log({ apiGetInterestRateById: data });
        openModal();
        let filteredData = Object.only(
          data,
          modalFields.map((v) => v.name)
        );
        console.log({ modalFields, data, filteredData });
        let allData = {
          ...Object.except(filteredData, ["bankName"]),
        };
        setPopupdata(allData);
        setEditData(data);
      });
  };

  const deleteInterestRate = (record) => {
    console.log({ deleteInterestRate: record.interestRateId });
    swal2
      .fire({
        title: "Are you sure you want to delete this interest?",
        icon: "info",
        iconColor: "#234C9B",
        showCancelButton: true,
        confirmButtonColor: "#234C9B",
        cancelButtonColor: "##D6DBE7",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
      .then((result) => {
        if (result.isConfirmed) {
          props.apis
            .apiDeleteInterestRate({ interestRateId: record.interestRateId })
            .then((res) => {
              console.log({ apiDeleteInterestRate: res });
              swal2.fire({
                title: "Interest Rate deleted successfully !!",
                icon: "success",
                iconColor: "#234C9B",
                timer: 4000,
              });
              getInterestRates();
            });
        }
      });
  };

  const handleSubmit = (e, data, form) => {
    e.preventDefault();
    // console.log("formdata: ", data);
    let formdata = data;
    let payload = {
      ...formdata,
    };
    console.log({ payload });

    if (editmode) {
      let editPayload = {
        ...Object.except(formdata, ["bankId", "bankName", "fileId"]),
      };
      editPayload.bankId = Number(payload.bankId);

      console.log({ editPayload });
      props.apis
        .apiUpdateInterestRate(editPayload, {
          interestRateId: editData?.interestRateId,
        })
        .then((res) => {
          console.log({ apiUpdateInterestRate: res });
          swal2.fire({
            title: "Interest Rate updated successfully.",
            icon: "success",
            iconColor: "#234C9B",
            timer: 4000,
          });
          closeModal();
          getInterestRates();
        });
    } else {
      let addPayload = {
        ...Object.except(formdata, ["bankId", "bankName", "fileId"]),
      };
      addPayload.bankId = Number(payload.bankId);

      console.log({ bankData });

      // let targetobj = bankData?.filter(
      //   (v) => v.bankId === Number(data.bankName)
      // );
      // let selectedId = targetobj[0].fileId;
      // let selectedBank = targetobj[0].bankName;

      // console.log({ targetobj, selectedId });

      // addPayload.fileId = selectedId;

      console.log({ apiAddInterestRate: addPayload });

      props.apis.apiAddInterestRate(addPayload).then((res) => {
        console.log({ apiAddInterestRate: res });
        swal2.fire({
          title: "Interest Rate added successfully.",
          icon: "success",
          iconColor: "#234C9B",
          timer: 4000,
        });
        closeModal();
        getInterestRates();
      });
    }
  };

  const addModalFields = (values) => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    setPopupfields(fields);
    setPopupdata(formdata);
    setOpenCanvas(true);
  };

  const onModalClose = () => {
    console.log("onModalClose");
    closeModal();
    setPopupfields({});
    setViewmode(false);
    setEditmode(false);
    setEditData({});
  };

  useEffect(() => {
    getProductTypes();
    getBankNames();
  }, [openCanvas]);

  useEffect(() => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    setPopupfields(fields);
  }, [editData]);

  useEffect(() => {
    getInterestRates();
  }, [search]);

  useEffect(() => {
    getProductTypes();
    getInterestRates();
    getBankNames();
  }, []);

  props.$setNode({
    props,
    bankData,
    targetFileId,
  });

  return (
    <>
      <div className="page_header mb-3 text-end px-4">
        <Link
          to={"#"}
          className="btn btn-primary ms-1"
          onClick={(...args) => addModalFields(...args)}
        >
          <span className="text-white mb-0">+ Add New</span>
        </Link>
      </div>
      <AppTable
        data={tableData}
        columns={columns}
        onChange={(...arg) => onPageChange(...arg)}
        total={rowcount}
        reorder={true}
        editAction={(...args) => editInterestRate(...args)}
        viewAction={(e) => viewInterestRate(e)}
        deleteAction={(e) => deleteInterestRate(e)}
        loading={isFetching}
      ></AppTable>

      <InterestRateModal
        id="interestrateCanvas"
        title={`${
          editmode
            ? "Edit Interest Rate"
            : viewmode
            ? "View Interest Rate"
            : "Add Interest Rate"
        }`}
        show={openCanvas}
        onClose={() => onModalClose()}
        data={popupdata}
        editdata={editData}
        fields={popupfields}
        editmode={editmode}
        onSubmit={(...args) => handleSubmit(...args)}
        submitText={`${editmode ? "Update" : "Add"}`}
        disableallfields={viewmode ? true : false}
        errorMessage={errorMessage}
        viewmode={viewmode}
      />
    </>
  );
};

export default constore(InterestRate);
