import React, { useEffect, useState } from "react";

const LeadCards = (props) => {
  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    let loggedInUser = props?.$store?.user?.role;
    console.log({ LeadCards: loggedInUser });
    if (loggedInUser === _enum.ROUTE_PERSONAS.sales) {
      props.apis.apiGetTelecallerDashboardMoke().then((res) => {
        console.log({ apiGetTelecallerDashboardMoke: res });
        setCardData(res);
      });
    } else if (loggedInUser === _enum.ROUTE_PERSONAS.ops) {
      props.apis.apiGetOperationsDashboardMoke().then((res) => {
        console.log({ apiGetTelecallerDashboardMoke: res });
        setCardData(res);
      });
    } else {
      setCardData([]);
    }
  }, []);

  return (
    <>
      {cardData?.map((card, i) => (
        <div className="col-xl-3 col-sm-6" key={i}>
          <div className="card leads_card">
            <div className="card-body">
              <div className="d-flex pb-0">
                <div className="d-flex align-items-start me-3">
                  <img src={card.icon} alt="card icon" />
                </div>
                <div>
                  <h6>{card.title}</h6>
                  <h3 className="text-primary">{card.value}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default constore(LeadCards);
