import React, { Component } from "react";
import { Link } from "react-router-dom";
export class BankDumpsSearch extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {};
    this.fields = [
      {
        name: "name",
        placeholder: "Bank Name",
        type: "select",
        options: [],
      },
      {
        name: "mobileNumber",
        placeholder: "Application No.",
        type: "text",
      },
      {
        name: "city",
        placeholder: "Product Type",
        type: "select",
        options: [],
      },
      {
        name: "designation",
        placeholder: "Month & Year",
        type: "month",
      },
    ];
  }

  componentDidMount() {}

  onSearch(reset = false) {
    let data = this.refs.form.form.current.getData();
    console.log("onSearch formData", data, reset);
    if (reset) {
      data = {
        ...this.state.search,
      };
    } else {
      data = Object.filter(data, (val) => val);
      // console.log("data on search", data);
    }
    this.props.onSearch && this.props.onSearch(data, reset);
  }

  resetSearchForm() {
    this.refs.form.form.current.reset();
    this.onSearch(true);
  }

  render() {
    let { fields } = this;

    return (
      <AppForm className="filter-row flex-auto mx-auto" ref="form">
        <div className="d-none col-sm-6 col-md-2 col-lg-4"></div>
        {fields.map((field, i) => (
          <div className="col-6 col-md-4 col-lg-3 col-xl-2" key={i}>
            <AppInputFocus ref="inputname" {...field}></AppInputFocus>
          </div>
        ))}
        <div className="col-6 col-md-1 col-lg-1 col-xl-1 p-0 d-flex align-items-start justify-content-center mw-75">
          <button
            className="btn btn-primary search_btn"
            onClick={() => this.onSearch()}
          >
            <i className="fa fa-search"></i>
            <span>Search</span>
          </button>
        </div>
        <div className="col-6 col-md-1 col-lg-1 col-xl-1 p-0 d-flex align-items-start justify-content-center mw-75">
          <button
            type="button"
            className="btn btn-primary clear_filter_btn"
            onClick={(...args) => this.resetSearchForm(...args)}
          >
            <i class="fa-solid fa-filter-circle-xmark"></i>
          </button>
        </div>
      </AppForm>
    );
  }
}

export default constore(BankDumpsSearch);
