import masterdataApi from "./devapis/masterdata.api";
import myleadsapi from "./devapis/myleadsapi.api";
import offlineleads from "./devapis/offlineleads.api";
import myearnings from "./devapis/myearnings.api";
import leadsApi from "./devapis/leads.api";
import applicationsApi from "./devapis/applications.api";

export default {
    ...masterdataApi,
    ...myleadsapi,
    ...offlineleads,
    ...myearnings,
    ...leadsApi,
    ...applicationsApi,

    HEARTBEAT: {
        url: '/heartbeat',
        method: "get",
    },
    LOGIN: {
        url: "/auth/login",
        method: "post"
    },
    OTP_LOGIN: {
        url: "/auth/otp/{mobile}",
        method: "get"
    },
    OTP_LOGIN_VERIFY: {
        url: "/auth/verify",
        method: "post"
    },
    REFRESH_TOKEN: {
        url: "/auth/refresh",
        method: "post"
    },
    PASSWORD_RESET: {
        url: "/auth/change",
        method: "put"
    },
    GENERATE_TEMPORARY_PASSWORD: {
        url: "/auth/reset/{email}",
        method: "put"
    },
    GET_USER: {
        url: "/auth/me",
        method: "get"
    },
    LOGOUT: {
        url: "/auth/logout",
        method: "post"
    },

    // MY PROFILE
    GET_DIGIREP: {
        url: "/profile",
        method: "get"
    },
    CREATE_DIGIREP: {
        url: "/profile",
        method: "post",
    },
    UPDATE_EMPLOYEE: {
        url: "/profile",
        method: "put"
    },
    GET_EMPLOYEE_BY_ID: {
        url: "/profile/{profileId}",
        method: "get"
    },
    DELETE_EMPLOYEE: {
        url: "/profile/{profileId}",
        method: "delete"
    },
    GET_MY_INFO: {
        url: "/profile/myinfo",
        method: "get",
    },
    GET_SINGLE_PROFILE_INFO: {
        url: "/profile/{profileId}",
        method: "get",
    },

    // CUSTOMER KYC
    KYC_SEND_OTP: {
        url: "/kyc/profile/mobile/request",
        method: "post"
    },
    KYC_VERIFY_OTP: {
        url: "/kyc/profile/mobile/verify",
        method: "post"
    },
    GET_CUSTOMER_PAN_DETAILS: {
        url: "/kyc/profile/pan/request",
        method: "post"
    },
    VERIFY_CUSTOMER_PAN: {
        url: "/kyc/profile/pan/verify",
        method: "post"
    },
    DELETE_CUSTOMER_PAN: {
        url: "/kyc/profile/pan/{profileId}",
        method: "delete"
    },
    GET_CUSTOMER_AADHAR_DETAILS: {
        url: "/kyc/profile/aadhar/request",
        method: "post"
    },
    VERIFY_CUSTOMER_AADHAR: {
        url: "/kyc/profile/aadhar/verify",
        method: "post"
    },
    DELETE_CUSTOMER_AADHAR: {
        url: "/kyc/profile/aadhar/{profileId}",
        method: "delete"
    },
    PROFILE_UPLOAD_DOCS: {
        url: "/kyc/profile/doc-upload",
        method: "put"
    },
    PROFILE_UPLOAD_DOCS: {
        url: "/kyc/profile/doc-upload",
        method: "put"
    },
    SEND_AGREEMENT_FOR_ESIGN: {
        url: "/kyc/profile/agreement/{profileId}",
        method: "post"
    },

    // CUSTOMER AUTHENTICATION
    CUSTOMER_SIGNUP_REQUEST: {
        url: "/customer-auth/signup/request/{mobileNumber}",
        method: "get"
    },
    CUSTOMER_SIGNUP_VERIFY: {
        url: "/customer-auth/signup/verify",
        method: "post"
    },
    CUSTOMER_SIGNIN_REQUEST: {
        url: "/customer-auth/signin/request/{mobileNumber}",
        method: "get"
    },
    CUSTOMER_SIGNIN_VERIFY: {
        url: "/customer-auth/signin/verify",
        method: "post"
    },
    CUSTOMER_SIGNUP_COMPLETE: {
        url: "/customer/signup/complete",
        method: "post"
    },
    CUSTOMER_INFORMATION: {
        url: "/customer",
        method: "get"
    },
    // FILE API'S
    FILE_UPLOAD: {
        url: "/files/upload/{foldername}",
        method: "post"
    },
    GET_FILE_METADATA: {
        url: "/files/metadata/{fileId}",
        method: "get"
    },
    FILE_DOWNLOAD: {
        url: "/files/download/{fileId}",
        method: "get"
    },
    THUMBNAIL_DOWNLOAD: {
        url: "/files/tuhmbnail/{fileId}",
        method: "get"
    },
    DELETE_FILE: {
        url: "/files/remove/{fileId}",
        method: "delete"
    },

    // VAS - WALLET
    VAS_RECHARGE: {
        url: "/vas/wallet/recharge",
        method: "post"
    },
    GET_USER_BALANCE: {
        url: "/vas/wallet/balance",
        method: "get"
    },
    GET_PAST_TRANSACTION: {
        url: "/vas/wallet/transaction",
        method: "get"
    },

    // VAS - SERVICES
    VAS_GET_PAN_DETAILS: {
        url: "/vas/services/pan",
        method: "post"
    },
    VAS_GET_AADHAR_INFO: {
        url: "/vas/services/aadhar/request",
        method: "post"
    },
    VAS_AADHAR_VERIFY: {
        url: "/vas/services/aadhar/verify",
        method: "post"
    },
    // REQUEST
    CONNECTOR_REQUEST_RECHARGE: {
        url: "/request-recharge",
        method: "post"
    },
    GET_REQUEST_RECHARGE: {
        url: "/request-recharge",
        method: "get"
    },
    GET_REQUEST_RECHARGE_BY_ID: {
        url: "/request-recharge/{rechargeId}",
        method: "get"
    },
    UPDATE_REQUEST_RECHARGE_BY_ID: {
        url: "/request-recharge/{rechargeId}",
        method: "put"
    },
    SEND_REQUEST_RECHARGE_OTP: {
        url: "/request-recharge/processing/request/{rechargeId}",
        method: "get"
    },
    VERIFY_REQUEST_RECHARGE_OTP: {
        url: "/request-recharge/processing/verify/{rechargeId}",
        method: "put"
    },
    REJECT_REQUEST_RECHARGE_OTP: {
        url: "/request-recharge/reject/{rechargeId}",
        method: "put"
    },

    // SERVICE UTILIZATION
    GET_SERVICE_UTILIZATION: {
        url: "/service-utilization",
        method: "get"
    },

    // MASTER SUBSCRIPTION
    GET_MASTER_SUBSCRIPTION: {
        url: "/master-subscription",
        method: "get"
    },

}
