import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import WorkLocationModal from "./WorkLocationModal";

const Worklocation = (props) => {
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [errorMessage, setErrorMessage] = useState({ name: "", message: "" });
  const [modalFields, setModalFields] = useState([
    {
      label: "Location Name",
      name: "locationName",
      type: "text",
      placeholder: "Please Enter",
      onChange: (e) => validateLocation(e),
      required: true,
    },
    {
      label: "State",
      name: "state",
      type: "select",
      options: Object.keys(statecity).options(),
      onChange: (value) => {
        getCities(value);
      },
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "City",
      name: "city",
      type: "select",
      options: [],
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Latitude",
      name: "latitude",
      type: "text",
      placeholder: "Please Enter",
      onChange: (e) => validateLatitude(e),
      required: true,
    },
    {
      label: "Longitude",
      name: "longitude",
      type: "text",
      placeholder: "Please Enter",
      onChange: (e) => validateLongitude(e),
      required: true,
    },
    // {
    //   label: "Status",
    //   name: "status",
    //   type: "select",
    //   options: _enum.STATUS,
    //   defaultValue: "active",
    //   placeholder: "Please Enter",
    // },
  ]);
  const [editData, setEditData] = useState([]);
  const [editmode, setEditmode] = useState(false);
  const [viewmode, setViewmode] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [popupfields, setPopupfields] = useState({});
  const [popupdata, setPopupdata] = useState({});

  const [rowcount, setRowcount] = useState(0);
  const columns = [
    {
      dataIndex: "locationName",
      title: "Location Name",
      render: (text, record) => {
        return <span className="table-primary-text">{text}</span>;
      },
    },
    {
      dataIndex: "state",
      title: "State",
    },
    {
      dataIndex: "city",
      title: "City",
    },
    {
      dataIndex: "latitude",
      title: "Latitude",
    },
    {
      dataIndex: "longitude",
      title: "Longitude",
    },
    // {
    //   dataIndex: "status",
    //   title: "Status",
    //   render: (text, record) => {
    //     return (
    //       <span
    //         className={`badge light border-0 ${
    //           text.toLowerCase() === "active" ? "badge-success" : "badge-danger"
    //         } `}
    //       >
    //         {text?.toTitleCase()}
    //       </span>
    //     );
    //   },
    // },
  ];

  const validateLocation = (e) => {
    const stringReg = _enum?.REGEX?.onlystring;
    if (stringReg.test(e.target.value)) setErrorMessage("");
    else
      setErrorMessage({
        name: "locationName",
        message: "Special characters are not allowed",
      });
  };

  const validateLatitude = (e) => {
    const numberReg = _enum?.REGEX?.degrees;
    if (numberReg.test(e.target.value)) setErrorMessage("");
    else
      setErrorMessage({
        name: "latitude",
        message: "Characters are not allowed",
      });
  };

  const validateLongitude = (e) => {
    const numberReg = _enum?.REGEX?.degrees;
    if (numberReg.test(e.target.value)) setErrorMessage("");
    else
      setErrorMessage({
        name: "longitude",
        message: "Characters are not allowed",
      });
  };

  const onPageChange = (pageData) => {
    console.log({ onPageChange: pageData });
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const openModal = () => {
    setOpenCanvas(true);
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };

  const getCities = (value) => {
    let city = modalFields?.find((o) => o.name == "city");
    console.log({ city });
    city.options = Object.assign(
      city.options,
      statecity[value[0].key].options()
    );
    setModalFields([...modalFields]);
  };

  const getMasterLocations = () => {
    let payload = {
      ...search,
    };
    props.apis.apiGetMasterLocations(payload).then(({ data, count }) => {
      setTableData(data);
      setIsFetching(false);
      setRowcount(count);
    });
  };

  const viewLocation = (record) => {
    setEditmode(false);
    setViewmode(true);
    // GET LOCATION BY ID
    props.apis
      .apiGetLocationById({ locationId: record.locationId })
      .then((data) => {
        console.log({ apiGetLocationById: data });
        let filteredData = Object.only(
          data,
          modalFields.map((v) => v.name)
        );

        // setting default values
        let stateVal = filteredData?.state;
        let cityVal = filteredData?.city;
        let city = modalFields?.find((o) => o.name == "city");
        city.options = Object.assign(
          city.options,
          statecity[stateVal].options()
        );
        city.defaultValue = cityVal;

        console.log({ filteredData });
        setPopupdata(filteredData);
        setEditData(data);
        openModal();
      });
  };

  const editLocation = (record) => {
    setEditmode(true);
    setViewmode(false);
    // GET LOCATION BY ID
    props.apis
      .apiGetLocationById({ locationId: record.locationId })
      .then((data) => {
        console.log({ apiGetLocationById: data });
        let filteredData = Object.only(
          data,
          modalFields.map((v) => v.name)
        );

        // setting default values
        let stateVal = filteredData?.state;
        let cityVal = filteredData?.city;
        let city = modalFields?.find((o) => o.name == "city");
        city.options = Object.assign(
          city.options,
          statecity[stateVal].options()
        );
        city.defaultValue = cityVal;

        console.log({ filteredData });
        setPopupdata(filteredData);
        setEditData(data);
        openModal();
      });
  };

  const deleteLocation = (record) => {
    console.log({ deleteLocation: record.locationId });
    swal2
      .fire({
        title: "Are you sure you want to delete this location?",
        icon: "info",
        iconColor: "#234C9B",
        showCancelButton: true,
        confirmButtonColor: "#234C9B",
        cancelButtonColor: "##D6DBE7",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
      .then((result) => {
        if (result.isConfirmed) {
          props.apis
            .apiDeleteMasterLocation({ locationId: record.locationId })
            .then((res) => {
              console.log({ apiDeleteMasterLocation: res });
              swal2.fire({
                title: "Location deleted successfully !!",
                icon: "success",
                iconColor: "#234C9B",
                timer: 4000,
              });
              getMasterLocations();
            });
        }
      });
  };

  const handleSubmit = (e, data, form) => {
    e.preventDefault();
    console.log("formdata: ", data);
    let formdata = data;
    let payload = {
      ...formdata,
    };
    if (editmode) {
      props.apis
        .apiUpdateMasterLocation(payload, {
          locationId: editData?.locationId,
        })
        .then((res) => {
          console.log({ apiUpdateMasterLocation: res });
          swal2.fire({
            title: "Location updated successfully.",
            icon: "success",
            iconColor: "#234C9B",
            timer: 4000,
          });
          closeModal();
          getMasterLocations();
        });
    } else {
      props.apis.apiAddMasterLocation(payload).then((res) => {
        console.log({ apiAddMasterLocation: res });
        swal2.fire({
          title: "Location added successfully.",
          icon: "success",
          iconColor: "#234C9B",
          timer: 4000,
        });
        closeModal();
        getMasterLocations();
      });
    }
  };

  const addModalFields = (values) => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    console.log("formdata: ", fields, formdata);
    setPopupfields(fields);
    setPopupdata(formdata);
    setOpenCanvas(true);
  };

  const onModalClose = () => {
    console.log("onModalClose");
    closeModal();
    setPopupfields({});
    setViewmode(false);
    setEditmode(false);
    setEditData({});
  };

  useEffect(() => {
    getMasterLocations();
  }, [search]);

  useEffect(() => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    // console.log({ updatedfields: fields });
    setPopupfields(fields);
  }, [editData]);

  useEffect(() => {
    getMasterLocations();
  }, []);

  props.$setNode({
    props,
    search,
    editData,
  });
  return (
    <>
      <div className="page_header mb-3 text-end pe-4">
        <Link
          to={"#"}
          className="btn btn-primary ms-1"
          onClick={(...args) => addModalFields(...args)}
        >
          <span className="text-white mb-0">+ Add Location</span>
        </Link>
      </div>

      <AppTable
        data={tableData}
        columns={columns}
        onChange={(...arg) => onPageChange(...arg)}
        total={rowcount}
        reorder={true}
        editAction={(e) => editLocation(e)}
        viewAction={(e) => viewLocation(e)}
        deleteAction={(e) => deleteLocation(e)}
        loading={isFetching}
      ></AppTable>

      <WorkLocationModal
        id="worklocationCanvas"
        title={`${
          editmode
            ? "Edit Location"
            : viewmode
            ? "View Location"
            : "Add Location"
        }`}
        show={openCanvas}
        onClose={() => onModalClose()}
        data={popupdata}
        editdata={editData}
        fields={popupfields}
        editmode={editmode}
        onSubmit={(...args) => handleSubmit(...args)}
        submitText={`${editmode ? "Update" : "Submit"}`}
        disableallfields={viewmode ? true : false}
        errorMessage={errorMessage}
        viewmode={viewmode}
        swapfields={["city", "state"]}
      />
    </>
  );
};

export default constore(Worklocation);
