export default {

    apiAddLead(data) {
        return this.request(this.api().ADD_LEAD, data).exec().log().get();
    },

    apiGetLeads(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_LEADS, payload)
            .exec().log()
            .mapresults((response) => {
                return {
                    data: response?.data,
                    total: response?.count
                }
            })
            .get();
    },

    apiGetLeadsById(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().GET_LEADS_BY_ID,)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((res) => {
                return res
            })
            .get();
    },

    apiUpdateLead(data, urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };

        let payload = {
            leadName: data?.leadName,
            mobileNumber: data?.mobileNumber,
            emailAddress: data?.emailAddress,
            productType: data?.productType,
            source: data?.source,
            remarks: data?.remarks,
            status: data?.status,
        }
        payload = Object.filter(payload, (v) => v);

        return this.request(this.api().UPDATE_LEAD, payload).urltransform(urldata).exec().log().get();
    },

    apiDeleteLead(urldata = mixins.params()) {

        return this.request(this.api().DELETE_LEAD)
            .urltransform(urldata)
            .exec().log().get();
    },

    apiAssignDeptToLead(data) {
        return this.request(this.api().ASSIGN_DEPT_TO_LEAD, data)
            .exec()
            .log()
            .get();
    },

    apiGetLeadHistory(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_LEAD_HISTORY)
            .exec().log()
            .mapresults((response) => {
                return response
            })
            .get();
    },

    // BULK FILES
    apiBulkUploadLead(data, urldata = mixins.params()) {
        console.log(data, urldata);
        return this.request(this.api().BULK_UPLOAD_LEAD, data)
            .urltransform(urldata)
            .multipart()
            .exec()
            .log()
            .get();
    },
    apiDownloadLeadsTemplate() {
        return this.request(this.api().DOWNLOAD_LEADS_TEMPLATE)
            .exec()
            .log()
            .get();
    },
    apiBulkDownloadLead(data) {
        return this.request(this.api().DOWNLOAD_LEADS_LISTS, data)
            .exec()
            .log()
            .get();
    },
}