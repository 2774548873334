import BusinesstoBusiness from "../../../jsx/components/Dashboard/BusinesstoBusiness/businesstobusiness";

const MyLeadsWrapper = () => {
  return <BusinesstoBusiness />;
};

export const allowedRoles = [
  _enum.ROUTE_PERSONAS.admin,
  _enum.ROUTE_PERSONAS.connector,
  _enum.ROUTE_PERSONAS.manager,
];

export default constore(MyLeadsWrapper);
