import React, { Component } from "react";

export class InCredFinanceSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        limit: 10,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
    };
    this.fields = [
      {
        name: "applicant_name",
        label: "Applicant Name",
        placeholder: "Application Name",
      },
      {
        name: "mobileNo",
        label: "Applicant Mobile No.",
        placeholder: "Applicant Mobile No.",
      },
      {
        name: "date",
        label: "Application Date",
        type: "date",
        render: (text, record) => {
          let date = new Date(text).dateToDDMMYYYY(new Date(text));
          return date;
        },
        placeholder: "DD-MM-YYYY",
      },
      {
        name: "application_status",
        label: "Application Status",
        type: "select",
        options: _enum.LOAN_STATUS,
        placeholder: "Application Status",
      },
    ];
  }

  componentDidMount() {
    this.getProductTypes();
  }

  getProductTypes() {
    // this.props.apis.apiGetProductTypes().then((res) => {
    //   let productType = this.fields.find((o) => o.name == "productType");
    //   productType.options = res.options("productType");
    // });
  }

  onSearch = (reset = false) => {
    let data = this.refs.form.form.current.getData();
    console.log("onSearch formData", data, reset);
    data = Object.filter(data, (val) => val);
    this.props.onSearch && this.props.onSearch(data, reset);
  };

  resetSearchForm() {
    this.refs.form.form.current.reset();
    this.onSearch(true);
  }

  render() {
    return (
      <>
        <AppForm
          className="filter-row flex-auto mx-auto justify-content-end"
          ref="form"
        >
          <div className="d-none d-xl-flex col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"></div>
          {this.fields.map((field, i) => (
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" key={i}>
              <AppInputFocus ref="inputname" {...field}></AppInputFocus>
            </div>
          ))}
          {/* <div className="col-12 col-sm-3 col-md-2 col-lg-1 p-0 d-flex align-items-start justify-content-center">
            <button
              className="btn btn-primary search_btn"
              onClick={() => this.onSearch()}
            >
              <i className="fa fa-search"></i>
              <span>Search</span>
            </button>
          </div> */}
          <div className="col-6 col-md-1 col-lg-1 col-xl-1 p-0 d-flex align-items-start justify-content-center mw-75">
            <button
              className="btn btn-primary search_btn"
              onClick={() => this.onSearch()}
            >
              <i className="fa fa-search"></i>
              <span>Search</span>
            </button>
          </div>
          <div className="col-6 col-md-1 col-lg-1 col-xl-1 p-0 d-flex align-items-start justify-content-center mw-75">
            <button
              type="button"
              className="btn btn-primary clear_filter_btn"
              onClick={(...args) => this.resetSearchForm(...args)}
            >
              <i class="fa-solid fa-filter-circle-xmark"></i>
            </button>
          </div>
        </AppForm>
      </>
    );
  }
}

export default constore(InCredFinanceSearch);
