import statecity from "./statecity";
window.statecity = statecity;
export const state = {
    language: "english",
    config: {
        name: "",
        shortname: "",
        tagline: "",
        logo: "self",
        colors: {
            "defaultbg": "white",
            "defaultText": "black",
            "primary": "#1c67f6",
            "primaryText": "white",
            "secoundary": "#692E20",
            "secoundaryText": "white",
        }
    },
    // persona: "admin",
    persona: "",
    loading: false,
    requests: [],
    theme: "",
    auth: "",
    user: null,
}

export default state;