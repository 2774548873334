import React, { useEffect, useState } from "react";
import ProjectStatusBlogDonutChart from "./ProjectStatusBlogDonutChart";
import { Dropdown } from "react-bootstrap";

const mediaBlog = [
  { title: "Approved Leads", color: "var(--primary)", total_leads: 25 },
  { title: "Disbursed Leads", color: "#3AC977", total_leads: 25 },
  { title: "Pending Leads", color: "#FF9F00", total_leads: 25 },
  { title: "Rejected Leads", color: "#FF5E5E", total_leads: 25 },
];
const ProjectStatusBlog = (props) => {
  const [globalSelect, setGlobalSelect] = useState("today");
  const [globalSelect2, setGlobalSelect2] = useState("Product Type");

  useEffect(() => {}, []);

  props.$setNode({
    props,
  });
  return (
    <>
      <div className="card">
        <div className="card-header pb-0 border-0">
          <h4 className="heading mb-0">{props?.title}</h4>
          <Dropdown className="global-drop">
            <Dropdown.Toggle as="div" className="i-false global-drop-toggle">
              {globalSelect?.spaceCase().toTitleCase()}{" "}
              <i className="fa-solid fa-chevron-down" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="global-drop-menu">
              <Dropdown.Item onClick={() => setGlobalSelect("today")}>
                Today
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setGlobalSelect("currentMonth")}>
                Current Month
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setGlobalSelect("lastMonth")}>
                Last Month
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown className="global-drop">
            <Dropdown.Toggle as="div" className="i-false global-drop-toggle">
              {globalSelect2} <i className="fa-solid fa-chevron-down" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="global-drop-menu">
              {props.products &&
                props.products.map((product, i) => {
                  return (
                    <Dropdown.Item
                      onClick={() => setGlobalSelect2(product?.productType)}
                    >
                      {product?.productType}
                    </Dropdown.Item>
                  );
                })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="card-body">
          <ProjectStatusBlogDonutChart />
          <div className="project-date">
            {mediaBlog.map((data, ind) => (
              <div className="project-media" key={ind}>
                <p className="mb-0">
                  <svg
                    className="me-2"
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      y="0.5"
                      width="12"
                      height="12"
                      rx="3"
                      fill={data.color}
                    />
                  </svg>{" "}
                  {data.title}
                </p>
                <span>{data?.total_leads} Leads</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default constore(ProjectStatusBlog);
