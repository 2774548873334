import React from "react";

const PanCard = (props) => {
  const isPanVerified = props.$store?.user?.isPanVerified;
  console.log(props.pandata);
  const handleAadharVerify = () => {
    swal2.fire({
      title: "Caution: PAN & Aadhaar Unlinked, TDS at 20%",
      text: "We've noticed your PAN & Aadhaar are unlinked. According to compliance, TDS will be 20%, not 5%.",
      icon: "info",
      iconColor: "#234C9B",
    });
  };
  return (
    <div className="card profile_card">
      <div className="card-header py-0 border-0">
        <div className="d-flex align-items-start">
          <img src={imagepaths.panIcon} alt="aadhar" />
          <h5 className="title ms-2">Pan</h5>
        </div>
        <div
          className={Object.className({
            "action_box d-flex align-items-center": true,
          })}
        >
          {isPanVerified ? (
            <i className="fa fa-check check_icon"></i>
          ) : (
            <button onClick={(...args) => props.onEdit(...args, "pan")}>
              <i className="fa fa-pencil"></i>
            </button>
          )}
        </div>
      </div>
      <hr />
      <div className="card-body py-0">
        <h5>Name as per PAN</h5>
        <p className="text-primary">{props?.pandata?.name || "-"}</p>
        <h5>PAN No</h5>
        <p className="text-primary">{props?.pandata?.number || "-"}</p>
        <div className="d-flex justify-content-between">
          <div className="text_content_box">
            <h5>Aadhar Seeding Successful</h5>
            <p className="text-primary">
              {props?.pandata === null
                ? "-"
                : props?.pandata?.aadhaarSeedingStatus === "Successful"
                ? "Yes"
                : "No"}
            </p>
          </div>
          {props?.pandata !== null &&
            props?.pandata?.aadhaarSeedingStatus !== "Successful" && (
              <button
                type="button"
                className="btn info_btn"
                onClick={(...args) => handleAadharVerify(...args)}
              >
                <i className="fa fa-info"></i>
              </button>
            )}
        </div>
      </div>
    </div>
  );
};

export default constore(PanCard);
