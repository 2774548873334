export default {

    apiAddApplication(data) {
        return this.request(this.api().ADD_APPLICATION, data).exec().log().get();
    },

    apiGetApplications(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().GET_APPLICATIONS, payload)
            .exec().log()
            .mapresults((response) => {
                return {
                    data: response?.data,
                    total: response?.count
                }
            })
            .get();
    },

    apiGetApplicationById(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().GET_APPLICATION_BY_ID,)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((res) => {
                return res
            })
            .get();
    },

    apiUpdateApplication(data, urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };

        let payload = {
            leadId: data?.leadId,
            leadName: data?.leadName,
            mobileNumber: data?.mobileNumber,
            productType: data?.productType,
            bankName: data?.bankName,
            applicationDate: data?.applicationDate,
            loanAmount: data?.loanAmount,
            status: data?.status,
            source: data?.source,
            remarks: data?.remarks,
        }
        payload = Object.filter(payload, (v) => v);

        return this.request(this.api().UPDATE_APPLICATION, payload).urltransform(urldata).exec().log().get();
    },

    apiDeleteApplication(urldata = mixins.params()) {

        return this.request(this.api().DELETE_APPLICATION)
            .urltransform(urldata)
            .exec().log().get();
    },
}