import { Component, Suspense, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Layout from "./jsx/page-layouts";
import ErrorView from './utils/errors/ErrorView';


const Navigate = (props) => {
  let navigate = useNavigate();
  window.navigate = (...args) => {
    navigate(...args);
  };
  return props.children;
};

function getLayoutComponent(currentpath) {
  let layoutName = currentpath.basename();
  if (layoutName == "open") {
    return Layout;
  } else {
    try {
      return require(`./jsx/page-layouts/${layoutName}`).default;
    } catch (err) {
      return Layout;
    }
  }
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error });
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorView message={this.state.error?.message} />;
    }
    return this.props.children;
  }
}

function App(props) {

  const userRole = () => {
    return props?.$store?.user?.role;
  }

  const loggedpath = () => {
    let path;
    // console.log(userRole);
    switch (userRole) {
      case _enum.ROUTE_PERSONAS.admin:
        path = "/app/home";
        break;
      case _enum.ROUTE_PERSONAS.connector:
        path = "/app/connectorhome";
        break;
      case _enum.ROUTE_PERSONAS.sales:
        path = "/app/leads";
        break;
      case _enum.ROUTE_PERSONAS.ops:
        path = "/app/leads";
        break;
      default:
        path = "/app/connectorhome";
    }
    // console.log({ path });
    return path;
  }
  const token = () => { return props?.$store.auth; }

  const redirectpath = () => { return token ? loggedpath : "/open/login"; }

  useEffect(() => {
    redirectpath();
  }, [])

  const CustomLayout = getLayoutComponent(window.location.pathname);

  props.$setNode({
    props
  });

  return (
    <ErrorBoundary>
      <Navigate>
        {
          CustomLayout && (
            <Suspense>
              <CustomLayout>
                {props.children}
              </CustomLayout>
            </Suspense>
          )
        }
      </Navigate>
    </ErrorBoundary>
  );
};

setTimeout(() => {
  window.root.vnode = window.App;
  window.app = root.vnode;
});
export default constore(App);

