import React, { useEffect, useRef, useState } from "react";

import MainPagetitle from "../../../layouts/MainPagetitle";
import BankDumpsSearch from "./BankDumpsSearch";
import BulkUploadModal from "./BulkUploadModal";

const BankDumps = (props) => {
  const bulkUploadModalRef = useRef(0);
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [totalPageChange, setTotalPageChange] = useState({
    page: 1,
    limit: 10,
  });
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const [rowcount, setRowcount] = useState(0);
  const columns = [
    {
      dataIndex: "year",
      title: "Year",
    },
    {
      dataIndex: "month",
      title: "Month",
    },
    {
      dataIndex: "bankName",
      title: "Bank Name",
    },
    {
      dataIndex: "productType",
      title: "Product Type",
    },
    {
      dataIndex: "applicationNumber",
      title: "Application No.",
    },
    {
      dataIndex: "disbursementAmount",
      title: "Loan Amount",
      render: (text) => {
        return <>{text?.cur() || "-"}</>;
      },
    },
    {
      dataIndex: "customerName",
      title: "Customer Name",
    },
    {
      dataIndex: "disbursementDate",
      title: "Disb. Date",
      render: (text, record) => {
        if (record?.disbursedDate) {
          let date = new Date(text).dateToDDMMYYYY(new Date(text));
          return date;
        } else {
          return "-";
        }
      },
    },
    {
      dataIndex: "digirepPayoutPct",
      title: "Digirep PO",
      render: (text) => {
        return <>{text ? `${text}%` : "-"}</>;
      },
    },
    {
      dataIndex: "bankPayoutPct",
      title: "Bank PO",
      render: (text) => {
        return <>{text ? `${text}%` : "-"}</>;
      },
    },
    {
      dataIndex: "remarks",
      title: "Remarks",
      render: (text) => {
        return <>{text ? `${text}` : "-"}</>;
      },
    },
  ];
  //
  const [viewmode, setViewmode] = useState(false);
  const [popupBulkUploadFields, setPopupBulkUploadFields] = useState({});
  const [popupBulkUploadData, setPopupBulkUploadData] = useState({});
  const [bulkUploadModalFields, setBulkUploadModalFields] = useState([
    {
      label: "File Upload (Max limit 10,000)",
      name: "file",
      type: "filearea",
      accept: ".xlsx, .xls",
      customcolspan: "12",
      required: true,
    },
  ]);

  const onPageChange = (pageData) => {
    // console.log({ onPageChange: pageData });
    // setTotalPageChange({
    //   ...search,
    //   page: pageData.current,
    //   limit: pageData.pageSize || pageData.defaultPageSize || 25,
    // });

    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 10,
    });
  };

  const getBankDumps = (params = {}, reset) => {
    console.log({ params, reset });
    let payload = {};
    if (reset) {
      let defaultSearchData = Object.only(search, [
        "limit",
        "page",
        // "order_by",
        // "order_direction",
      ]);

      setSearch(defaultSearchData);
      payload = {
        ...defaultSearchData,
        ...params,
      };
      // console.log({ defaultSearchData: payload });
    } else {
      payload = {
        ...search,
        ...params,
      };
    }
    props.apis.apiGetUploadedBankPayouts(payload).then(({ data, total }) => {
      console.log({ apiGetUploadedBankPayouts: data, total });

      setTableData(data);
      setIsFetching(false);
      setRowcount(total);
    });
  };

  const onSearch = (data, reset) => {
    console.log("onSearch on digirep", data, reset);
    if (Object.keys(data).length === 0) {
      setSearch({
        ...data,
        ...search,
      });
      getBankDumps(data, reset);
    } else {
      setSearch({
        ...data,
        ...search,
      });
      getBankDumps(data, reset);
    }
  };

  const downloadTemplateLeads = (e) => {
    props.apis.apiDownloadBankPayoutTemplate().then((res) => {
      console.log({ res });
      if (res) {
        let url = `${props.apis.api().api_base}/bank-payout/template`;
        var downloadURL = url;
        var link = document.createElement("a");
        link.setAttribute(
          "download",
          `bank_payout_template_${new Date().toLocaleDateString()}.xlsx`
        );
        link.href = downloadURL;
        link.click();
      }
    });
  };

  const downloadBulkLeads = (values) => {
    // let leadIds = tableData?.map((v) => JSON.parse(v.leadId));
    // let payload = {};
    // payload.leads = leadIds;
    // console.log({ downloadBulkLeads: payload });
    // props.apis.apiBulkDownloadLead(payload).then((res) => {
    //   console.log({ res });
    //   // if (res) {
    //   //   let url = `${props.apis.api().api_base}/leads/bulk-download`;
    //   //   var downloadURL = url;
    //   //   var link = document.createElement("a");
    //   //   link.setAttribute(
    //   //     "download",
    //   //     `leads_${new Date().toLocaleDateString()}.xlsx`
    //   //   );
    //   //   link.href = downloadURL;
    //   //   // link.target = "_blank";
    //   //   link.click();
    //   // }
    // });
  };

  // HANDLE BULK UPLOAD
  const openBulkUploadmodal = () => {
    bulkUploadModalRef.current.click();
  };

  const closeBulkUploadmodal = () => {
    document.querySelector("#bulkUploadModal #bsmodalclose").click();
    setPopupBulkUploadData({});
    setPopupBulkUploadFields({});
  };

  const addBulkUploadModalFields = () => {
    let fields = (bulkUploadModalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    setPopupBulkUploadFields(fields);
    setPopupBulkUploadData(formdata);
    openBulkUploadmodal();
  };

  const handleBulkUploadSubmit = (e, data, form) => {
    let payload = {
      file: data?.file,
    };
    console.log({ payload });

    props.apis.apiBulkUploadBankPayout(payload).then((res) => {
      console.log({ apiBulkUploadBankPayout: res });
      if (res) {
        swal2.fire({
          title: "Bulk Uploaded Successfully.",
          text: `Records Uploaded : ${res?.count || 0}`,
          icon: "success",
          iconColor: "#234C9B",
          timer: 4000,
        });
        closeBulkUploadmodal();
        getBankDumps();
      }
    });
  };

  useEffect(() => {
    console.log({ BankDumps: props });
    getBankDumps();
  }, [search]);

  props.$setNode({
    props,
  });

  return (
    <>
      <MainPagetitle
        mainTitle="Bank Dumps"
        pageTitle={"Bank Dumps"}
        parentTitle={"Home"}
        pageIcon={imagepaths.FileSearchIconActive}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="tbl-caption-container pb-0">
                  <BankDumpsSearch
                    inactive={props.inactive}
                    onSearch={(...arg) => onSearch(...arg)}
                  />
                </div>
                <div className="header_btn_box transparent_btns mb-3 px-3">
                  <button
                    className="btn btn-sm btn-outline-secondary me-2"
                    onClick={(...args) => downloadTemplateLeads(...args)}
                  >
                    <i className="fa fa-download me-2"></i>
                    <span>Download Template</span>
                  </button>
                  <button
                    className="btn btn-sm btn-outline-secondary me-2"
                    onClick={(e) => addBulkUploadModalFields(e)}
                    ref={bulkUploadModalRef}
                    data-bs-toggle="modal"
                    data-bs-target="#bulkUploadModal"
                  >
                    <i className="fa fa-upload me-2"></i>
                    <span>Bulk Upload</span>
                  </button>
                  <button
                    className="btn btn-sm btn-outline-secondary me-2"
                    onClick={(...args) => downloadBulkLeads(...args)}
                    // disabled={disableBulkDownload}
                  >
                    <i className="fa fa-download me-2"></i>
                    <span>Bulk Download</span>
                  </button>
                </div>
                <AppTable
                  data={tableData}
                  columns={columns}
                  onChange={(...arg) => onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  loading={isFetching}
                  editable={false}
                  viewable={false}
                  deletable={false}
                ></AppTable>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BulkUploadModal
        id="bulkUploadModal"
        modalSize="modal-lg"
        title="Bulk Upload"
        submitText="Upload"
        data={popupBulkUploadData}
        viewmode={viewmode}
        fields={popupBulkUploadFields}
        onSubmit={(...args) => handleBulkUploadSubmit(...args)}
        modaltype="centeredmodal"
        onClose={() => closeBulkUploadmodal()}
      />
    </>
  );
};

export default constore(BankDumps);
