import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { ProductTypeModal } from "./productTypeModal";

const ProductType = (props) => {
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [errorMessage, setErrorMessage] = useState({ name: "", message: "" });
  const [modalFields, setModalFields] = useState([
    {
      label: "Product Name",
      name: "productType",
      type: "text",
      placeholder: "Please Enter",
      required: true,
      onChange: (e) => handleProductType(e),
      customcolspan: "12",
    },
  ]);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [popupfields, setPopupfields] = useState({});
  const [popupdata, setPopupdata] = useState({});

  const [rowcount, setRowcount] = useState(0);
  const columns = [
    {
      dataIndex: "productType",
      title: "Product Name",
      render: (text, record) => {
        return <span className="table-primary-text">{text}</span>;
      },
    },
  ];

  const onPageChange = (pageData) => {
    console.log({ onPageChange: pageData });
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const openModal = () => {
    setOpenCanvas(true);
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };

  const handleProductType = (e) => {
    let btn = document.querySelector("#ProductNamesCanvas form .submitBtn");
    const stringReg = _enum?.REGEX?.onlystring;
    const fieldValue = e.target.value;
    if (stringReg.test(fieldValue)) {
      setErrorMessage("");
    } else
      setErrorMessage({
        name: "productType",
        message: "Special characters are not allowed",
      });
  };

  const getProductTypes = () => {
    let payload = {
      ...search,
    };
    props.apis.apiGetProductTypes(payload).then((res) => {
      console.log({ apiGetProductTypes: res });
      setTableData(res);
      setIsFetching(false);
      // setRowcount(count);
    });
  };

  const deleteProductName = (record) => {
    swal2
      .fire({
        title: "Are you sure you want to delete this product?",
        icon: "info",
        iconColor: "#234C9B",
        showCancelButton: true,
        confirmButtonColor: "#234C9B",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
      .then((result) => {
        if (result.isConfirmed) {
          props.apis
            .apiDeleteProductType({ productType: record.productType })
            .then((res) => {
              console.log({ apiDeleteProductType: res });
              swal2.fire({
                title: "Product deleted successfully !!",
                icon: "success",
                iconColor: "#234C9B",
                timer: 4000,
              });
              getProductTypes();
            });
        }
      });
  };

  const handleSubmit = (e, data, form) => {
    e.preventDefault();
    let formdata = data;
    let payload = {
      ...formdata,
    };
    const stringReg = _enum?.REGEX?.onlystring;
    if (stringReg.test(payload?.productType)) {
      props.apis.apiAddProductType(payload).then((res) => {
        console.log({ apiAddProductType: res });
        swal2.fire({
          title: "Product Type added successfully.",
          icon: "success",
          iconColor: "#234C9B",
          timer: 4000,
        });
        closeModal();
        getProductTypes();
      });
    }
  };

  const addModalFields = (values) => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    setPopupfields(fields);
    setPopupdata(formdata);
    setOpenCanvas(true);
  };

  const onModalClose = () => {
    console.log("onModalClose");
    closeModal();
    setPopupfields({});
  };

  useEffect(() => {
    console.log({ ProductNames: props });
    getProductTypes();
  }, []);

  return (
    <>
      {/* <div className="page_header mb-3 text-end px-4">
        <Link
          to={"#"}
          className="btn btn-primary ms-1"
          onClick={(...args) => addModalFields(...args)}
        >
          <span className="text-white mb-0">+ Add New</span>
        </Link>
      </div> */}
      <AppTable
        data={tableData}
        columns={columns}
        onChange={(...arg) => onPageChange(...arg)}
        total={rowcount}
        reorder={true}
        editable={false}
        viewable={false}
        deletable={false}
        loading={isFetching}
      ></AppTable>

      <ProductTypeModal
        id="ProductNamesCanvas"
        title="Add Product Type"
        show={openCanvas}
        onClose={() => onModalClose()}
        data={popupdata}
        fields={popupfields}
        onSubmit={(...args) => handleSubmit(...args)}
        submitText="Submit"
        errorMessage={errorMessage}
      />
    </>
  );
};

export default constore(ProductType);
