export default {

    // APPLICATIONS
    ADD_APPLICATION: {
        url: "/applications",
        method: "post",
    },
    GET_APPLICATIONS: {
        url: "/applications",
        method: "get",
    },
    GET_APPLICATION_BY_ID: {
        url: "/applications/{applicationNumber}",
        method: "get",
    },
    UPDATE_APPLICATION: {
        url: "/applications/{applicationNumber}",
        method: "put",
    },
    DELETE_APPLICATION: {
        url: "/applications/{applicationNumber}",
        method: "delete",
    },
}