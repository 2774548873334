import React, { useEffect, useState } from "react";
import MainPagetitle from "../../../layouts/MainPagetitle";
import { Link } from "react-router-dom";

const DigirepView = (props) => {
  var BASE_URL = `${endpoints[environment].api_base}/files/download/`;
  const isAdmin = props?.$store?.user?.role === _enum.ROUTE_PERSONAS.admin;
  const [empdata, setEmpdata] = useState({});
  // const [myinfo, setMyinfo] = useState({});

  const handleAadharVerify = () => {
    swal2.fire({
      title: "Caution: PAN & Aadhaar Unlinked, TDS at 20%",
      text: "We've noticed your PAN & Aadhaar are unlinked. According to compliance, TDS will be 20%, not 5%.",
      icon: "info",
      iconColor: "#234C9B",
    });
  };

  // const getEmployee = () => {
  //   let profileId = props?.urlparams?.params?.profileId;
  //   console.log(profileId);
  //   props.apis.apiGetEmployeeById({ profileId: profileId }).then((data) => {
  //     console.log({ apiGetEmployeeById: data });
  //     setEmpdata(data);
  //   });
  // };

  const getSingleProfileInfo = () => {
    let profileId = props?.urlparams?.params?.profileId;
    props.apis.apiGetSingleProfileInfo({ profileId: profileId }).then((res) => {
      console.log({ apiGetSingleProfileInfo: res });
      setEmpdata(res);
    });
  };

  const handleDelete = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    console.log({ value });
    let properties = {
      title: `Are you sure you want to delete this user's ${value?.toTitleCase()} details?`,
      icon: "info",
      iconColor: "#234C9B",
      showCancelButton: true,
      confirmButtonColor: "#234C9B",
      cancelButtonColor: "##D6DBE7",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    };
    let successProperties = {
      title: `${value?.toTitleCase()} details deleted successfully !!`,
      icon: "success",
      iconColor: "#234C9B",
      timer: 4000,
    };
    swal2.fire(properties).then((result) => {
      if (result.isConfirmed) {
        const resolve = () => {
          swal2.fire(successProperties);
          getSingleProfileInfo();
        };
        if (value === "aadhar") {
          props.apis
            .apiDeleteAadhar({ profileId: empdata?.profileId })
            .then(() => resolve());
        }
        if (value === "pan") {
          props.apis
            .apiDeletePan({ profileId: empdata?.profileId })
            .then(() => resolve());
        }
      }
    });
  };

  const handleAgreement = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let payload = {
      profileId: empdata?.profileId,
    };
    props.apis.apiSendAgreementForEsign(payload).then((res) => {
      console.log({ res });
    });
  };

  useEffect(() => {
    // getEmployee();
    getSingleProfileInfo();
  }, []);

  props.$setNode({
    props,
  });
  return (
    <>
      <MainPagetitle
        mainTitle="Digi Team Member"
        back={`${empdata?.name || "User"}`}
        backLink="/app/digirep"
        pageTitle={"Digi Team Member"}
        parentTitle={"Home"}
      />
      <div className="container-fluid employee_card_container">
        <div className="row">
          <div className="col-xl-12">
            <div className="card employee_card">
              <div className="card-body">
                <div className="card_header">
                  <div className="avatar_card">
                    <div className="me-3">
                      <img
                        src={imagepaths.fakeUserIcon}
                        className="avatar"
                        alt="avatar"
                      />
                    </div>
                    <div className="content">
                      <h3 className="title">{empdata?.name || "-"}</h3>
                      <p className="desc">{empdata?.designation || "-"}</p>
                    </div>
                  </div>
                  {/* <button
                    // onClick={(...args) => onEdit(...args)}
                    className="edit_btn"
                  >
                    <i className="fa fa-pencil"></i>
                  </button> */}
                </div>
                <hr />
                <div className="card_body row mx-auto">
                  <div className="col-12 col-md-4 content_card">
                    <p>
                      <span>Employee ID:</span> {empdata?.profileId || "-"}
                    </p>
                    <p>
                      <span>Mobile No:</span> {empdata?.mobileNumber || "-"}
                    </p>
                    <p>
                      <span>Email ID:</span> {empdata?.emailAddress || "-"}
                    </p>
                    <p>
                      <span>Work Location:</span> {empdata?.workLocation || "-"}
                    </p>
                  </div>
                  <div className="col-12 col-md-4 content_card">
                    <p>
                      <span>State:</span> {empdata?.state || "-"}
                    </p>
                    <p>
                      <span>City:</span> {empdata?.city || "-"}
                    </p>
                    <p>
                      <span>Designation:</span> {empdata?.designation || "-"}
                    </p>
                    <p>
                      <span>Reporting Manager:</span>
                      {empdata?.supervisor?.name || "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* BOTTOM CARDS */}
        <div className="row">
          {/* AADHAR */}
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card profile_card">
              <div className="card-header py-0 border-0">
                <div className="d-flex align-items-start">
                  <img src={imagepaths.aadharIcon} alt="aadhar" />
                  <h5 className="title ms-2">Aadhar</h5>
                </div>
                <div
                  className={Object.className({
                    "action_box d-flex align-items-center": true,
                  })}
                >
                  {empdata?.isAadharVerified ? (
                    <>
                      <button
                        type="button"
                        onClick={(...args) => handleDelete(...args, "aadhar")}
                      >
                        <i class="fa-regular fa-trash-can"></i>
                      </button>
                      <div className="separator"></div>
                      <i className="fa fa-check check_icon"></i>
                    </>
                  ) : (
                    <i class="fa-solid fa-xmark check_icon"></i>
                  )}
                </div>
              </div>
              <hr />
              <div className="card-body py-0">
                <h5>Aadhar no</h5>
                <p className="text-primary">
                  {empdata?.profileAadharCardInfo?.uid || "-"}
                </p>
                <h5>Address</h5>
                <p className="text-primary">
                  {empdata?.profileAadharCardInfo?.address || "-"}
                </p>
              </div>
            </div>
          </div>

          {/* PAN */}
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card profile_card">
              <div className="card-header py-0 border-0">
                <div className="d-flex align-items-start">
                  <img src={imagepaths.panIcon} alt="aadhar" />
                  <h5 className="title ms-2">Pan</h5>
                </div>
                <div
                  className={Object.className({
                    "action_box d-flex align-items-center": true,
                  })}
                >
                  {empdata?.isPanVerified ? (
                    <>
                      <button
                        type="button"
                        onClick={(...args) => handleDelete(...args, "pan")}
                      >
                        <i class="fa-regular fa-trash-can"></i>
                      </button>
                      <div className="separator"></div>
                      <i className="fa fa-check check_icon"></i>
                    </>
                  ) : (
                    <i class="fa-solid fa-xmark check_icon"></i>
                  )}
                </div>
              </div>
              <hr />
              <div className="card-body py-0">
                <h5>Name as per PAN</h5>
                <p className="text-primary">
                  {empdata?.profilePanCardInfo?.name || "-"}
                </p>
                <h5>PAN No</h5>
                <p className="text-primary">
                  {empdata?.profilePanCardInfo?.number || "-"}
                </p>
                <div className="d-flex justify-content-between">
                  <div className="text_content_box">
                    <h5>Aadhar Seeding Successful</h5>
                    <p className="text-primary">
                      {empdata?.profilePanCardInfo === null
                        ? "-"
                        : empdata?.profilePanCardInfo?.aadhaarSeedingStatus ===
                          "Successful"
                        ? "Yes"
                        : "No"}
                    </p>
                  </div>
                  {empdata?.profilePanCardInfo !== null &&
                    empdata?.profilePanCardInfo?.aadhaarSeedingStatus !==
                      "Successful" && (
                      <button
                        type="button"
                        className="btn info_btn"
                        onClick={(...args) => handleAadharVerify(...args)}
                      >
                        <i className="fa fa-info"></i>
                      </button>
                    )}
                </div>
              </div>
            </div>
          </div>

          {/* ACCOUNT INFO */}
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card profile_card">
              <div className="card-header py-0 border-0">
                <div className="d-flex align-items-start">
                  <img src={imagepaths.accountIcon} alt="aadhar" />
                  <h5 className="title ms-2">Account Info</h5>
                </div>
                <div className="action_box d-flex align-items-center">
                  <i className="fa fa-check check_icon"></i>
                </div>
              </div>
              <hr />
              <div className="card-body py-0">
                <h5>Account holder name</h5>
                <p className="text-primary">
                  {empdata?.accountHolderName || "-"}
                </p>
                <div className="row">
                  <div className="col">
                    <h5>Account No.</h5>
                    <p className="text-primary">
                      {empdata?.accountNumber || "-"}
                    </p>
                  </div>
                  <div className="col">
                    <h5>Account Type</h5>
                    <p className="text-primary">
                      {empdata?.accountType || "-"}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h5>Bank name</h5>
                    <p className="text-primary">
                      {empdata?.accountHolderBankName || "-"}
                    </p>
                  </div>
                  <div className="col">
                    <h5>IFSC Code</h5>
                    <p className="text-primary">
                      {empdata?.accountHolderBankIFSC || "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="card profile_card">
              <div className="card-header p-0 border-0">
                <div className="d-flex align-items-center mb-3">
                  <img
                    src={imagepaths.cardGaurdIcon}
                    alt="cardGaurdIcon"
                    className="card_icon"
                  />
                  <h5 className="title ms-2 mb-0">Docs Details</h5>
                </div>
              </div>
              <div className="card-body p-0">
                <div className="docs_card_container row g-0">
                  <div className="col-6 col-sm-4 col-md-6 col-lg-3 col-xl-2">
                    <div className="docs_card">
                      <img
                        src={imagepaths.docsCardBlueIcon}
                        alt="docsCardBlueIcon"
                      />
                      <h2 className="title">PAN Card Front</h2>
                      {empdata?.panFileId &&
                      empdata?.panFileId?.length !== 0 ? (
                        <a
                          href={`${BASE_URL}${empdata?.panFileId}`}
                          className="text-primary"
                        >
                          Download
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-6 col-sm-4 col-md-6 col-lg-3 col-xl-2">
                    <div className="docs_card">
                      <img
                        src={imagepaths.docsCardBlueIcon}
                        alt="docsCardBlueIcon"
                      />
                      <h2 className="title">Aadhar Card (Front)</h2>
                      {empdata?.aadharFrontFileId &&
                      empdata?.aadharFrontFileId?.length !== 0 ? (
                        <a
                          href={`${BASE_URL}${empdata?.aadharFrontFileId}`}
                          className="text-primary"
                        >
                          Download
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-6 col-sm-4 col-md-6 col-lg-3 col-xl-2">
                    <div className="docs_card">
                      <img
                        src={imagepaths.docsCardBlueIcon}
                        alt="docsCardBlueIcon"
                      />
                      <h2 className="title">Aadhar Card (Back)</h2>
                      {empdata?.aadharBackFileId &&
                      empdata?.aadharBackFileId?.length !== 0 ? (
                        <a
                          href={`${BASE_URL}${empdata?.aadharBackFileId}`}
                          className="text-primary"
                        >
                          Download
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-6 col-sm-4 col-md-6 col-lg-3 col-xl-2">
                    <div className="docs_card">
                      <img
                        src={imagepaths.docsCardBlueIcon}
                        alt="docsCardBlueIcon"
                      />
                      <h2 className="title">Photo</h2>
                      {empdata?.photoFileId &&
                      empdata?.photoFileId?.length !== 0 ? (
                        <a
                          href={`${BASE_URL}${empdata?.photoFileId}`}
                          className="text-primary"
                        >
                          Download
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-sm-8 col-md-8 col-lg-3 col-xl-4">
                    <div className="docs_card d-flex align-items-start ps-5">
                      <div className="">
                        <img
                          src={imagepaths.docsCardBlueIcon}
                          alt="docsCardBlueIcon"
                        />
                        <h2 className="title">Agreement</h2>
                        {empdata?.agreementFileId &&
                        empdata?.agreementFileId?.length !== 0 ? (
                          <a
                            href={`${BASE_URL}${empdata?.agreementFileId}`}
                            className="text-primary"
                          >
                            Download
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="esign_box">
                        <div className="d-flex align-items-center">
                          <i
                            className={Object.className({
                              "me-2": true,
                              "fa-regular fa-circle-check text-green":
                                empdata?.isAgreementSigned === true,
                              "fa-regular fa-circle-xmark":
                                empdata?.isAgreementSigned === false,
                            })}
                          ></i>
                          <p className="m-0">E-Signed Done</p>
                        </div>
                        {empdata?.isAgreementSigned !== true && (
                          <button
                            type="button"
                            className={Object.className({
                              "d-none": !isAdmin,
                            })}
                            onClick={(...args) => handleAgreement(...args)}
                          >
                            Re-Initiate
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 text-end">
          <Link to={"/app/digirep"} class="btn btn-primary px-4">
            Back
          </Link>
        </div>
      </div>
    </>
  );
};

export default constore(DigirepView);
