import React, { Component } from "react";

export class LeadsModal extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.LeadsModal = this;
  }
  render() {
    let { props } = this;
    let {
      id,
      title,
      show,
      onClose,
      modalSize,
      logsData,
      logsColumns,
      editmode,
      errorMessage,
    } = props;
    let attrs = Object.except(props, ["id", "title", "type", "onClose"]);
    // console.log({ logsData });
    return (
      <AppCanvas
        id={id}
        title={title}
        show={show}
        onClose={onClose}
        modalSize={modalSize}
      >
        <AppFormData
          errorMessage={errorMessage}
          {...attrs}
          onClose={onClose}
        ></AppFormData>
        {!editmode && logsData.length > 0 && (
          <>
            <h5 class="modal-title mb-3">Logs</h5>
            <AppTable
              data={logsData || []}
              columns={logsColumns}
              reorder={true}
              showPagination={false}
              editable={false}
              viewable={false}
              deletable={false}
            ></AppTable>
          </>
        )}
      </AppCanvas>
    );
  }
}

export default LeadsModal;
