import React, { useEffect, useState } from "react";
import TransactionDetailsModal from "../TransactionDetailsModal";
import MyRequestSearch from "./MyRequestSearch";
import { Modal } from "react-bootstrap";
import OTPInput from "react-otp-input";
import { toast } from "react-toastify";

const MyRequest = (props) => {
  const isAdmin = props?.$store?.user?.role === _enum.ROUTE_PERSONAS.admin;
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [totalPageChange, setTotalPageChange] = useState({
    page: 1,
    limit: 10,
  });
  const [rowcount, setRowcount] = useState(0);
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const columns = [
    {
      dataIndex: "name",
      title: "Connector Name",
      render: (text, record) => {
        return <>{record?.profile?.name || "-"}</>;
      },
      display: isAdmin ? true : false,
    },
    {
      dataIndex: "mobileNumber",
      title: "Mobile No.",
      render: (text, record) => {
        return <>{record?.profile?.mobileNumber || "-"}</>;
      },
      display: isAdmin ? true : false,
    },
    {
      dataIndex: "amount",
      title: isAdmin ? "Amount" : "Requested Amount",
      render: (text) => {
        return <>{text?.cur() || "-"}</>;
      },
    },
    {
      dataIndex: "createdAt",
      title: "Date",
      render: (text, record) => {
        if (text) {
          let date = new Date(text).dateToDDMMYYYY(new Date(text));
          return date;
        } else {
          return "-";
        }
      },
    },
    {
      dataIndex: "rechargeStatus",
      title: "Status",
      render: (text) => {
        return (
          <span
            className={`badge light badge-${
              _enum.STATUS_COLOR_MAP[text.toLowerCase()]
            }`}
          >
            {text?.toTitleCase() || "-"}
          </span>
        );
      },
    },
    {
      dataIndex: "remarks",
      title: "Remarks",
    },
  ];

  // ----------------------------------------------------------------

  const [modalFields, setModalFields] = useState([
    { label: "Name", name: "name", type: "text" },
    { label: "Mobile No.", name: "mobileNumber", type: "number" },
    { label: "Amount", name: "amount", type: "number" },
    { label: "Date", name: "createdAt", type: "date" },
    {
      label: "Status",
      name: "rechargeStatus",
      type: "select",
      options: _enum.ADMIN_KYC_REQUEST_STATUS,
    },
    { label: "Remarks", name: "remarks", type: "textarea" },
  ]);
  const [editmode, setEditmode] = useState(false);
  const [viewmode, setViewmode] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [editData, setEditData] = useState({});
  const [popupdata, setPopupdata] = useState({});
  const [popupfields, setPopupfields] = useState({});
  const [disabledFields, setDisabledFields] = useState([]);

  const openModal = () => {
    setOpenCanvas(true);
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };

  const onModalClose = () => {
    console.log("onModalClose");
    closeModal();
    setPopupfields({});
    setViewmode(false);
    setEditmode(false);
    setEditData({});
  };

  const handleSubmit = (e, data, form) => {
    e.preventDefault();
    console.log("formdata: ", data);
    let payload = {
      rechargeStatus: data?.rechargeStatus,
      remarks: data?.remarks,
    };

    if (editmode) {
      props.apis
        .apiUpdateRequestRecharge(payload, {
          rechargeId: editData?.rechargeId,
        })
        .then((res) => {
          console.log({ apiUpdateRequestRecharge: res });
          swal2.fire({
            title: "Request Updated successfully.",
            icon: "success",
            iconColor: "#234C9B",
            timer: 4000,
          });
          closeModal();
          getMyRequestData();
        });
    }
  };
  // ----------------------------------------------------------------

  const onSearch = (data, reset) => {
    console.log("onSearch on digirep", data, reset);
    if (Object.keys(data).length === 0) {
      setSearch({
        ...data,
        ...search,
      });
      getMyRequestData(data, reset);
    } else {
      setSearch({
        ...data,
        ...search,
      });
      getMyRequestData(data, reset);
    }
  };

  const onPageChange = (pageData) => {
    // setSearch({
    //   page: pageData.current,
    //   limit: pageData.pageSize || pageData.defaultPageSize || 25,
    // });
    setTotalPageChange({
      ...search,
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const getMyRequestData = (params = {}, reset) => {
    console.log({ params, reset });
    let payload = {};
    if (reset) {
      let defaultSearchData = Object.only(search, ["limit", "page"]);

      setSearch(defaultSearchData);
      payload = {
        ...defaultSearchData,
      };
    } else {
      payload = {
        ...search,
        ...params,
      };
    }
    props.apis.apiGetRequestRecharge(payload).then(({ data, total }) => {
      setIsFetching(false);
      setData(data);
      setRowcount(total);
    });
  };

  // ----------------------------------------------------------------
  const [otpValue, setOtpValue] = useState("");
  const [requestRemarks, setRequestRemarks] = useState("");
  const [activeRechargeId, setActiveRechargeId] = useState(null);
  const [isOtpDisabled, setIsOtpDisabled] = useState(true);
  const [showOTPModal, setShowOTPModal] = useState(false);

  const toastConfig = {
    autoClose: 3000,
    closeButton: false,
    hideProgressBar: true,
  };

  const closeOTPModal = () => {
    setShowOTPModal(false);
    setOtpValue("");
    setActiveRechargeId(null);
  };

  const resendRequestOTP = (e) => {
    e.preventDefault();
    props.apis
      .apiSendRequestRechargeOtp({ rechargeId: activeRechargeId })
      .then((data) => {
        console.log({ apiSendRequestRechargeOtp: data });
        toast.success("OTP sent successfully!!", toastConfig);
      });
  };

  const handleOTPChange = (otp) => {
    console.log(otp, "otp");
    // setOTP(otp);
    setOtpValue(otp);
    if (otp.length === 6) {
      setIsOtpDisabled(false);
    } else {
      setIsOtpDisabled(true);
    }
  };

  const handleRequestRemarks = (event) => {
    setRequestRemarks(event.target.value);
  };

  const processRequest = (record) => {
    console.log({ record });

    props.apis
      .apiSendRequestRechargeOtp({ rechargeId: record.rechargeId })
      .then((data) => {
        console.log({ apiSendRequestRechargeOtp: data });
        setShowOTPModal(true);
        setActiveRechargeId(record.rechargeId);
        toast.success("OTP sent successfully!!", toastConfig);
      });
  };

  const rejectRequest = (record) => {
    console.log({ record });

    swal2
      .fire({
        title: "Are you sure want to reject this request?",

        icon: "info",
        iconColor: "#234C9B",
        showCancelButton: true,
        confirmButtonColor: "#234C9B",
        cancelButtonColor: "##D6DBE7",
        confirmButtonText: "Yes",
        cancelButtonText: "No",

        input: "text",
        html: '<div class="text-start ms-2"><label for="requestRemarks" class="fs-5">Remarks</label></div>',
        inputAttributes: {
          autocapitalize: "off",
          label: "Remarks",
          id: "requestRemarks",
          required: "true",
        },
        inputValidator: (value) => {
          if (!value) {
            return "Remarks is required";
          }
        },

        preConfirm: async (value) => {
          let payload = {
            remarks: value,
          };
          let urldata = {
            rechargeId: record.rechargeId,
          };
          console.log({ payload, urldata });

          props.apis
            .apiRejectRequestRecharge(payload, urldata)
            .then((response) => {
              return true;
            });
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          swal2.fire({
            title: "Request Rejected Successfully.",
            icon: "success",
            iconColor: "#234C9B",
            timer: 4000,
            confirmButtonText: "ok",
          });
          getMyRequestData(totalPageChange);
        }
      });
  };

  const handleRequestOtpVerification = (e, otpcode) => {
    e.preventDefault();
    let payload = {
      otp: otpcode,
      remarks: requestRemarks,
    };

    console.log({ payload });

    props.apis
      .apiVerifyRequestRecharge(payload, { rechargeId: activeRechargeId })
      .then((res) => {
        console.log({ apiVerifyRequestRecharge: res });
        closeModal();
        swal2.fire({
          title: "Request Processed Successfully.",
          icon: "success",
          iconColor: "#234C9B",
          timer: 4000,
          confirmButtonText: "ok",
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("OTP mismatched, Please enter valid OTP!!", toastConfig);
      });
  };

  // ----------------------------------------------------------------

  useEffect(() => {
    console.log(search);
    getMyRequestData(totalPageChange);
  }, [totalPageChange]);

  // useEffect(() => {
  //   getMyRequestData();
  // }, []);

  return (
    <>
      <MyRequestSearch
        onSearch={(...arg) => onSearch(...arg)}
        isAdmin={isAdmin}
      />
      <AppTable
        data={data}
        columns={columns}
        onChange={(...arg) => onPageChange(...arg)}
        total={rowcount}
        reorder={true}
        viewable={false}
        editable={false}
        {...(isAdmin
          ? {
              processLink: true,
              rejectLink: true,
              processLinkAction: (...args) => processRequest(...args),
              rejectLinkAction: (...args) => rejectRequest(...args),
            }
          : {
              processLink: false,
              rejectLink: false,
            })}
        deletable={false}
        loading={isFetching}
      ></AppTable>

      <TransactionDetailsModal
        id="payoutModal"
        title={`${
          editmode ? "Edit Request" : viewmode ? "Request" : "Request"
        }`}
        show={openCanvas}
        onClose={() => onModalClose()}
        data={popupdata}
        fields={popupfields}
        editmode={editmode}
        viewmode={viewmode}
        onSubmit={(...args) => handleSubmit(...args)}
        submitText={`${editmode ? "Update" : "Submit"}`}
        disableallfields={viewmode ? true : false}
        disablefields={disabledFields}
      />

      {/* REQUEST OTP MODAL */}
      <Modal
        show={showOTPModal}
        onHide={closeOTPModal}
        centered
        backdrop="static"
        className="consent_otp_modal"
      >
        <Modal.Header closeButton className="border-0 py-4"></Modal.Header>
        <Modal.Body className="text-center">
          <h2 className="mb-5">OTP to verify</h2>
          <p className="desc fs-4">We have sent an OTP to your mobile no.</p>
          <div className="row g-3 mb-4">
            <div className="col-12">
              <OTPInput
                value={otpValue}
                onChange={(e) => handleOTPChange(e)}
                separator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
                numInputs={6}
                shouldAutoFocus={true}
                isInputNum={true}
                containerStyle="otp_container"
              />
            </div>
            <div className="col-12">
              <div className="text-align-start otp_bottom">
                <span className="me-2">Not received OTP?</span>
                <button
                  className="btn px-0 text-primary fs-5"
                  onClick={(e) => resendRequestOTP(e)}
                >
                  Resend
                </button>
              </div>
            </div>
            <div className="col-12 text-start">
              <AppInput
                name="remarks"
                label="Remakrs"
                type="text"
                onChange={(...args) => handleRequestRemarks(...args)}
                value={requestRemarks}
              ></AppInput>
            </div>
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary w-100 py-3"
                disabled={isOtpDisabled}
                onClick={(e) => handleRequestOtpVerification(e, otpValue)}
              >
                Verify
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default constore(MyRequest);
