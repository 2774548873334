import React, { useEffect, useRef, useState } from "react";

import MainPagetitle from "../../../layouts/MainPagetitle";
import InfoCard from "./InfoCard";
import AadharCard from "./AadharCard";
import PanCard from "./PanCard";
import AccountInfoCard from "./AccountInfoCard";
import ProfileModal from "./ProfileModal";
import DocsCard from "../BusinessToCustomer/RegisteredUsers/components/DocsCard";

const MyProfile = (props) => {
  const [myinfo, setMyinfo] = useState({});
  const [openCanvas, setOpenCanvas] = useState(false);
  const [goSteps, setGoSteps] = useState(0);
  const { isNumberVerified, isAadharVerified, isPanVerified } =
    props?.$store.user || {};

  useEffect(() => {
    console.log({ isNumberVerified, isAadharVerified, isPanVerified });

    if (!isAadharVerified || !isPanVerified) {
      swal2
        .fire({
          title: "Please update your profile",
          icon: "info",
          iconColor: "#FF4D4D",
          confirmButtonText: "Update",
          showCancelButton: true,
          cancelButtonText: "Skip for now",
        })
        .then((result) => {
          if (result.isConfirmed) {
            openModal();
          }
        });
    }
  }, []);

  const openModal = () => {
    setOpenCanvas(true);
  };

  const closeModal = () => {
    setOpenCanvas(false);
    getMyInfo();
    getUser();
  };

  const onModalClose = () => {
    closeModal();
  };

  const handleSubmit = (e, pandetails) => {
    console.log({ handleSubmit: pandetails });
    closeModal();
    getMyInfo();
    swal2.fire({
      title: "Profile updated successfully",
      icon: "success",
      iconColor: "#234C9B",
      timer: 4000,
    });
  };

  const onEdit = (e, route) => {
    openModal();
    route === "pan"
      ? setGoSteps(2)
      : route === "aadhar"
      ? setGoSteps(1)
      : route === "accountinfo"
      ? setGoSteps(3)
      : setGoSteps(0);
    getMyInfo();
  };

  const getMyInfo = () => {
    props.apis.apiGetMyInfo().then((res) => {
      setMyinfo(res);
    });
  };

  const getUser = () => {
    props.apis.apiGetUser().then((res) => {
      let userResponse = {
        ...res,
        refresh: props?.$store?.user?.refresh,
      };
      props.setstore("user", userResponse);
    });
  };

  useEffect(() => {
    getMyInfo();
    getUser();
  }, []);

  props.$setNode({
    props,
  });

  return (
    <>
      <MainPagetitle
        mainTitle="My Profile"
        pageTitle={"My Profile"}
        parentTitle={"Home"}
        pageIcon={imagepaths.ProfileActive}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card px-2 pt-2">
              <div className="card-body">
                <InfoCard
                  onEdit={(...args) => onEdit(...args)}
                  infodata={myinfo}
                />
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-6 col-xl-4">
                    <AadharCard
                      onEdit={(...args) => onEdit(...args)}
                      aadhardata={myinfo?.profileAadharCardInfo}
                    />
                  </div>
                  <div className="col-12 col-md-12 col-lg-6 col-xl-4">
                    <PanCard
                      onEdit={(...args) => onEdit(...args)}
                      pandata={myinfo?.profilePanCardInfo}
                    />
                  </div>
                  <div className="col-12 col-md-12 col-lg-6 col-xl-4">
                    <AccountInfoCard
                      onEdit={(...args) => onEdit(...args)}
                      accountinfodata={myinfo}
                    />
                  </div>
                </div>
                <div className="">
                  <DocsCard data={myinfo} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ProfileModal
        id="profileCanvas"
        className="profile_canvas"
        title="Please Verify Below Information"
        show={openCanvas}
        handleSubmit={(...args) => handleSubmit(...args)}
        setGoSteps={setGoSteps}
        goSteps={goSteps}
        onClose={() => onModalClose()}
        myinfo={myinfo}
      />
    </>
  );
};

export default constore(MyProfile);
