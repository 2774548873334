import React, { Component, createRef } from "react";
//Import Components
import MainPagetitle from "../../layouts/MainPagetitle";
import ProductCard from "./elements/ProductCard";
import ChangePasswordModal from "./elements/ChangePasswordModal";
class ConnectorHome extends Component {
  constructor(props) {
    super(props);
    this.changePasswordModalRef = createRef();

    props.initialconstruct.bind(this)();
    this.state = {
      data: [],
      myinfo: [],
      dashboardData: [],
      changepasswordData: {},
      popupChangePasswordFields: {},
      changepasswordFields: [
        {
          label: "Old Password",
          name: "oldPassword",
          type: "password",
          customcolspan: "12",
        },
        {
          label: "New Password ",
          name: "newPassword",
          type: "password",
          customcolspan: "12",
        },
        {
          label: "Confirm Password",
          name: "confirmPassword",
          type: "password",
          customcolspan: "12",
        },
      ],
    };
    this.columns = [
      {
        dataIndex: "name",
        title: "Name",
        render: (text) => {
          return <span className="text-primary">{text}</span>;
        },
      },
      {
        dataIndex: "mobileNumber",
        title: "Mobile No.",
      },
      {
        dataIndex: "productType",
        title: "Product Type",
      },
      {
        dataIndex: "date",
        title: "Enquire Date",
        render: (text) => {
          let date = new Date(text).dateToDDMMYYYY(new Date(text));
          return date;
        },
      },
      {
        dataIndex: "status",
        title: "Status",
      },
    ];

    this.featured = [
      {
        title: "Verify PAN",
        image: imagepaths.panNewIcon,
        buttonType: "active",
        buttonText: "Verify",
        path: "/app/kyc",
      },
      {
        title: "Verify Aadhar",
        image: imagepaths.aadharNewIcon,
        buttonType: "active",
        buttonText: "Verify",
        path: "/app/kyc",
      },
      {
        title: "Bank Statement",
        image: imagepaths.dollarFileIcon,
        buttonType: "active",
        buttonText: "Verify",
      },
      {
        title: "Digital Flows",
        image: imagepaths.blueMonitorIcon,
        buttonType: "active",
        buttonText: "Verify",
      },
      {
        title: "My Leads",
        image: imagepaths.threeFilterIcon,
        buttonType: "active",
        buttonText: "Verify",
        path: "/app/businesstobusiness?activetab=overallleads",
      },
      {
        title: "Credit Score",
        image: imagepaths.meterScoreIcon,
        buttonType: "inactive",
        buttonText: "Coming Soon",
      },
    ];
  }

  addChangepasswordData() {
    let fields = (this.state.changepasswordFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    console.log("formdata: ", fields, formdata);
    this.setState({ popupChangePasswordFields: fields });
    this.setState({ changepasswordData: formdata });
    this.openChangePasswordmodal();
  }

  openChangePasswordmodal() {
    this.changePasswordModalRef.current.click();
  }

  closeChangePasswordmodal() {
    document.querySelector("#changepasswordModal #bsmodalclose").click();
    this.setState({ changepasswordFields: {} });
    this.setState({ changepasswordData: {} });
  }

  handleChangePasswordSubmit(e, data) {
    console.log({ handleChangePasswordSubmit: data });
    if (data.newPassword !== data.confirmPassword) {
      swal2.fire({
        title: "Password and Confirm Password does not match",
        icon: "warning",
        iconColor: "#234C9B",
        timer: 4000,
      });
    } else {
      let payload = {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      };

      this.props.apis.apiPasswordReset(payload).then((res) => {
        console.log({ apiPasswordReset: res });
        if (res.status === 200) {
          swal2
            .fire({
              title: "Password changed successfully, Please re-login",
              icon: "success",
              iconColor: "#234C9B",
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.closeChangePasswordmodal();
                this.props.navigate("/app/logout");
              }
            });
        }
      });
    }
  }

  componentDidMount() {
    console.log("ConnectorHome", this.props);
    this.fetchData();
    this.getMyInfo();

    console.log(this.props?.$store, "store values");
    const { emailAddress } = this.props?.$store?.logged;
    const { isPasswordUpdated, role } = this.props?.$store?.user || {};
    if (
      !isPasswordUpdated & (role !== _enum.ROUTE_PERSONAS.admin) &&
      emailAddress !== undefined
    ) {
      this.addChangepasswordData();
    }
  }
  fetchData() {
    // this.props.apis.apiGetConnectorDashboardMoke().then(res => {
    // 	this.setState({ dashboardData: res })
    // })
    // this.props.apis.apiGetConnectorsHistoryMoke().then(res => {
    // 	this.setState({ data: res })
    // });
  }

  getMyInfo() {
    this.props.apis.apiGetMyInfo().then((res) => {
      this.setState({
        myinfo: res,
      });
    });
  }
  render() {
    let { columns, featured } = this;
    let { dashboardData, data, myinfo } = this.state;
    return (
      <>
        <MainPagetitle
          mainTitle="Dashboard"
          pageTitle="Dashboard"
          parentTitle="Home"
        />

        <div className="container-fluid">
          <div className="row mx-auto g-3">
            <div className="col-xl-12">
              <h2 className="heading_title mb-1">
                Welcome , {myinfo?.name || "User"}
              </h2>
              <p className="sub_title">Here is your overall information!</p>
            </div>
            <div className="col-xl-12">
              <div className="info_card">
                <h4 className="title text-white mb-3">My Info</h4>
                <div className="content">
                  <div className="mini_box">
                    <img src={imagepaths.infoPersonIcon} alt="info card" />
                    <p>{myinfo?.name || "-"}</p>
                  </div>
                  <div className="mini_box">
                    <img src={imagepaths.infoPhoneIcon} alt="info card" />
                    <p>{myinfo?.mobileNumber || "-"}</p>
                  </div>
                  <div className="mini_box end">
                    <img src={imagepaths.infoEnvelopeIcon} alt="info card" />
                    <p>{myinfo?.emailAddress || "-"}</p>
                  </div>
                  {/* <button
                    className={Object.className({
                      edit_btn: true,
                      // "d-none": props.$store?.user?.isAadharVerified,
                    })}
                    onClick={(...args) => this.props.onEdit(...args)}
                  >
                    <i className="fa fa-pencil"></i>
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid pt-0">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body p-0">
                  <div className="tbl-caption-container">
                    <h4 className="heading mb-0">Featured</h4>
                  </div>
                  <div className="row mx-auto g-3 px-3 mb-5">
                    {featured &&
                      featured.map((item, i) => {
                        return (
                          <ProductCard
                            key={i}
                            title={item.title}
                            desc={item.desc}
                            image={item.image}
                            buttonType={item.buttonType}
                            buttonText={item.buttonText}
                            path={item.path}
                            // handleProduct={props.handleProduct}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container-fluid pt-0">
					<div className="row">
						<div className="col-xl-12">
							<div className="card">
								<div className="card-body p-0">
									<div className="tbl-caption-container">
										<h4 className="heading mb-0">History</h4>
									</div>
									<AppTable
										data={data}
										columns={columns}
										reorder={true}
										showPagination={false}
										editable={false}
										viewable={false}
										deletable={false}
									></AppTable>
								</div>
							</div>
						</div>
					</div>
				</div> */}
        {/* below button hidden on UI */}
        <button
          className="btn btn-sm btn-outline-secondary d-none"
          onClick={(e) => this.addChangepasswordData(e)}
          ref={this.changePasswordModalRef}
          data-bs-toggle="modal"
          data-bs-target="#changepasswordModal"
        >
          <span>Change Password</span>
        </button>
        <ChangePasswordModal
          id="changepasswordModal"
          title="Change Password"
          submitText="Change Password"
          modalSize="default"
          hidecancelbtn={true}
          centerchangebtn={true}
          hidecloseicon={false}
          data={this.state.changepasswordData}
          fields={this.state.popupChangePasswordFields}
          onSubmit={(...args) => this.handleChangePasswordSubmit(...args)}
          modaltype="centeredmodal"
          onClose={() => this.closeChangePasswordmodal()}
        />
      </>
    );
  }
}
export default constore(ConnectorHome);
