import React, { useEffect, useState } from "react";
import MainPagetitle from "../../../../layouts/MainPagetitle";
import AadharCard from "./components/AadharCard";
import DocsCard from "./components/DocsCard";
import IncomeCard from "./components/IncomeCard";
import InfoCard from "./components/InfoCard";
import PanCard from "./components/PanCard";

const RegisteredUserView = (props) => {
  const [myinfo, setMyinfo] = useState(null);

  const getInfo = () => {
    setMyinfo({
      customerId: 6,
      customerName: "User Test",
      emailAddress: "test@gmail.com",
      mobileNumber: "9182938394",
      status: "KYC_DETAILS_UPDATED",
      isSalaried: true,
      companyname: "ABB INDIA LIMITED",
      monthlySalary: "80000",
      salaryMode: "Online",
      officialEmail: "test@infopark.com",
      dob: "1998-07-25T00:00:00.000Z",
      netMonthlyIncome: null,
      natureOfBusiness: null,
      isAadharVerified: false,
      isPanVerified: true,
      panFileId: "",
      aadharFrontFileId: "",
      aadharBackFileId: "",
      photoFileId: "",
      CustomerAddressInfo: [
        {
          addressId: 16,
          residenceStatus: "OWNED",
          pincode: "500001",
          city: "Hyderabad",
          state: "Telangana",
          isAadharAddress: true,
          addressType: "COMMUNICATION",
          address1: "address 1",
          address2: "address 2",
          address3: "address 3",
          landmark: "",
          docType: "",
          fileId: "",
        },
      ],
      customerPanCardInfo: {
        number: "ABCDE0394D",
      },
      customerAadharCardInfo: null,
    });
    // props.apis.apiGetCustomerInfo().then((res) => {
    //   console.log({ res });
    //   setMyinfo(res);
    //   if (res?.status !== "COMPLETED") {
    //     swal2
    //       .fire({
    //         title: "Please update your profile",
    //         icon: "info",
    //         iconColor: "#FF4D4D",
    //         confirmButtonText: "Update",
    //         showCancelButton: true,
    //         cancelButtonText: "Skip for now",
    //       })
    //       .then((result) => {
    //         if (result.isConfirmed) {
    //           openModal();
    //           setGoSteps(1);
    //         }
    //       });
    //   }
    // });
  };

  const onEdit = (e) => {
    // openModal();
  };

  const onDelete = (e, name) => {
    console.log({ name });
    // openModal();
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <>
      <MainPagetitle
        pageTitle={"Registered User"}
        mainTitle="Registered User"
        back={`${myinfo?.customerName || "User"}`}
        backLink="/app/businesstocustomer"
        parentTitle={"Home"}
      />
      <div className="container-fluid">
        <div className="row g-0">
          <div className="col-xl-12">
            <InfoCard data={myinfo} />
            <div className="row mx-auto g-4">
              <div className="col-12 col-lg-4">
                <IncomeCard
                  data={myinfo}
                  onEdit={(...args) => onEdit(...args)}
                />
              </div>
              <div className="col-12 col-lg-4">
                <AadharCard onDelete={(...args) => onDelete(...args)} />
              </div>
              <div className="col-12 col-lg-4">
                <PanCard onDelete={(...args) => onDelete(...args)} />
              </div>
              <div className="col-12">
                <DocsCard data={myinfo} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default constore(RegisteredUserView);
