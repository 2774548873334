import React from "react";
import { useNavigate } from "react-router-dom";

const OfflineLeadCard = ({ id, title, image, placeholder }) => {
  const navigate = useNavigate();
  const offlinePage = () => {
    navigate(`/app/offlineLeads?leadname=${title}`);
  };

  return (
    <div
      className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3"
      onClick={offlinePage}
    >
      <div className="offline_card">
        <div className="body d-flex">
          <img src={image} className="img-fluid me-3" alt={title} />
          <h4 className="title d-flex align-items-center">
            {placeholder?.toTitleCase() || title?.toTitleCase()}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default OfflineLeadCard;
