import React, { Component } from "react";

export class OfflineModal extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.ApplicationModal = this;
  }

  render() {
    let { props } = this;
    let {
      id,
      title,
      show,
      onClose,
      modalSize,
      logsData,
      logsColumns,
      errorMessage,
      loader,
      viewmode,
    } = props;
    let attrs = Object.except(props, ["title", "type", "onClose"]);

    return (
      <AppCanvas
        id={id}
        title={title}
        show={show}
        onClose={onClose}
        modalSize={modalSize}
      >
        <AppFormData
          {...attrs}
          onClose={onClose}
          errorMessage={errorMessage}
          viewmode={viewmode}
        ></AppFormData>
        {/* {logsData?.length > 0 && (
          <>
            <h5 class="modal-title mb-3">Logs</h5>
            <AppTable
              data={logsData || []}
              columns={logsColumns}
              reorder={true}
              showPagination={false}
              editable={false}
              viewable={false}
              deletable={false}
            ></AppTable>
          </>
        )}
        {loader && <div className="loader"></div>} */}
      </AppCanvas>
    );
  }
}

export default OfflineModal;
