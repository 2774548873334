import React, { useEffect, useState } from "react";

const ConfigurationPage = (props) => {
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [rowcount, setRowcount] = useState(0);
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const columns = [
    {
      dataIndex: "service",
      title: "Name Of The Service",
    },
    {
      dataIndex: "userRole",
      title: "Role",
    },
    {
      dataIndex: "numOfFreeLimit",
      title: "Monthly Limit",
    },
    {
      dataIndex: "price",
      title: "Cost Per Hit",
      render: (text) => {
        return <>{text?.cur() || "-"}</>;
      },
    },
  ];

  const onPageChange = (pageData) => {
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const getKycConfigurationData = (requestData) => {
    props.apis.apiGetMasterSubscriptions().then((response) => {
      setIsFetching(false);
      setData(response);
    });
  };

  useEffect(() => {
    getKycConfigurationData();
  }, []);

  return (
    <>
      <AppTable
        data={data}
        columns={columns}
        onChange={(...arg) => onPageChange(...arg)}
        total={rowcount}
        reorder={true}
        viewable={false}
        editable={false}
        deletable={false}
        loading={isFetching}
      ></AppTable>
    </>
  );
};

export default constore(ConfigurationPage);
