import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const BankWiseOffer = (props) => {
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [rowcount, setRowcount] = useState(0);
  const columns = [
    {
      dataIndex: "bankName",
      title: "Bank Name",
      render: (text, record) => {
        return <span className="table-primary-text">{text}</span>;
      },
    },
    {
      dataIndex: "minSalary",
      title: "Minimum Salary",
      render: (text, record) => {
        return <>{text ? text.cur() : "-"}</>;
      },
    },
    {
      dataIndex: "maxLoanAmount",
      title: "Max. Loan Amt.",
      render: (text, record) => {
        return <>{text ? text.cur() : "-"}</>;
      },
    },
    {
      dataIndex: "tenure",
      title: "Tenure",
    },
    {
      dataIndex: "lowestROI",
      title: "ROI Starting From",
      render: (text, record) => {
        return <>{`${text}%`}</>;
      },
    },
    {
      dataIndex: "tat",
      title: "TAT",
    },
  ];

  const onPageChange = (pageData) => {
    console.log({ onPageChange: pageData });
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const getBankOffers = () => {
    let payload = {
      ...search,
    };
    props.apis.apiGetBankOffers(payload).then(({ data, total }) => {
      setTableData(data);
      setIsFetching(false);
      setRowcount(total);
    });
  };

  const view = (record) => {
    navigate(
      `/app/viewbankoffer?bankId=${record?.bankId}&bankName=${record?.bankName}`
    );
  };

  const edit = (record) => {
    navigate(
      `/app/addbankoffer?bankId=${record?.bankId}&bankName=${record?.bankName}`
    );
  };

  const deleteBankOffer = (record) => {
    swal2
      .fire({
        title: "Are you sure you want to delete this bank offer?",
        icon: "info",
        iconColor: "#234C9B",
        showCancelButton: true,
        confirmButtonColor: "#234C9B",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
      .then((result) => {
        if (result.isConfirmed) {
          props.apis
            .apiDeleteBankOffer({ bankId: record.bankId })
            .then((res) => {
              console.log({ apiDeleteBankname: res });
              swal2.fire({
                title: "Bank Name deleted successfully !!",
                icon: "success",
                iconColor: "#234C9B",
                timer: 4000,
              });
              getBankOffers();
            });
        }
      });
  };

  const addBankOffer = () => {
    navigate(`/app/addbankoffer`);
  };

  useEffect(() => {
    getBankOffers();
  }, [search]);

  useEffect(() => {
    getBankOffers();
  }, []);

  props.$setNode({
    props,
    search,
  });
  return (
    <>
      <div className="page_header mb-3 text-end pe-4">
        <Link
          to={"#"}
          className="btn btn-primary ms-1"
          onClick={(...args) => addBankOffer(...args)}
        >
          <span className="text-white mb-0">+ Add New</span>
        </Link>
      </div>

      <AppTable
        data={tableData}
        columns={columns}
        onChange={(...arg) => onPageChange(...arg)}
        total={rowcount}
        reorder={true}
        editAction={(e) => edit(e)}
        viewAction={(e) => view(e)}
        deleteAction={(e) => deleteBankOffer(e)}
        loading={isFetching}
      ></AppTable>
    </>
  );
};

export default constore(BankWiseOffer);
