import React, { useState } from "react";
import OTPInput from "react-otp-input";

const MobileVerification = (props) => {
  let errorsObj = { otpcode: "" };
  const [otpcode, setOtpcode] = useState();
  const [isMobileDisabled, setIsMobileDisabled] = useState(true);
  const [isOtpDisabled, setIsOtpDisabled] = useState(true);
  const isNumberVerified = props.$store?.user?.isNumberVerified;

  const onChangeMobile = (e) => {
    let value = e.target.value;
    if (value.length > 0) {
      setIsMobileDisabled(false);
    } else {
      setIsMobileDisabled(true);
    }
  };

  const handleChange = (otp) => {
    console.log("otp changed: ", otp);
    setOtpcode(otp);
    if (otp.length === 6) {
      setIsOtpDisabled(false);
    } else {
      setIsOtpDisabled(true);
    }
  };

  const handleMobileVerify = (e, otpcode) => {
    setOtpcode();
    props.onMobileVerify(e, otpcode);
  };

  return (
    <>
      {isNumberVerified ? (
        // {/* change below logic once got mobile number verification API */}
        // {true ? (
        <div
          className={Object.className({
            profile_details_container: true,
          })}
        >
          <div className="profile_detail mb-3">
            <p className="m-0">Mobile Number</p>
            <h4>{props?.myinfo?.mobileNumber || "-"}</h4>
          </div>
          <div className="nav_container">
            <button
              className="btn btn-primary ml-auto"
              onClick={(...args) => props.onHandleStep(...args, 1)}
            >
              Next
            </button>
          </div>
        </div>
      ) : (
        <AppForm onSubmit={props.onMobileSubmit}>
          <div className="col-12 col-md-8 mb-3">
            <label htmlFor="mobileNumber">Mobile No.</label>
            <input
              type="number"
              label="Mobile No."
              className="form-control"
              onChange={(e) => onChangeMobile(e)}
              name="mobileNumber"
            />
          </div>
          <div className="col-12 col-md-3 mb-3 d-flex align-items-end">
            <button
              type="submit"
              className="btn btn-primary w-100"
              disabled={isMobileDisabled}
            >
              Verify
            </button>
          </div>
        </AppForm>
      )}

      {/* MOBILE OTP VERIFICATION CONTAINER */}
      <div
        className={Object.className({
          "otp_box mt-5": true,
          hidden: !props.showMobileVerify,
        })}
      >
        <div className="col-12">
          <h4>OTP to verify</h4>
          <p className="m-0">We have sent an OTP to your mobile no.</p>
        </div>
        <div className="row">
          <div className="col-12 col-xl-6">
            <OTPInput
              value={otpcode}
              onChange={(...args) => handleChange(...args)}
              numInputs={6}
              separator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
              containerStyle="otp_container"
            />
          </div>
          <div className="col-12 col-xl-6 d-flex align-items-center">
            <button
              className="btn btn-primary fs-5"
              disabled={isOtpDisabled}
              onClick={(...args) => handleMobileVerify(...args, otpcode)}
            >
              Verify
            </button>
          </div>
          <div className="col-12">
            <div className="text-start otp_bottom">
              <span className="me-2">Not received OTP?</span>
              <button
                className="btn px-0 text-primary fw-bold"
                onClick={(...args) => props.handleMobileResend(...args)}
              >
                Resend
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default constore(MobileVerification);
