import React, { useState } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import MainPagetitle from "../../../layouts/MainPagetitle";
import Teamdetails from "./teamdetails/teamdetails";
import Teamleads from "./teamleads/teamleads";

const MyTeam = (props) => {
  const isAdmin = props?.$store?.user?.role === _enum.ROUTE_PERSONAS.admin;

  const [activeKey, setActiveKey] = useState("teamdetails");
  const tabData = [
    {
      key: "teamdetails",
      name: "Team Details",
    },
    {
      key: "teamleads",
      name: "Team Leads",
    },
  ];

  const handleSelect = (key) => {
    setActiveKey(key);
  };

  const getBackLink = () => {
    return "/app/myteam";
  };

  return (
    <>
      <MainPagetitle
        mainTitle="My Team"
        backLink={getBackLink()}
        pageIcon={imagepaths.AdminLeadsIconActive}
      />
      <div className="container-fluid">
        <Tab.Container>
          <Card name="custom-tab" className="dz-card min-h-[600px]">
            <Tab.Content>
              <Card.Body>
                {/* <!-- Nav tabs --> */}
                <div className="custom-tab-1">
                  <Tab.Container
                    defaultActiveKey={activeKey}
                    activeKey={activeKey}
                    onSelect={handleSelect}
                  >
                    <Nav as="ul" className="nav-tabs">
                      {tabData.map((data, i) => (
                        <Nav.Item as="li" key={i}>
                          <Nav.Link eventKey={data.key}>{data.name}</Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                    <Tab.Content className="pt-4">
                      {activeKey === "teamdetails" && (
                        <Tab.Pane eventKey="teamdetails">
                          <Teamdetails />
                        </Tab.Pane>
                      )}
                      {activeKey === "teamleads" && (
                        <Tab.Pane eventKey="teamleads">
                          <Teamleads />
                        </Tab.Pane>
                      )}
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </Card.Body>
            </Tab.Content>
          </Card>
        </Tab.Container>
      </div>
    </>
  );
};
export default constore(MyTeam);
