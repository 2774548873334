import React, { useEffect, useState } from "react";

const InfoCard = (props) => {
  const [customerinfo, setCustomerinfo] = useState({});
  useEffect(() => {
    setCustomerinfo({
      name: props?.$store?.user?.customerName,
      mobile: props?.$store?.user?.mobileNumber,
      email: props?.$store?.user?.emailAddress,
    });
  }, []);

  const { data } = props;
  let dob = data?.dob;
  const date_of_birth = new Date(dob).dateToDDMMYYYY(new Date(dob));
  const age = new Date(dob).getAge();
  var address;
  if (data?.CustomerAddressInfo.length > 0) {
    address = `${data?.CustomerAddressInfo[0]?.address1},
    ${data?.CustomerAddressInfo[0]?.address2},
    ${data?.CustomerAddressInfo[0]?.address3}.`;
  } else {
    address = "";
  }

  if (props?.data) {
    let name = props?.data?.customerName;
    let firstLetter = name.charAt(0); // Get the first letter of the first word
    let secondLetterIndex = name.indexOf(" ") + 1; // Get the index of the first letter of the word after the space
    let secondLetter = name.charAt(secondLetterIndex); // Get the second letter of the word after the space
    let result = firstLetter + secondLetter; // Concatenate the first and second letters
    var initialValue = result ? result : "";
  }

  return (
    <div className="info_card user_view">
      <div className="content">
        <div className="name_card">
          <div className="avatar_circle">{initialValue || ""}</div>
          <h2 className="name">{data?.customerName || "-"}</h2>
        </div>
        <div className="content_list_container row">
          <ul className="col-12 col-md-6 col-lg-4 content_list">
            <li>
              <span className="icon">User ID: </span>
              <span>{data?.customerId || "-"}</span>
            </li>
            <li>
              <span className="icon">Gender: </span>
              <span>{data?.gender || "-"}</span>
            </li>
            <li>
              <span className="icon">Date of Birth:</span>
              <span>{date_of_birth || "-"}</span>
            </li>
            <li>
              <span className="icon">Age: </span>
              <span>{age || "-"}</span>
            </li>
          </ul>
          <ul className="col-12 col-md-6 col-lg-4 content_list">
            <li>
              <span className="icon">Mobile No:</span>
              <span>{data?.mobileNumber || "-"}</span>
            </li>
            <li>
              <span className="icon">Date of Joining:</span>
              <span>{data?.dateOfJoining || "-"}</span>
            </li>
            <li>
              <span className="icon">Work Location:</span>
              <span>{data?.workLocation || "-"}</span>
            </li>
            <li>
              <span className="icon">PIN Code: </span>
              <span>{data?.pincode || "-"}</span>
            </li>
          </ul>
          <ul className="col-12 col-md-6 col-lg-4 content_list">
            <li>
              <span className="icon">Email ID:</span>
              <span>{data?.emailAddress || "-"}</span>
            </li>
            <li>
              <span className="icon">Address:</span>
              <span className="whitespace-preline">{address || "-"}</span>
            </li>
          </ul>
        </div>
        {/* <button
          className="edit_btn"
          onClick={(...args) => props.onEdit(...args, "incomedetails")}
        >
          <i className="fa fa-pencil"></i>
        </button> */}
      </div>
    </div>
  );
};

export default constore(InfoCard);
