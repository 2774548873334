import Digitalleads from "../../../jsx/components/Dashboard/DigitalLeads/digitalleads";

const ApplicantsWrapper = () => {
  return <Digitalleads />;
};

export const allowedRoles = [
  _enum.ROUTE_PERSONAS.admin,
  _enum.ROUTE_PERSONAS.connector,
  _enum.ROUTE_PERSONAS.manager,
];

export default constore(ApplicantsWrapper);
