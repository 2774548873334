import ConnectorHome from "../../../jsx/components/Dashboard/ConnectorHome";

const ConnectorHomeWrapper = () => {
  return <ConnectorHome />;
};

export const allowedRoles = [
  _enum.ROUTE_PERSONAS.connector,
  _enum.ROUTE_PERSONAS.manager,
];

export default constore(ConnectorHomeWrapper);
