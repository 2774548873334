import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const Eligibitlity = (props) => {
  const [errorMessage, setErrorMessage] = useState({ name: "", message: "" });
  const [fields, setFields] = useState([
    {
      name: "mobileNo",
      label: "Applicant Mobile No.",
      type: "number",
      onChange: (value) => handleMobileNo(value),
      required: true,
    },
    {
      name: "panNo",
      label: "Applicant PAN No.",
      type: "text",
      onChange: (value) => handlePanCard(value),
      required: true,
    },
  ]);

  const handleMobileNo = (e) => {
    const numberReg = _enum?.REGEX?.number;
    const fieldValue = e.target.value;
    if (numberReg.test(fieldValue) && fieldValue.length === 10)
      setErrorMessage("");
    else
      setErrorMessage({
        name: "mobileNo",
        message: "Please enter a valid Mobile Number",
      });
  };

  const handlePanCard = (e) => {
    const panRegex = _enum?.REGEX?.pan;
    if (panRegex.test(e.target.value)) setErrorMessage("");
    else setErrorMessage({ name: "panNo", message: "Invalid PAN Number" });
  };

  const updateFields = (data) => {
    let allowedFields = [...fields.map((v) => v.name)];
    let updatedfields = Object.only(data, allowedFields);

    let farr = Object.keys(updatedfields);
    console.log({ updateFields: data, updatedfields, farr });

    farr.forEach((name) => {
      let tfield = fields.find((o) => o.name === name);
      tfield.defaultValue = data[name];
    });
    setFields([...fields]);
  };

  const handleEligibilityCheck = (e) => {
    props.onhandleCustomerCheck(e);
  };

  props.$setNode({
    props,
    errorMessage,
  });

  return (
    <>
      <AppForm className="container" onSubmit={handleEligibilityCheck}>
        {fields?.map((field, i) => {
          return (
            <div
              className={`col-12 col-md-${field?.customcolspan || 12} col-lg-6`}
              key={i}
            >
              <AppInput
                name={field?.name}
                label={field?.label}
                type={field?.type}
                defaultValue={field?.defaultValue || ""}
                options={field?.options || []}
                {...field}
              ></AppInput>
              {errorMessage?.name === field?.name && (
                <div className="invalid-feedback d-block">
                  {errorMessage.message}
                </div>
              )}
            </div>
          );
        })}
        <div className="col-12 col-md-12 mt-4 d-flex justify-content-end">
          <button
            type="button"
            className="formdata-cancel-btn btn"
            onClick={props?.onClose}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary ms-3"
            disabled={Object.keys(errorMessage).length > 0 ? true : false}
          >
            Proceed
          </button>
        </div>
      </AppForm>
    </>
  );
};

export default constore(Eligibitlity);
