export default {
  // Online Loan Application services: L&T

  apiGetDashboardMoke() {
    let data = {
      leads_entered: {
        number_of_leads: 100,
        amount: 800000,
      },
      approved_leads: {
        number_of_leads: 10,
        amount: 500000,
      },
      disbursement_leads: {
        number_of_leads: 6,
        amount: 80000,
      },
    };
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },

  apiGetIncredDataMoke() {
    let data = [
      {
        connectorName: "Yasha Agarwal",
        mobileNumber: "9988556644",
        applicationDate: new Date().toISOString(),
        leadStatus: "Success",
        applicationStatus: "-",
        irr: "5",
        maxEligibility: "2500",
      }
    ];
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },

  apiGetIncredPanDataMoke() {
    let data = [
      {
        name: "Ravindra M",
        panNumber: "DZEPA2346N"
      }
    ];
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },

  apiGetIncredPanSubmitMoke() {
    let data = true;
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },

  apiGetZypeDataMoke() {
    let data = [
      {
        application_id: "12345678",
        applicant_first_name: "Yasha",
        applicant_last_name: "Agarwal",
        mobileNo: "9988556644",
        applicationDate: new Date().toISOString(),
        status: "Success",
        applicationStatus: "-",
        irr: "5",
        maxEligibility: "2500",
      }
    ];
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },

  apiAddLntApplicant(data) {
    return this.request(this.api().ADD_LNT_APPLICANT, data).exec().log().get();
  },

  apiGetLntApplicants(data) {
    let payload = {
      ...data,
    };
    return this.request(this.api().GET_LNT_APPLICANTS, payload)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response?.data,
          total: response?.count,
        };
      })
      .get();
  },

  apiGetLntApplicantById(data, urldata = mixins.params()) {
    let payload = {
      ...data,
    };
    return this.request(this.api().GET_LNT_APPLICANT_BY_ID, payload)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return {
          data: res,
        };
      })
      .get();
  },

  apiSendConsentOTP(urldata = mixins.params()) {
    return this.request(this.api().SEND_CONSENT_OTP)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return {
          data: res,
        };
      })
      .get();
  },

  apiResendConsentOTP(urldata = mixins.params()) {
    return this.request(this.api().RESEND_CONSENT_OTP)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return {
          data: res,
        };
      })
      .get();
  },

  apiVerifyConsentOTP(data) {
    return this.request(this.api().VERIFY_CONSENT_OTP, data).exec().log().get();
  },

  apiGetLntLeadsDashboard(data) {
    let payload = {
      ...data,
    };
    return this.request(this.api().LNT_LEADS_DASHBOARD, payload)
      .exec()
      .log()
      .mapresults((response) => {
        return response;
      })
      .get();
  },
  // apiPincode(urldata = mixins.params()) {
  //     return this.request(this.api().PINCODE)
  //         .urltransform(urldata)
  //         .exec().log()
  //         .mapresults((res) => {
  //             return {
  //                 data: res,
  //             }
  //         })
  //         .get();
  // },

  apiGetOverallApplicants(data) {
    let payload = {
      ...data,
    };
    return this.request(this.api().GET_OVERALL_APPLICANTS, payload)
      .exec()
      .log()
      .mapresults((response) => {
        return response;
      })
      .get();
  },

  apiGetOverallApplicantsForManagerTeam(data) {
    let payload = {
      ...data,
    };
    return this.request(
      this.api().GET_OVERALL_APPLICANTS_FOR_MANAGER_TEAM,
      payload
    )
      .exec()
      .log()
      .mapresults((response) => {
        return response;
      })
      .get();
  },

  apiGetConsolidatedAppsById(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().GET_CONSOLIDATED_APPS_BY_ID)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return {
          data: {
            ...res,
            payoutType: res?.payoutType?.toLowerCase(),
          },
        };
      })
      .get();
  },

  apiUpdateConsolidatedAppById(data, urldata = {}) {
    console.log({ apiUpdateConsolidatedAppById: data });
    urldata = { ...mixins.params(), ...urldata };

    let payload = {
      bankPayoutPct: data?.bankPayoutPct,
      bankPayoutAmt: data?.bankPayoutAmt,
      digirepPayoutPct: data?.digirepPayoutPct,
      digirepPayoutAmt: data?.digirepPayoutAmt,
      netAmt: data?.netAmt,
      tdsPct: data?.tdsPct,
      tdsAmt: data?.tdsAmt,
      bankPayoutReceiveDate: data?.bankPayoutReceiveDate,
      remarks: data?.remarks,
      paymentStatus: data?.paymentStatus,
      paymentDate: data?.paymentDate,
      payoutType: data?.payoutType?.toLowerCase(),
    };

    return this.request(this.api().UPDATE_CONSOLIDATED_APP_BY_ID, payload)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  // INCRED

  apiGetIncredApplicants(data) {
    let payload = {
      ...data,
    };
    return this.request(this.api().GET_INCRED_APPLICANTS, payload)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response?.data,
          total: response?.count,
        };
      })
      .get();
  },

  apiGetIncredManagers(data) {
    let payload = {
      ...data,
    };
    return this.request(this.api().GET_ALL_INCRED_MANAGERS, payload)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response?.data,
          total: response?.count,
        };
      })
      .get();
  },

  apiGetIncredApplicantById(urldata = mixins.params()) {

    return this.request(this.api().GET_INCRED_APPLICANT_BY_ID)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return {
          data: res,
        };
      })
      .get();
  },

  apiIncredSendConsentOTP(urldata = mixins.params()) {
    return this.request(this.api().INCRED_SEND_CONSENT_OTP)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return {
          data: res,
        };
      })
      .get();
  },

  apiIncredResendConsentOTP(urldata = mixins.params()) {
    return this.request(this.api().INCRED_RESEND_CONSENT_OTP)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return {
          data: res,
        };
      })
      .get();
  },

  apiIncredVerifyConsentOTP(data) {
    return this.request(this.api().INCRED_VERIFY_CONSENT_OTP, data).exec().log().get();
  },

  apiAddIncredApplicant(data) {
    return this.request(this.api().ADD_INCRED_APPLICANT, data).exec().log().get();
  },

  // ZYPE

  apiGetZypeLeads(data) {
    let payload = {
      ...data,
    };
    return this.request(this.api().GET_ALL_ZYPE_LEADS, payload)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response?.data,
          total: response?.count,
        };
      })
      .get();
  },

  apiGetZypeLeadById(urldata = mixins.params()) {

    return this.request(this.api().GET_ZYPE_LEAD_BY_ID)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return {
          data: res,
        };
      })
      .get();
  },

  apiAddZypeLead(data) {
    return this.request(this.api().CREATE_ZYPE_LEAD, data).exec().log().get();
  },

  apiZypeCustomerEligibilityCheck(data) {
    return this.request(this.api().ZYPE_CUSTOMER_ELIGIBILITY_CHECK, data).exec().log().get();
  },

  // LIVLONG INSURANCE

  apiGetLivlongInsuranceRedirectUrl() {

    return this.request(this.api().GET_LIVLONG_INSURANCE_REDIRECT_URL)
      .exec()
      .log()
      .mapresults((res) => {
        return {
          data: res,
        };
      })
      .get();
  },

  // MASTER PINCODE
  apiGetMasterPincodes(data) {
    let payload = {
      ...data,
    };
    return this.request(this.api().GET_MASTER_PINCODES, payload)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response?.data,
          total: response?.count,
        };
      })
      .get();
  },

  apiGetIndividualMasterPincode(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().GET_INDIVIDUAL_MASTER_PINCODE)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res;
      })
      .get();
  },
  // MASTER COMPANY
  apiGetMasterCompanies(data) {
    let payload = {
      ...data,
    };
    return this.request(this.api().GET_ALL_MASTER_COMPANY, payload)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response?.data,
          total: response?.count,
        };
      })
      .get();
  },

  apiAddTataCapitalApplicant(data, urldata = mixins.params()) {
    return this.request(this.api().ADD_TATA_CAPITAL_APPLICANT, data)
      //   .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiTataSendConsentOTP(urldata = mixins.params()) {
    return this.request(this.api().TATA_SEND_CONSENT_OTP)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return {
          data: res,
        };
      })
      .get();
  },

  apiTataResendConsentOTP(urldata = mixins.params()) {
    return this.request(this.api().TATA_RESEND_CONSENT_OTP)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return {
          data: res,
        };
      })
      .get();
  },

  apiTataVerifyConsentOTP(data) {
    return this.request(this.api().TATA_VERIFY_CONSENT_OTP, data)
      .exec()
      .log()
      .get();
  },

  apiTataGetOverallApplicants(data) {
    let payload = {
      ...data,
    };
    return this.request(this.api().GET_TATA_CAPITAL_OVERALL_APPLICANTS, payload)
      .exec()
      .log()
      .mapresults((response) => {
        return response;
      })
      .get();
  },

};
