export default {

    // ONLINE LOAN APPLICATION SERVICE: L&T

    ADD_LNT_APPLICANT: {
        url: "/online-apps/lnt",
        method: "post",
    },
    GET_LNT_APPLICANTS: {
        url: "/online-apps/lnt",
        method: "get",
    },
    GET_LNT_APPLICANT_BY_ID: {
        url: "/online-apps/lnt/unique/{uid}",
        method: "get",
    },

    SEND_CONSENT_OTP: {
        url: "/online-apps/lnt/consent/{mobileNo}",
        method: "get",
    },
    RESEND_CONSENT_OTP: {
        url: "/online-apps/lnt/consent/resend/{uid}",
        method: "get",
    },
    VERIFY_CONSENT_OTP: {
        url: "/online-apps/lnt/consent/verify",
        method: "post",
    },
    LNT_LEADS_DASHBOARD: {
        url: "/online-apps/lnt/dashboard",
        method: "get",
    },

    // ONLINE LOAN APPLICATION SERVICE: INCRED FINANCE

    GET_INCRED_APPLICANTS: {
        url: "/online-apps/incred",
        method: "get",
    },
    GET_ALL_INCRED_MANAGERS: {
        url: "/online-apps/incred/manager",
        method: "get",
    },
    GET_INCRED_APPLICANT_BY_ID: {
        url: "/online-apps/incred/unique/{application_id}",
        method: "get",
    },

    INCRED_SEND_CONSENT_OTP: {
        url: "/online-apps/incred/consent/{mobileNo}",
        method: "get",
    },

    INCRED_RESEND_CONSENT_OTP: {
        url: "/online-apps/incred/consent/resend/{applicationId}",
        method: "get",
    },

    INCRED_VERIFY_CONSENT_OTP: {
        url: "/online-apps/incred/consent/verify",
        method: "post",
    },

    ADD_INCRED_APPLICANT: {
        url: "/online-apps/incred",
        method: "post",
    },

    // ZYPE
    ZYPE_CUSTOMER_ELIGIBILITY_CHECK: {
        url: "/online-apps/zype/customer/eligibility",
        method: "post",
    },

    CREATE_ZYPE_LEAD: {
        url: "/online-apps/zype",
        method: "post",
    },

    GET_ALL_ZYPE_LEADS: {
        url: "/online-apps/zype",
        method: "get",
    },

    GET_ZYPE_LEAD_BY_ID: {
        url: "/online-apps/zype/unique/{application_id}",
        method: "get",
    },

    // LIVLONG INSURANCE

    GET_LIVLONG_INSURANCE_REDIRECT_URL: {
        url: "/online-apps/livlong-insurance",
        method: "get",
    },


    // PINCODE 
    CREATE_MASTER_PINCODE: {
        url: "/master-pincode",
        method: "post",
    },
    GET_MASTER_PINCODES: {
        url: "/master-pincode",
        method: "get",
    },
    GET_MASTER_PINCODE: {
        url: "/master-pincode",
        method: "get",
    },
    GET_INDIVIDUAL_MASTER_PINCODE: {
        url: "/master-pincode/{pincode}",
        method: "get",
    },
    UPDATE_MASTER_PINCODE: {
        url: "/master-pincode/{pincode}",
        method: "put",
    },
    DELETE_MASTER_PINCODE: {
        url: "/master-pincode/{pincode}",
        method: "delete",
    },

    // MASTER COMPANY
    CREATE_MASTER_COMPANY: {
        url: "/master-company",
        method: "post",
    },
    GET_ALL_MASTER_COMPANY: {
        url: "/master-company",
        method: "get",
    },
    GET_MASTER_COMPANY_BY_CIN: {
        url: "/master-company/{cin}",
        method: "get",
    },
    UPDATE_MASTER_COMPANY: {
        url: "/master-company/{cin}",
        method: "put",
    },
    DELETE_MASTER_COMPANY: {
        url: "/master-company/{cin}",
        method: "delete",
    },

    // CONSOLIDATED LAED APPLICATIONS
    GET_OVERALL_APPLICANTS: {
        url: "/consolidated-apps",
        method: "get",
    },
    GET_OVERALL_APPLICANTS_FOR_MANAGER_TEAM: {
        url: "/consolidated-apps/team",
        method: "get",
    },
    GET_CONSOLIDATED_APPS_BY_ID: {
        url: "/consolidated-apps/{leadId}",
        method: "get",
    },
    UPDATE_CONSOLIDATED_APP_BY_ID: {
        url: "/consolidated-apps/{leadId}",
        method: "put",
    },

    ADD_TATA_CAPITAL_APPLICANT: {
        url: "/online-apps/tcfsl/pl/eligibility?NSDL-FLAG=N",
        method: "post",
    },
    GET_TATA_CAPITAL_OVERALL_APPLICANTS: {
        url: "/online-apps/tcfsl/pl",
        method: "get",
    },
    TATA_SEND_CONSENT_OTP: {
        url: "/online-apps/tcfsl/consent/{mobileNo}",
        method: "get",
    },
    TATA_RESEND_CONSENT_OTP: {
        url: "/online-apps/tcfsl/consent/resend/{uid}",
        method: "get",
    },
    TATA_VERIFY_CONSENT_OTP: {
        url: "/online-apps/tcfsl/consent/verify",
        method: "post",
    },
}