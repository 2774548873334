import React, { Component, useContext, useEffect } from "react";
//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import MainPagetitle from "../../layouts/MainPagetitle";
import CardWidget from "./elements/CardWidget";
import ProjectStatusBlog from "./elements/ProjectStatusBlog";

class Home extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)();
    this.state = {
      b2b: [],
      b2c: [],
      productTypes: [],
    };
  }

  getDashboardData = () => {
    this.props.apis.apiGetMokeDashboardData().then((res) => {
      console.log({ res });
      let { total_digirep, total_manager, total_leads, total_registered_user } =
        res;
      this.setState({
        b2b: [
          {
            title: "Total Digi Team Member",
            subtitle: "Total No. of Digi Team Member",
            image: imagepaths.userCheckIcon,
            value: total_digirep,
          },
          {
            title: "Total Manager",
            subtitle: "Total No. of Manager",
            image: imagepaths.userTieIcon,
            value: total_manager,
          },
        ],
        b2c: [
          {
            title: "Total Registered User",
            subtitle: "Total No. of User",
            image: imagepaths.userIconLight,
            value: total_registered_user,
          },
          {
            title: "Total Leads",
            subtitle: "Total No. of Leads",
            image: imagepaths.userStarIcon,
            value: total_leads,
          },
        ],
      });
    });
  };

  getProductTypes() {
    this.props.apis.apiGetProductTypes().then((res) => {
      this.setState({
        productTypes: res,
      });
    });
  }

  componentDidMount() {
    this.setState({ isClient: true });
    this.props.store("mounted", true);
    this.services
      .checkAuthorize(this.props, "admin")
      .then(() => {
        this.services.checkExpiry();
      })
      .catch((err) => {});
    console.log("Home", this.props);
    this.getDashboardData();
    this.getProductTypes();
  }

  render() {
    let { b2b, b2c, productTypes } = this.state;

    return (
      <>
        <MainPagetitle
          mainTitle="Dashboard"
          pageTitle="Dashboard"
          parentTitle="Home"
          pageIcon={imagepaths.DashboardActive}
        />
        <div className="container-fluid">
          <div className="row mx-auto">
            <div className="col-xl-12">
              <div className="row mx-auto">
                <CardWidget title="B2B" fields={b2b} />
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row mx-auto">
                <CardWidget title="B2C" fields={b2c} />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-5">
              <ProjectStatusBlog
                title="B2B Lead Status"
                products={productTypes}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-5">
              <ProjectStatusBlog
                title="B2C Lead Status"
                products={productTypes}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default constore(Home);
