export default {

    // LEADS
    ADD_LEAD: {
        url: "/leads",
        method: "post",
    },
    GET_LEADS: {
        url: "/leads",
        method: "get",
    },
    GET_LEADS_BY_ID: {
        url: "/leads/{leadId}",
        method: "get",
    },
    UPDATE_LEAD: {
        url: "/leads/{leadId}",
        method: "put",
    },
    DELETE_LEAD: {
        url: "/leads/{leadId}",
        method: "delete",
    },
    ASSIGN_DEPT_TO_LEAD: {
        url: "/leads/assignment",
        method: "put",
    },
    GET_LEAD_HISTORY: {
        url: "/assigned-lead-history",
        method: "get",
    },
    // BULK FILES
    BULK_UPLOAD_LEAD: {
        url: "/leads/bulk-upload/{productType}",
        method: "post",
    },
    DOWNLOAD_LEADS_TEMPLATE: {
        url: "/leads/bulk-upload/template",
        method: "get",
    },
    DOWNLOAD_LEADS_LISTS: {
        url: "/leads/bulk-download",
        method: "post",
    },
}