import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const DocsUpload = (props) => {
  const hasEditData =
    props?.myinfo?.panFileId?.length !== 0 || props?.myinfo?.panFileId !== null;
  const [isAccountDisabled, setIsAccountDisabled] = useState(true);
  const [fields, setFields] = useState([
    {
      name: "panFileId",
      label: "Upload PAN Card",
      type: "file",
      fileLabel: "Upload PAN Card",
      foldername: "kycDocs",
      accept: ".png, .jpg, .jpeg",
      required: true,
    },
    {
      name: "aadharFrontFileId",
      label: "Upload Aadhar Card (Front)",
      type: "file",
      fileLabel: "Upload Aadhar Card",
      foldername: "kycDocs",
      accept: ".png, .jpg, .jpeg",
      required: true,
    },
    {
      name: "aadharBackFileId",
      label: "Upload Aadhar Card (Back)",
      type: "file",
      fileLabel: "Upload Aadhar Card",
      foldername: "kycDocs",
      accept: ".png, .jpg, .jpeg",
      required: true,
    },
    {
      name: "photoFileId",
      label: "Photo",
      type: "file",
      fileLabel: "Upload Photo",
      foldername: "kycDocs",
      accept: ".png, .jpg, .jpeg",
      required: true,
    },
  ]);

  const toastConfig = {
    autoClose: 3000,
    closeButton: false,
    hideProgressBar: true,
  };

  const handleAcknowledge = (e, data) => {
    let value = e.target.checked;
    setIsAccountDisabled(!value);
  };

  const updateFields = (fields, data) => {
    let result = fields?.map((field) => {
      if (field.type === "file") {
        console.log({ data, field });
        field.defaultValue = data[field.name];
        field.value = data[field.name];
        // field.disabled = true;
        return {
          ...field,
        };
      } else {
        return {
          ...field,
          defaultValue: data[field?.name],
          // disabled: true,
        };
      }
    });
    return result;
  };

  const updateDocs = (e, data) => {
    let payload = {
      ...Object.except(data, "acknowledgement"),
    };
    console.log({ payload });
    props.apis.apiUploadDocs(payload).then((res) => {
      props?.onDocsSubmit(e);
    });
    props.apis
      .apiSendAgreementForEsign({ profileId: props?.$store?.user?.profileId })
      .then((res) => {
        console.log({ res });
      });
  };

  useEffect(() => {
    console.log(props?.myinfo);
    if (hasEditData) {
      let alteredData = {
        panFileId: props?.myinfo?.panFileId,
        aadharFrontFileId: props?.myinfo?.aadharFrontFileId,
        aadharBackFileId: props?.myinfo?.aadharBackFileId,
        photoFileId: props?.myinfo?.photoFileId,
      };
      let updatedFields = updateFields(fields, alteredData);
      setFields([...updatedFields]);
    }
  }, []);
  return (
    <>
      <AppForm className="container" onSubmit={updateDocs}>
        {fields?.map((field, i) => {
          return (
            <div className={`col-12 col-md-12 col-lg-6`} key={i}>
              <AppInput {...field}></AppInput>
            </div>
          );
        })}
        <div className="col-12 col-md-12 acknowledgement">
          <label htmlFor="acknowledgement" className="cursor-pointer d-flex">
            <input
              className="form-check-input mt-0 me-2"
              onChange={(e) => handleAcknowledge(e)}
              type="checkbox"
              name="acknowledgement"
            />
            <span>
              By clicking on submit you have read and agreed to the
              <a
                href="#"
                className="table-primary-text ms-2"
                data-bs-toggle="modal"
                data-bs-target="#dataPrivacyModal"
              >
                Starpowerz Digiloans T&C & Privacy Policy.
              </a>
            </span>
          </label>
        </div>
        <div className="col-12 col-md-12 mt-4 d-flex justify-content-end">
          <button
            className="formdata-cancel-btn btn"
            onClick={(...args) => props.onHandleStep(...args, 3)}
          >
            Back
          </button>
          <button
            type="submit"
            className="btn btn-primary ms-3"
            // onClick={(...args) => updateDocs(...args)}
            disabled={isAccountDisabled}
          >
            Submit
          </button>
        </div>
      </AppForm>
    </>
  );
};

export default constore(DocsUpload);
