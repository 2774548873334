import React, { useEffect, useState } from "react";
import RoleCard from "./RoleCard/RoleCard";

const Roles = (props) => {
  const [roles, setRoles] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);

  useEffect(() => {
    getRoles();
    getDepartments();
    getDesignations();
  }, []);

  const getRoles = () => {
    props.apis.apiGetMasterRoles().then((res) => {
      let roles = res.filter((v) => v.role !== "ADMIN");
      let newArray = roles?.map((obj) => {
        if (obj.role === "DIGIREP-MANAGER") {
          return { ...obj, role: "DIGI TEAM MANAGER" };
        }
        if (obj.role === "CONNECTOR") {
          return { ...obj, role: "DIGI TEAM MEMBER" };
        }
        return obj;
      });
      setRoles(newArray);
    });
  };

  const getDepartments = () => {
    props.apis.apiGetMasterDepartments().then((res) => {
      setDepartments(res);
    });
  };

  const getDesignations = () => {
    props.apis.apiGetMasterDesignations().then((res) => {
      if (res) {
        let newArray = res?.map((obj) => {
          if (obj.designation === "EXECUTIVE") {
            return { ...obj, designation: "DIGI TEAM MEMBER" };
          }
          return obj;
        });
        setDesignations(newArray);
      }
    });
  };

  const handleRole = (e) => {
    console.log({ handleRoleSubmit: e.target.getData() });
    let payload = {
      ...e.target.getData(),
    };
    // ADD NEW ROLE
    props.apis.apiAddMasterRole(payload).then((res) => {
      console.log({ apiAddMasterRole: res });
      getRoles();
      document.getElementById("role-role-input").value = "";
    });
  };

  const handleDepartment = (e) => {
    console.log({ handleDepartment: e.target.getData() });
    let payload = {
      ...e.target.getData(),
    };
    // ADD NEW DEPARTMENT
    props.apis.apiAddMasterDepartments(payload).then((res) => {
      console.log({ apiAddMasterDepartments: res });
      getDepartments();
      document.getElementById("role-department-input").value = "";
      resetToggle("department");
    });
  };

  const handleDesignation = (e) => {
    console.log({ handleDesignation: e.target.getData() });
    let payload = {
      ...e.target.getData(),
    };
    // ADD NEW DESIGNATION
    props.apis.apiAddMasterDesignations(payload).then((res) => {
      console.log({ apiAddMasterDesignations: res });
      getDesignations();
      resetToggle("designation");
      // document.getElementById("role-designation-input").value = "";
    });
  };
  const handleToggleForm = (e, name) => {
    e.preventDefault();
    e.stopPropagation();
    let target = e.target;
    console.log(target, name);
    let footer = document.querySelector(`.role_card .${name}_card_footer`);
    if (footer.classList.contains("hide")) {
      footer.classList.remove("hide");
      footer.classList.add("show");
    } else {
      footer.classList.remove("show");
      footer.classList.add("hide");
    }
  };

  const resetToggle = (name) => {
    console.log(name);
    let footer = document.querySelector(`.role_card .${name}_card_footer`);
    if (footer.classList.contains("hide")) {
      footer.classList.remove("hide");
      footer.classList.add("show");
    } else {
      footer.classList.remove("show");
      footer.classList.add("hide");
    }
  };

  // DELETE MASTER ROLE
  const handleDeleteRole = (e, data) => {
    let payload = {
      role: data.role,
    };
    props.apis.apiDeleteMasterRole(payload).then(() => {
      getRoles();
    });
  };

  // DELETE MASTER DEPARTMENT
  const handleDeleteDepartment = (e, data) => {
    let payload = {
      department: data.department,
    };
    console.log({ handleDeleteDepartment: e, data });
    props.apis.apiDeleteMasterDepartment(payload).then(() => {
      getDepartments();
    });
  };

  // DELETE MASTER DESIGNATION
  const handleDeleteDesignation = (e, data) => {
    let payload = {
      designation: data.designation,
    };
    console.log({ handleDeleteDesignation: e, data });
    props.apis.apiDeleteMasterDesignation(payload).then(() => {
      getDesignations();
    });
  };

  props.$setNode({
    props,
  });

  return (
    <div className="role_card_container row px-2 py-3">
      <div className="col-12 col-md-4 col-xl-4">
        <RoleCard
          name="department"
          data={departments}
          onSubmit={handleDepartment}
          onDelete={handleDeleteDepartment}
          toggleForm={(...args) => handleToggleForm(...args)}
        />
      </div>
      <div className="col-12 col-md-4 col-xl-4">
        <RoleCard
          name="designation"
          data={designations}
          onSubmit={handleDesignation}
          onDelete={handleDeleteDesignation}
          toggleForm={(...args) => handleToggleForm(...args)}
        />
      </div>
      <div className="col-12 col-md-4 col-xl-4">
        <RoleCard
          name="role"
          data={roles}
          onSubmit={handleRole}
          onDelete={handleDeleteRole}
          toggleForm={(...args) => handleToggleForm(...args)}
          crudOperation={false}
        />
      </div>
    </div>
  );
};

export default constore(Roles);
