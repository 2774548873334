import React, { useEffect, useState } from "react";

const DocsRequired = (props) => {
  const isEditMode = props?.urlparams?.params?.bankId?.length > 0;
  const [fields, setFields] = useState([
    {
      name: "aadharRequired",
      label: "Aadhar Card",
      type: "select",
      options: _enum.YES_OR_NO,
      required: true,
    },
    {
      name: "panRequired",
      label: "PAN Card",
      type: "select",
      options: _enum.YES_OR_NO,
      required: true,
    },
    {
      name: "salarySlipRequired",
      label: "Salary Slip",
      type: "select",
      options: _enum.YES_OR_NO,
      required: true,
    },
    {
      name: "addressProofRequired",
      label: "Address Proof",
      type: "select",
      options: _enum.YES_OR_NO,
      required: true,
    },
    {
      name: "bankStatementRequired",
      label: "Last 3 Months Bank Statement",
      type: "select",
      options: _enum.YES_OR_NO,
      required: true,
    },
  ]);

  const handleSubmit = (e, data) => {
    let payload = {
      aadharRequired: JSON.parse(data?.aadharRequired),
      panRequired: JSON.parse(data?.panRequired),
      salarySlipRequired: JSON.parse(data?.salarySlipRequired),
      addressProofRequired: JSON.parse(data?.addressProofRequired),
      bankStatementRequired: JSON.parse(data?.bankStatementRequired),
    };
    console.log(payload, props.selectedBank?.bankId);

    let bankId;
    if (isEditMode) {
      bankId = props?.urlparams?.params?.bankId;
    } else {
      bankId = props.selectedBank?.bankId;
    }
    props.apis
      .apiUpdateBankOffer(payload, {
        bankId: bankId,
      })
      .then((res) => {
        if (res) {
          props.changeTab(e, "pointofcontact");
        }
      });
  };
  const updateFields = (fields, data) => {
    let result = fields?.map((field) => {
      console.log({ field });
      if (field.type === "file") {
        console.log({ data, field });
        field.defaultValue = data[field.name];
        field.value = data[field.name];
        // field.disabled = true;
        return {
          ...field,
        };
      } else {
        return {
          ...field,
          defaultValue: data[field?.name],
          // disabled: true,
        };
      }
    });
    return result;
  };

  useEffect(() => {
    if (isEditMode) {
      let payload = {
        bankId: props?.urlparams?.params?.bankId,
      };
      props.apis.apiGetBankOfferById(payload).then((res) => {
        if (res) {
          let updatedFields = updateFields(fields, res);
          setFields([...updatedFields]);
        }
      });
    }
  }, []);
  return (
    <>
      <AppForm className="container" onSubmit={handleSubmit}>
        {fields?.map((field, i) => {
          return (
            <div
              className={`col-12 col-md-${field?.customcolspan || 4}`}
              key={i}
            >
              <AppInput {...field}></AppInput>
            </div>
          );
        })}
        <div className="nav_container text-end mt-4">
          <button
            type="button"
            className="formdata-cancel-btn btn"
            onClick={(...args) => props.changeTab(...args, "eligibility")}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary ms-3">
            {isEditMode ? "Update" : "Submit"}
          </button>
        </div>
      </AppForm>
    </>
  );
};

export default constore(DocsRequired);
