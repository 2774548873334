import React, { Component } from "react";

export class ApplicationSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        limit: 10,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
    };
    this.fields = [
      {
        name: "productType",
        label: "Product Type",
        type: "select",
        options: [],
        placeholder: "Product Type",
      },
      {
        name: "leadName",
        label: "Customer Name (as per PAN)",
        type: "text",
        placeholder: "Customer Name",
      },
      {
        name: "status",
        label: "Login Status",
        type: "select",
        options: _enum.STATUS,
        placeholder: "Login Status",
      },
    ];
  }

  componentDidMount() {
    this.getProductTypes();
  }

  getProductTypes() {
    this.props.apis.apiGetProductTypes().then((res) => {
      let productType = this.fields.find((o) => o.name == "productType");
      productType.options = res.options("productType");
    });
  }

  onSearch = (reset = false) => {
    let data = this.refs.form.form.current.getData();
    console.log("onSearch formData", data, reset);
    if (reset) {
      data = {
        ...this.state.search,
      };
    } else {
      data = Object.filter(data, (val) => val);
      // console.log("data on search", data);
    }
    this.props.onSearch && this.props.onSearch(data, reset);
    this.refs.form.form.current.reset();
  };

  resetSearchForm() {
    this.refs.form.form.current.reset();
    this.onSearch(true);
  }

  render() {
    return (
      <>
        <AppForm
          className="row filter-row flex-auto mx-auto position-relative application_search"
          ref="form"
        >
          {this.fields.map((field, i) => (
            <div
              className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 input_column"
              key={i}
            >
              <AppInputFocus ref="inputname" {...field}></AppInputFocus>
            </div>
          ))}
          <div className="col-6 col-md-1 col-lg-1 col-xl-1 d-flex align-items-start justify-content-center mw-lg-75">
            <button
              className="btn btn-primary search_btn"
              onClick={() => this.onSearch()}
            >
              <i className="fa fa-search"></i>
              <span>Search</span>
            </button>
          </div>
          <div className="col-6 col-md-1 col-lg-1 col-xl-1 mw-lg-75">
            <button
              type="button"
              className="btn btn-primary clear_filter_btn mb-3"
              onClick={(...args) => this.resetSearchForm(...args)}
            >
              <i class="fa-solid fa-filter-circle-xmark"></i>
            </button>
          </div>
        </AppForm>
      </>
    );
  }
}

export default constore(ApplicationSearch);
