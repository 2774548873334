import React, { useState } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import MainPagetitle from "../../../layouts/MainPagetitle";
import Leads from "./Leads/leads";
import Applications from "./Applications/applications";
import RegisteredUsers from "./RegisteredUsers/registeredusers";

const BusinessToCustomer = () => {
  const [activeKey, setActiveKey] = useState("registeredUsers");
  const tabData = [
    {
      key: "registeredUsers",
      name: "Registered Users",
    },
    {
      key: "leads",
      name: "Leads",
    },
    {
      key: "applications",
      name: "Applications",
    },
  ];

  const handleSelect = (key) => {
    setActiveKey(key);
  };

  return (
    <>
      <MainPagetitle
        mainTitle="B2C Leads"
        pageTitle="B2C Leads"
        parentTitle="Home"
      />
      <div className="container-fluid">
        <Tab.Container>
          <Card name="custom-tab" className="dz-card min-h-[600px]">
            <Tab.Content>
              <Card.Body>
                <div className="custom-tab-1">
                  <Tab.Container
                    defaultActiveKey={activeKey}
                    activeKey={activeKey}
                    onSelect={handleSelect}
                  >
                    <Nav as="ul" className="nav-tabs">
                      {tabData.map((data, i) => (
                        <Nav.Item as="li" key={i}>
                          <Nav.Link eventKey={data.key}>{data.name}</Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                    <Tab.Content
                      className={Object.className({
                        "pt-4": true,
                        "mt-3": activeKey === "applications" ? true : false,
                      })}
                    >
                      {activeKey === "registeredUsers" && (
                        <Tab.Pane eventKey="registeredUsers">
                          <RegisteredUsers />
                        </Tab.Pane>
                      )}
                      {activeKey === "leads" && (
                        <Tab.Pane eventKey="leads">
                          <Leads />
                        </Tab.Pane>
                      )}
                      {activeKey === "applications" && (
                        <Tab.Pane eventKey="applications">
                          <Applications />
                        </Tab.Pane>
                      )}
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </Card.Body>
            </Tab.Content>
          </Card>
        </Tab.Container>
      </div>
    </>
  );
};
export default constore(BusinessToCustomer);
