import React, { useEffect, useState } from "react";

const UploadedDocs = (props) => {
  const isEditMode = props?.urlparams?.params?.bankId?.length > 0;
  const [fields, setFields] = useState([
    {
      name: "rateCardFileId",
      label: "Upload Rate Card",
      type: "file",
      fileLabel: "Upload Here",
      accept: ".pdf",
      foldername: "bankWiseDocs",
    },
    {
      name: "policyFileId",
      label: "Upload Policy One Pager",
      type: "file",
      fileLabel: "Upload Here",
      accept: ".pdf",
      foldername: "bankWiseDocs",
    },
  ]);

  const handleSubmit = (e, data) => {
    let payload = {
      ...data,
    };
    console.log(payload, props.selectedBank?.bankId);

    let bankId;
    if (isEditMode) {
      bankId = props?.urlparams?.params?.bankId;
    } else {
      bankId = props.selectedBank?.bankId;
    }

    props.apis.apiUpdateBankOffer(payload, { bankId: bankId }).then((res) => {
      if (res) {
        swal2.fire({
          title: "Bank Wise Offer added successfully.",
          icon: "success",
          iconColor: "#234C9B",
          timer: 4000,
        });
        navigate("/app/masterdata?activetab=bankwiseoffer");
      }
    });
  };
  const updateFields = (fields, data) => {
    let result = fields?.map((field) => {
      console.log({ field });
      if (field.type === "file") {
        console.log({ data, field });
        field.defaultValue = data[field.name];
        field.value = data[field.name];
        // field.disabled = true;
        return {
          ...field,
        };
      } else {
        return {
          ...field,
          defaultValue: data[field?.name],
          // disabled: true,
        };
      }
    });
    return result;
  };

  useEffect(() => {
    if (isEditMode) {
      let payload = {
        bankId: props?.urlparams?.params?.bankId,
      };
      props.apis.apiGetBankOfferById(payload).then((res) => {
        if (res) {
          let updatedFields = updateFields(fields, res);
          setFields([...updatedFields]);
        }
      });
    }
  }, []);
  return (
    <>
      <AppForm className="container" onSubmit={handleSubmit}>
        {fields?.map((field, i) => {
          return (
            <div
              className={`col-12 col-md-${field?.customcolspan || 4}`}
              key={i}
            >
              <AppInput {...field}></AppInput>
            </div>
          );
        })}
        <div className="nav_container text-end mt-4">
          <button
            type="button"
            className="formdata-cancel-btn btn"
            onClick={(...args) => props.changeTab(...args, "pointofcontact")}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary ms-3">
            {isEditMode ? "Update" : "Submit"}
          </button>
        </div>
      </AppForm>
    </>
  );
};

export default constore(UploadedDocs);
