export const AdminMenuList = [
  {
    title: "Dashboard",
    iconStyle: imagepaths.Dashboard,
    iconActive: imagepaths.DashboardActive,
    to: "/app/home",
    id: "home",
  },
  {
    title: "Digi Team Member",
    iconStyle: imagepaths.LeadsIcon,
    iconActive: imagepaths.LeadsIconActive,
    to: "/app/digirep",
    // id: ["digirep", "digirepview"], 
    id: "digirep",
  },
  {
    title: "Master Data",
    iconStyle: imagepaths.MasterDataIcon,
    iconActive: imagepaths.MasterDataIconActive,
    to: "/app/masterdata",
    id: ["masterdata", "addbankoffer", "viewbankoffer"],
  },
  {
    title: "Leads",
    iconStyle: imagepaths.AdminLeadsIcon,
    iconActive: imagepaths.AdminLeadsIconActive,
    to: "/app/businesstobusiness?activetab=overallleads",
    id: ["businesstobusiness", "businesstocustomer", "digitalleads", "offlineleads"],
    classChange: "mm-collapse",
    content: [
      {
        title: "B2B",
        iconStyle: imagepaths.SidebarDot,
        iconActive: imagepaths.SidebarDot,
        to: "/app/businesstobusiness?activetab=overallleads",
        // id: "businesstobusiness",
        id: ["businesstobusiness", "digitalleads", "offlineleads"],
      },
      {
        title: "B2C",
        iconStyle: imagepaths.SidebarDot,
        iconActive: imagepaths.SidebarDot,
        to: "/app/businesstocustomer",
        id: "businesstocustomer",
      },
    ],
  },
  {
    title: "Payout Details",
    iconStyle: imagepaths.WalletIcon,
    iconActive: imagepaths.WalletIconActive,
    to: "/app/myearnings",
    id: "myearnings",
  },
  {
    title: "Bank Dumps",
    iconStyle: imagepaths.FileSearchIcon,
    iconActive: imagepaths.FileSearchIconActive,
    to: "/app/bankdumps",
    id: "bankdumps",
  },
  {
    title: "Value Added Services",
    iconStyle: imagepaths.vasIcon,
    iconActive: imagepaths.vasIconActive,
    imgclass: "vas-icon",
    to: "/app/valueaddedservices",
    id: ["valueaddedservices", "transactiondetails"],
  },
  {
    title: "Policies",
    iconStyle: imagepaths.shieldIcon,
    iconActive: imagepaths.shieldIconActive,
    to: "/app/connectorhome",
    id: ["dataPrivacyModal", "dataStorageModal", "rbipolicy"],
    classChange: "mm-collapse",
    content: [
      {
        title: "Data Privacy Policy",
        iconStyle: imagepaths.PrivacyPolicy,
        iconActive: imagepaths.PrivacyPolicyActive,
        to: "/app/connectorhome",
        classChange: "menu-popup",
        id: "dataPrivacyModal",
        showTitle: true
      },
      {
        title: "Data Storage Policy",
        iconStyle: imagepaths.StoragePolicy,
        iconActive: imagepaths.StoragePolicyActive,
        to: "/app/connectorhome",
        classChange: "menu-popup",
        id: "dataStorageModal",
      },
      {
        title: "RBI Policy",
        iconStyle: imagepaths.RBIPolicy,
        iconActive: imagepaths.RBIPolicyActive,
        id: "rbipolicy",
        classChange: "menu-link",
      },
    ],
  },
];
