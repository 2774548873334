import React, { useEffect, useState } from "react";
import MainPagetitle from "../../../layouts/MainPagetitle";
import { Nav, Tab } from "react-bootstrap";
import Features from "./Features/Features";
import Eligibility from "./Eligibility/Eligibility";
import DocsRequired from "./DocsRequired/DocsRequired";
import PointOfContact from "./PointOfContact/PointOfContact";
import UploadedDocs from "./PointOfContact/UploadedDocs";

const AddBankOffer = (props) => {
  const isEditMode = props?.urlparams?.params?.bankId?.length > 0;
  const [search, setSearch] = useState({
    page: 1,
  });
  const [showtabs, setShowtabs] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);
  const [banknames, setBanknames] = useState([]);
  const [activeKey, setActiveKey] = useState("features");
  const tabData = [
    { key: "features", name: "Features & Benefits" },
    { key: "eligibility", name: "Eligibility Criteria" },
    { key: "docsrequired", name: "Documents Required" },
    { key: "pointofcontact", name: "Point Of Contact" },
    { key: "uploadeddocs", name: "Uploaded Doc" },
  ];

  const handleSelect = (key) => {
    setActiveKey(key);
  };

  const handleBankNameChange = (record) => {
    console.log({ record });
    setShowtabs(true);
    setSelectedBank(record[0]);
  };

  const changeTab = (e, key) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveKey(key);
  };

  const getBankNames = () => {
    let payload = {
      ...search,
    };
    props.apis.apiGetBanknames(payload).then(({ data }) => {
      if (data) {
        let bankdata = data;
        // filter banks that already exist in table data
        props.apis.apiGetBankOffers(payload).then(({ data, total }) => {
          let filteredArray = bankdata.filter(
            (obj1) => !data.some((obj2) => obj1.bankId === obj2.bankId)
          );
          setBanknames(filteredArray.options("bankId", "bankName"));
        });
      }
    });
  };

  const getBreadCums = () => {
    let pagetitle = isEditMode ? props?.urlparams?.params?.bankName : "Add New";
    return pagetitle;
  };

  const getBackLink = () => {
    return "/app/masterdata?activetab=bankwiseoffer";
  };

  useEffect(() => {
    getBankNames();
    console.log(isEditMode);
    if (isEditMode) {
      setShowtabs(true);
    }
  }, []);

  return (
    <>
      <MainPagetitle
        mainTitle="Master Data | Bank Wise Offers"
        pageTitle={"Master Data"}
        parentTitle={"Home"}
        back={getBreadCums()}
        backLink={getBackLink()}
        pageIcon={imagepaths.MasterDataIconActive}
      />
      <div className="container-fluid">
        <div className="card p-4 h-100">
          <div className="row mb-4">
            <div className="col-12 col-md-3">
              {isEditMode ? (
                <>
                  <AppInput
                    name="bankname"
                    label="Bank Name"
                    type="text"
                    defaultValue={props?.urlparams?.params?.bankName}
                    readOnly={true}
                  ></AppInput>
                </>
              ) : (
                <AppInput
                  name="bankname"
                  label="Bank Name"
                  type="select"
                  options={banknames}
                  onChange={(...args) => handleBankNameChange(...args)}
                  required
                ></AppInput>
              )}
            </div>
          </div>
          {/* <!-- Nav tabs --> */}
          <div
            className={Object.className({
              "custom-tab-1": true,
              "d-none": !showtabs,
            })}
          >
            <Tab.Container
              defaultActiveKey={activeKey}
              activeKey={activeKey}
              onSelect={handleSelect}
            >
              <Nav as="ul" className="nav-tabs border-0">
                {tabData.map((data, i) => (
                  <Nav.Item as="li" key={i}>
                    <Nav.Link
                      eventKey={data.key}
                      className="text-dark bg-transparent"
                    >
                      {data.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content className="">
                {activeKey === "features" && (
                  <Tab.Pane eventKey="features">
                    <Features
                      changeTab={(...args) => changeTab(...args)}
                      selectedBank={selectedBank}
                    />
                  </Tab.Pane>
                )}
                {activeKey === "eligibility" && (
                  <Tab.Pane eventKey="eligibility">
                    <Eligibility
                      changeTab={(...args) => changeTab(...args)}
                      selectedBank={selectedBank}
                    />
                  </Tab.Pane>
                )}
                {activeKey === "docsrequired" && (
                  <Tab.Pane eventKey="docsrequired">
                    <DocsRequired
                      changeTab={(...args) => changeTab(...args)}
                      selectedBank={selectedBank}
                    />
                  </Tab.Pane>
                )}
                {activeKey === "pointofcontact" && (
                  <Tab.Pane eventKey="pointofcontact">
                    <PointOfContact
                      changeTab={(...args) => changeTab(...args)}
                      selectedBank={selectedBank}
                    />
                  </Tab.Pane>
                )}
                {activeKey === "uploadeddocs" && (
                  <Tab.Pane eventKey="uploadeddocs">
                    <UploadedDocs
                      changeTab={(...args) => changeTab(...args)}
                      selectedBank={selectedBank}
                    />
                  </Tab.Pane>
                )}
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default constore(AddBankOffer);
