export default {
    GET_ADMIN_PAYOUTS: {
        url: "/payout/admin",
        method: "get",
    },
    SEND_ADMIN_LEADS_FOR_PAYMENT: {
        url: "/payout/admin",
        method: "put",
    },
    ADMIN_CHANGE_PAYMENT_STATUS_TO_PROCESSED: {
        url: "/payout/processed",
        method: "put",
    },
    GET_CONNECTOR_PAYOUTS: {
        url: "/payout/connector",
        method: "get",
    },
    SEND_CONNECTOR_LEADS_FOR_PAYMENT: {
        url: "/payout/connector",
        method: "put",
    },
    DOWNLOAD_CONNECTOR_PAYOUT: {
        url: "/payout/admin/download",
        method: "get",
    },

    GET_UPLOADED_BANK_PAYOUTS: {
        url: "/bank-payout/upload",
        method: "get",
    },

    MANUAL_TRIGGER_TO_MATCH_RECORDS: {
        url: "/bank-payout/trigger",
        method: "post",
    },
    
    GET_PREVIOUSLY_RUNNED_BANK_PAYOUT_JOBS: {
        url: "/bank-payout/jobs",
        method: "get",
    },

    // BULK FILES
    BULK_UPLOAD_BANK_PAYOUT: {
        url: "/bank-payout/upload",
        method: "post",
    },
    DOWNLOAD_BANK_PAYOUT_TEMPLATE: {
        url: "/bank-payout/template",
        method: "get",
    },

    // PAYMENT DETAILS
    GET_PAYMENT_INFO: {
        url: "/payment-info/connector",
        method: "get",
    },
    GET_PAYMENT_INFO_BY_ID: {
        url: "/payment-info/lead/{digiRepPaidAppId}",
        method: "get",
    },
    GET_PAYOUT_DASHBOARD: {
        url: "/payout/dashboard",
        method: "get",
    },
    DOWNLOAD_LEADS_PAYMENT: {
        url: "/payment-info/admin/download",
        method: "get",
    },
}