import React, { useState } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import MainPagetitle from "../../../layouts/MainPagetitle";
import ConfigurationPage from "./ConfigurationPage/ConfigurationPage";
import Utilization from "./Utilization/Utilization";
import Kyc from "../KYC/kyc";
import MyRequest from "../KYC/TransactionDetails/MyRequest/MyRequest";

const ValueAddedServices = () => {
  const [activeKey, setActiveKey] = useState("kyc");
  const tabData = [
    {
      key: "kyc",
      name: "KYC",
    },
    {
      key: "request",
      name: "Request",
    },
    {
      key: "utilization",
      name: "Utilization",
    },
    {
      key: "configuration",
      name: "Configuration",
    },
  ];

  const handleSelect = (key) => {
    setActiveKey(key);
  };

  const getBackLink = () => {
    return "/app/valueaddedservices";
  };
  return (
    <>
      <MainPagetitle
        mainTitle={"Value Added Services"}
        backLink={getBackLink()}
        pageIcon={imagepaths.DashboardActive}
      />

      <div className="container-fluid">
        <Tab.Container>
          <Card name="custom-tab" className="dz-card min-h-[600px]">
            <Tab.Content>
              <Card.Body>
                {/* <!-- Nav tabs --> */}
                <div className="custom-tab-1">
                  <Tab.Container
                    defaultActiveKey={activeKey}
                    activeKey={activeKey}
                    onSelect={handleSelect}
                  >
                    <Nav as="ul" className="nav-tabs">
                      {tabData.map((data, i) => (
                        <Nav.Item as="li" key={i}>
                          <Nav.Link eventKey={data.key}>{data.name}</Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                    <Tab.Content className="pt-4">
                      {activeKey === "kyc" && (
                        <Tab.Pane eventKey="kyc">
                          <Kyc />
                        </Tab.Pane>
                      )}
                      {activeKey === "request" && (
                        <Tab.Pane eventKey="request">
                          <MyRequest />
                        </Tab.Pane>
                      )}
                      {activeKey === "utilization" && (
                        <Tab.Pane eventKey="utilization">
                          <Utilization />
                        </Tab.Pane>
                      )}
                      {activeKey === "configuration" && (
                        <Tab.Pane eventKey="configuration">
                          <ConfigurationPage />
                        </Tab.Pane>
                      )}
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </Card.Body>
            </Tab.Content>
          </Card>
        </Tab.Container>
      </div>
    </>
  );
};

export default constore(ValueAddedServices);
