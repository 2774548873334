import React, { Component } from "react";

export class AppMonthPicker extends Component {
  constructor(props) {
    super(props);
    this.appinput = React.createRef();
    this.state = {
      attrs: this.attrs,
      focus: false,
      placeholder: {
        month: "Select Month",
      },
      ...this.setModelDate(),
      days: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
      months: Object.entries([
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ]).map(([key, value]) => ({ key, value })),
    };
  }

  get date() {
    let { year, month, dd, hh, mm, ss } = this.state;
    return new Date(year, month, dd, hh, mm, ss) || new Date();
  }

  get calender() {
    return this.date.calender();
  }

  get classes() {
    return {
      input:
        "mt-1 max-w-full focus:ring focus:outline-none rounded w-full dark:placeholder-gray-400 border-0",
      middle: "h-10 flex justify-center items-center",
      button: "btn add-btn",
    };
  }

  handleOutCLick(e) {
    let flag = mixins.checkParent(
      e.target,
      (p, e) => e == this.appinput.current
    );
    if (!flag) {
      this.setState({
        focus: false,
      });
    }
  }

  componentDidMount() {
    window.ComponentAppDatePicker = this;
    this.outclickmounted = (e) => this.handleOutCLick(e);
    document.addEventListener("click", this.outclickmounted);
  }

  componentWillUnmount() {
    this.outclickmounted &&
      document.removeEventListener("click", this.outclickmounted);
  }

  monthToDate(value) {
    let months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    if (value) {
      let [monthName, year] = value.toString().split("-");
      return new Date(
        year,
        months.findIndex((o) => o.toLowerCase() == monthName.toLowerCase()),
        1
      ).toDate();
    }
    return new Date().toDate();
  }

  setModelDate(value = this.monthToDate(this?.props?.value), set = false) {
    let self = this;
    let datearr = value?.split(" ") || [];
    let [
      year = new Date().getFullYear(),
      month = new Date().getMonth() + 1,
      dd = new Date().getDate(),
    ] = datearr[0]?.split("-") || [];
    let [hh = 0, mm = 0, ss = 0] = datearr[1]?.split(":") || [];
    month = month - 1;
    let obj = { year, month, dd, hh, mm, ss };
    if (set) {
      Object.entries(obj).map(([k, v]) => {
        self[k] = +v;
      });
    }
    return obj;
  }

  get attrs() {
    let { props } = this;
    return Object.except(props, [
      "className",
      "style",
      "labelClass",
      "controlClass",
      "labelpos",
      "children",
      "type",
    ]);
  }

  handelTimeInput(e, name) {
    this.setState({
      [name]: e.target.value,
    });
  }

  setTime() {
    let now = new Date();
    let timestamp = now.toDateTime().split(" ");
    let [yy, mm, dd] = timestamp[0].split("-");
    let [hh, mi, ss] = timestamp[1].split(":");
    yy = yy.parse();
    (mm = mm - 1),
      (dd = dd.parse()),
      (hh = hh.parse()),
      (mi = mi.parse()),
      (ss = ss.parse() + this.ahead);
    this.setDate(new Date(yy, mm, dd, hh, mi, ss));
  }

  setDate(d, set = true) {
    let { time } = this.props;
    let { attrs, year, months } = this.state;
    this.setModelDate(d.toDateTime(), set);
    let month = d.getMonth();
    let firstDate = time ? d.toDateTime() : d.toDate();
    let lastDate = firstDate.date().lastDate();
    console.log({ year, month, date: new Date(year, month, 1) });
    let displayValue = new Date(year, month, 1).getMonthDisplay();
    let value = `${firstDate},${lastDate.toDate()}`;
    this.setState({
      focus: false,
      attrs: {
        ...attrs,
        value: displayValue,
      },
    });
    this.props.onChange &&
      this.props.onChange(value, firstDate, lastDate, displayValue);
  }

  selectMonth(e) {
    this.setState({
      month: Number(e.target.value),
    });
  }

  setYear(e, offset) {
    let { year } = this.state;
    this.setState({ year: year - offset });
  }

  render() {
    let { props, state, classes } = this;
    let { className, disabledDates = () => false, type } = props;
    let { focus, year, months, attrs, placeholder } = state;
    //attrs.defaultValue = attrs.defaultValue || attrs.value || "";
    return (
      <div
        ref={this.appinput}
        className={"AppDateTimePicker outer relative " + className}
        tabIndex={0}
      >
        <input
          className={classes.input}
          type="text"
          readOnly
          {...attrs}
          placeholder={placeholder[type]}
          onFocus={() => this.setState({ focus: true })}
        />
        <>
          {focus && (
            <div className="calender absolute top-full left-0 w-full min-w-max  bg-white dark:bg-gray-800 p-4 z-10 border">
              <div className="flex">
                <div
                  className={
                    classes.middle +
                    " w-10 cursor-pointer text-primary text-2xl"
                  }
                  onClick={(e) => this.setYear(e, 1)}
                >
                  {" "}
                  {"<"}{" "}
                </div>
                <div className={classes.middle + " w-full text-center flex"}>
                  <span className="mx-2 font-bold text-2xl">{year}</span>
                </div>
                <div
                  className={
                    classes.middle +
                    " w-10 cursor-pointer text-primary text-2xl"
                  }
                  onClick={(e) => this.setYear(e, -1)}
                >
                  {">"}
                </div>
              </div>
              <div className="mt-4">
                {months &&
                  months.chunk(4).map((row, i) => (
                    <div key={i} className="flex gap-1">
                      {row &&
                        row.map((d, j) => (
                          <div
                            key={j}
                            className={Object.className({
                              "w-10 h-10 flex justify-center items-center flex-grow": true,
                              "cursor-pointer font-bold hover:bg-primary hover:text-white border rounded ": true,
                              "border !rounded-full":
                                +d.key === new Date().getMonth(),
                              "bg-primary text-white hover:bg-primary":
                                d.key ==
                                new Date(
                                  attrs?.value?.split(",")[0]
                                ).getMonth(),
                              "opacity-25": disabledDates(d),
                            })}
                            onClick={() =>
                              !disabledDates(d) &&
                              this.setDate(
                                (
                                  `${year}-${+d.key + 1}-01` +
                                  " " +
                                  "00:00:00"
                                ).date()
                              )
                            }
                          >
                            <span>{d.value}</span>
                          </div>
                        ))}
                    </div>
                  ))}
              </div>
            </div>
          )}
        </>
      </div>
    );
  }
}

export default AppMonthPicker;
