import React, { useEffect, useState } from "react";
import PendingSearch from "./PendingSearch";
import { Link } from "react-router-dom";
import PayoutModal from "../PayoutModal";
import moment from "moment";

const Pending = (props) => {
  const isAdmin = props?.$store?.user?.role === _enum.ROUTE_PERSONAS.admin;
  const [isFetching, setIsFetching] = useState(true);
  const [search, setSearch] = useState({
    page: 1,
    // limit: 10,
    paymentStatus: isAdmin ? "PENDING,REJECTED" : "PENDING",
  });
  const [rowcount, setRowcount] = useState(0);
  const [data, setData] = useState([]);
  const columns = [
    {
      dataIndex: "connectorName",
      title: "Digi Team Member Name",
      render: (text, record) => {
        return (
          <span className="table-primary-text">{record?.createdBy?.name}</span>
        );
      },
      display: isAdmin ? true : false,
    },
    {
      dataIndex: "applicantName",
      title: "Customer Name",
    },
    {
      dataIndex: "connectorMobileNo",
      title: "Digi Team Member Mobile No.",
      render: (text, record) => {
        return (
          <span className="table-primary-text">
            {record?.createdBy?.mobileNumber}
          </span>
        );
      },
      display: isAdmin ? true : false,
    },
    {
      dataIndex: "applicationNo",
      title: "Application No.",
    },
    {
      dataIndex: "bankName",
      title: "Bank Name",
    },
    {
      dataIndex: "disbursedAmt",
      title: "Disb. Amt.",
      render: (text) => {
        return <>{text?.cur() || "-"}</>;
      },
    },
    {
      dataIndex: "disbursedDate",
      title: "Disb. Date",
      render: (text, record) => {
        if (record?.disbursedDate) {
          let date = new Date(text).dateToDDMMYYYY(new Date(text));
          return date;
        } else {
          return "-";
        }
      },
    },
    {
      dataIndex: "payoutType",
      title: "Payout Type",
      display: isAdmin ? false : true,
      sorter: false,
    },
    {
      dataIndex: "paymentStatus",
      title: "Payment Status",
      render: (text) => {
        return (
          <span
            className={`badge light badge-${
              _enum.STATUS_COLOR_MAP[text.toLowerCase()]
            }`}
          >
            {text?.toTitleCase() || "-"}
          </span>
        );
      },
    },
  ];
  //   PAYOUT MODAL

  const [modalFields, setModalFields] = useState([
    {
      label: "Loan Info:",
      name: "loanInfo",
      type: "textarea",
      customcolspan: 12,
      outerclass: "form-title",
    },
    {
      label: "Digi Team Member Name",
      name: "connectorName",
      type: "text",
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Digi Team Member Mobile No.",
      name: "mobileNo",
      type: "number",
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Customer Name (as per PAN)",
      name: "applicantName",
      type: "text",
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Mobile No.",
      name: "mobileNo",
      type: "number",
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Product Type",
      name: "productType",
      type: "text",
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Bank Name",
      name: "bankName",
      type: "text",
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Loan Amount",
      name: "loanAmt",
      type: "number",
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Loan Status",
      name: "loanStatus",
      type: "text",
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Application No. ",
      name: "applicationNo",
      type: "text",
      placeholder: "Please Enter",
    },
    {
      label: "Application Date ",
      name: "createdAt",
      type: "date",
      disabledDate: (current) => {
        return current && current < moment().startOf("day");
      },
      placeholder: "Please Enter",
    },
    {
      label: "Disb. Amount",
      name: "disbursedAmt",
      type: "number",
      placeholder: "Please Enter",
    },
    {
      label: "Disb. Date",
      name: "disbursedDate",
      type: "date",
      disabledDate: (current) => {
        return current && current < moment().startOf("day");
      },
      placeholder: "Please Enter",
    },
    {
      label: "Payout Type",
      name: "payoutType",
      type: "select",
      options: _enum.PAYOUT_TYPES,
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "LAN",
      name: "lan",
      type: "number",
      placeholder: "Please Enter",
    },
    // PAYOUT INFO FIELDS
    {
      label: "Digi Team Member Payout %",
      name: "digirepPayoutPct",
      type: "number",
      step: ".01",
      placeholder: "Please Enter",
      onChange: (...args) => handleDigirepPayoutPct(...args),
    },
    {
      label: "Digi Team Member Payout Amt.",
      name: "digirepPayoutAmt",
      type: "number",
      step: ".01",
      placeholder: "Please Enter",
    },
    {
      label: "TDS %",
      name: "tdsPct",
      type: "number",
      step: ".01",
      placeholder: "Please Enter",
      onChange: (...args) => handleTDSPct(...args),
    },
    {
      label: "TDS Amt.",
      name: "tdsAmt",
      type: "number",
      step: ".01",
      placeholder: "Please Enter",
    },
    {
      label: "Net Payout Amt.",
      name: "netAmt",
      type: "number",
      step: ".01",
      placeholder: "Please Enter",
    },
    {
      label: "Payment Status",
      name: "paymentStatus",
      type: "select",
      onChange: (...args) => handlePaymentStatus(...args),
      options: isAdmin ? _enum.ADMIN_PAYOUT_STATUS : _enum.PAYOUT_STATUS,
      placeholder: "Please Enter",
    },
    {
      label: "Bank Payout %",
      name: "bankPayoutPct",
      type: "number",
      step: ".01",
      placeholder: "Please Enter",
      onChange: (...args) => handleBankPayoutPct(...args),
    },
    {
      label: "Bank Payout Amt.",
      name: "bankPayoutAmt",
      type: "number",
      step: ".01",
      placeholder: "Please Enter",
    },
    {
      label: "Payment Date",
      name: "paymentDate",
      type: "date",
      disabledDate: (current) => {
        return current && current < moment().startOf("day");
      },
      placeholder: "Please Enter",
    },
    {
      label: "Bank Payout Received Date",
      name: "bankPayoutReceiveDate",
      type: "date",
      placeholder: "Please Enter",
    },
    {
      label: "Remarks",
      name: "remarks",
      type: "textarea",
      customcolspan: 12,
    },
    {
      label: "Payout Info:",
      name: "payoutInfo",
      type: "textarea",
      customcolspan: 12,
      outerclass: "form-title my-3",
    },
  ]);
  const [editmode, setEditmode] = useState(false);
  const [viewmode, setViewmode] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [editData, setEditData] = useState({});
  const [popupdata, setPopupdata] = useState({});
  const [popupfields, setPopupfields] = useState({});
  const [disabledFields, setDisabledFields] = useState([]);
  // ----------------------------------------------------------------

  const getData = (params = {}, reset) => {
    let payload = {};
    if (reset) {
      let defaultSearchData = Object.only(search, [
        "page",
        "limit",
        "paymentStatus",
      ]);

      setSearch(defaultSearchData);
      payload = {
        ...defaultSearchData,
      };
      console.log({ defaultSearchData: payload });
    } else {
      payload = {
        ...search,
        ...params,
      };
    }

    if (!isAdmin) {
      props.apis.apiGetConnectorPayouts(payload).then(({ data, count }) => {
        console.log({ apiGetConnectorPayouts: data });
        setData(data);
        setRowcount(count);
        setIsFetching(false);
      });
    } else {
      props.apis.apiGetAdminPayouts(payload).then(({ data, count }) => {
        console.log({ apiGetAdminPayouts: data });
        setData(data);
        setRowcount(count);
        setIsFetching(false);
      });
    }
  };

  const onSearch = (data, reset) => {
    console.log("onSearch on pending", data, reset);
    if (Object.keys(data).length === 0) {
      setSearch({
        ...data,
        ...search,
      });
      getData(data, reset);
    } else {
      setSearch({
        ...data,
        ...search,
      });
      getData(data, reset);
    }
  };
  const onPageChange = (pageData) => {
    // console.log({ onPageChange: pageData });
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const view = (record) => {
    setEditmode(false);
    setViewmode(true);

    let payload = {
      leadId: record.leadId,
    };
    props.apis.apiGetConsolidatedAppsById(payload).then(({ data }) => {
      let loanInfoFields = [
        "loanInfo",
        "applicationNo",
        "applicantName",
        "mobileNo",
        "productType",
        "bankName",
        "loanAmt",
        "loanStatus",
        "disbursedAmt",
        "disbursedDate",
        "payoutType",
        // "application_date",
        "payoutInfo",
      ];

      let editdata = {
        // LOAN INFO DATA --------------------------------
        loanInfo: "",
        digi_team_member_name: data?.createdBy?.name,
        digi_team_member_mobile_no: data?.createdBy?.mobileNumber,
        ...Object.only(data, loanInfoFields),
        // application_date: new Date(data?.applicationDate).dateToDDMMYYYY(
        //   new Date(data?.applicationDate)
        // ),
      };

      if (isAdmin) {
        // PAYOUT INFO DATA --------------------------------
        editdata.payoutInfo = "";
        editdata.digirepPayoutPct = data?.digirepPayoutPct;
        editdata.digirepPayoutAmt = data?.digirepPayoutAmt;
        editdata.tdsPct = data?.tdsPct;
        editdata.tdsAmt = data?.tdsAmt;
        editdata.netAmt = data?.netAmt;
        editdata.paymentDate = data?.paymentDate;
        editdata.paymentStatus = data?.paymentStatus;
        editdata.bankPayoutPct = data?.bankPayoutPct;
        editdata.bankPayoutAmt = data?.bankPayoutAmt;
        editdata.bankPayoutReceiveDate = data?.bankPayoutReceiveDate;
        editdata.remarks = data?.remarks;
      }

      let fields = (modalFields || []).reduce(
        (o, n) => ({ ...o, [n.name]: n }),
        {}
      );
      console.log({
        modalFields,
        editdata,
        fields,
      });
      setPopupfields(fields);

      if (isAdmin) {
        setPopupdata(editdata);
        updatefields("bankPayoutAmt", data?.bankPayoutAmt);
        updatefields("digirepPayoutAmt", data?.digirepPayoutAmt);
        updatefields("tdsAmt", data?.tdsAmt);
      } else {
        setPopupdata(
          Object.except(editdata, [
            "digi_team_member_name",
            "digi_team_member_mobile_no",
          ])
        );
      }
      setEditData(data);
      // ----------------------------------------------------------------
      let disabledFields = [
        "digi_team_member_name",
        "digi_team_member_mobile_no",
        "digirepPayoutAmt",
        "bankPayoutAmt",
        "tdsAmt",
        // "application_date",
        ...modalFields.map((field) => field.name),
      ];
      setDisabledFields(disabledFields);
      openModal();
    });
  };

  const edit = (record) => {
    if (record?.paymentStatus === "PROCESSED") {
      swal2.fire({
        title: "Proessed lead cannot be edited",
        icon: "error",
        iconColor: "#FF4D4D",
        timer: 4000,
      });
    } else if (record?.paymentStatus === "PAID") {
      swal2.fire({
        title: "Paid lead cannot be edited",
        icon: "error",
        iconColor: "#FF4D4D",
        timer: 4000,
      });
    } else {
      setEditmode(true);
      setViewmode(false);

      let payload = {
        leadId: record.leadId,
      };
      props.apis.apiGetConsolidatedAppsById(payload).then(({ data }) => {
        let loanInfoFields = [
          "loanInfo",
          "applicationNo",
          "applicantName",
          "mobileNo",
          "productType",
          "bankName",
          "loanAmt",
          "loanStatus",
          "disbursedAmt",
          "disbursedDate",
          "payoutType",
          // "application_date",
          "payoutInfo",
        ];

        let editdata = {
          // LOAN INFO FIELDS --------------------------------
          loanInfo: "",
          digi_team_member_name: data?.createdBy?.name,
          digi_team_member_mobile_no: data?.createdBy?.mobileNumber,
          ...Object.only(data, loanInfoFields),
          // application_date: new Date(data?.applicationDate).dateToDDMMYYYY(
          //   new Date(data?.applicationDate)
          // ),
        };

        if (isAdmin) {
          // PAYOUT INFO DATA --------------------------------
          editdata.payoutInfo = "";
          editdata.digirepPayoutPct = data?.digirepPayoutPct;
          editdata.digirepPayoutAmt = data?.digirepPayoutAmt;
          editdata.tdsPct = data?.tdsPct;
          editdata.tdsAmt = data?.tdsAmt;
          editdata.netAmt = data?.netAmt;
          editdata.paymentDate = data?.paymentDate;
          editdata.paymentStatus = data?.paymentStatus;
          editdata.bankPayoutPct = data?.bankPayoutPct;
          editdata.bankPayoutAmt = data?.bankPayoutAmt;
          editdata.bankPayoutReceiveDate = data?.bankPayoutReceiveDate;
          editdata.remarks = data?.remarks;
        }

        let fields = (modalFields || []).reduce(
          (o, n) => ({ ...o, [n.name]: n }),
          {}
        );
        console.log({
          modalFields,
          editdata,
          fields,
        });

        setPopupfields(fields);
        setEditData(data);

        if (isAdmin) {
          setPopupdata(editdata);
          updatefields("bankPayoutAmt", data?.bankPayoutAmt);
          updatefields("digirepPayoutAmt", data?.digirepPayoutAmt);
          updatefields("tdsAmt", data?.tdsAmt);
        } else {
          setPopupdata(
            Object.except(editdata, [
              "digi_team_member_name",
              "digi_team_member_mobile_no",
            ])
          );
        }
        // ----------------------------------------------------------------
        let adminDisabledFields = [
          ...loanInfoFields,
          "digi_team_member_name",
          "digi_team_member_mobile_no",
          "digirepPayoutAmt",
          "bankPayoutAmt",
          "tdsAmt",
        ];

        let connectorDisabledFields = loanInfoFields.filter(
          (field) => field !== "payoutType"
        );
        // console.log({ adminDisabledFields, connectorDisabledFields });
        if (isAdmin) {
          setDisabledFields(adminDisabledFields);
        } else {
          setDisabledFields(connectorDisabledFields);
        }
        openModal();
      });
    }
  };

  const updatefields = (field, data) => {
    let fieldElem = modalFields.find((o) => o.name === field);
    // fieldElem.defaultValue = data;
    fieldElem.value = data;
  };
  // ----------------------------------------------------------------
  // PAYOUT INFO CALCULATION
  // 1. Bank Payout Amt = (Bank Payout) % of  Disb. Amount
  // 2. Digirep Payout Amt = (Digi Team Member Payout) % of Disb. Amount
  // 3. Net Payout Amt = Digirep Payout Amt - (TDS) % of  Digirep Payout Amt

  const handleBankPayoutPct = (e) => {
    let bankpct = Number(e.target.value);
    let disbursedAmtField = document.querySelector("[name='disbursedAmt']");

    const amount = disbursedAmtField.value;
    const percent = bankpct;
    const bankPayoutAmnt = (percent / 100) * amount;
    console.log({ amount, percent, bankPayoutAmnt });

    let bankPayoutAmtField = modalFields.find(
      (o) => o.name === "bankPayoutAmt"
    );

    // bankPayoutAmtField.defaultValue = bankPayoutAmnt;
    bankPayoutAmtField.value = bankPayoutAmnt;
    setModalFields([...modalFields]);
  };

  const handleDigirepPayoutPct = (e) => {
    let connectorpct = Number(e.target.value);
    let disbursedAmtField = document.querySelector("[name='disbursedAmt']");

    const amount = disbursedAmtField.value;
    const percent = connectorpct;
    const bankPayoutAmnt = (percent / 100) * amount;
    console.log({ amount, percent, bankPayoutAmnt });

    let digirepPayoutAmtField = modalFields.find(
      (o) => o.name === "digirepPayoutAmt"
    );

    digirepPayoutAmtField.value = bankPayoutAmnt;
    // ----------------------------------------------------------------
    setModalFields([...modalFields]);
  };

  const handleTDSPct = (e) => {
    let tdspct = Number(e.target.value);
    let netPayout, tdsAmount;
    let digirepPayoutAmtElem = document.querySelector(
      "[name='digirepPayoutAmt']"
    );

    let digirepPayoutAmt = Number(digirepPayoutAmtElem.value);

    if (tdspct === 0) {
      netPayout = 0;
      tdsAmount = 0;
    } else {
      tdsAmount = (tdspct / 100) * digirepPayoutAmt;

      netPayout = digirepPayoutAmt - digirepPayoutAmt * (tdspct / 100);
    }

    let netAmtField = modalFields.find((o) => o.name === "netAmt");
    netAmtField.value = netPayout;

    let tdsAmtField = modalFields.find((o) => o.name === "tdsAmt");
    tdsAmtField.value = tdsAmount;

    console.log({ tdspct, digirepPayoutAmt, netPayout, tdsAmount });
    setModalFields([...modalFields]);

    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );

    setPopupfields(fields);
  };
  // ----------------------------------------------------------------

  const handlePaymentStatus = (values) => {
    let value = values[0].key;
    console.log({ value });

    let tfields = [
      "digirepPayoutPct",
      "digirepPayoutAmt",
      "tdsPct",
      "tdsAmt",
      "netAmt",
      "bankPayoutAmt",
      "paymentDate",
      "bankPayoutReceiveDate",
      "bankPayoutAmt",
      "bankPayoutPct",
    ];

    // if (value === "UNPAID") {
    //   tfields.forEach((field) => {
    //     let tfield = modalFields.find((o) => o.name === field);
    //     console.log(tfield);
    //     tfield.required = false;
    //   });
    //   setPayoutinfoFields([...modalFields]);
    // } else {
    //   tfields.forEach((field) => {
    //     let tfield = modalFields.find((o) => o.name === field);
    //     tfield.required = true;
    //   });
    //   setPayoutinfoFields([...modalFields]);
    // }
  };

  const handleSubmit = (e, data, form) => {
    e.preventDefault();
    console.log("formdata: ", data);
    let payload = {};

    if (!isAdmin) {
      payload.payoutType = data?.payoutType;
    } else {
      payload.tdsPct = Number(data?.tdsPct);
      payload.tdsAmt = Number(data?.tdsAmt);
      payload.netAmt = Number(data?.netAmt);
      payload.digirepPayoutPct = Number(data?.digirepPayoutPct);
      payload.digirepPayoutAmt = Number(data?.digirepPayoutAmt);
      payload.bankPayoutPct = Number(data?.bankPayoutPct);
      payload.bankPayoutAmt = Number(data?.bankPayoutAmt);
      payload.paymentStatus = data?.paymentStatus;
      payload.remarks = data?.remarks;

      if (data?.paymentDate?.length > 0) {
        payload.paymentDate = new Date(data?.paymentDate).toISOString();
      } else {
        payload.paymentDate = null;
      }
      if (data?.bankPayoutReceiveDate?.length > 0) {
        payload.bankPayoutReceiveDate = new Date(
          data?.bankPayoutReceiveDate
        ).toISOString();
      } else {
        payload.bankPayoutReceiveDate = null;
      }
    }

    console.log({ payload });

    props.apis
      .apiUpdateConsolidatedAppById(payload, {
        leadId: editData?.leadId,
      })
      .then((res) => {
        console.log({ apiUpdateConsolidatedAppById: res });
        swal2.fire({
          title: "Lead updated successfully.",
          icon: "success",
          iconColor: "#234C9B",
          timer: 4000,
        });
        closeModal();
        getData();
      });
  };

  const addModalFields = (values) => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    console.log("formdata: ", fields, formdata);
    setPopupfields(fields);
    setPopupdata(formdata);
    setOpenCanvas(true);
  };

  const onModalClose = () => {
    console.log("onModalClose");
    closeModal();
    setPopupfields({});
    setViewmode(false);
    setEditmode(false);
    setEditData({});
    let bankPayoutAmtField = modalFields.find(
      (o) => o.name === "bankPayoutAmt"
    );
    let digirepPayoutAmtField = modalFields.find(
      (o) => o.name === "digirepPayoutAmt"
    );
    let tdsAmtField = modalFields.find((o) => o.name === "tdsAmt");
    digirepPayoutAmtField.defaultValue = "";
    digirepPayoutAmtField.value = "";

    bankPayoutAmtField.defaultValue = "";
    bankPayoutAmtField.value = "";

    tdsAmtField.defaultValue = "";
    tdsAmtField.value = "";

    // let netAmtField = modalFields.find((o) => o.name === "netAmt");
    // netAmtField.defaultValue = "";

    setModalFields([...modalFields]);
    // setEditData2({});
  };
  //   PAYOUT MODAL
  const openModal = () => {
    setOpenCanvas(true);
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };

  // ----------------------------------------------------------------
  // ROW SELECTION
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [notSelected, setNotSelected] = useState(true);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log({ newSelectedRowKeys });
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => {
      if (record?.isBankPayoutMatched === true) {
        return { disabled: false };
      } else {
        return { disabled: true };
      }
    },
  };
  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      console.log("has values");
      setNotSelected(false);
    } else {
      console.log("has no values");
      setNotSelected(true);
    }
  }, [selectedRowKeys]);

  const handlePaymentUpdate = () => {
    let payload = {
      leadIds: [...selectedRowKeys],
    };
    console.log({ handlePaymentUpdate: payload });

    swal2
      .fire({
        title:
          "Are you sure you want to change the payment status of selected leads to processed?",
        icon: "info",
        iconColor: "#234C9B",
        showCancelButton: true,
        confirmButtonColor: "#234C9B",
        cancelButtonColor: "##D6DBE7",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
      .then((result) => {
        if (result.isConfirmed) {
          props.apis
            .apiAdminChangePaymentStatusToProcessed(payload)
            .then((res) => {
              if (res) {
                swal2.fire({
                  title: "Payment Status Updated",
                  text: "Payment status changed to processed",
                  icon: "success",
                  iconColor: "#234C9B",
                  timer: 4000,
                });
                setSelectedRowKeys([]);
                getData();
              }
            });
        }
      });
  };
  // ----------------------------------------------------------------
  const checkLastFiveStatus = (data) => {
    // Sort the data by jobId in descending order
    const sortedData = data.sort((a, b) => b.jobId - a.jobId);

    // Get the last 5 objects
    const lastFiveJobs = sortedData.slice(0, 5);

    // Check if any of the statuses is "WAITING"
    for (let job of lastFiveJobs) {
      if (job.status === "WAITING") {
        return false;
      }
    }

    // If all statuses are "COMPLETED"
    return true;
  };

  const handleManualTrigger = (e) => {
    e.preventDefault();
    let payload = {
      ...search,
    };
    props.apis
      .apiGetPreviouslyRunnedeBankPayoutJobs(payload)
      .then(({ data }) => {
        if (data) {
          const isAllCompleted = checkLastFiveStatus(data);

          if (isAllCompleted) {
            props.apis.apiManualTriggerToMatchRecords().then((res) => {
              console.log({ res });
            });
          } else {
            swal2.fire({
              title: "Please wait untill previous triggered job to complete",
              icon: "warning",
              iconColor: "#234C9B",
              showCancelButton: false,
              showConfirmButton: false,
              timer: 4000,
            });
          }
        }
      });
  };
  // ----------------------------------------------------------------
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      {isAdmin && (
        <div className="bulk_download_btn_outer">
          <button
            type="button"
            className="btn btn-primary btn-sm ms-1"
            onClick={(...args) => handleManualTrigger(...args)}
          >
            <span className="text-white mb-0">Manual Sync</span>
          </button>
        </div>
      )}
      <PendingSearch onSearch={(...arg) => onSearch(...arg)} />
      <AppTable
        data={data}
        columns={columns}
        onChange={(...arg) => onPageChange(...arg)}
        total={rowcount}
        reorder={true}
        editAction={(e) => edit(e)}
        viewAction={(e) => view(e)}
        deletable={false}
        loading={isFetching}
        {...(isAdmin
          ? {
              rowSelection: rowSelection,
              checkbox: true,
            }
          : {})}
      ></AppTable>
      <div
        className={Object.className({
          "row bottom_container": true,
          "d-none": !isAdmin,
        })}
      >
        <div className="col-12 text-end">
          <button
            className={Object.className({
              "btn btn-primary m-4": true,
            })}
            onClick={(e) => handlePaymentUpdate(e)}
            disabled={notSelected}
          >
            Send to Processed
          </button>
        </div>
      </div>

      <PayoutModal
        id="payoutModal"
        title={`${editmode ? "Edit Lead" : viewmode ? "View Lead" : "Add"}`}
        show={openCanvas}
        onClose={() => onModalClose()}
        data={popupdata}
        // editdata={editData}
        fields={popupfields}
        editmode={editmode}
        viewmode={viewmode}
        onSubmit={(...args) => handleSubmit(...args)}
        submitText={`${editmode ? "Update" : "Submit"}`}
        disableallfields={viewmode ? true : false}
        disablefields={disabledFields}
      />
    </>
  );
};

export default constore(Pending);
