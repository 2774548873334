import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const KYCDetails = (props) => {
  const [showAadharModal, setShowAadharModal] = useState(false);
  const [showaddressinfo, setShowaddressinfo] = useState(false);
  const handleAadharVerify = () => {
    setShowAadharModal(true);
  };

  const closeAadharModal = () => {
    setShowAadharModal(false);
  };

  const handleDigilockerLogin = () => {
    setShowaddressinfo(true);
    closeAadharModal();
  };

  return (
    <>
      <div className="row g-5">
        {!showaddressinfo ? (
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
            <div
              className="product_card"
              onClick={(...args) => handleAadharVerify(...args)}
            >
              <div className="body d-flex">
                <img
                  src={imagepaths.PANplaceholderImg}
                  className="img-fluid me-4"
                  alt="aadhar"
                />
                <div className="content">
                  <h4 className="title text-primary fw-bold">
                    Aadhar Verification
                  </h4>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-12">
            <div className="card address_confirmation_box p-4">
              <div className="card_header">Address Confirmation</div>
              <hr />
              <div className="card_body">
                <div>
                  <p className="label">Address</p>
                  <p className="address">
                    House no. 49, Vivekananda colony, Badangpet, Hyderabad
                  </p>
                </div>
                <div className="row">
                  <div className="col-12 col-md-2">
                    <p className="label">PIN Code</p>
                    <p className="value">400705</p>
                  </div>
                  <div className="col-12 col-md-2">
                    <p className="label">City</p>
                    <p className="value">Hyderabad</p>
                  </div>
                  <div className="col-12 col-md-2">
                    <p className="label">State</p>
                    <p className="value">Telangana</p>
                  </div>
                </div>
                <hr />
                <div className="my-4 d-flex flex-column input_box">
                  <div className="input_box_label">
                    <input
                      type="radio"
                      id="html"
                      name="fav_language"
                      value="HTML"
                    />
                    <label for="html">
                      I confirm that the address shown above as per Aadhar card
                      is my current residential address
                    </label>
                  </div>
                  <br />
                  <div className="input_box_label">
                    <input
                      type="radio"
                      id="css"
                      name="fav_language"
                      value="CSS"
                    />
                    <label for="css">
                      Please take my current address as per application form
                    </label>
                    <br />
                  </div>
                  <label
                    htmlFor="acknowledgement"
                    className="cursor-pointer mt-4"
                  >
                    <input
                      className="form-check-input mt-0 me-2"
                      type="checkbox"
                      name="acknowledgement"
                    />
                    I hereby declare that all the information voluntarily
                    furnished by me is true, correct & complete.
                  </label>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6"></div>
                  <div className="col-12 col-md-6 mb-3 text-end d-flex justify-content-end">
                    <button
                      type="button"
                      className="formdata-cancel-btn btn me-2"
                      onClick={(...args) => props.onHandleStep(...args, 1)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary submitBtn"
                      onClick={() => props.kycDetailsSubmit}
                    >
                      Continue & Preview
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* AADHAR MODAL */}
      <Modal
        show={showAadharModal}
        onHide={closeAadharModal}
        centered
        size="lg"
        backdrop="static"
        className="consent_otp_modal modal-lg"
      >
        <Modal.Header closeButton className="border-0 py-4"></Modal.Header>
        <Modal.Body className="pt-0 kyc_container">
          <h2 className="mb-4">Aadhar Verification</h2>
          <p className="sec_title">Instruction to follow:</p>
          <ol>
            <li>
              Click on the below
              <span className="text-primary mx-2">Login to Digilocker</span>
              button.
            </li>
            <li>
              It will take you a new tab to enter your 12 digit Aadhar card
              number.
            </li>
            <li>
              Click <span className="text-primary">Next</span> and provide the
              OTP from the Aadhar card owner's phone.
            </li>
            <li>
              After clicking <span className="text-primary mx-1">Continue</span>
              button, accept <span className="text-primary">Signzy</span> access
              to <span className="text-primary mx-1">DigiLocker</span>
              by clicking on <span className="text-primary">Allow</span> button
            </li>
          </ol>
          <div className="my-4">
            <label htmlFor="acknowledgement" className="cursor-pointer">
              <input
                className="form-check-input mt-0 me-2"
                type="checkbox"
                name="acknowledgement"
              />
              I hereby understand the above instructions and wiling to use this
              service.
            </label>
          </div>
          <div className="col-6 mx-auto">
            <button
              type="submit"
              className="btn btn-sm btn-primary w-100 py-3 mb-4"
              onClick={(...args) => handleDigilockerLogin(...args)}
            >
              Login to Digilocker
            </button>
            <button
              type="submit"
              className="btn btn-sm btn-outline-primary w-100 py-3"
              onClick={closeAadharModal}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default constore(KYCDetails);
