import React, { useEffect, useState } from "react";
import MainPagetitle from "../../../layouts/MainPagetitle";
import { Card, Modal, Nav, Tab } from "react-bootstrap";
import OTPInput from "react-otp-input";
import TransactionTable from "./TransactionTable";
import { Link } from "react-router-dom";

const kyc = (props) => {
  const isAdmin = props?.$store?.user?.role === _enum.ROUTE_PERSONAS.admin;
  const [showPanModal, setShowPanModal] = useState(false);
  const [pandetails, setPandetails] = useState(null);
  const [showPanDetails, setShowPanDetails] = useState(false);
  const [showAadharModal, setShowAadharModal] = useState(false);
  const [showotpinput, setShowotpinput] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [reqid, setReqid] = useState(null);
  const [aadharinfo, setAadharinfo] = useState(null);
  const [showaadharinfo, setShowaadharinfo] = useState(false);
  const [isOtpDisabled, setIsOtpDisabled] = useState(true);
  const [isAadharDisabled, setIsAadharDisabled] = useState(true);

  // RECHARGE MODAL
  const [showrechargemodal, setShowrechargemodal] = useState(false);
  const [showCustomAmountInput, setShowCustomAmountInput] = useState(false);
  const [userbalance, setUserbalance] = useState(null);

  // TRANSACTION MODAL
  const [showtransmodal, setShowtransmodal] = useState(false);
  const [activeKey, setActiveKey] = useState("this-month");
  const tabData = [
    {
      key: "this-month",
      name: "This Month",
      content: <TransactionTable />,
    },
    {
      key: "last-three-months",
      name: "Last 3 Months",
      content: <TransactionTable />,
    },
    {
      key: "last-six-months",
      name: "Last 6 Months",
      content: <TransactionTable />,
    },
    {
      key: "custom",
      name: "Custom",
      content: <TransactionTable />,
    },
  ];
  const handleSelect = (key) => {
    setActiveKey(key);
  };
  const handleKycList = (item) => {
    console.log({ item });
    if (item.type === "modal") {
      openModal(item?.modalname);
    }
    if (item.type === "link") {
      navigate("/app/checkeligibility");
    }
  };

  const kyclists = [
    {
      title: "PAN Verification",
      charges: "Rs. 2",
      limit: "Rs. 10",
      image: imagepaths.PANplaceholderImg,
      type: "modal",
      modalname: "pan",
    },
    {
      title: "Aadhar Verification",
      charges: "Rs. 2",
      limit: "Rs. 10",
      image: imagepaths.AadharplaceholderImg,
      type: "modal",
      modalname: "aadhar",
    },
    {
      title: "Bank Statement Analyzer",
      charges: "Rs. 20",
      limit: "Rs. 5",
      image: imagepaths.bankstatementplaceholderImg,
      type: "modal",
      modalname: "bank-statement",
    },
    {
      title: "Credit Score",
      charges: "Coming Soon",
      limit: "Coming Soon",
      image: imagepaths.creditplaceholderImg,
      type: "modal",
      modalname: "credit-score",
    },
    {
      title: "Check Eligibility",
      charges: "Free",
      limit: "Free",
      image: imagepaths.eligibilityplaceholderImg,
      type: "link",
      modalname: "check-eligibility",
    },
  ];

  const openModal = (modalname) => {
    if (modalname === "pan") {
      setShowPanModal(true);
    }
    if (modalname === "aadhar") {
      setShowAadharModal(true);
    }
  };

  const closePanModal = () => {
    setShowPanModal(false);
    setShowPanDetails(false);
  };

  const closeAadharModal = () => {
    setShowAadharModal(false);
    setShowotpinput(false);
    setOtpValue("");
    setReqid(null);
    setIsAadharDisabled(true);
    setShowaadharinfo(false);
    setAadharinfo(null);
  };

  const handlePanVerify = (e, data) => {
    e.preventDefault();
    e.stopPropagation();
    let payload = {
      pan: data.pan,
    };
    props.apis.apiGetVASPanDetails(payload).then((response) => {
      setPandetails(response);
      setShowPanDetails(true);
    });
  };

  const handlePanSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    closePanModal();
    setShowPanDetails(false);
  };

  // AADHAR
  const handleAadharVerify = (e, data) => {
    e.preventDefault();
    e.stopPropagation();
    let payload = {
      aadhaarNumber: data.aadhaarNumber,
    };
    props.apis.apiGetVASAadharInfo(payload).then((response) => {
      let { requestId } = response?.data;
      setReqid(requestId);
      setShowotpinput(true);
    });
  };

  const handleOTPChange = (otp) => {
    // setOTP(otp);
    setOtpValue(otp);
    if (otp.length === 6) {
      setIsOtpDisabled(false);
    } else {
      setIsOtpDisabled(true);
    }
  };

  const handleOtpVerify = (e) => {
    let payload = {
      requestId: reqid,
      otp: otpValue,
    };
    props.apis.apiGetVASAadharVerify(payload).then((response) => {
      let { aadharInfo } = response;
      setAadharinfo(aadharInfo);
      setShowaadharinfo(true);
      setIsAadharDisabled(false);
    });
  };

  const resendOTP = () => {};

  const handleAadharSubmit = (e) => {
    closeAadharModal();
  };

  // RECHARGE MODAL
  const closeRechargeModal = () => {
    setShowrechargemodal(false);
  };

  const handleRechargeAmount = (e) => {
    let key = e[0].key;
    if (key === "others") {
      setShowCustomAmountInput(true);
    } else {
      setShowCustomAmountInput(false);
    }
  };

  const handleRechargeSubmit = (e, data) => {
    e.preventDefault();
    let obj = {
      ...data,
    };
    let payload = {
      profileId: props?.$store?.user?.profileId,
      ...Object.only(obj, ["amount"]),
    };

    if (data?.amount === "others") {
      payload.amount = Number(data.custom_amount);
    } else {
      payload.amount = Number(data.amount);
    }
    if (props?.$store?.user?.role === _enum.ROUTE_PERSONAS.admin) {
      props.apis.apiVasRecharge(payload).then((res) => {
        closeRechargeModal();
        getUserBalance();
      });
    } else {
      let cpayload = {
        ...Object.only(payload, "amount"),
      };
      console.log({ cpayload });

      props.apis.apiConnectorRequestRecharge(cpayload).then((res) => {
        closeRechargeModal();
        getUserBalance();
      });
    }
  };

  // TRANSACTION MODAL
  const closeTransModal = () => {
    setShowtransmodal(false);
  };

  const getUserBalance = () => {
    props.apis.apiGetUserBalance().then((res) => {
      setUserbalance(res);
    });
  };

  const getBackLink = () => {
    return "/app/kyc";
  };

  useEffect(() => {
    if (showrechargemodal) {
      if (props?.$store?.user?.role !== _enum.ROUTE_PERSONAS.admin) {
        setTimeout(() => {
          let rEmailInput = document.querySelector("[name='email']");
          let rPhoneInput = document.querySelector("[name='phone']");
          console.log(rEmailInput, rPhoneInput);

          if (rEmailInput && rPhoneInput) {
            props.apis.apiGetMyInfo().then((res) => {
              if (res) {
                rEmailInput.defaultValue = res?.emailAddress;
                rPhoneInput.defaultValue = res?.mobileNumber;

                rEmailInput.disabled = true;
                rPhoneInput.disabled = true;
              }
            });
          }
        }, 100);
      }
    }
  }, [showrechargemodal]);

  useEffect(() => {
    getUserBalance();
  }, []);

  return (
    <>
      {!isAdmin && (
        <MainPagetitle
          mainTitle="KYC"
          backLink={getBackLink()}
          pageIcon={imagepaths.PersonSearchIconActive}
        />
      )}
      <div
        className={Object.className({
          "container-fluid": !isAdmin,
        })}
      >
        <div
          className={Object.className({
            kyc_container: true,
            admin: isAdmin,
          })}
        >
          {!isAdmin && <h2 className="heading-title">Know Your Customer</h2>}
          <div className="row g-4">
            <div className="col-12 col-md-12 col-lg-6">
              <div className="metrics_card">
                <div className="col_left">
                  <img src={imagepaths.walletWhiteIcon} alt="card icon" />
                </div>
                <div className="col_right">
                  <h5 className="card-title">
                    Rs. {userbalance?.balAmount?.cur()}
                  </h5>
                  <div className="bottom_content">
                    <p className="card-text">
                      <span>Balance</span>
                    </p>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-primary white_btn ms-3"
                  onClick={() => setShowrechargemodal(true)}
                >
                  Recharge Your Wallet
                </button>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-6">
              <div className="metrics_card">
                <div className="col_left">
                  <img src={imagepaths.transactionWhiteIcon} alt="card icon" />
                </div>
                <div className="col_right">
                  <h5 className="card-title">Transactions</h5>
                  <div className="bottom_content">
                    <p className="card-text">
                      <span>Details</span>
                    </p>
                  </div>
                </div>
                <Link
                  to={`/app/transactiondetails?frompath=${
                    isAdmin ? "vas" : "kyc"
                  }`}
                  className="btn btn-primary white_btn ms-3"
                  // onClick={() => setShowtransmodal(true)}
                >
                  View Details
                </Link>
              </div>
            </div>
          </div>
          <div className="row g-3 py-4">
            {kyclists?.map((item, i) => {
              return (
                <div
                  className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3"
                  key={i}
                >
                  <div
                    className="offline_card"
                    onClick={() => handleKycList(item)}
                  >
                    <div className="body d-flex">
                      <img
                        src={item.image}
                        className="img-fluid me-3"
                        alt={item.title}
                      />
                      <div>
                        <h4 className="title d-flex align-items-center">
                          {item.title}
                        </h4>
                        <p className="m-0">Charge: {item.charges}</p>
                        <p className="m-0">Free Limit: {item.limit}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* PAN MODAL */}
      <Modal
        show={showPanModal}
        onHide={closePanModal}
        centered
        backdrop="static"
        className="consent_otp_modal"
      >
        <Modal.Header
          closeButton
          className="border-0 py-4 d-flex align-item-start"
        >
          <h2>PAN Verification</h2>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="row g-3 mb-4">
            {!showPanDetails ? (
              <AppForm onSubmit={handlePanVerify}>
                <AppInput type="text" name="pan" label="Enter PAN No." />
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary w-100 py-3 mb-3"
                  >
                    Verify
                  </button>
                  <button
                    type="button"
                    className="btn text-primary w-100 py-3"
                    onClick={(e) => closePanModal()}
                  >
                    Cancel
                  </button>
                </div>
              </AppForm>
            ) : (
              <div class="profile_details_container">
                <div class="profile_detail mb-3">
                  <p class="m-0">Name as per PAN</p>
                  <h4>{pandetails?.name || "-"}</h4>
                </div>
                {/* <div class="profile_detail mb-3">
                  <p class="m-0">Date of birth as per PAN</p>
                  <h4>{pandetails?.dob || "-"}</h4>
                </div> */}
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary w-100 py-3 mb-3"
                    onClick={(...args) => handlePanSubmit(...args)}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="btn text-primary w-100 py-3"
                    onClick={(e) => closePanModal()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>

      {/* AADHAR MODAL */}
      <Modal
        show={showAadharModal}
        onHide={closeAadharModal}
        centered
        backdrop="static"
        className="consent_otp_modal"
      >
        <Modal.Header
          closeButton
          className="border-0 py-4 d-flex align-item-start"
        >
          <h2>Aadhar Verification</h2>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="row g-3 mb-4">
            <AppForm onSubmit={handleAadharVerify}>
              <AppInput
                type="text"
                name="aadhaarNumber"
                label="Enter Aadhar No."
              />
              <div
                className={Object.className({
                  "col-12": true,
                  "d-none": showotpinput,
                })}
              >
                <button
                  type="submit"
                  className="btn btn-primary w-100 py-3 mb-3"
                >
                  Verify
                </button>
                <button
                  type="button"
                  className="btn text-primary w-100 py-3"
                  onClick={(e) => closeAadharModal()}
                >
                  Cancel
                </button>
              </div>
            </AppForm>
            <div
              className={Object.className({
                "d-none": !showotpinput,
              })}
            >
              {!showaadharinfo ? (
                <>
                  <div className="col-12 d-flex flex-column">
                    <div className="">
                      <h3 className="">OTP to verify</h3>
                      <p className="">We have sent an OTP to your mobile no.</p>
                    </div>
                    <div className="d-flex">
                      <OTPInput
                        value={otpValue}
                        onChange={(e) => handleOTPChange(e)}
                        separator={<span>-</span>}
                        renderInput={(props) => <input {...props} />}
                        numInputs={6}
                        shouldAutoFocus={true}
                        isInputNum={true}
                        containerStyle="otp_container small p-0"
                      />
                      <button
                        type="button"
                        className="btn text-primary"
                        onClick={(...args) => handleOtpVerify(...args)}
                      >
                        Verify
                      </button>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="text-start otp_bottom">
                      <span className="me-2">Not received OTP?</span>
                      <button
                        className="btn px-0 text-primary fs-5 border-0"
                        disabled={isOtpDisabled}
                        onClick={(e) => resendOTP(e)}
                      >
                        Resend
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="aadhar_info_Card">
                    <ul>
                      <li>
                        <span>Name:</span> {aadharinfo?.full_name || "-"}
                      </li>
                      <li>
                        <span>Date of Birth:</span> {aadharinfo?.dob || "-"}
                      </li>
                    </ul>
                  </div>
                </>
              )}
              <div className="col-12">
                <button
                  type="button"
                  className="btn btn-primary w-100 py-3 mb-3"
                  disabled={isAadharDisabled}
                  onClick={(...args) => handleAadharSubmit(...args)}
                >
                  Submit
                </button>
                <button
                  type="submit"
                  className="btn text-primary w-100 py-3"
                  onClick={(e) => closeAadharModal()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* RECHARGE MODAL */}
      <Modal
        show={showrechargemodal}
        onHide={closeRechargeModal}
        centered
        backdrop="static"
        className="consent_otp_modal"
      >
        <Modal.Header
          closeButton
          className="border-0 py-4 d-flex align-item-start"
        >
          <h2>Send Request</h2>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="row g-3 mb-4">
            <AppForm onSubmit={handleRechargeSubmit}>
              <AppInput type="email" name="email" label="Email Address" />
              <AppInput type="number" name="phone" label="Phone Number" />
              <AppInput
                type="select"
                name="amount"
                label="Recharge Amount"
                options={_enum.RECHARGE_AMOUNTS}
                onChange={(...args) => handleRechargeAmount(...args)}
                required={true}
              />
              {showCustomAmountInput && (
                <AppInput
                  type="number"
                  name="custom_amount"
                  label="Enter Amount"
                  required={true}
                />
              )}
              <div
                className={Object.className({
                  "col-12 mt-3": true,
                })}
              >
                <button
                  type="submit"
                  className="btn btn-primary w-100 py-3 mb-3"
                >
                  Send Request
                </button>
                <button
                  type="button"
                  className="btn text-primary w-100 py-3"
                  onClick={(e) => closeRechargeModal()}
                >
                  Cancel
                </button>
              </div>
            </AppForm>
          </div>
        </Modal.Body>
      </Modal>

      {/* TRANSACTION MODAL */}
      <Modal
        show={showtransmodal}
        onHide={closeTransModal}
        centered
        backdrop="static"
        className="consent_otp_modal modal-lg"
        size="xl"
      >
        <Modal.Header
          closeButton
          className="border-0 py-4 d-flex align-item-start"
        >
          <h2>Transaction Details</h2>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Tab.Container>
            <Card name="custom-tab" className="dz-card border-0 shadow-none">
              <Tab.Content>
                <Card.Body>
                  {/* <!-- Nav tabs --> */}
                  <div className="custom-tab-1">
                    <Tab.Container
                      defaultActiveKey={activeKey}
                      activeKey={activeKey}
                      onSelect={handleSelect}
                    >
                      <Nav as="ul" className="nav-tabs">
                        {tabData.map((data, i) => (
                          <Nav.Item as="li" key={i}>
                            <Nav.Link eventKey={data.key}>{data.name}</Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                      <Tab.Content className="pt-4">
                        {activeKey === "this-month" && (
                          <Tab.Pane eventKey="this-month">
                            <TransactionTable keyvalue="this-month" />
                          </Tab.Pane>
                        )}

                        {activeKey === "last-three-months" && (
                          <Tab.Pane eventKey="last-three-months">
                            <TransactionTable keyvalue="last-three-months" />
                          </Tab.Pane>
                        )}

                        {activeKey === "last-six-months" && (
                          <Tab.Pane eventKey="last-six-months">
                            <TransactionTable keyvalue="last-six-months" />
                          </Tab.Pane>
                        )}

                        {activeKey === "custom" && (
                          <Tab.Pane eventKey="custom">
                            <TransactionTable keyvalue="custom" />
                          </Tab.Pane>
                        )}
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </Card.Body>
              </Tab.Content>
            </Card>
          </Tab.Container>
          <div className="row g-3 mb-4">
            <div
              className={Object.className({
                "col-12 mt-3 text-end": true,
              })}
            >
              <button
                type="button"
                className="btn btn-sm btn-primary py-3 px-5 fs-3"
                onClick={(e) => closeTransModal(e)}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default constore(kyc);
