import React, { Component } from "react";
import { Link } from "react-router-dom";

export class OfflineSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        page: 1,
      },
    };
    this.fields = [
      {
        name: "customer_name",
        label: "Customer Name (as per PAN)",
        placeholder: "Customer Name",
      },
      {
        name: "mobile_num",
        label: "Mobile No.",
        placeholder: "Mobile No.",
      },
      // {
      //   name: "loan_status",
      //   label: "Loan Status",
      //   type: "select",
      //   options: _enum.LOAN_STATUS,
      //   placeholder: "Loan Status",
      // },
      {
        name: "bank_name",
        label: "Bank Name",
        type: "select",
        options: [],
        placeholder: "Bank Name",
      },
    ];

    this.isPanVerified = this.props.$store?.user?.isPanVerified;
    this.isAadharVerified = this.props.$store?.user?.isAadharVerified;
    this.isAgreementSigned = this.props.$store?.user?.isAgreementSigned;
  }

  componentDidMount() {
    this.getBankNames();

    const adminPersona =
      this.props?.$store?.user?.role === _enum.ROUTE_PERSONAS.admin;

    if (!adminPersona) {
      console.log(
        this.isPanVerified,
        this.isAadharVerified,
        this.isAgreementSigned
      );

      if (
        !this.isPanVerified ||
        !this.isAadharVerified ||
        !this.isAgreementSigned
      ) {
        swal2.fire({
          title: "Account Verification Pending",
          text: "Please complete your profile to add lead",
          icon: "info",
          iconColor: "#234C9B",
          timer: 6000,
        });
      }
    }
  }

  onSearch = (reset = false) => {
    let data = this.refs.form.form.current.getData();
    console.log("onSearch formData", data, reset);
    if (reset) {
      data = {
        ...this.state.search,
      };
    } else {
      data = Object.filter(data, (val) => val);
      // console.log("data on search", data);
    }
    this.props.onSearch && this.props.onSearch(data, reset);
  };

  getBankNames = () => {
    let payload = {
      ...this.state.search,
    };
    this.props.apis.apiGetBanknames(payload).then(({ data, count }) => {
      console.log({ apiGetBanknames: data });
      let textField = this.fields;
      let bankNameField = textField.find((o) => o.name === "bank_name");
      bankNameField.options = data.options("bankName");
      this.setState([...textField]);
    });
  };

  resetSearchForm() {
    this.refs.form.form.current.reset();
    // clear selected option
    let bankBameField = document.querySelector(
      "[name='appinput-bank_name'].form-group"
    );
    let { values: supervisorNames = [] } =
      bankBameField.vnode.refs.select.state;
    if (supervisorNames.length) {
      console.log({ supervisorNames });
      bankBameField.vnode.refs.select.clearAll();
    }
    this.onSearch(true);
  }

  render() {
    const adminPersona =
      this.props?.$store?.user?.role === _enum.ROUTE_PERSONAS.admin;
    return (
      <>
        <AppForm className="filter-row flex-auto mx-auto" ref="form">
          {this.fields.map((field, i) => (
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" key={i}>
              <AppInputFocus ref="inputname" {...field}></AppInputFocus>
            </div>
          ))}
          <div
            className={Object.className({
              "col-3 col-md-1 col-lg-1 col-xl-1 d-flex align-items-start justify-content-center mw-lg-75": true,
              "mw-lg-50": !adminPersona,
            })}
          >
            <button
              className="btn btn-primary search_btn"
              onClick={() => this.onSearch()}
            >
              <i className="fa fa-search"></i>
              <span>Search</span>
            </button>
          </div>
          <div
            className={Object.className({
              "col-5 col-md-1 col-lg-1 col-xl-1 d-flex align-items-start justify-content-center mw-75": true,
              "mw-md-50": !adminPersona,
            })}
          >
            <button
              type="button"
              className="btn btn-primary clear_filter_btn"
              onClick={(...args) => this.resetSearchForm(...args)}
            >
              <i class="fa-solid fa-filter-circle-xmark"></i>
            </button>
          </div>
          {!adminPersona &&
            this.isPanVerified &&
            this.isAadharVerified &&
            this.isAgreementSigned && (
              <div
                className={Object.className({
                  "col-7 col-sm-6 col-lg-4 col-xl-2": true,
                  "d-flex align-items-start justify-content-center":
                    this.isPanVerified &&
                    this.isAadharVerified &&
                    this.isAgreementSigned,
                })}
              >
                {!adminPersona && (
                  <div className="header_box d-flex flex-col align-items-center">
                    <div className="row"></div>
                    <div className="d-flex flex-row-reverse">
                      <Link
                        className="btn btn-primary"
                        onClick={(...args) =>
                          this.props.addModalFields(...args)
                        }
                      >
                        Add Leads
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            )}
        </AppForm>
      </>
    );
  }
}

export default constore(OfflineSearch);
