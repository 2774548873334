import React, { useEffect, useState } from "react";

import { Card, Nav, Tab } from "react-bootstrap";
import MainPagetitle from "../../../layouts/MainPagetitle";
import Banknames from "./banknames/banknames";
import InterestRate from "./interestrate/interestrate";
import ProductType from "./producttype/producttype";
import Roles from "./roles/roles";
import Worklocation from "./worklocation/worklocation";
import Bankwiseoffer from "./bankwiseoffer/bankwiseoffer";

const MasterData = (props) => {
  const [activeKey, setActiveKey] = useState("worklocation");
  const tabData = [
    {
      key: "worklocation",
      name: "Work Location",
    },
    {
      key: "roles",
      name: "Roles",
    },
    {
      key: "banknames",
      name: "Bank Names",
    },
    {
      key: "interestrates",
      name: "Interest Rate",
    },
    {
      key: "producttype",
      name: "Product Type",
    },
    {
      key: "bankwiseoffer",
      name: "Bank Wise Offer",
    },
  ];

  const handleSelect = (key) => {
    console.log(key);
    setActiveKey(key);
    navigate(`/app/masterdata?activetab=${key}`);
  };

  useEffect(() => {
    let paramsActiveTab = props.urlparams.params.activetab;
    if (paramsActiveTab) {
      // console.log(paramsActiveTab);
      setActiveKey(paramsActiveTab);
    } else {
      setActiveKey("worklocation");
      navigate(`/app/masterdata?activetab=worklocation`);
    }
  }, []);

  return (
    <>
      <MainPagetitle
        mainTitle="Master Data"
        pageTitle={"Master Data"}
        parentTitle={"Home"}
        pageIcon={imagepaths.MasterDataIconActive}
      />
      <div className="container-fluid">
        <Tab.Container>
          <Card name="custom-tab" className="dz-card min-h-[600px]">
            <Tab.Content>
              <Card.Body>
                {/* <!-- Nav tabs --> */}
                <div className="custom-tab-1">
                  <Tab.Container
                    defaultActiveKey={activeKey}
                    activeKey={activeKey}
                    onSelect={handleSelect}
                  >
                    <Nav as="ul" className="nav-tabs border-0">
                      {tabData.map((data, i) => (
                        <Nav.Item as="li" key={i}>
                          <Nav.Link eventKey={data.key} className="text-dark">
                            {data.name}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                    <Tab.Content className="pt-4">
                      {activeKey === "worklocation" && (
                        <Tab.Pane eventKey="worklocation">
                          <Worklocation />
                        </Tab.Pane>
                      )}
                      {activeKey === "roles" && (
                        <Tab.Pane eventKey="roles">
                          <Roles />
                        </Tab.Pane>
                      )}
                      {activeKey === "banknames" && (
                        <Tab.Pane eventKey="banknames">
                          <Banknames />
                        </Tab.Pane>
                      )}
                      {activeKey === "interestrates" && (
                        <Tab.Pane eventKey="interestrates">
                          <InterestRate />
                        </Tab.Pane>
                      )}
                      {activeKey === "producttype" && (
                        <Tab.Pane eventKey="producttype">
                          <ProductType />
                        </Tab.Pane>
                      )}
                      {activeKey === "bankwiseoffer" && (
                        <Tab.Pane eventKey="bankwiseoffer">
                          <Bankwiseoffer />
                        </Tab.Pane>
                      )}
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </Card.Body>
            </Tab.Content>
          </Card>
        </Tab.Container>
      </div>
    </>
  );
};

export default constore(MasterData);
