import React, { Component } from "react";
import menu from "../menu";
import Nav from "../../layouts/nav";
import Footer from "../../layouts/Footer";
// import ScrollToTop from "../layouts/ScrollToTop";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.menu = menu;
  }
  componentDidMount() {
    this.setState({ isClient: true });
    this.props.store("mounted", true);
    this.props.services
      .checkAuthorize(this.props, "admin")
      .then(() => {
        this.props.services.checkExpiry();
      })
      .catch((err) => {});
  }
  render() {
    let props = this.props;
    let sideMenu = props.$store.sideMenu;
    return (
      <div
        id="main-wrapper"
        className={`show ${sideMenu ? "menu-toggle" : ""}`}
      >
        <Nav />
        <div
          className="content-body"
          style={{ minHeight: window.screen.height - 45 }}
        >
          {props.children}
        </div>
        <Footer />

        {/* TOP LEVEL LAYOUT MODALS */}

        {/* DATA PRIVACY MODAL */}
        <div
          className="modal fade"
          id="dataPrivacyModal"
          tabIndex="-1"
          aria-labelledby="dataPrivacyModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="dataPrivacyModalLabel">
                  Data Privacy Policy for Intermediaries in Digital Lending
                </h5>
              </div>
              <div className="modal-body">
                <p className="para_heading">Effective Date:</p>
                <ul className="ps-lg-3">
                  <li>
                    <p className="para_heading">1. Introduction</p>
                    <p>
                      M/s Star Powerz Digital Technologies Pvt Ltd is committed
                      to safeguarding the privacy and security of personal data
                      in our role as intermediaries in the digital lending
                      industry. This Data Privacy Policy outlines how we
                      collect, use, disclose, and protect personal data in
                      compliance with relevant data protection laws.
                    </p>
                  </li>
                  <li>
                    <p className="para_heading">2. Definitions </p>
                    <p>
                      Personal Data: Any information that can directly or
                      indirectly identify an individual. Processing: Any
                      operation or set of operations performed on personal data,
                      whether by automated means or not, such as collection,
                      recording, organization, storage, adaptation, and
                      retrieval. Data Subject: An individual to whom personal
                      data pertains.
                    </p>
                  </li>
                  <li>
                    <p className="para_heading">3. Data Collection </p>
                    <ul className="inner-ul">
                      <p className="sub-para_heading">
                        We collect personal data for the following purposes:
                      </p>
                      <li>
                        • Identifying and verifying applicants for digital
                        loans.
                      </li>
                      <li>• Assessing creditworthiness. </li>
                      <li>• Processing loan applications. </li>
                      <li>• Servicing and managing loans. </li>
                      <li>
                        • Complying with legal and regulatory requirements.
                      </li>
                    </ul>
                    <ul className="inner-ul">
                      <p className="sub-para_heading">
                        We may collect the following types of personal data,
                        among others:
                      </p>
                      <li>
                        • Contact information (name, address, email, phone
                        number).
                      </li>
                      <li>
                        • Financial information (income, expenses, credit
                        history).
                      </li>
                      <li>
                        • Identification information (government-issued ID,
                        social security number).
                      </li>
                      <li>• Employment and income details. </li>
                      <li>• Information related to loan transactions. </li>
                    </ul>
                  </li>
                  <li>
                    <p className="para_heading">
                      4. Legal Basis for Processing{" "}
                    </p>
                    <ul className="inner-ul">
                      <p className="sub-para_heading">
                        We process personal data on the following legal bases:
                      </p>
                      <li>
                        • To perform the contract with the data subject (e.g.,
                        processing loan applications).
                      </li>
                      <li>
                        • To comply with legal obligations (e.g., regulatory
                        reporting).
                      </li>
                      <li>
                        • Based on the data subject's consent when required.
                      </li>
                      <li>
                        • For legitimate interests (e.g., fraud prevention, risk
                        assessment).
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p className="para_heading">5. Data Security </p>
                    <ul className="inner-ul">
                      <p className="sub-para_heading">
                        We implement technical and organizational measures to
                        protect personal data from unauthorized access,
                        disclosure, alteration, and destruction. These measures
                        include:
                      </p>
                      <li>• Encryption of data in transit and at rest. </li>
                      <li>• Regular security assessments and audits. </li>
                      <li>• Access controls and authentication. </li>
                      <li>• Employee training on data security. </li>
                    </ul>
                  </li>
                  <li>
                    <p className="para_heading">6. Data Sharing </p>
                    <ul className="inner-ul">
                      <p className="sub-para_heading">
                        We may share personal data with the following parties:
                      </p>
                      <li>• Lending institutions and financial partners. </li>
                      <li>• Credit bureaus and reporting agencies. </li>
                      <li>• Legal and regulatory authorities. </li>
                      <li>
                        • Service providers who assist with loan processing.
                      </li>
                      <p className="sub-para_heading">
                        We ensure that third parties have adequate data
                        protection measures in place.
                      </p>
                    </ul>
                  </li>
                  <li>
                    <p className="para_heading">7. Data Subject Rights </p>
                    <ul className="inner-ul">
                      <p className="sub-para_heading">
                        Data subjects have the following rights:
                      </p>
                      <li>• Right to access and rectify personal data. </li>
                      <li>• Right to erasure (where applicable). </li>
                      <li>• Right to restrict processing. </li>
                      <li>• Right to data portability. </li>
                      <li>• Right to object to processing. </li>
                      <li>
                        • Right not to be subject to automated decision-making.
                      </li>
                      <p className="para_heading">
                        To exercise these rights, please contact our Data
                        Protection Officer (DPO) at [DPO contact details].
                      </p>
                    </ul>
                  </li>
                  <li>
                    <p className="para_heading">8. Data Breach Response</p>
                    <ul className="inner-ul">
                      <li>
                        In the event of a data breach, we will notify affected
                        data subjects and relevant authorities as required by
                        law.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p className="para_heading">
                      9. International Data Transfers{" "}
                    </p>
                    <ul className="inner-ul">
                      <li>
                        If we transfer personal data outside the jurisdiction,
                        we will ensure that adequate safeguards are in place,
                        such as standard contractual clauses or approved binding
                        corporate rules.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p className="para_heading">10. Policy Updates </p>
                    <ul className="inner-ul">
                      <li>
                        We will regularly review and update this policy to stay
                        compliant with data protection regulations. We encourage
                        data subjects to periodically review this policy for any
                        changes.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p className="para_heading">11. Contact Information</p>
                    <ul className="inner-ul">
                      <li>
                        If you have any questions or concerns about this policy,
                        please contact our Data Protection Officer (Gutta
                        Rajesh) at [Rajesh@stardigiloans.com].
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="modal-footer py-2">
                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* DATA STORAGE MODAL */}
        <div
          className="modal fade"
          id="dataStorageModal"
          tabIndex="-1"
          aria-labelledby="dataStorageModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="dataStorageModalLabel">
                  Data Storage Policy
                </h5>
              </div>
              <div className="modal-body footer_modal_content">
                <p className="para_heading">Effective Date:</p>
                <ul className="ps-lg-3">
                  <li>
                    <p className="para_heading">1. Introduction</p>
                    <p>
                      M/s Star Powerz Digital Technologies Pvt Ltd is committed
                      to the responsible and secure storage of personal and
                      financial data. This Data Storage Policy outlines how we
                      store, retain, and protect data in compliance with
                      relevant data protection and industry regulations.
                    </p>
                  </li>
                  <li>
                    <p className="para_heading">2. Data Types and Categories</p>
                    <p>
                      We store various types of data, including but not limited
                      to: Personal information: Names, addresses, contact
                      details. Financial information: Income, expenses, credit
                      history. Identification information: Government-issued
                      IDs, social security numbers. Employment and income
                      details. Loan transaction information.
                    </p>
                  </li>
                  <li>
                    <p className="para_heading">3. Data Storage Principles</p>
                    <ul className="inner-ul">
                      <p className="sub-para_heading">
                        Our data storage practices are guided by the following
                        principles:
                      </p>
                      <li>
                        <b>a. Legal Compliance: </b>
                        <p>
                          We adhere to all applicable data protection laws,
                          regulations, and industry standards. We are committed
                          to maintaining the highest standards of data privacy
                          and security.
                        </p>
                      </li>
                      <li>
                        <b>b. Data Minimization: </b>
                        <p>
                          We store only the data that is necessary for our
                          legitimate purposes, such as loan processing and
                          compliance with legal obligations.
                        </p>
                      </li>
                      <li>
                        <b>c. Retention Period:</b>
                        <p>
                          We retain data for the period required by law and
                          regulatory requirements or as necessary for our
                          legitimate business purposes. We will not retain data
                          longer than needed.
                        </p>
                      </li>
                      <li>
                        <b>d. Data Security: </b>
                        <p>
                          We implement robust technical and organizational
                          measures to protect stored data from unauthorized
                          access, disclosure, alteration, or destruction. These
                          measures include encryption, access controls, and
                          regular security assessments.
                        </p>
                      </li>
                      <li>
                        <b>e. Regular Review: </b>
                        <p>
                          We periodically review and assess our data storage
                          practices to ensure compliance with changing
                          regulations and evolving security threats.
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p className="para_heading">4. Access Control</p>
                    <p>
                      Access to stored data is restricted to authorized
                      personnel who require it for legitimate business purposes.
                      Access controls include user authentication and
                      authorization mechanisms.
                    </p>
                  </li>
                  <li>
                    <p className="para_heading">5. Data Backup</p>
                    <p>
                      We maintain secure and regular data backups to ensure data
                      integrity and availability. Backup procedures include
                      encryption and secure storage.
                    </p>
                  </li>
                  <li>
                    <p className="para_heading">
                      6. Data Retention and Deletion
                    </p>
                    <p>
                      We retain data only for the period required by law or as
                      necessary for legitimate business purposes. When data is
                      no longer required, it is securely deleted or anonymized
                      to protect the data subject's privacy.
                    </p>
                  </li>
                  <li>
                    <p className="para_heading">7. Data Transfer</p>
                    <p>
                      If data is transferred outside the organization, we ensure
                      appropriate safeguards are in place to protect data
                      privacy and security, including encryption and secure
                      transmission.
                    </p>
                  </li>
                  <li>
                    <p className="para_heading">8. Policy Review and Updates</p>
                    <p>
                      This policy will be periodically reviewed and updated to
                      ensure compliance with data protection regulations and
                      industry best practices. Data subjects and stakeholders
                      will be informed of significant policy changes.
                    </p>
                  </li>
                  <li>
                    <p className="para_heading">9. Contact Information</p>
                    <p>
                      If you have questions or concerns about our data storage
                      policy, please contact our Data Protection Officer Gutta
                      Rajesh at Rajesh@stardigiloans.com.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="modal-footer py-2">
                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default constore(Layout);
