import React, { useState } from "react";
import { Step, Stepper } from "react-form-stepper";
import { ToastContainer, toast } from "react-toastify";
import PersonalDetails from "./PersonalDetails";
import OTPInput from "react-otp-input";
import { Modal } from "react-bootstrap";
import IncomeDetails from "./IncomeDetails";
import KYCDetails from "./KYCDetails";
import moment from "moment";
import ReferenceDetails from "./ReferenceDetails";
import DocsUpload from "./DocsUpload";

const Onboarding = (props) => {
  // PERSONAL DETAILS
  const [otpValue, setOtpValue] = useState("");
  const [payload, setPayload] = useState({});
  const [consentUid, setConsentUid] = useState(null);
  const [isOtpDisabled, setIsOtpDisabled] = useState(true);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [showpreview, setShowpreview] = useState(false);
  // FIELDS
  const personalfields = [
    {
      name: "emp_type",
      label: "Employment Type",
      type: "select",
      options: _enum.EMPLOYEE_TYPE,
    },
    {
      name: "PHONE-NUMBER",
      label: "Mobile No.",
      type: "number",
    },
    {
      name: "PAN",
      label: "PAN No.",
      type: "text",
    },
    {
      name: "FIRST-NAME",
      label: "First Name as per PAN",
      type: "text",
    },
    {
      name: "LAST-NAME",
      label: "Last Name as per PAN",
      type: "text",
    },
    {
      name: "GENDER",
      label: "Gender",
      type: "select",
      options: _enum.GENDER,
    },
    {
      name: "DATE-OF-BIRTH",
      label: "DOB",
      type: "date",
    },
    {
      name: "MARITAL-STATUS",
      label: "Marital Status",
      type: "select",
      options: _enum.MARITAL_STATUS,
    },
    {
      name: "EMAIL-ID",
      label: "Personal Email ID",
      type: "email",
    },
    {
      name: "ADDRESS-PIN",
      label: "PIN Code",
      type: "number",
    },
    {
      name: "ADDRESS-CITY",
      label: "City",
      type: "text",
    },
  ];
  const incomefields = [
    {
      name: "company_name",
      label: "Company Name",
      type: "select",
      options: [],
      onSearch: (value) => handleCompanyNameSearch(value),
    },
    {
      name: "monthly_salary",
      label: "Monthly Salary",
      type: "number",
    },
    {
      name: "monthly_obligations",
      label: "Monthly Obligations",
      type: "text",
    },
    {
      name: "total_work_exp",
      label: "Total Work Experience (in months)",
      type: "number",
    },
    {
      name: "email",
      label: "Office Email",
      type: "email",
    },
  ];
  const referencefields = [
    {
      name: "referenceName",
      label: "Reference Name",
      type: "text",
    },
    {
      name: "mobileNumber",
      label: "Mobile no",
      type: "number",
    },
    {
      name: "relationship",
      label: "Relationship",
      type: "select",
      options: [],
    },
    {
      name: "pincode",
      label: "PIN",
      type: "text",
    },
    {
      name: "city",
      label: "City",
      type: "text",
    },
    {
      name: "state",
      label: "State",
      type: "text",
    },
  ];
  const docsfields = [
    {
      name: "documentType",
      label: "Document Type",
      type: "select",
      options: [],
    },
    {
      name: "upload",
      label: "Upload",
      type: "file",
      accept: ".pdf",
    },
  ];

  const addressfields = [
    {
      name: "address",
      label: "Address",
      type: "textarea",
      customcolspan: "12",
    },
    {
      name: "pincode",
      label: "PIN",
      type: "text",
    },
    {
      name: "city",
      label: "City",
      type: "text",
    },
    {
      name: "state",
      label: "State",
      type: "text",
    },
  ];

  const handleCompanyNameSearch = (value) => {
    // console.log(value);
    let payload = {
      // page: 1,
      // limit: 10,
      companyName: value,
    };
    if (value.length >= 3) {
      mixins.debounce(() => {
        props.apis.apiGetMasterCompanies(payload).then(({ data }) => {
          if (data) {
            let companyNameInput = document.querySelector(
              "[name='appinput-company_name'].form-group"
            );
            let companySelect = companyNameInput.vnode.refs.select;
            let { searchResults } = companySelect.state;
            let datawithkey = data?.options("companyName");
            // console.log(datawithkey);
            searchResults = datawithkey;
            companySelect.setState({ searchResults });
          }
        });
      }, 1000);
    }
  };

  const onPersonalDetailsSubmit = (e, data) => {
    e.preventDefault();
    let requiredFormatPayload = {
      "APPLICANT-DATA": {
        "APPLICANT-NAME": {
          "FIRST-NAME": data["FIRST-NAME"],
          "LAST-NAME": data["LAST-NAME"],
        },
        GENDER: data["GENDER"],
        "DATE-OF-BIRTH": data["DATE-OF-BIRTH"].replaceAll("-", ""),
        AGE: moment().diff(moment(data["DATE-OF-BIRTH"]), "years").toString(),
        "MARITAL-STATUS": "Single",
        "ADDRESS-DTLS": [
          {
            "ADDRESS-TYPE": "RESIDENCE",
            "ADDRESS-LINE-1": data["ADDRESS-CITY"],
            "ADDRESS-LINE-2": "",
            "ADDRESS-CITY": data["ADDRESS-CITY"],
            "ADDRESS-PIN": data["ADDRESS-PIN"],
            "ADDRESS-STATE": "",
            "ADDRESS-COUNTRY": "",
          },
        ],
        "PHONE-DTLS": [
          {
            "PHONE-TYPE": "MOBILE PHONE",
            "PHONE-NUMBER": data["PHONE-NUMBER"],
          },
        ],
        "EMAIL-DTLS": [
          {
            "EMAIL-TYPE": "PERSONAL",
            "EMAIL-ID": data["EMAIL-ID"],
          },
        ],
        "ID-DTLS": [
          {
            "ID-NAME": "07",
            "ID-NUMBER": data["PAN"],
          },
        ],
      },
    };
    setPayload(requiredFormatPayload);
    props.apis
      .apiTataSendConsentOTP({ mobileNo: data["PHONE-NUMBER"] })
      .then(({ data }) => {
        setConsentUid(data.uid);
        setShowOTPModal(true);
      })
      .catch((err) => console.error(err));
  };

  const closeOTPModal = () => {
    setShowOTPModal(false);
    setOtpValue("");
  };

  const handleOTPChange = (otp) => {
    console.log(otp, "otp");
    setOtpValue(otp);
    if (otp.length === 6) {
      setIsOtpDisabled(false);
    } else {
      setIsOtpDisabled(true);
    }
  };

  const resendConsentOTP = (e) => {
    e.preventDefault();
    let payload = {
      uid: consentUid,
    };
    props.apis
      .apiResendConsentOTP(payload)
      .then(({ data }) => {
        setConsentUid(data.uid);
      })
      .catch((err) => console.error(err));
  };

  const handlePersonalDetailsOtpVerification = (e, otpcode) => {
    e.preventDefault();
    console.log({ otpcode });
    props.apis
      .apiTataVerifyConsentOTP({ uid: consentUid, consentCode: otpcode })
      .then((res) => {
        props.setGoSteps(1);
        setShowOTPModal(false);
      })
      .catch((err) => console.error(err));
  };

  // INCOME DETAILS
  const [showcommonModal, setShowcommonModal] = useState(false);
  const [showfinalmodal, setShowfinalmodal] = useState(false);
  const [showincomeresult, setShowincomeresult] = useState(false);

  const closeCommonModal = () => {
    setShowincomeresult(false);
    setShowcommonModal(false);
  };
  const closeFinalModal = () => {
    setShowfinalmodal(false);
  };

  const onIncomeDetailsSubmit = (e, data) => {
    e.preventDefault();
    console.log({ onIncomeDetailsSubmit: data });
    let payloadWithFinancials = {
      ...payload,
      "EMPLOYMENT-DTLS": [
        {
          "EMPLOYER-NAME": data["company_name"],
          "TIME-WITH-EMPLOYER": data["monthly_obligations"],
          "TOTAL-WORK-EXPERIENCE": data["total_work_exp"],
          "MONTHLY-INCOME": data["monthly_salary"],
          "GROSS-INCOME": (data["monthly_salary"] * 12).toString(),
        },
      ],
      "APPLICATION-DATA": {
        "REQ-LOAN-AMOUNT": "100000",
        "REQ-LOAN-TENOR": "12",
        "REQ-LOAN-IRR": "15",
        "LOAN-PURPOSE": "VACATION",
      },
    };
    setPayload(payloadWithFinancials);
    props.apis
      .apiAddTataCapitalApplicant(payloadWithFinancials)
      .then((res) => {
        console.log({ apiAddTataCapitalApplicant: res });
        setShowcommonModal(true);
        setTimeout(() => {
          setShowincomeresult(true);
        }, 2000);
        swal2.fire({
          title: "Application submitted successfully.",
          icon: "success",
          iconColor: "#234C9B",
          timer: 4000,
          confirmButtonText: "ok",
        });
      })
      .catch((err) => {
        console.log(err);
      });
    // toast.success("OTP Sent Successfully", toastConfig);
    // props.setGoSteps(1);
  };

  const handleIncomeDetailsSubmit = () => {
    // -------------------
    props.setGoSteps(2);
    setShowincomeresult(false);
    setShowcommonModal(false);
    // -------------------
  };

  // KYC DETAILS
  const kycDetailsSubmit = (e, data) => {
    console.log({ data });
    props.setGoSteps(3);
  };

  // REFERENCE DETAILS

  const onReferenceDetailsSubmit = (e, data) => {
    console.log({ data });
    setShowpreview(true);
  };

  // DOCS UPLOAD
  const onDocsSubmit = (e, data) => {
    console.log({ data });
    swal2.fire({
      title: "Document Uploaded Successfully",
      icon: "success",
      iconColor: "#234C9B",
      confirmButtonColor: "#234C9B",
      confirmButtonText: "Continue",
    });
    // .then((result) => {
    //   if (result.isConfirmed) {
    //   }
    // });

    setShowpreview(false);
    props.setGoSteps(0);
    props.hideStepper();
  };

  const handleFinalSubmit = () => {
    console.log("reset");
    // props.navigate("/app/tatacapital");
    setShowpreview(false);
    props.setGoSteps(0);
    props.hideStepper();
  };

  const handlePreviewSubmit = (e, data) => {
    console.log({ data });
    closeFinalModal();
    setShowpreview(false);
    props.setGoSteps(4);
  };
  // ----------------------------------------------------------------

  const handleStep = (e, step) => {
    e.preventDefault();
    console.log({ step });
    props.setGoSteps(step);
  };

  const handleSubmit = () => {
    setShowfinalmodal(true);
  };

  return (
    <>
      <ToastContainer />
      {!showpreview ? (
        <div className="form-wizard">
          <Stepper className="nav-wizard" activeStep={props.goSteps}>
            <Step
              className="nav-link"
              label="Personal Details"
              onClick={() => props.setGoSteps(0)}
              stepClassName="personaldetails"
              // completed={isNumberVerified}
              // completed={true}
            />
            <Step
              className="nav-link"
              label="Income Details"
              onClick={() => props.setGoSteps(1)}
              stepClassName="incomedetails"
              // completed={isAadharVerified}
            />
            <Step
              className="nav-link"
              label="KYC Details"
              onClick={() => props.setGoSteps(2)}
              stepClassName="kycdetails"
              // completed={isPanVerified}
            />
            <Step
              className="nav-link"
              label="Reference Details"
              onClick={() => props.setGoSteps(3)}
              stepClassName="referrencedetails"
              // completed={isPanVerified}
            />
            <Step
              className="nav-link"
              label="Document Upload"
              onClick={() => props.setGoSteps(4)}
              stepClassName="docsupload"
              // completed={true}
            />
          </Stepper>
          <div className="card outer_card">
            {props.goSteps === 0 && (
              <>
                <PersonalDetails
                  fields={personalfields}
                  skipToStep={(...args) => skipToStep(...args)}
                  onPersonalDetailsSubmit={(...args) =>
                    onPersonalDetailsSubmit(...args)
                  }
                  onHandleStep={(...args) => handleStep(...args)}
                  myinfo={props.myinfo}
                  showStepper={(...args) => props.showStepper(...args)}
                  hideStepper={(...args) => props.hideStepper(...args)}
                />
              </>
            )}
            {props.goSteps === 1 && (
              <>
                <IncomeDetails
                  fields={incomefields}
                  skipToStep={(...args) => skipToStep(...args)}
                  onIncomeDetailsSubmit={(...args) =>
                    onIncomeDetailsSubmit(...args)
                  }
                  onHandleStep={(...args) => handleStep(...args)}
                  myinfo={props.myinfo}
                  showStepper={(...args) => props.showStepper(...args)}
                  hideStepper={(...args) => props.hideStepper(...args)}
                />
              </>
            )}
            {props.goSteps === 2 && (
              <>
                <KYCDetails
                  skipToStep={(...args) => skipToStep(...args)}
                  onHandleStep={(...args) => handleStep(...args)}
                  myinfo={props.myinfo}
                  showStepper={(...args) => props.showStepper(...args)}
                  hideStepper={(...args) => props.hideStepper(...args)}
                  kycDetailsSubmit={(...args) => kycDetailsSubmit(...args)}
                />
              </>
            )}
            {props.goSteps === 3 && (
              <>
                <ReferenceDetails
                  fields={referencefields}
                  skipToStep={(...args) => skipToStep(...args)}
                  onReferenceDetailsSubmit={(...args) =>
                    onReferenceDetailsSubmit(...args)
                  }
                  onHandleStep={(...args) => handleStep(...args)}
                  myinfo={props.myinfo}
                  showStepper={(...args) => props.showStepper(...args)}
                  hideStepper={(...args) => props.hideStepper(...args)}
                />
              </>
            )}
            {props.goSteps === 4 && (
              <>
                <DocsUpload
                  fields={docsfields}
                  skipToStep={(...args) => skipToStep(...args)}
                  onDocsSubmit={(...args) => onDocsSubmit(...args)}
                  onHandleStep={(...args) => handleStep(...args)}
                  myinfo={props.myinfo}
                  showStepper={(...args) => props.showStepper(...args)}
                  hideStepper={(...args) => props.hideStepper(...args)}
                />
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="card p-4 confirm_preview_box">
          <AppForm>
            <h2 className="title">Confirm Your Personal Details</h2>
            {personalfields?.map((field, i) => {
              return (
                <div
                  className={`col-12 col-md-${
                    field?.customcolspan || 4
                  } col-xl-3`}
                  key={i}
                >
                  <AppInput
                    name={field?.name}
                    label={field?.label}
                    type={field?.type}
                    defaultValue={field?.defaultValue || ""}
                    options={field?.options || []}
                  ></AppInput>
                </div>
              );
            })}
            <hr />
            <h2 className="title">Confirm Your Income Details</h2>
            {incomefields?.map((field, i) => {
              return (
                <div
                  className={`col-12 col-md-${
                    field?.customcolspan || 4
                  } col-xl-3`}
                  key={i}
                >
                  <AppInput
                    name={field?.name}
                    label={field?.label}
                    type={field?.type}
                    defaultValue={field?.defaultValue || ""}
                    options={field?.options || []}
                  ></AppInput>
                </div>
              );
            })}
            <hr />
            <h2 className="title">Confirm Your Address</h2>
            {addressfields?.map((field, i) => {
              return (
                <div
                  className={`col-12 col-md-${
                    field?.customcolspan || 4
                  } col-xl-${field?.customcolspan || 3}`}
                  key={i}
                >
                  <AppInput
                    name={field?.name}
                    label={field?.label}
                    type={field?.type}
                    defaultValue={field?.defaultValue || ""}
                    options={field?.options || []}
                  ></AppInput>
                </div>
              );
            })}
            <div className="row">
              <div className="col-12 col-md-6"></div>
              <div className="col-12 col-md-6 mb-3 text-end d-flex justify-content-end">
                <button type="button" className="formdata-cancel-btn btn me-2">
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary submitBtn"
                  onClick={(...args) => handleSubmit(...args)}
                >
                  Continue
                </button>
              </div>
            </div>
          </AppForm>
        </div>
      )}

      {/* PERSONAL DETAILS OTP MODAL */}
      <Modal
        show={showOTPModal}
        onHide={closeOTPModal}
        centered
        backdrop="static"
        className="consent_otp_modal"
      >
        <Modal.Header closeButton className="border-0 py-4"></Modal.Header>
        <Modal.Body className="text-center">
          <h2 className="mb-5">OTP to verify</h2>
          <p className="desc fs-4">We have sent an OTP to your mobile no.</p>
          <div className="row g-3 mb-4">
            <div className="col-12">
              <OTPInput
                value={otpValue}
                onChange={(e) => handleOTPChange(e)}
                separator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
                numInputs={6}
                shouldAutoFocus={true}
                isInputNum={true}
                containerStyle="otp_container"
              />
            </div>
            <div className="col-12">
              <div className="text-align-start otp_bottom">
                <span className="me-2">Not received OTP?</span>
                <button
                  className="btn px-0 text-primary fs-5"
                  onClick={(e) => resendConsentOTP(e)}
                >
                  Resend
                </button>
              </div>
            </div>
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary w-100 py-3"
                disabled={isOtpDisabled}
                onClick={(e) =>
                  handlePersonalDetailsOtpVerification(e, otpValue)
                }
              >
                Verify
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* COMMON MODAL */}
      <Modal
        show={showcommonModal}
        onHide={closeCommonModal}
        centered
        backdrop="static"
        className="consent_otp_modal"
      >
        <Modal.Header closeButton className="border-0 py-4"></Modal.Header>
        <Modal.Body className="text-center pt-0">
          {showincomeresult ? (
            <div className="text-center pb-4 result_box">
              <img src={imagepaths.BlueCheckIcon} alt="" className="icon" />
              <p className="table-primary-text text-center">
                Congratulations {props?.$store?.user?.name || "User"}!
              </p>
              <h3>
                You are now eligible for a personal loan of upto Rs. 25,00,000
                for the time period of 60 months
              </h3>
              <button
                className="btn btn-primary w-100 py-3 mt-4"
                onClick={(...args) => handleIncomeDetailsSubmit(...args)}
              >
                Continue
              </button>
            </div>
          ) : (
            <div className="spinner_box">
              <img src={imagepaths.BlueSpinner} alt="" className="icon" />
              <h2>Please wait we are getting your eligibility check.</h2>
            </div>
          )}
        </Modal.Body>
      </Modal>

      {/* FINAL MODAL */}
      <Modal
        show={showfinalmodal}
        onHide={closeFinalModal}
        centered
        backdrop="static"
        className="consent_otp_modal"
      >
        <Modal.Header closeButton className="border-0 py-4"></Modal.Header>
        <Modal.Body className="text-center pt-0">
          <div className="text-center pb-4 result_box">
            <img src={imagepaths.BlueCheckIcon} alt="" className="icon" />
            <p className="table-primary-text text-center">
              Congratulations {props?.$store?.user?.name || "User"}!
            </p>
            <h3>
              You are now eligible for a personal loan of upto Rs. 5,00,000
            </h3>
            <button
              type="button"
              className="btn btn-primary w-100 py-3 mt-4"
              onClick={(...args) => handlePreviewSubmit(...args, 4)}
            >
              Continue
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default constore(Onboarding);
