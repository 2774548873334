import React from "react";

const DocsCard = (props) => {
  var BASE_URL = `${endpoints[environment].api_base}/files/download/`;
  const isAdmin = props?.$store?.user?.role === _enum.ROUTE_PERSONAS.admin;
  if (props?.data) {
    console.log(props?.data);
    var panFileId = props?.data?.panFileId;
    var aadharFrontFileId = props?.data?.aadharFrontFileId;
    var aadharBackFileId = props?.data?.aadharBackFileId;
    var photoFileId = props?.data?.photoFileId;
    var isAgreementSigned = props?.data?.isAgreementSigned;
    var agreementFileId = props?.data?.agreementFileId;
  }
  const handleAgreement = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let payload = {
      profileId: props?.data?.profileId,
    };
    props.apis.apiSendAgreementForEsign(payload).then((res) => {
      console.log({ res });
    });
  };
  return (
    <div className="card profile_card ps-4">
      <div className="card-header p-0 border-0">
        <div className="d-flex align-items-center mb-3">
          <img
            src={imagepaths.cardGaurdIcon}
            alt="cardGaurdIcon"
            className="card_icon"
          />
          <h5 className="title ms-2 mb-0">Docs Details</h5>
        </div>
      </div>
      <div className="card-body p-0">
        <div className="docs_card_container row g-0">
          <div className="col-6 col-sm-4 col-md-6 col-lg-3 col-xl-2">
            <div className="docs_card">
              <img src={imagepaths.docsCardBlueIcon} alt="docsCardBlueIcon" />
              <h2 className="title">PAN Card Front</h2>
              {panFileId && panFileId?.length !== 0 ? (
                <a href={`${BASE_URL}${panFileId}`} className="text-primary">
                  Download
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-6 col-sm-4 col-md-6 col-lg-3 col-xl-2">
            <div className="docs_card">
              <img src={imagepaths.docsCardBlueIcon} alt="docsCardBlueIcon" />
              <h2 className="title">Aadhar Card (Front)</h2>
              {aadharFrontFileId && aadharFrontFileId?.length !== 0 ? (
                <a
                  href={`${BASE_URL}${aadharFrontFileId}`}
                  className="text-primary"
                >
                  Download
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-6 col-sm-4 col-md-6 col-lg-3 col-xl-2">
            <div className="docs_card">
              <img src={imagepaths.docsCardBlueIcon} alt="docsCardBlueIcon" />
              <h2 className="title">Aadhar Card (Back)</h2>
              {aadharBackFileId && aadharBackFileId?.length !== 0 ? (
                <a
                  href={`${BASE_URL}${aadharBackFileId}`}
                  className="text-primary"
                >
                  Download
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-6 col-sm-4 col-md-6 col-lg-3 col-xl-2">
            <div className="docs_card">
              <img src={imagepaths.docsCardBlueIcon} alt="docsCardBlueIcon" />
              <h2 className="title">Photo</h2>
              {photoFileId && photoFileId?.length !== 0 ? (
                <a href={`${BASE_URL}${photoFileId}`} className="text-primary">
                  Download
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-12 col-sm-8 col-md-8 col-lg-3 col-xl-4">
            <div className="docs_card d-flex align-items-start ps-5">
              <div className="">
                <img src={imagepaths.docsCardBlueIcon} alt="docsCardBlueIcon" />
                <h2 className="title">Agreement</h2>
                {isAgreementSigned && agreementFileId?.length !== 0 ? (
                  <a
                    href={`${BASE_URL}${agreementFileId}`}
                    className="text-primary"
                  >
                    Download
                  </a>
                ) : (
                  ""
                )}
              </div>
              <div className="esign_box">
                <div className="d-flex align-items-center">
                  <i
                    className={Object.className({
                      "me-2": true,
                      "fa-regular fa-circle-check text-green":
                        isAgreementSigned === true,
                      "fa-regular fa-circle-xmark": isAgreementSigned === false,
                    })}
                  ></i>
                  <p className="m-0">E-Signed Done</p>
                </div>
                <button
                  type="button"
                  className={Object.className({
                    "d-none": !isAdmin,
                  })}
                  onClick={(...args) => handleAgreement(...args)}
                >
                  Re-Initiate
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default constore(DocsCard);
