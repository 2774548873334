import React, { useState } from "react";
import OTPInput from "react-otp-input";

const AadharVerification = (props) => {
  const [aadharcode, setAadharcode] = useState();
  const [isAadharDisabled, setIsAadharDisabled] = useState(true);
  const [isAadharOtpDisabled, setIsAadharOtpDisabled] = useState(true);
  const { name, address } = props?.aadhardetails;
  const isAadharVerified = props.$store?.user?.isAadharVerified;

  const onChangeAadhar = (e) => {
    let value = e.target.value;
    if (value.length > 0) {
      setIsAadharDisabled(false);
    } else {
      setIsAadharDisabled(true);
      props.handleAadharDetailsClose();
    }
  };

  function handleChange(otp) {
    console.log("otp changed: ", otp);
    setAadharcode(otp);
    if (otp.length === 6) {
      setIsAadharOtpDisabled(false);
    } else {
      setIsAadharOtpDisabled(true);
    }
  }

  const handleAadharVerify = (e, aadharcode) => {
    setAadharcode();
    props.onAadharVerify(e, aadharcode);
  };

  const resetField = () => {
    let aadharInput = document.querySelector('[name="aadharNumber"]');
    if (aadharInput) {
      aadharInput.value = "";
    }
    props.handleAadharDetailsClose();
  };

  return (
    <>
      {!isAadharVerified && (
        <AppForm onSubmit={props.onAadharCheck}>
          <div className="col-12 col-md-8 mb-3">
            <label htmlFor="mobileNumber">Aadhar No.</label>
            <input
              type="number"
              label="Mobile No."
              className="form-control"
              onChange={(e) => onChangeAadhar(e)}
              name="aadharNumber"
            />
          </div>
          <div className="col-12 col-md-3 mb-3 d-flex align-items-end">
            <button
              type="submit"
              className="btn btn-primary w-100"
              disabled={isAadharDisabled}
              id="aadharVerifyBtn"
            >
              Verify
            </button>
          </div>
        </AppForm>
      )}
      {/* AADHAR OTP VERIFICATION CONTAINER */}
      <div
        className={Object.className({
          "otp_box mt-5": true,
          hidden: !props.showAadharVerify,
        })}
      >
        <div className="col-12">
          <h4>OTP to verify</h4>
          <p className="m-0">We have sent an OTP to your mobile no.</p>
        </div>
        <div className="row">
          <div className="col-12 col-xl-6">
            <OTPInput
              value={aadharcode}
              onChange={(...args) => handleChange(...args)}
              numInputs={6}
              separator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
              containerStyle="otp_container"
            />
          </div>
          <div className="col-12 col-xl-6 d-flex align-items-center">
            <button
              className="btn btn-primary fs-5"
              disabled={isAadharOtpDisabled}
              onClick={(...args) => handleAadharVerify(...args, aadharcode)}
            >
              Verify
            </button>
          </div>
          <div className="col-12">
            <div className="text-start otp_bottom">
              <span className="me-2">Not received OTP?</span>
              <button className="btn px-0 text-primary fw-bold">Resend</button>
            </div>
          </div>
        </div>
      </div>
      {/* AADHAR DETAILS CONTAINER */}

      {isAadharVerified ? (
        // VIEW MODE CARD
        <div
          className={Object.className({
            profile_details_container: true,
          })}
        >
          <div className="profile_detail mb-3">
            <p className="m-0">Name as per Aadhar</p>
            <h4>{props?.myinfo?.name || "-"}</h4>
          </div>
          <div className="profile_detail mb-3">
            <p className="m-0">Address as per Aadhar</p>
            <h4>
              {address || props?.myinfo?.profileAadharCardInfo?.address || "-"}
            </h4>
          </div>
          <div className="nav_container">
            <button
              className="formdata-cancel-btn btn"
              onClick={(...args) => props.onHandleStep(...args, 0)}
            >
              Back
            </button>
            <button
              className="btn btn-primary ms-3"
              onClick={(...args) => props.onHandleStep(...args, 2)}
            >
              Next
            </button>
          </div>
        </div>
      ) : (
        <div
          className={Object.className({
            profile_details_container: true,
            hidden: !props.showAadharDetails || isAadharVerified,
          })}
        >
          <div className="profile_detail mb-3">
            <p className="m-0">Name as per Aadhar</p>
            <h4>{name || "-"}</h4>
          </div>
          <div className="profile_detail mb-3">
            <p className="m-0">Address as per Aadhar</p>
            <h4>{address || "-"}</h4>
          </div>
          <button
            className="formdata-cancel-btn btn me-3"
            onClick={(...args) => resetField(...args)}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={(...args) => props.onAadharSubmit(...args, aadharcode)}
          >
            Save & Submit
          </button>
        </div>
      )}
    </>
  );
};

export default constore(AadharVerification);
