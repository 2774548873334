import React from 'react';

const CardWidget = ({ title, fields }) => {
    return (
        <>
            <div className={Object.className({
                "metrics_card_container mb-4": true,
                "blue_card": title === "B2C" ? true : false,

            })}>
                <div className="metrics_card mw-150">
                    <div className="col_right">
                        <h5 className="card-title">For {title}</h5>
                    </div>
                </div>
                {
                    fields && fields.map((field, i) => {
                        return <div className="metrics_card">
                            <div className="col_left">
                                <img src={field?.image} alt="card icon" />
                            </div>
                            <div className="col_right">
                                <h5 className="card-title">{field?.title}</h5>
                                <div className="bottom_content">
                                    <p className="card-text">
                                        {field?.subtitle} : <span> {field?.value}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </>
    );
};

export default CardWidget;