import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const AccountDetails = (props) => {
  const [isAccountDisabled, setIsAccountDisabled] = useState(true);
  const [fields, setFields] = useState([
    {
      name: "accountHolderName",
      label: "Account holder name",
      type: "text",
    },
    {
      name: "accountNumber",
      label: "Account No.",
      type: "number",
    },
    {
      name: "reEnterAccountNumber",
      label: "Re-enter account No.",
      type: "text",
    },
    {
      name: "accountType",
      label: "Account type",
      type: "select",
      options: _enum.ACCOUNT_TYPE,
    },
    {
      name: "accountHolderBankName",
      label: "Bank name",
      type: "select",
      options: [],
    },
    {
      name: "accountHolderBankIFSC",
      label: "IFSC code",
      type: "text",
    },
  ]);

  const toastConfig = {
    autoClose: 3000,
    closeButton: false,
    hideProgressBar: true,
  };

  const handleAcknowledge = (e, data) => {
    let value = e.target.checked;
    setIsAccountDisabled(!value);
  };

  const updateFields = (data) => {
    let allowedFields = [...fields.map((v) => v.name), "reEnterAccountNumber"];
    let updatedfields = Object.only(data, allowedFields);
    updatedfields.reEnterAccountNumber = data?.accountNumber;

    let farr = Object.keys(updatedfields);
    console.log({ updateFields: data, updatedfields, farr });

    farr.forEach((name) => {
      let tfield = fields.find((o) => o.name === name);
      tfield.defaultValue = data[name];
    });
    setFields([...fields]);

    let reEnterAccountHolderNameField = fields.find(
      (o) => o.name === "reEnterAccountNumber"
    );
    reEnterAccountHolderNameField.defaultValue = data?.accountNumber;
    setFields([...fields]);
  };

  const updateAccInfo = (e) => {
    // update account info
    let data = e.target.getData();

    let payload = {
      ...Object.except(data, "acknowledgement"),
      profileId: props?.$store?.user?.profileId,
    };

    // check if account number matches with re-enter account number
    if (data?.accountNumber !== data?.reEnterAccountNumber) {
      toast.error(
        "Account No. and Re-enter account No. did not match",
        toastConfig
      );
    } else {
      console.log({ payload });
      props.apis.apiUpdateEmployee(payload).then((res) => {
        props?.onAccountSubmit(e);
      });
    }
  };

  const getBankNames = () => {
    let payload = {
      page: 1,
    };
    props.apis.apiGetBanknames(payload).then(({ data }) => {
      // console.log({apiGetBanknames: data});
      let bankNameField = fields.find((o) => o.name == "accountHolderBankName");
      bankNameField.options = data.options("bankName");
      console.log({ bankNameField });
      setFields([...fields]);
    });
  };

  useEffect(() => {
    getBankNames();
    updateFields(props?.myinfo);
  }, []);

  return (
    <>
      <AppForm className="container" onSubmit={updateAccInfo}>
        {fields?.map((field, i) => {
          return (
            <div
              className={`col-12 col-md-${field?.customcolspan || 12} col-lg-6`}
              key={i}
            >
              <AppInput
                name={field?.name}
                label={field?.label}
                type={field?.type}
                defaultValue={field?.defaultValue || ""}
                options={field?.options || []}
              ></AppInput>
            </div>
          );
        })}
        <div className="col-12 col-md-12 mt-4 d-flex justify-content-end">
          <button
            className="formdata-cancel-btn btn"
            onClick={(...args) => props.onHandleStep(...args, 2)}
          >
            Back
          </button>
          <button
            type="submit"
            className="btn btn-primary ms-3"
            // onClick={(...args) => updateAccInfo(...args)}
          >
            Save & Next
          </button>
        </div>
      </AppForm>
    </>
  );
};

export default constore(AccountDetails);
