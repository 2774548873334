import React, { useState } from "react";
import MainPagetitle from "../../../layouts/MainPagetitle";

const CheckEligibility = (props) => {
  const isAdmin = props?.$store?.user?.role === _enum.ROUTE_PERSONAS.admin;
  const [search, setSearch] = useState({
    limit: 10,
    order_by: "updatedAt",
    order_direction: "DESC",
    page: 1,
  });
  const [fields, setFields] = useState([
    {
      name: "company_name",
      label: "Company Name",
      type: "select",
      options: [],
      placeholder: "Company Name",
    },
    {
      name: "salary",
      label: "Salary",
      type: "number",
      placeholder: "Salary",
    },
    {
      name: "obligations",
      label: "Obligations",
      type: "text",
      placeholder: "Obligations",
    },
  ]);
  const onSearch = (reset = false) => {
    // let data = this.refs.form.form.current.getData();
    // console.log("onSearch formData", data, reset);
    // data = Object.filter(data, (val) => val);
    // this.props.onSearch && this.props.onSearch(data, reset);
  };

  const resetSearchForm = () => {
    //   this.refs.form.form.current.reset();
    // onSearch(true);
  };

  const getBreadCums = () => {
    let pagetitle = "Check Eligibility";
    return pagetitle;
  };

  const getBackLink = () => {
    let link;
    if (isAdmin) {
      link = "/app/valueaddedservices";
    } else {
      link = "/app/kyc";
    }
    return link;
  };
  return (
    <>
      <MainPagetitle
        mainTitle={"KYC"}
        back={getBreadCums()}
        backLink={getBackLink()}
        pageTitle="Check Eligibility"
        parentTitle="Home"
        pageIcon={imagepaths.PersonSearchIconActive}
      />

      <div className="container-fluid">
        <div className="card p-4">
          <AppForm
            className="filter-row flex-auto mx-auto justify-content-end"
            // ref="form"
          >
            {fields.map((field, i) => (
              <div className="col-12 col-sm-6 col-md-3" key={i}>
                <AppInput
                  // ref="inputname"
                  {...field}
                ></AppInput>
              </div>
            ))}
            {/* <div className="col-6 col-sm-3 col-md-3 p-0 d-flex align-items-center justify-content-start">
              <button
                className="btn btn-primary search_btn mt-3"
                onClick={() => onSearch()}
              >
                <i className="fa fa-search"></i>
                <span>Search</span>
              </button>
            </div> */}
            <div className="col-3 col-md-1 col-lg-1 col-xl-1 d-flex align-items-center justify-content-center mw-lg-75 mt-md-3">
              <button
                className="btn btn-primary search_btn"
                onClick={() => this.onSearch()}
              >
                <i className="fa fa-search"></i>
                <span>Search</span>
              </button>
            </div>
            <div className="col-5 col-md-1 col-lg-1 col-xl-1 d-flex align-items-center justify-content-center mw-75 mw-md-50 mt-md-3">
              <button
                type="button"
                className="btn btn-primary clear_filter_btn"
                onClick={(...args) => this.resetSearchForm(...args)}
              >
                <i class="fa-solid fa-filter-circle-xmark"></i>
              </button>
            </div>
          </AppForm>
          <hr />
        </div>
      </div>
    </>
  );
};

export default constore(CheckEligibility);
