import React, { Component } from "react";

export class OpenRouteModel extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.OpenRouteModel = this;
  }
  render() {
    let { props } = this;
    let { id, title, onClose, modalSize, modaltype, footer, okText } = props;
    let attrs = Object.except(props, ["id", "title", "type", "onClose"]);

    return (
      <AppModalBs
        id={id}
        title={title}
        onClose={onClose}
        modalSize={modalSize}
        footer={footer}
        okText={okText}
      >
            <div>
                <h4>1. Introduction</h4>
                <p>M/s Bank Loan Hun Pvt Ltd is committed to safeguarding the privacy and security of personal data in our role as intermediaries in the digital lending industry. This Data Privacy Policy outlines how we collect, use, disclose, and protect personal data in compliance with relevant data protection laws.</p>
                <h4>2. Definitions</h4>
                <p> Personal Data: Any information that can directly or indirectly identify an individual. Processing: Any operation or set of operations performed on personal data, whether by automated means or not, such as collection, recording, organization, storage, adaptation, and retrieval. Data Subject: An individual to whom personal data pertains.</p>
                <h4>3. Data Collection</h4>
                <h4>We collect personal data for the following purposes:</h4>
                <p>• Identifying and verifying applicants for digital loans.<br />• Assessing creditworthiness.<br />• Processing loan applications.<br />• Servicing and managing loans.<br />• Complying with legal and regulatory requirements</p>
                <h4>We may collect the following types of personal data, among others:</h4>
                <p>• Contact information (name, address, email, phone number).<br />• Financial information (income, expenses, credit history).<br />• Identification information (government-issued ID, social security number).<br />• Employment and income details.<br />• Information related to loan transactions.</p>
                <h4>4. Legal Basis for Processing</h4>
                <h4>We process personal data on the following legal bases:</h4>
                <p>• To perform the contract with the data subject (e.g., processing loan applications).<br />• To comply with legal obligations (e.g., regulatory reporting).<br />• Based on the data subject's consent when required.<br />• For legitimate interests (e.g., fraud prevention, risk assessment).</p>
                <h4>5. Data Security</h4>
                <h4>We implement technical and organizational measures to protect personal data from unauthorized access, disclosure, alteration, and destruction. These measures include:</h4>
                <p>• Encryption of data in transit and at rest.<br />• Regular security assessments and audits.<br />• Access controls and authentication.<br />• Employee training on data security.</p>
                <h4>6. Data Sharing</h4>
                <h4>We may share personal data with the following parties:</h4>
                <p>• Lending institutions and financial partners.<br />• Credit bureaus and reporting agencies.<br />• Legal and regulatory authorities.<br />• Service providers who assist with loan processing.</p>
                <h4>We ensure that third parties have adequate data protection measures in place.</h4>
                <h4>7. Data Subject Rights</h4>
                <h4>Data subjects have the following rights:</h4>
                <p>• Right to access and rectify personal data.<br />• Right to erasure (where applicable).<br />• Right to restrict processing.<br />• Right to data portability.<br />• Right to object to processing.<br />• Right not to be subject to automated decision-making.</p>
                <h4>To exercise these rights, please contact our Data Protection Officer (DPO) at [DPO contact details].</h4>
                <h4>8. Data Breach Response</h4>
                <p>In the event of a data breach, we will notify affected data subjects and relevant authorities as required by law.</p>
                <h4>9. International Data Transfers</h4>
                <p>If we transfer personal data outside the jurisdiction, we will ensure that adequate safeguards are in place, such as standard contractual clauses or approved binding corporate rules.</p>
                <h4>10. Policy Updates</h4>
                <p>We will regularly review and update this policy to stay compliant with data protection regulations. We encourage data subjects to periodically review this policy for any changes.</p>
                <h4>11. Contact Information</h4>
                <p>If you have any questions or concerns about this policy, please contact our Data Protection Officer (Gutta Rajesh) at [Rajesh@stardigiloans.com].</p>
            </div>
      </AppModalBs>
    );
  }
}

export default OpenRouteModel;
