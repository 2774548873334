export default {
    apiAddOfflineLeads(data) {
        return this.request(this.api().ADD_OFFLINE_LEADS, data).exec().log().get();
    },

    apiGetOfflineLeads(data, urldata = {}) {
        let payload = {
            ...data
        };
        return this.request(this.api().FETCH_OFFLINE_LEADS, payload)
            .urltransform(urldata)
            .exec().log()
            .mapresults((response) => {
                return {
                    data: response.data,
                    total: response.count
                }
            })
            .get();
    },

    apiGetOfflineLeadsById(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().GET_OFFLINE_BY_ID)
            .urltransform(urldata)
            .exec().log()
            .mapresults((res) => {
                return {
                    data: res,
                }
            })
            .get();
    },

    apiUpdateOfflineLeads(data, urldata = {}) {
        console.log({ apiUpdateOfflineLeads: data });
        urldata = { ...mixins.params(), ...urldata };

        let payload = {
            customer_name: data?.customer_name,
            mobile_num: data?.mobile_num,
            product_type: data?.product_type,
            bank_name: data?.bank_name,
            loan_amount: data?.loan_amount,
            login_status: data?.login_status,
            application_num: data?.application_num,
            application_date: data?.application_date,
            loan_status: data?.loan_status,
            disbursement_amount: data?.disbursement_amount,
            disbursement_date: data?.disbursement_date,
            channel_type: data?.channel_type,

        }
        return this.request(this.api().UPDATE_OFFLINE_BY_ID, payload).urltransform(urldata).exec().log().get();
    },

    apiDeleteOfflineLeads(urldata = mixins.params()) {

        return this.request(this.api().DELETE_OFFLINE_BY_ID)
            .urltransform(urldata)
            .exec().log().get();
    },

    apiBulkUploadOfflineLead(data, urldata = mixins.params()) {
        console.log(data, urldata);
        return this.request(this.api().BULK_UPLOAD_OFFLINE_LEADS, data)
            .urltransform(urldata)
            .multipart()
            .exec()
            .log()
            .get();
    },

    apiDownloadOfflineLeadsTemplate() {
        return this.request(this.api().DOWNLOAD_OFFLINE_LEADS_TEMPLATE)
            .exec()
            .log()
            .get();
    },

}