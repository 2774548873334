import React, { useEffect, useState } from "react";
import MainPagetitle from "../../../layouts/MainPagetitle";
import DigitalLeads from "./digitalLeads/digitalLeads";
import OfflineLeads from "./offlineLeads/offlineLeads";
import OverallLeads from "./overallLeads/overallLeads";

const MyLeads = (props) => {
  const isAdmin = props?.$store?.user?.role === _enum.ROUTE_PERSONAS.admin;
  const [activeKey, setActiveKey] = useState("overallleads");

  const filterTabData = [
    {
      key: "overallleads",
      name: "Overall Leads",
    },
    {
      key: "digitalleads",
      name: "Digital Leads",
    },
    {
      key: "offlineleads",
      name: "Offline Leads",
    },
  ];

  const handleSelect = (e, key) => {
    console.log({ key });
    setActiveKey(key);
    navigate(`/app/businesstobusiness?activetab=${key}`);
  };

  useEffect(() => {
    // PERSIST ACTIVE TAB ON PAGE RE-LOAD
    let paramsActiveTab = props.urlparams.params.activetab;
    if (paramsActiveTab) {
      setActiveKey(paramsActiveTab);
    }

    // scroll page to top
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MainPagetitle
        mainTitle={isAdmin ? "B2B Leads" : "My Leads"}
        pageTitle="B2B Leads"
        parentTitle="Home"
        pageIcon={
          isAdmin ? imagepaths.AdminLeadsIconActive : imagepaths.LeadsIconActive
        }
      />
      <div className="container-fluid position-relative">
        <div className="filter_tab_container">
          {filterTabData?.map((btn) => {
            return (
              <>
                <button
                  type="button"
                  id={btn.key}
                  className={Object.className({
                    active: activeKey === btn.key,
                  })}
                  onClick={(...args) => handleSelect(...args, btn.key)}
                >
                  {btn.name}
                </button>
              </>
            );
          })}
        </div>
        <div className="filter_tab_pane">
          {activeKey === "overallleads" && <OverallLeads />}
          {activeKey === "digitalleads" && <DigitalLeads />}
          {activeKey === "offlineleads" && <OfflineLeads />}
        </div>
      </div>
    </>
  );
};
export default constore(MyLeads);
