import React from "react";
import { Link } from "react-router-dom";

const Welcome = () => {
  return (
    <>
      <div className="page-wraper">
        <div className="browse-job login-style3">
          <div
            className="bg-img-fix overflow-hidden"
            style={{ backgroundImage: "url(" + imagepaths.loginBg + ")" }}
          >
            <div className="row gx-0">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 vh-100">
                <div className="logo_header pt-5 ps-5">
                  <Link to={"/open/login"} className="logo">
                    <img src={imagepaths.logoWithTagline} alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 vh-100">
                <div
                  id="mCSB_1"
                  className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                  style={{ maxHeight: "653px" }}
                >
                  <div
                    id="mCSB_1_container"
                    className="mCSB_container"
                    style={{
                      position: "relative",
                      top: "0",
                      left: "0",
                      dir: "ltr",
                    }}
                  >
                    <h1>WELCOME TO BANKLOAN HUB</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome;
