import React, { Component } from "react";

export class ChangedPasswordModal extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.ChangedPasswordModal = this;
  }
  render() {
    let { props } = this;
    let { id, title, onClose, modalSize, modaltype, hidecancelbtn, centerchangebtn, hidecloseicon } = props;
    let attrs = Object.except(props, ["id", "title", "type", "onClose"]);

    return (
      <AppModalBs
        id={id}
        title={title}
        onClose={onClose}
        modalSize={modalSize}
        hidecancelbtn={hidecancelbtn}
        hidecloseicon={hidecloseicon}
      >
        <AppFormData
          {...attrs}
          onClose={onClose}
          modaltype={modaltype}
          hidecancelbtn={hidecancelbtn}
          centerchangebtn={centerchangebtn}
        ></AppFormData>
      </AppModalBs>
    );
  }
}

export default ChangedPasswordModal;
