import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import MainPagetitle from "../../../layouts/MainPagetitle";
import DigitalLeadCard from "../DigitalLeads/DigitalLeadCard";
import TataCapitalSearch from "./TataCapitalSearch/TataCapitalSearch";
import Onboarding from "./Onboarding/Onboarding";

const TataCapital = (props) => {
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
    // order_by: "updatedAt",
    // order_direction: "DESC",
  });
  const adminPersona = props?.$store?.user?.role === _enum.ROUTE_PERSONAS.admin;
  const pageID = props?.urlparams?.params?.leadname;
  const isPanVerified = props.$store?.user?.isPanVerified;
  const isAadharVerified = props.$store?.user?.isAadharVerified;
  const [isAccountVerified, setIsAccountVerified] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const [rowcount, setRowcount] = useState(0);
  const columns = [
    {
      dataIndex: "connectorName",
      title: "Digi Team Member Name",
      render: (text, record) => {
        return (
          <span className="table-primary-text">
            {record?.createdBy?.name || "-"}
          </span>
        );
      },
      display: adminPersona ? true : false,
    },
    {
      dataIndex: "connector_mobile_no",
      title: "Digi Team Member Mobile No.",
      display: adminPersona ? true : false,
      render: (text, record) => {
        return (
          <span className="table-primary-text">
            {record?.createdBy?.mobileNumber || "-"}
          </span>
        );
      },
    },
    {
      dataIndex: "APPLICANT_NAME",
      title: "Applicant Name",
      render: (text, record) => {
        return (
          <span className="table-primary-text">
            {text["FIRST-NAME"]} {text["LAST-NAME"]}
          </span>
        );
      },
    },
    {
      dataIndex: "PHONE_DTLS",
      title: "Mobile No.",
      sorter: false,
      display: adminPersona ? false : true,
      render: (text, record) => {
        return (
          <span className="table-primary-text">
            {text[0]?.["PHONE-NUMBER"]}
          </span>
        );
      },
    },
    {
      dataIndex: "applicationDate",
      title: "App. Date",
      display: adminPersona ? false : true,
      render: (text, record) => {
        let date = new Date(record?.createdAt).dateToDDMMYYYY(
          new Date(record?.createdAt)
        );
        return date;
      },
    },
    {
      dataIndex: "leadStatus",
      title: "Lead Status",
      display: adminPersona ? false : true,
      sorter: false,
      render: (text, record) => {
        return (
          <span
            className={`badge light border-0 ${
              text === "REJECTED" ? "badge-danger" : "badge-success"
            } `}
          >
            {text?.toTitleCase()}
          </span>
        );
      },
    },
    {
      dataIndex: "applicationStatus",
      title: "Loan Status",
      sorter: false,
      render: (text, record) => {
        return <>{text || "-"}</>;
      },
    },
    {
      dataIndex: "irr",
      title: "ROI",
      sorter: false,
    },
    {
      dataIndex: "Max_loan_amount",
      title: "Max Eligibility",
      render: (text, record) => {
        let value = "";
        if (Object.keys(record).includes("breMaxEligibilityAmount")) {
          value = record?.breMaxEligibilityAmount;
        } else {
          value = text;
        }
        return <>{value}</>;
      },
    },
    {
      dataIndex: "remarks",
      title: "Remarks",
      // display: adminPersona ? true : false,
    },
  ];

  const [showstepper, setShowstepper] = useState(false);
  const [goSteps, setGoSteps] = useState(0);
  const [myinfo, setMyinfo] = useState({});

  const toastConfig = {
    autoClose: 3000,
    closeButton: false,
    hideProgressBar: true,
  };

  const onSearch = (data, reset) => {
    console.log("onSearch on leads", data, reset);
    if (Object.keys(data).length === 0) {
      setSearch({
        ...data,
        ...search,
      });
      getTataCapitalApplications(data, reset);
    } else {
      let searchData = {
        ...data,
        ...(data?.startDate
          ? {
              startDate: new Date(data?.startDate).toISOString(),
            }
          : {}),
        ...(data?.endDate
          ? {
              endDate: new Date(data?.endDate).toISOString(),
            }
          : {}),
      };
      console.log({ searchData });
      setSearch({
        ...searchData,
        ...search,
      });
      getTataCapitalApplications(searchData, reset);
    }
  };

  const onPageChange = (pageData) => {
    // console.log({ onPageChange: pageData });
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const viewApplication = (record) => {
    // GET APPLICANTS BY ID
    let payload = {
      uid: record.uid,
    };
    console.log({ payload });
  };

  const showGuidelines = () => {
    swal2.fire({
      imageUrl: imagepaths.RBIGuidelines,
      width: 1200,
      imageAlt: "RBI Guideline image",
      confirmButtonText: "Close",
    });
  };

  const getBreadCums = () => {
    let pagetitle = _enum?.LEAD_TITLE_MAP?.tata_capital;
    return pagetitle;
  };

  const getBackLink = () => {
    return "/app/businesstobusiness?activetab=digitalleads";
  };

  const handleBulkDownload = (e) => {
    let ids = tableData?.map((v) => v.uid);
    var payload = {
      consolidatedLeadIds: ids,
    };
    console.log({ ids, payload });
    if (typeof ids[0] !== undefined) {
      props.apis.apiDownloadAdminPaymentInfo(payload).then((res) => {
        console.log({ res });
        if (res) {
          let url = `${
            props.apis.api().api_base
          }/payment-info/admin/download?consolidatedLeadIds=${ids}`;
          var downloadURL = url;
          var link = document.createElement("a");
          link.setAttribute(
            "download",
            `digi_rep_payout_${new Date().toLocaleDateString()}.xlsx`
          );
          link.href = downloadURL;
          link.click();
        }
      });
    }
  };

  // TATA CAPITAL FLOW
  const getTataCapitalApplications = (params = {}, reset) => {
    let payload = {};
    if (reset) {
      let defaultSearchData = Object.only(search, ["limit", "page"]);
      setSearch(defaultSearchData);
      payload = {
        ...defaultSearchData,
      };
    } else {
      payload = {
        ...search,
        ...params,
      };
    }
    props.apis
      .apiTataGetOverallApplicants(payload)
      .then(({ data, count }) => {
        console.log({ apiTataGetOverallApplicants: data, count });
        setTableData(data);
        setRowcount(count);
      })
      .catch((err) => console.error(err));
    setIsFetching(false);
    setRowcount(0);
  };

  const handleApply = () => {
    console.log("redirect");
    setShowstepper(true);
  };

  const showStepper = () => {
    setShowstepper(true);
  };

  const hideStepper = () => {
    setShowstepper(false);
  };

  useEffect(() => {
    console.log({ pageID });

    getTataCapitalApplications();

    if (isPanVerified && isAadharVerified) {
      setIsAccountVerified(true);
    } else {
      setIsAccountVerified(false);
    }
  }, []);

  props.$setNode({
    props,
    search,
  });
  return (
    <>
      <MainPagetitle
        mainTitle={
          adminPersona
            ? "B2B Leads | Digital Leads "
            : "My Leads | Digital Leads "
        }
        back={getBreadCums()}
        backLink={getBackLink()}
        pageIcon={imagepaths.AdminLeadsIcon}
      />
      {!showstepper ? (
        <>
          <div className="container-fluid d-flex flex-column">
            <DigitalLeadCard />
            {!adminPersona && (
              <div className="d-flex flex-row-reverse">
                {isAccountVerified && (
                  <button
                    type="button"
                    className={Object.className({
                      "apply-now-btn btn btn-primary ms-3 me-3": true,
                    })}
                    onClick={(...args) => handleApply(...args)}
                  >
                    Apply Now
                  </button>
                )}
                <button
                  type="button"
                  className={Object.className({
                    "mt-3 border-0 bg-transparent": true,
                  })}
                  onClick={(...args) => handleBulkDownload(...args)}
                >
                  <i className="mr-1">
                    <img
                      src={imagepaths.DownloadIcon}
                      alt="download icon"
                      width="18px"
                    />
                  </i>
                  Bulk Download
                </button>
              </div>
            )}
          </div>
          <div className="container-fluid pb-5">
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="tbl-caption-container">
                      <TataCapitalSearch
                        onSearch={(...arg) => onSearch(...arg)}
                      />
                    </div>
                    <AppTable
                      data={tableData}
                      columns={columns}
                      onChange={(...arg) => onPageChange(...arg)}
                      total={rowcount}
                      reorder={true}
                      viewAction={(...args) => viewApplication(...args)}
                      editable={false}
                      deletable={false}
                      loading={isFetching}
                    ></AppTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="myleads-footer">
            <img src={imagepaths.footerLNTIcon} alt="LNT Icon" />
            <a
              href="https://www.ltfs.com/our-products/personal-loans/fees-charges"
              target="_blank"
              rel="noopener noreferrer"
              className="text text-primary ms-1"
            >
              <u className="me-3">L&T Fees Chargers</u>
            </a>
            |{" "}
            <img
              src={imagepaths.footerRBIIcon}
              alt="RBI Icon"
              className="ms-3"
            />
            <a href="#" className="text text-primary ms-1">
              <u
                onClick={() => {
                  showGuidelines();
                }}
              >
                RBI Digital Guidelines
              </u>
            </a>
          </p>
        </>
      ) : (
        <div className="container-fluid pb-5 Onboarding-stepper">
          <Onboarding
            handleSubmit={(...args) => handleSubmit(...args)}
            setGoSteps={setGoSteps}
            goSteps={goSteps}
            myinfo={myinfo}
            showStepper={(...args) => showStepper(...args)}
            hideStepper={(...args) => hideStepper(...args)}
          />
        </div>
      )}
    </>
  );
};

export default constore(TataCapital);
