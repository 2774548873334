import React from "react";

const AadharCard = (props) => {
  const isAadharVerified = props.$store?.user?.isAadharVerified;
  return (
    <div className="card profile_card">
      <div className="card-header py-0 border-0">
        <div className="d-flex align-items-start">
          <img src={imagepaths.aadharIcon} alt="aadhar" />
          <h5 className="title ms-2">Aadhar</h5>
        </div>
        <div
          className={Object.className({
            "action_box d-flex align-items-center": true,
          })}
        >
          {isAadharVerified ? (
            <i className="fa fa-check check_icon"></i>
          ) : (
            <button onClick={(...args) => props.onEdit(...args, "aadhar")}>
              <i className="fa fa-pencil"></i>
            </button>
          )}
        </div>
      </div>
      <hr />
      <div className="card-body py-0">
        <h5>Aadhar no</h5>
        <p className="text-primary">{props?.aadhardata?.uid || "-"}</p>
        <h5>Address</h5>
        <p className="text-primary">
          {/* House no. 49, Vivekananda colony, Badangpet, Hyderabad */}
          {props?.aadhardata?.address || "-"}
        </p>
      </div>
    </div>
  );
};

export default constore(AadharCard);
