import React, { useEffect, useState } from "react";

const MyEarningCards = (props) => {
  const [cardData, setCardData] = useState([]);
  const [lastSixMonthDate, setLastSixMonthDate] = useState(null);

  // DATE CALCULATIONS
  // start date
  const midnightDate = new Date();
  midnightDate.setHours(0, 0, 0, 0);
  const startDate = midnightDate.toISOString();

  // end date
  const elevenFiftyNinePMDate = new Date();
  elevenFiftyNinePMDate.setHours(23, 59, 0, 0);
  const endDate = elevenFiftyNinePMDate.toISOString();

  useEffect(() => {
    fetchDashboardData({
      startDate: startDate,
      endDate: endDate,
    });
    getLastSixMonthsStartDate();
  }, []);

  const fetchDashboardData = (params) => {
    let payload = {
      ...params,
    };
    props.apis.apiGetPayoutDashboard(payload).then((res) => {
      console.log({ apiGetPayoutDashboard: res });
      setCardData(res);
    });
  };

  const handleDateChange = (value) => {
    let val = value[0].key;
    console.log({ value });
    let payload = {};
    if (val === "last_six_month") {
      payload.startDate = lastSixMonthDate;
      payload.endDate = endDate;
    } else {
      payload.startDate = startDate;
      payload.endDate = endDate;
    }
    fetchDashboardData(payload);
  };

  const getLastSixMonthsStartDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1; // Months are zero-based
    let startMonth = month - 6;
    let startYear = year;

    if (startMonth <= 0) {
      startMonth = 12 + startMonth;
      startYear--;
    }

    const isoDate = new Date(startYear, startMonth - 1, 1).toISOString();
    setLastSixMonthDate(isoDate);
  };

  return (
    <>
      <div className="metrics_card_container mb-4">
        <div className="leads_detail_select_box">
          <AppInput
            type="select"
            options={_enum.LEADS_DETAIL}
            name="leads_detail"
            onChange={(...args) => handleDateChange(...args)}
            defaultValue={"today"}
            placeholder="Select"
          ></AppInput>
        </div>
        <div className="metrics_card">
          <div className="col_left">
            <img src={imagepaths.userCheckIcon} alt="card icon" />
          </div>
          <div className="col_right">
            <h5 className="card-title">Leads Entered</h5>
            <div className="bottom_content">
              <p className="card-text pe-4">
                No. Of Leads :<span> {cardData?.totalLeadsCount}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="metrics_card">
          <div className="col_left">
            <img src={imagepaths.walletIcon} alt="card icon" />
          </div>
          <div className="col_right">
            <h5 className="card-title">Disbursement Amt.</h5>
            <div className="bottom_content">
              <p className="card-text">
                Amt :<span> {cardData?.totalDisbursedAmount?.cur()}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="metrics_card">
          <div className="col_left">
            <img src={imagepaths.moneyLentIcon} alt="card icon" />
          </div>
          <div className="col_right">
            <h5 className="card-title">Payout Amt.</h5>
            <div className="bottom_content">
              <p className="card-text">
                Amt :<span> {cardData?.totalPayoutAmount?.cur()}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default constore(MyEarningCards);
