import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

function Login(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [mobilefield, setMobilefield] = useState("");
  let errorsObj = { email: "", password: "" };
  let mobilefieldErrorObj = { mobilefield: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [otpErrors, setOtpErrors] = useState(mobilefieldErrorObj);
  const [password, setPassword] = useState("");
  const [loginToggle, setLoginToggle] = useState(true);
  const [otpToggle, setOtpToggle] = useState(true);
  const [passwordToggle, setPasswordToggle] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [isMobileLoading, setMobileLoading] = useState(false);

  const toastConfig = {
    autoClose: 3000,
    closeButton: false,
    hideProgressBar: true,
  };

  function onLogin(e) {
    e.preventDefault();
    setLoading(true);
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === "") {
      toast.error("Email is Required", toastConfig);
      setLoading(false);
      error = true;
    }
    if (password === "") {
      toast.error("Password is Required", toastConfig);
      setLoading(false);
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }

    let data = {
      emailAddress: email?.toLowerCase(),
      password: password,
    };
    // console.log({ data });

    props.apis
      .apiLogin(data)
      .then((res) => {
        props.store("logged", res.logged);
        props.store("loggedIn", new Date().getTime());
        props.store("auth", res.auth);
        props.store("user", res.user);
        props.store("persona", "admin");

        let loginpath;
        if (res?.user?.role === _enum.ROUTE_PERSONAS.admin) {
          loginpath = "/app/home";
        } else if (
          res?.user?.role === _enum.ROUTE_PERSONAS.sales ||
          res?.user?.role === _enum.ROUTE_PERSONAS.ops ||
          res?.user?.role === _enum.ROUTE_PERSONAS.accounts
        ) {
          loginpath = "/app/leads";
        } else {
          loginpath = "/app/connectorhome";
        }
        navigate(loginpath);
        setLoading(false);
      })
      .catch(() => {
        const errorObj = { ...errorsObj };
        toast.error("login failed", toastConfig);
        setErrors(errorObj);
        setLoading(false);
      });
  }

  function onOTPLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...mobilefieldErrorObj };
    var mobilePattern = /^\d{10}$/;

    if (mobilefield === "") {
      toast.error("Email or Mobile Number is Required", toastConfig);
      error = true;
    } else if (!mobilePattern.test(mobilefield)) {
      toast.error("Invalid Mobile Number", toastConfig);
      error = true;
    } else {
      setMobileLoading(true);
    }
    setOtpErrors(errorObj);
    if (error) {
      return;
    }
    let data = {
      mobile: mobilefield,
    };
    props.apis
      .apiOTPLogin(data)
      .then((res) => {
        console.log({ onOTPSubmit: res });
        props.store("OTPresponses", res);
        props.store("mobileNumber", mobilefield);

        navigate("/open/otp");
        setMobileLoading(false);
      })
      .catch((err) => {
        console.log({ OTPlogin: err });
        const errorObj = { ...errorsObj };
        toast.error("Mobile number login failed", toastConfig);
        setOtpErrors(errorObj);
        setMobileLoading(false);
      });
  }

  const handleLoginToggle = (val) => {
    if (val === "mobilefield") {
      setOtpToggle(true);
      setPasswordToggle(false);
      setLoginToggle(true);
    }
    if (val === "password") {
      setOtpToggle(false);
      setPasswordToggle(true);
      setLoginToggle(false);
    }
  };

  const toggleSwitchPassword = () => {
    const passwordField = document.querySelector("#password");
    const togglePassword = document.querySelector("#togglePassword");

    togglePassword.addEventListener("click", function () {
      if (passwordField.type === "password") {
        passwordField.type = "text";
        togglePassword.classList.remove("fa-eye");
        togglePassword.classList.add("fa-eye-slash");
      } else {
        passwordField.type = "password";
        togglePassword.classList.remove("fa-eye-slash");
        togglePassword.classList.add("fa-eye");
      }
    });
  };

  // useEffect(() => {
  //   console.log("DESTROY_SESSION");
  //   app?.props?.store("auth", false);
  //   app?.props?.store("user", false);
  //   app?.props?.store("persona", "");
  //   localStorage.clear();
  // }, []);

  props.$setNode({
    props,
  });

  return (
    <>
      <ToastContainer />
      <div className="page-wraper">
        <div className="browse-job login-style3">
          <div
            className="bg-img-fix overflow-hidden"
            style={{ backgroundImage: "url(" + imagepaths.loginBg + ")" }}
          >
            <div className="parent-container row gx-0">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="logo_header pt-5 ps-5">
                  <Link to={"/open/login"} className="logo">
                    <img src={imagepaths.logoWithTagline} alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                <div className="login-form style-2 py-4">
                  <div className="card-body">
                    <div className="nav nav-tabs border-bottom-0">
                      <div className="tab-content w-100" id="nav-tabContent">
                        <div
                          className="tab-pane fade active show"
                          id="nav-personal"
                        >
                          {props.errorMessage && (
                            <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                              {props.errorMessage}
                            </div>
                          )}
                          {props.successMessage && (
                            <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                              {props.successMessage}
                            </div>
                          )}
                          <h3 className="form-title m-t0 text-center">Login</h3>
                          <div className="dz-separator-outer m-b5">
                            <div className="dz-separator bg-primary style-liner"></div>
                          </div>
                          <p className="sub-title text-center">
                            Access to explore more
                          </p>

                          <div className="login_toggle_container my-4">
                            <button
                              type="button"
                              onClick={() => handleLoginToggle("mobilefield")}
                              className={otpToggle ? "btn active" : "btn"}
                            >
                              OTP
                            </button>
                            <button
                              type="button"
                              onClick={() => handleLoginToggle("password")}
                              className={passwordToggle ? "btn active" : "btn"}
                            >
                              Password
                            </button>
                          </div>
                          <div>
                            {!loginToggle && (
                              <form
                                className=" dz-form pb-3"
                                // onSubmit={onLogin}
                                onSubmit={!isLoading ? onLogin : null}
                              >
                                <div className="form-group mb-3">
                                  <label>Email</label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                  {errors.email && (
                                    <div className="text-danger fs-12">
                                      {errors.email}
                                    </div>
                                  )}
                                </div>
                                <div className="password-container form-group mb-3">
                                  <label>Password</label>
                                  <input
                                    type="password"
                                    id="password"
                                    className="form-control"
                                    value={password}
                                    onChange={(e) =>
                                      setPassword(e.target.value)
                                    }
                                  />
                                  <i
                                    className="far fa-eye"
                                    id="togglePassword"
                                    onMouseEnter={() => toggleSwitchPassword()}
                                  ></i>
                                  {errors.password && (
                                    <div className="text-danger fs-12">
                                      {errors.password}
                                    </div>
                                  )}
                                </div>
                                <div className="form-group mb-5 d-flex align-items-start justify-content-between">
                                  <span className="form-check d-inline-block ms-2">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="check1"
                                      name="example1"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="check1"
                                    >
                                      Remember me
                                    </label>
                                  </span>
                                  <span>
                                    <NavLink
                                      to="/open/forgetpassword"
                                      className="ms-auto text-primary fw-bold"
                                    >
                                      Forgot Password ?
                                    </NavLink>
                                  </span>
                                </div>
                                <div
                                  className={Object.className({
                                    "form-group text-left": true,
                                    "not-allowed": isLoading,
                                  })}
                                >
                                  <button
                                    type="submit"
                                    className="btn btn-primary button-md btn-block"
                                    disabled={isLoading}
                                  >
                                    {isLoading ? "Loading…" : "login"}
                                  </button>
                                </div>
                              </form>
                            )}
                          </div>
                          <div>
                            {loginToggle && (
                              <form
                                className=" dz-form pb-3"
                                onSubmit={onOTPLogin}
                              >
                                <div className="form-group my-4">
                                  <label htmlFor="">Mobile no.</label>
                                  <div className="position-relative">
                                    <input
                                      // value={email}
                                      type="text"
                                      onChange={(e) =>
                                        setMobilefield(e.target.value)
                                      }
                                      className="form-control"
                                      placeholder="Please Enter"
                                    />
                                    <img
                                      className="user-icon"
                                      src={imagepaths.InputUserIcon}
                                      alt="user icon"
                                    />
                                  </div>
                                  {/* {errors.email && (
                                        <div className="text-danger fs-12">
                                          {errors.email}
                                        </div>
                                      )} */}
                                  {/* {otpErrors.mobilefield && (
                                  <div className="text-danger fs-12">
                                    {otpErrors.mobilefield}
                                  </div>
                                )} */}
                                </div>
                                <div
                                  className={Object.className({
                                    "form-group text-left": true,
                                    "not-allowed": isMobileLoading,
                                  })}
                                >
                                  <button
                                    type="submit"
                                    className="btn btn-primary button-md btn-block"
                                    disabled={isMobileLoading}
                                  >
                                    {isMobileLoading ? "Loading…" : "Send OTP"}
                                  </button>
                                </div>
                              </form>
                            )}
                          </div>
                          <div className="text-center bottom d-none">
                            <span>Not a member? </span>
                            <NavLink
                              // to="/open/registration"
                              className="text-primary fw-bold"
                            >
                              Enquire Now
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default constore(Login);
