import React, { useEffect, useState } from "react";
import MainPagetitle from "../../../layouts/MainPagetitle";

const BankwiseOffersList = (props) => {
  const [search, setSearch] = useState({
    page: 1,
  });
  const [banklists, setBanklists] = useState([]);

  const handleBankOffer = (record) => {
    console.log({ record });
    if (record?.bankId) {
      navigate(
        `/app/viewbankoffer?bankId=${record?.bankId}&bankName=${record?.bankName}&route=connector`
      );
    }
  };

  useEffect(() => {
    let payload = {
      ...search,
    };
    props.apis.apiGetBankOffers(payload).then(({ data, total }) => {
      if (data) {
        let alteredData = data.map((obj) => {
          console.log({
            name: obj.bankName,
            iconName: toCamelCase(obj?.bankName),
          });
          let retdata = {
            ...obj,
            icon: imagepaths[`${toCamelCase(obj?.bankName)}`],
          };
          return retdata;
        });
        setBanklists(alteredData);
      }
    });
  }, []);

  const toCamelCase = (str) => {
    return str
      .toLowerCase()
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/[^a-zA-Z]/g, "")
      .replace(/\s+/g, "");
  };

  props.$setNode({
    props,
    banklists,
  });
  return (
    <>
      <MainPagetitle
        mainTitle="Bank Wise Offers"
        pageIcon={imagepaths.percentageIconActive}
      />
      <div className="container-fluid">
        <div className="card p-4">
          <div className="row">
            <div className="d-none d-md-flex col-4 col-md-6 col-lg-8"></div>
            <div className="col-6 col-md-4 col-lg-3">
              <AppInputFocus
                name="bankname"
                label="Bank Name"
                placeholder="Bank Name"
                type="select"
                options={[]}
              ></AppInputFocus>
            </div>
            <div className="col-6 col-md-1">
              <button
                className="btn btn-primary search_btn"
                // onClick={() => onSearch()}
              >
                <i className="fa fa-search"></i>
                <span>Search</span>
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="row g-3">
              {banklists?.map((bank, i) => {
                return (
                  <div
                    className={Object.className({
                      "col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 bankwise_offer_card": true,
                      active: bank.bankId,
                      inactive: !bank.bankId,
                    })}
                    onClick={(...args) => handleBankOffer(bank, ...args)}
                    key={i}
                  >
                    <div className="offline_card">
                      <div className="body d-flex">
                        <img
                          src={bank?.icon}
                          // src={`${toCamelCase(bank?.bankName)}`}
                          className="img-fluid me-3"
                          alt={bank?.title}
                        />
                        <h4 className="title d-flex align-items-center">
                          {bank?.bankName?.toTitleCase()}
                        </h4>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default constore(BankwiseOffersList);
