import Leads from "../../../jsx/components/Dashboard/BusinessToCustomer/Leads/leads";

const LeadsWrapper = () => {
  return <Leads />;
};

export const allowedRoles = [
  _enum.ROUTE_PERSONAS.sales,
  _enum.ROUTE_PERSONAS.ops,
];

export default constore(LeadsWrapper);
