import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { BankNamesModal } from "./BankNamesModal";

const BankNames = (props) => {
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [errorMessage, setErrorMessage] = useState({ name: "", message: "" });
  const [modalFields, setModalFields] = useState([
    {
      label: "Bank Name",
      name: "bankName",
      type: "text",
      placeholder: "Please Enter",
      onChange: (e) => handleBankName(e),
      required: true,
      customcolspan: "12",
    },
    // {
    //   label: "Upload Logo",
    //   name: "ETag",
    //   type: "file",
    //   foldername: "bankNamesFolder",
    //   customcolspan: "12",
    // },
  ]);
  const [editData, setEditData] = useState([]);
  const [editmode, setEditmode] = useState(false);
  const [viewmode, setViewmode] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [popupfields, setPopupfields] = useState({});
  const [popupdata, setPopupdata] = useState({});

  const [rowcount, setRowcount] = useState(0);
  const columns = [
    {
      dataIndex: "bankName",
      title: "Bank Name",
      render: (text, record) => {
        return <span className="table-primary-text">{text}</span>;
      },
    },
    // {
    //   dataIndex: "ETag",
    //   title: "Logo",
    //   render: (text, record) => {
    //     return (
    //       <a
    //         className="text-primary"
    //         href={downloadFile(record?.ETag)}
    //         target="_blank"
    //       >
    //         <u>Download</u>
    //       </a>
    //     );
    //   },
    // },
  ];

  const handleBankName = (e) => {
    const stringReg = _enum?.REGEX?.onlystring;
    const fieldValue = e.target.value;
    if (stringReg.test(fieldValue)) {
      setErrorMessage("");
    } else
      setErrorMessage({
        name: "bankName",
        message: "Special characters are not allowed",
      });
  };

  const onPageChange = (pageData) => {
    console.log({ onPageChange: pageData });
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const openModal = () => {
    setOpenCanvas(true);
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };

  const getBankNames = () => {
    let payload = {
      ...search,
    };
    props.apis.apiGetBanknames(payload).then(({ data, count }) => {
      console.log({ apiGetBanknames: data });
      setTableData(data);
      setIsFetching(false);
      setRowcount(count);
    });
  };

  const deleteBankName = (record) => {
    console.log({ deleteBankName: record.bankId });
    swal2
      .fire({
        title: "Are you sure you want to delete this bank?",
        icon: "info",
        iconColor: "#234C9B",
        showCancelButton: true,
        confirmButtonColor: "#234C9B",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
      .then((result) => {
        if (result.isConfirmed) {
          props.apis
            .apiDeleteBankname({ bankId: record.bankId })
            .then((res) => {
              console.log({ apiDeleteBankname: res });
              swal2.fire({
                title: "Bank Name deleted successfully !!",
                icon: "success",
                iconColor: "#234C9B",
                timer: 4000,
              });
              getBankNames();
            });
        }
      });
  };

  const viewBankName = (record) => {
    setEditmode(false);
    setViewmode(true);
    // GET BANK-NAMES BY ID
    props.apis.apiGetBanknameById({ bankId: record.bankId }).then((data) => {
      console.log({ apiGetBanknameById: data });
      openModal();
      let filteredData = Object.only(
        data,
        modalFields.map((v) => v.name)
      );
      console.log({ filteredData });
      setPopupdata(filteredData);
      setEditData(data);
    });
  };

  const editBankName = (record) => {
    openModal();
    setEditmode(true);
    setViewmode(false);
    // GET BANK NAME BY ID
    props.apis.apiGetBanknameById({ bankId: record.bankId }).then((data) => {
      console.log({ apiGetBanknameById: data });
      let filteredData = Object.only(
        data,
        modalFields.map((v) => v.name)
      );
      console.log({ filteredData });
      setPopupdata(filteredData);
      setEditData(data);
    });
  };

  const handleSubmit = (e, data, form) => {
    e.preventDefault();
    let formdata = data;
    let payload = {
      ...formdata,
    };
    const stringReg = _enum?.REGEX?.onlystring;
    if (!stringReg.test(payload?.bankName)) {
      console.log("not vaild");
    } else {
      console.log("valid");
      if (editmode) {
        props.apis
          .apiUpdateBankname(payload, {
            bankId: editData?.bankId,
          })
          .then((res) => {
            console.log({ apiUpdateBankname: res });
            swal2.fire({
              title: "Bank Name updated successfully.",
              icon: "success",
              iconColor: "#234C9B",
              timer: 4000,
            });
            closeModal();
            getBankNames();
          });
      } else {
        props.apis.apiAddBankname(payload).then((res) => {
          console.log({ apiAddBankname: res });
          swal2.fire({
            title: "Bank Name added successfully.",
            icon: "success",
            iconColor: "#234C9B",
            timer: 4000,
          });
          closeModal();
          getBankNames();
        });
      }
    }
  };

  const downloadFile = (fileId) => {
    let authToken = props.$store.auth;
    // console.log({ authToken: authToken, fileId });
    return props.apis.apiGetFile({ auth: authToken }, { ETag: fileId });
  };

  const addModalFields = (values) => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    setPopupfields(fields);
    setPopupdata(formdata);
    setOpenCanvas(true);
  };

  const onModalClose = () => {
    console.log("onModalClose");
    closeModal();
    setPopupfields({});
    setViewmode(false);
    setEditmode(false);
    setEditData({});

    let fileInput = document.querySelector(
      `[name="appinput-ETag"] .filevalue_container`
    );
    if (editmode === false && viewmode === false) {
      console.log("clear upload data");
      if (fileInput && fileInput.value.length > 0) {
        props.apis
          .apiFileRemover({ ETag: fileInput.value })
          .then((res) => {
            console.log({ apiFileRemover: res });
          })
          .catch((err) => {
            console.log({ err });
          });
      }
    }
  };

  useEffect(() => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    // console.log({ updatedfields: fields });
    setPopupfields(fields);
  }, [editData]);

  useEffect(() => {
    getBankNames();
  }, [search]);

  useEffect(() => {
    console.log({ Banknames: props });
    getBankNames();
  }, []);

  props.$setNode({
    props,
    editmode,
    viewmode,
  });

  return (
    <>
      <div className="page_header mb-3 text-end px-4">
        <Link
          to={"#"}
          className="btn btn-primary ms-1"
          onClick={(...args) => addModalFields(...args)}
        >
          <span className="text-white mb-0">+ Add New</span>
        </Link>
      </div>
      <AppTable
        data={tableData}
        columns={columns}
        onChange={(...arg) => onPageChange(...arg)}
        total={rowcount}
        reorder={true}
        editAction={(e) => editBankName(e)}
        viewAction={(e) => viewBankName(e)}
        deleteAction={(e) => deleteBankName(e)}
        loading={isFetching}
      ></AppTable>

      <BankNamesModal
        id="banknamesCanvas"
        title={`${
          editmode
            ? "Edit Bank Names"
            : viewmode
            ? "View Bank Names"
            : "Add Bank Names"
        }`}
        show={openCanvas}
        onClose={() => onModalClose()}
        data={popupdata}
        editdata={editData}
        fields={popupfields}
        editmode={editmode}
        onSubmit={(...args) => handleSubmit(...args)}
        submitText={`${editmode ? "Update" : "Submit"}`}
        disableallfields={viewmode ? true : false}
        errorMessage={errorMessage}
        viewmode={viewmode}
      />
    </>
  );
};

export default constore(BankNames);
