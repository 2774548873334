import Incredfinance from "../../../jsx/components/Dashboard/IncredFinance/incredfinance";

const InCredFinanceWrapper = () => {
  return <Incredfinance />;
};

export const allowedRoles = [
  _enum.ROUTE_PERSONAS.admin,
  _enum.ROUTE_PERSONAS.connector,
  _enum.ROUTE_PERSONAS.manager,
];

export default constore(InCredFinanceWrapper);
