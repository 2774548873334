import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";

const AppCanvas = (props) => {
  let {
    title = "Modal Title",
    className = "",
    contentClass = "",
    onClose = () => "",
    show,
    onHide,
    id,
  } = props;
  //   const [openCanvas, setOpenCanvas] = useState(false);
  return (
    <>
      <Offcanvas
        show={show}
        onHide={onClose}
        className={"offcanvas-end customeoff " + className}
        placement="end"
        id={id}
      >
        <div className="offcanvas-header">
          <h5 className="modal-title" id="#gridSystemModal">
            {title}
          </h5>
          <button type="button" className="btn-close" onClick={onClose}>
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div className={"offcanvas-body " + contentClass}>
          <div className="container-fluid">{props.children}</div>
        </div>
      </Offcanvas>
    </>
  );
};

export default AppCanvas;
