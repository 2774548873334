export const ManagerMenuList = [
    {
        title: "Dashboard",
        iconStyle: imagepaths.Dashboard,
        iconActive: imagepaths.DashboardActive,
        to: "/app/connectorhome",
        id: "connectorhome",
    },
    {
        title: "My Profile",
        iconStyle: imagepaths.Profile,
        iconActive: imagepaths.ProfileActive,
        to: "/app/myprofile",
        id: "myprofile",
    },
    {
        title: "My Leads",
        iconStyle: imagepaths.LeadsIcon,
        iconActive: imagepaths.LeadsIconActive,
        to: "/app/businesstobusiness?activetab=overallleads",
        id: "businesstobusiness",
    },
    {
        title: "My Team",
        iconStyle: imagepaths.AdminLeadsIcon,
        iconActive: imagepaths.AdminLeadsIconActive,
        to: "/app/myteam",
        id: "myteam",
    },
    {
        title: "My Earnings",
        iconStyle: imagepaths.WalletIcon,
        iconActive: imagepaths.WalletIconActive,
        to: "/app/myearnings",
        id: "myearnings",
    },
    {
        title: "KYC",
        iconStyle: imagepaths.PersonSearchIcon,
        iconActive: imagepaths.PersonSearchIconActive,
        to: "/app/kyc",
        id: ["kyc", "checkeligibility"],
    },
    {
        title: "Policies",
        iconStyle: imagepaths.shieldIcon,
        iconActive: imagepaths.shieldIconActive,
        to: "/app/connectorhome",
        id: ["dataPrivacyModal", "dataStorageModal", "rbipolicy"],
        classChange: "mm-collapse",
        content: [
            {
                title: "Data Privacy Policy",
                iconStyle: imagepaths.PrivacyPolicy,
                iconActive: imagepaths.PrivacyPolicyActive,
                to: "/app/connectorhome",
                classChange: "menu-popup",
                id: "dataPrivacyModal",
                showTitle: true
            },
            {
                title: "Data Storage Policy",
                iconStyle: imagepaths.StoragePolicy,
                iconActive: imagepaths.StoragePolicyActive,
                to: "/app/connectorhome",
                classChange: "menu-popup",
                id: "dataStorageModal",
            },
            {
                title: "RBI Policy",
                iconStyle: imagepaths.RBIPolicy,
                iconActive: imagepaths.RBIPolicyActive,
                id: "rbipolicy",
                classChange: "menu-link",
            },
        ],
    },
];
