import React, { useState } from "react";

const RoleCard = (props) => {
  let { name, data = [], crudOperation = true } = props;
  const [isValid, setIsValid] = useState(true);
  console.log(name, data);

  const handleOnchange = (e) => {
    let val = e.target.value;
    val.length < 3 ? setIsValid(false) : setIsValid(true);
  };

  return (
    <div className="role_card">
      <div className="card_header">
        <h3 className="title">{name.toTitleCase()}</h3>
        <button
          type="button"
          className={Object.className({
            add_btn: true,
            "d-none": !crudOperation,
          })}
          onClick={(...args) => props.toggleForm(...args, name)}
        >
          <i className="fa fa-plus me-2"></i>
          <span>Add</span>
        </button>
      </div>
      <hr />
      <div className="card_body">
        {data &&
          data?.map((item, i) => {
            return (
              <div className="list_box" key={i}>
                <p className="title">{item[name]}</p>
                <div
                  className={Object.className({
                    action_box: true,
                    "d-none": !crudOperation,
                  })}
                >
                  <button
                    className="delete me-2"
                    onClick={(e) => props.onDelete(e, item)}
                  >
                    <i className="fa-regular fa-trash-can"></i>
                  </button>
                  {/* <button className="edit">
                    <i className="fa fa-pencil"></i>
                  </button> */}
                </div>
              </div>
            );
          })}
        {data.length == 0 && (
          <div className="text-center">No {name} found...</div>
        )}
      </div>
      {
        <div className={`card_footer ${name}_card_footer hide`}>
          <AppForm onSubmit={props.onSubmit}>
            <div className="col-12 mb-3">
              <AppInput
                id={`role-${name}-input`}
                type="text"
                className="form-control w-100"
                placeholder={`Please enter ${name}`}
                name={name}
                required={true}
                onChange={(...args) => handleOnchange(...args, name)}
                {...(!isValid
                  ? {
                      errorMessage: {
                        name: name,
                        message: "Please enter a minimum of 3 characters.",
                      },
                    }
                  : {})}
              />
            </div>
            <div className="col-12 mb-3">
              <button
                type="submit"
                className="btn btn-primary w-100"
                disabled={!isValid}
              >
                Submit
              </button>
            </div>
          </AppForm>
        </div>
      }
    </div>
  );
};

export default RoleCard;
