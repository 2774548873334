import React, { useState } from "react";

const IncomeDetails = (props) => {
  const [fields, setFields] = useState(props?.fields);

  return (
    <>
      <AppForm onSubmit={props.onIncomeDetailsSubmit}>
        {fields?.map((field, i) => {
          return (
            <div
              className={`col-12 col-md-${field?.customcolspan || 4} col-xl-3`}
              key={i}
            >
              <AppInput
                labelClass={"focus-label"}
                controlClass="floating"
                {...field}
                labelpos="top"
                className={"form-focus " + (field?.className || "")}
              ></AppInput>
            </div>
          );
        })}
        <div className="nav_container">
          <button
            className="formdata-cancel-btn btn"
            onClick={(...args) => props.onHandleStep(...args, 0)}
          >
            Back
          </button>
          <button
            type="submit"
            className="btn btn-primary ms-3"
            onClick={(...args) => props.onPersonalDetailsSubmit}
          >
            Save & Next
          </button>
        </div>
      </AppForm>
    </>
  );
};

export default constore(IncomeDetails);
