import React, { useEffect, useState } from "react";
import OverallModal from "./OverallModal";
import { Link } from "react-router-dom";
import moment from "moment";
import OverallLeadsSearch from "./OverallLeadsSearch";

const OverallLeads = (props) => {
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const isAdmin = props?.$store?.user?.role === _enum.ROUTE_PERSONAS.admin;
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState({});
  const [editmode, setEditmode] = useState(false);
  const [viewmode, setViewmode] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [popupfields, setPopupfields] = useState({});
  const [popupdata, setPopupdata] = useState({});

  const [rowcount, setRowcount] = useState(0);
  const columns = [
    {
      dataIndex: "connectorName",
      title: "Digi Team Member Name",
      render: (text, record) => {
        return (
          <span className="table-primary-text">{record?.createdBy?.name}</span>
        );
      },
      display: isAdmin ? true : false,
    },
    {
      dataIndex: "connectorMobileNo",
      title: "Digi Team Member Mob No.",
      render: (text, record) => {
        return (
          <span className="table-primary-text">
            {record?.createdBy?.mobileNumber}
          </span>
        );
      },
      display: isAdmin ? true : false,
    },
    {
      dataIndex: "applicantName",
      title: isAdmin ? "Cust Name" : "Customer Name",
      render: (text, record) => {
        return <span className="table-primary-text">{text}</span>;
      },
    },
    {
      dataIndex: "mobileNo",
      title: "Mobile No.",
    },
    {
      dataIndex: "bankName",
      title: "Bank Name",
    },
    {
      dataIndex: "applicationNo",
      title: "Application Number",
      render: (text) => {
        return <>{text || "-"}</>;
      },
    },
    {
      dataIndex: "disbursedAmt",
      title: "Disb. Amount",
      render: (text) => {
        return <>{text?.cur() || "-"}</>;
      },
    },
    {
      dataIndex: "disbursedDate",
      title: "Disb. Date",
      render: (text, record) => {
        if (record?.disbursedDate) {
          let date = new Date(text).dateToDDMMYYYY(new Date(text));
          return date;
        } else {
          return "-";
        }
      },
    },
  ];

  const [modalFields, setModalFields] = useState([
    {
      name: "customer_name",
      label: "Customer Name (as per PAN)",
      type: "text",
      placeholder: "Please enter",
      required: true,
      onChange: (value) => handleCustName(value),
    },
    {
      name: "mobile_num",
      label: "Mobile No.",
      type: "text",
      placeholder: "Please enter",
      onChange: (value) => handleMobileNo(value),
    },
    {
      name: "product_type",
      label: "Product Type",
      type: "select",
      options: [],
      defaultValue: "Business loan",
      placeholder: "Please enter",
      required: true,
    },
    {
      name: "bank_name",
      label: "Bank Name",
      type: "select",
      options: [],
      placeholder: "Please enter",
      required: true,
    },
    {
      name: "loan_amount",
      label: "Loan Amount",
      type: "text",
      placeholder: "Please enter",
      required: true,
      onChange: (value) => handleLoanAmount(value),
    },
    {
      name: "loan_status",
      label: "Loan Status",
      type: "select",
      options: _enum.LOAN_STATUS,
      placeholder: "Please select",
      required: true,
      onChange: (value) => handleApplicationStatus(value),
    },
    {
      name: "application_num",
      label: "Application No.",
      type: "text",
      placeholder: "Please enter",
    },
    // {
    //   name: "application_date",
    //   label: "Application Date",
    //   type: "date",
    //   disabledDate: (current) => {
    //     return current && current < moment().startOf("day");
    //   },
    //   placeholder: "Please enter",
    // },
    {
      name: "disbursement_amount",
      label: "Disb. Amount",
      type: "text",
      placeholder: "Please enter",
      onChange: (value) => handleDisbAmount(value),
    },
    {
      name: "disbursement_date",
      label: "Disb. Date",
      type: "date",
      disabledDate: (current) => {
        return current && current < moment().startOf("day");
      },
      placeholder: "Please enter",
    },
  ]);

  const onSearch = (data, reset) => {
    console.log("onSearch ", data, reset, search);
    if (Object.keys(data).length === 0) {
      setSearch({
        ...data,
        ...search,
      });
      getData(data, reset);
    } else {
      setSearch({
        ...data,
        ...search,
      });
      getData(data, reset);
    }
  };

  const getOverallLeads = (params = {}) => {
    let payload = {
      ...search,
      ...params,
    };
    props.apis
      .apiGetOverallApplicants(payload)
      .then(({ data, count }) => {
        console.log({ apiGetOverallApplicants: data, count });
        setData(data);
        setRowcount(count);
      })
      .catch((err) => console.error(err));
  };

  const viewOverallLead = (record) => {
    setEditmode(false);
    setViewmode(true);

    let payload = {
      leadId: record.leadId,
    };
    props.apis.apiGetConsolidatedAppsById(payload).then(({ data }) => {
      console.log({ apiGetConsolidatedAppsById: data });
      let alteredData = {
        connector_name: data?.createdBy?.name,
        connector_mobile_no: data?.createdBy?.mobileNumber,
        customer_name: data?.applicantName,
        customer_mobile_no: data?.mobileNo,
        product_type: data?.productType,
        bank_name: data?.bankName,
        channel_type: data?.channelType,
        loan_amount: data?.loanAmt,
        disbursed_amount: data?.disbursedAmt,
        loan_status: data?.loanStatus,
        // application_date: new Date(data?.applicationDate).dateToDDMMYYYY(
        //   new Date(data?.applicationDate)
        // ),
      };

      if (
        data?.loanStatus === _enum?.LOAN_STATUS_MAP?.applied ||
        data?.loanStatus === _enum?.LOAN_STATUS_MAP?.approved
      ) {
        delete alteredData.disbursed_date;
        delete alteredData.disbursed_amount;
      } else {
        if (data?.disbursedDate) {
          alteredData.disbursed_date = new Date(
            data?.disbursedDate
          ).dateToDDMMYYYY(new Date(data?.disbursedDate));
        } else {
          alteredData.disbursed_date = "";
        }
      }
      setPopupdata(alteredData);
      setEditData(data);
      openModal();
    });
  };

  const openModal = () => {
    setOpenCanvas(true);
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };

  const addModalFields = (values) => {
    setEditmode(false);
    setViewmode(false);
    let updatedFields;
    if (applstatus !== _enum?.LOAN_STATUS_MAP?.disbursed) {
      updatedFields = modalFields.exclude(
        ["disbursement_date", "disbursement_amount"],
        "name"
      );
    } else {
      updatedFields = modalFields;
    }
    console.log({ updatedFields });
    let fields = (updatedFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    setPopupfields(fields);
    setPopupdata(formdata);
    setOpenCanvas(true);
  };
  const onModalClose = () => {
    setPopupfields({});
    setViewmode(false);
    setEditmode(false);
    setEditData({});
    closeModal();
  };

  const onPageChange = (pageData) => {
    console.log({ onPageChange: pageData });
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const getData = (params = {}, reset) => {
    let payload = {};
    if (reset) {
      let defaultSearchData = Object.only(search, ["page", "limit"]);

      setSearch(defaultSearchData);
      payload = {
        ...defaultSearchData,
      };
      console.log({ defaultSearchData: payload });
    } else {
      payload = {
        ...search,
        ...params,
      };
    }
    props.apis
      .apiGetOverallApplicants(payload)
      .then(({ data, count }) => {
        setData(data);
        setRowcount(count);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getData();
  }, [search]);
  return (
    <>
      <div className="header_box d-flex flex-col mx-5">
        <div className="row"></div>
        <div className="d-flex flex-row-reverse">
          <Link
            className="btn btn-primary ms-3 d-none"
            onClick={(...args) => addModalFields(...args)}
          >
            Add Leads
          </Link>
        </div>
      </div>
      <OverallLeadsSearch onSearch={(...arg) => onSearch(...arg)} />
      <AppTable
        data={data}
        columns={columns}
        onChange={(...args) => onPageChange(...args)}
        total={rowcount}
        reorder={true}
        viewAction={(...args) => viewOverallLead(...args)}
        editable={false}
        deletable={false}
      ></AppTable>

      <OverallModal
        id="overallLeadModal"
        title={`${
          editmode ? "Edit Leads" : viewmode ? "View Leads" : "Add Leads"
        }`}
        show={openCanvas}
        onClose={() => onModalClose()}
        data={popupdata}
        editdata={editData}
        fields={popupfields}
        viewmode={viewmode}
        editmode={editmode}
        noValidate={true}
        onSubmit={(...args) => handleSubmit(...args)}
        submitText={`${editmode ? "Update" : "Save & Submit"}`}
        disableallfields={viewmode ? true : false}
      />
    </>
  );
};

export default constore(OverallLeads);
