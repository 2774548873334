export default {

    // OFFLINE LEADS SERVICE

    ADD_OFFLINE_LEADS: {
        url: "/offlineLeads",
        method: "post",
    },
    FETCH_OFFLINE_LEADS: {
        url: "/offlineLeads",
        method: "get",
    },
    GET_OFFLINE_BY_ID: {
        url: "/offlineLeads/{leadId}",
        method: "get",
    },
    UPDATE_OFFLINE_BY_ID: {
        url: "/offlineLeads/{leadId}",
        method: "put",
    },
    DELETE_OFFLINE_BY_ID: {
        url: "/offlineLeads/{leadId}",
        method: "delete",
    },
    // BULK FILES
    BULK_UPLOAD_OFFLINE_LEADS: {
        url: "/offlineLeads/upload",
        method: "post",
    },
    DOWNLOAD_OFFLINE_LEADS_TEMPLATE: {
        url: "/offlineLeads/template",
        method: "get",
    },
}