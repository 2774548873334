import React, { useState } from "react";
import { Step, Stepper } from "react-form-stepper";
import MobileVerification from "./MobileVerification";
import PanVerification from "./PanVerification";
import AadharVerification from "./AadharVerification";
import AccountDetails from "./AccountDetails";
import { toast, ToastContainer } from "react-toastify";
import DocsUpload from "./DocsUpload";

const ProfileSteps = (props) => {
  const isAadharVerified = props.$store?.user?.isAadharVerified;
  const isPanVerified = props.$store?.user?.isPanVerified;
  const isNumberVerified = props.$store?.user?.isNumberVerified;

  const { accountNumber, panFileId, aadharFrontFileId, aadharBackFileId } =
    props?.myinfo;

  const isAccountDetailsVerified = accountNumber?.length > 0 ? true : false;
  const isDocsVerified =
    panFileId &&
    Object.keys(panFileId).length > 0 &&
    aadharFrontFileId &&
    Object.keys(aadharFrontFileId).length > 0 &&
    aadharBackFileId &&
    Object.keys(aadharBackFileId).length > 0
      ? true
      : false;

  const [pandetails, setPandetails] = useState({});
  const [requestId, setRequestId] = useState("");
  const [aadharRequestDetails, setAadharRequestDetails] = useState({});
  const [aadhardetails, setAadhardetails] = useState({});
  //
  const [showMobileVerify, setShowMobileVerify] = useState(false);
  const [usermobilenum, setUsermobilenum] = useState(null);
  //
  const [showAadharVerify, setShowAadharVerify] = useState(false);
  const [showAadharDetails, setShowAadharDetails] = useState(false);

  const [showPanVerify, setShowPanVerify] = useState(false);
  const [showPanDetails, setShowPanDetails] = useState(false);

  const toastConfig = {
    autoClose: 3000,
    closeButton: false,
    hideProgressBar: true,
  };

  // MOBILE
  const onMobileSubmit = (e, data) => {
    e.preventDefault();
    console.log({ onMobileSubmit: data });
    setUsermobilenum(data?.mobileNumber);
    let payload = {
      mobileNumber: data?.mobileNumber,
    };
    props.apis.apiKycSendOTP(payload).then((res) => {
      console.log({ apiKycSendOTP: res });
      setShowMobileVerify(true);
      toast.success("OTP Sent Successfully", toastConfig);
      let mobileVerifyBtn = document.querySelector("#mobileVerifyBtn");
      if (mobileVerifyBtn) {
        mobileVerifyBtn.disabled = true;
      }
    });
  };

  const onMobileVerify = (e, otpcode) => {
    e.preventDefault();
    console.log({ onMobileVerify: e, otpcode });
    let payload = {
      mobileNumber: usermobilenum,
      otp: otpcode,
    };
    props.apis.apiKycVerifyOTP(payload).then((res) => {
      console.log({ apiKycVerifyOTP: res });
      toast.success("Mobile No. Verified Successfully", toastConfig);
      props.store("user", {
        ...props?.$store?.user,
        isNumberVerified: true,
      });
      props.setGoSteps(1);
      setShowMobileVerify(false);
      setUsermobilenum(null);
    });
  };

  const handleMobileResend = () => {
    let payload = {
      mobileNumber: usermobilenum,
    };
    props.apis.apiKycSendOTP(payload).then((res) => {
      console.log({ apiKycSendOTP: res });
      setShowMobileVerify(true);
      toast.success("OTP Sent Successfully", toastConfig);
    });
  };

  // AADHAR
  const onAadharCheck = (e, data) => {
    e.preventDefault();
    console.log({ onAadharCheck: data });
    //
    let payload = {
      aadhaarNumber: data.aadharNumber,
    };
    console.log({ payload });
    props.apis.apiGetCustomerAadharDetails(payload).then(({ data }) => {
      console.log({ apiGetCustomerAadharDetails: data });
      toast.success("OTP Sent Successfully", toastConfig);
      setAadharRequestDetails(data);
      setShowAadharVerify(true);
      let aadharVerifyBtn = document.querySelector("#aadharVerifyBtn");
      if (aadharVerifyBtn) {
        aadharVerifyBtn.disabled = true;
      }
    });
  };

  const onAadharVerify = (e, otpcode) => {
    e.preventDefault();
    setShowAadharVerify(false);
    //
    console.log({ onAadharVerify: otpcode });
    let payload = {
      requestId: aadharRequestDetails?.requestId,
      otp: otpcode,
    };
    console.log({ payload });

    const resolve = (res) => {
      props.store("user", {
        ...props?.$store?.user,
        isAadharVerified: true,
      });
    };
    props.apis.apiVerifyCustomerAadharDetails(payload).then((res) => {
      console.log({ apiVerifyCustomerAadharDetails: res });
      setAadhardetails(res);
      setShowAadharDetails(true);
      resolve(res);
    });
  };

  // TOGGLE FUNCTION
  const handleAadharDetailsShow = () => {
    setShowAadharDetails(true);
  };
  const handleAadharDetailsClose = () => {
    setShowAadharDetails(false);
  };

  const onAadharSubmit = (e, aadharcode) => {
    e.preventDefault();
    toast.success("Aadhar Verified Successfully", toastConfig);
    props.setGoSteps(2);
    setShowAadharVerify(false);
    setShowAadharDetails(false);
  };

  // PAN
  const onPanCheck = (e, data) => {
    e.preventDefault();
    console.log({ onPanCheck: data });
    setShowPanVerify(true);
    toast.success("OTP Sent Successfully", toastConfig);
  };

  const onPanVerify = (e, data) => {
    e.preventDefault();
    console.log({ onPanVerify: data });
    let payload = {
      pan: data.panNumber,
    };
    console.log({ payload });
    props.apis.apiGetCustomerPanDetails(payload).then((res) => {
      console.log({ apiGetCustomerPanDetails: res });
      setPandetails(res);
      setShowPanDetails(true);
    });
  };

  // TOGGLE FUNCTION
  const handlePanDetailsShow = () => {
    setShowPanDetails(true);
  };
  const handlePanDetailsClose = () => {
    setShowPanDetails(false);
  };

  const onPanSubmit = (e) => {
    e.preventDefault();
    if (pandetails) {
      let payload = { ...pandetails };

      props.apis.apiVerifyCustomerPanDetails(payload).then((res) => {
        console.log({ apiVerifyCustomerPanDetails: res });
        toast.success("PAN Verified Successfully", toastConfig);
        props.store("user", {
          ...props?.$store?.user,
          isPanVerified: true,
        });
        props.setGoSteps(3);
        setShowPanDetails(false);
        setPandetails({});
      });
    }
  };

  const onAccountSubmit = (e) => {
    e.preventDefault();
    props.setGoSteps(4);
  };

  const onDocsSubmit = (e) => {
    e.preventDefault();
    props.setGoSteps(4);
    props.handleSubmit();
    getUser();
  };

  const getUser = () => {
    props.apis.apiGetUser().then((res) => {
      let userResponse = {
        ...res,
        refresh: props?.$store?.user?.refresh,
      };
      props.setstore("user", userResponse);
    });
  };

  const handleStep = (e, step) => {
    e.preventDefault();
    console.log({ step });
    props.setGoSteps(step);
  };
  return (
    <>
      <ToastContainer />
      <div className="form-wizard">
        <Stepper
          className="nav-wizard profile_stepper"
          id="customStepper"
          activeStep={props.goSteps}
        >
          <Step
            className="nav-link pe-none"
            label={`Mobile Number`}
            onClick={() => props.setGoSteps(0)}
            stepClassName="mobile"
            completed={isNumberVerified}
            // completed={true}
          />
          <Step
            className="nav-link pe-none"
            label="Aadhar Verification"
            onClick={() => props.setGoSteps(1)}
            stepClassName="aadhar"
            completed={isAadharVerified || aadhardetails?.length > 0}
          />
          <Step
            className="nav-link pe-none"
            label="PAN Verification"
            onClick={() => props.setGoSteps(2)}
            stepClassName="pan"
            completed={isPanVerified || pandetails?.length > 0}
          />
          <Step
            className="nav-link pe-none"
            label="Account Details"
            onClick={() => props.setGoSteps(3)}
            stepClassName="account"
            completed={isAccountDetailsVerified}
          />
          <Step
            className="nav-link pe-none"
            label="Document Upload"
            onClick={() => props.setGoSteps(4)}
            stepClassName="docsupload"
            completed={isDocsVerified}
          />
        </Stepper>
        {props.goSteps === 0 && (
          <>
            <MobileVerification
              skipToStep={(...args) => skipToStep(...args)}
              onMobileSubmit={(...args) => onMobileSubmit(...args)}
              onMobileVerify={(...args) => onMobileVerify(...args)}
              handleMobileResend={(...args) => handleMobileResend(...args)}
              showMobileVerify={showMobileVerify}
              onHandleStep={(...args) => handleStep(...args)}
              myinfo={props.myinfo}
            />
          </>
        )}
        {props.goSteps === 1 && (
          <>
            <AadharVerification
              onAadharCheck={(...args) => onAadharCheck(...args)}
              onAadharVerify={(...args) => onAadharVerify(...args)}
              onAadharSubmit={(...args) => onAadharSubmit(...args)}
              showAadharVerify={showAadharVerify}
              showAadharDetails={showAadharDetails}
              aadhardetails={aadhardetails}
              handleAadharDetailsShow={handleAadharDetailsShow}
              handleAadharDetailsClose={handleAadharDetailsClose}
              onHandleStep={(...args) => handleStep(...args)}
              myinfo={props.myinfo}
            />
          </>
        )}
        {props.goSteps === 2 && (
          <>
            <PanVerification
              showPanVerify={showPanVerify}
              onPanVerify={(...args) => onPanVerify(...args)}
              onPanSubmit={(...args) => onPanSubmit(...args)}
              showPanDetails={showPanDetails}
              handlePanDetailsShow={handlePanDetailsShow}
              handlePanDetailsClose={handlePanDetailsClose}
              pandetails={pandetails}
              onHandleStep={(...args) => handleStep(...args)}
              myinfo={props.myinfo}
            />
          </>
        )}
        {props.goSteps === 3 && (
          <>
            <AccountDetails
              onAccountSubmit={(...args) => onAccountSubmit(...args)}
              onHandleStep={(...args) => handleStep(...args)}
              myinfo={props.myinfo}
            />
          </>
        )}
        {props.goSteps === 4 && (
          <>
            <DocsUpload
              onDocsSubmit={(...args) => onDocsSubmit(...args)}
              onHandleStep={(...args) => handleStep(...args)}
              myinfo={props.myinfo}
            />
          </>
        )}
      </div>
    </>
  );
};

export default constore(ProfileSteps);
