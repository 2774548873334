import TransactionDetails from "../../../jsx/components/Dashboard/KYC/TransactionDetails/TransactionDetails";

const TransactionDetailsWrapper = () => {
  return <TransactionDetails />;
};

export const allowedRoles = [
  _enum.ROUTE_PERSONAS.admin,
  _enum.ROUTE_PERSONAS.connector,
  _enum.ROUTE_PERSONAS.manager,
];

export default constore(TransactionDetailsWrapper);
