import KYC from "../../../jsx/components/Dashboard/KYC/kyc";

const KYCWrapper = () => {
  return <KYC />;
};

export const allowedRoles = [
  _enum.ROUTE_PERSONAS.connector,
  _enum.ROUTE_PERSONAS.manager,
];

export default constore(KYCWrapper);
