import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import MainPagetitle from "../../../layouts/MainPagetitle";
import OfflineSearch from "./OfflineSearch/OfflineSearch";
import OfflineModal from "./OfflineModal";
import SendPayoutModal from "./SendPayoutModal";
import { toast } from "react-toastify";
import BulkUploadModal from "./BulkUploadModal";
import moment from "moment";

const OfflineLeads = (props) => {
  const toastConfig = {
    autoClose: 3000,
    closeButton: false,
    hideProgressBar: true,
  };
  const payoutModalRef = useRef(0);
  const [search, setSearch] = useState({
    page: 1,
    // limit: 10,
    product_type: props?.urlparams?.params?.leadname,
  });
  const adminPersona = props?.$store?.user?.role === _enum.ROUTE_PERSONAS.admin;
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [routeName, setRouteName] = useState("");
  const [applstatus, setApplstatus] = useState("APPLIED");
  const [errorMessage, setErrorMessage] = useState({ name: "", message: "" });
  const [modalFields, setModalFields] = useState([
    {
      name: "customer_name",
      label: "Customer Name (as per PAN)",
      type: "text",
      placeholder: "Please enter",
      required: true,
      onChange: (value) => handleCustName(value),
    },
    {
      name: "mobile_num",
      label: "Mobile No.",
      type: "text",
      placeholder: "Please enter",
      required: true,
      onChange: (value) => handleMobileNo(value),
    },
    {
      name: "product_type",
      label: "Product Type",
      type: "select",
      options: [],
      placeholder: "Please enter",
      required: true,
    },
    {
      name: "bank_name",
      label: "Bank Name",
      type: "select",
      options: [],
      placeholder: "Please enter",
      required: true,
    },
    {
      name: "loan_amount",
      label: "Loan Amount",
      type: "text",
      placeholder: "Please enter",
      required: true,
      onChange: (value) => handleLoanAmount(value),
    },
    {
      name: "application_num",
      label: "Application No.",
      type: "text",
      required: true,
      placeholder: "Please enter",
    },
    // {
    //   name: "application_date",
    //   label: "Application Date",
    //   type: "date",
    //   disabledDate: (current) => {
    //     return current && current < moment().startOf("day");
    //   },
    //   placeholder: "Please enter",
    //   required: true,
    // },
    // {
    //   name: "loan_status",
    //   label: "Loan Status",
    //   type: "select",
    //   options: _enum.LOAN_STATUS,
    //   placeholder: "Please select",
    //   required: true,
    //   onChange: (value) => handleApplicationStatus(value),
    // },
    {
      name: "disbursement_amount",
      label: "Disb. Amount",
      type: "text",
      placeholder: "Please enter",
      onChange: (value) => handleDisbAmount(value),
      required: true,
      // outerclass: "d-none",
    },
    {
      name: "disbursement_date",
      label: "Disb. Date",
      type: "date",
      disabledDate: (current) => {
        return current && current < moment().startOf("day");
      },
      placeholder: "Please enter",
      required: true,
      // outerclass: "d-none",
    },
  ]);
  const [editData, setEditData] = useState({});
  const [editmode, setEditmode] = useState(false);
  const [viewmode, setViewmode] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [popupfields, setPopupfields] = useState({});
  const [popupdata, setPopupdata] = useState({});
  const [leadId, setLeadId] = useState("");

  const [rowcount, setRowcount] = useState(0);
  const columns = [
    {
      dataIndex: "connector_name",
      title: "Digi Team Member Name",
      render: (text, record) => {
        return (
          <span className="table-primary-text">{record?.createdBy?.name}</span>
        );
      },
      display: adminPersona ? true : false,
    },
    {
      dataIndex: "customer_name",
      title: "Cust Name",
      render: (text, record) => {
        return <span className="table-primary-text">{text}</span>;
      },
    },
    {
      dataIndex: "mobile_num",
      title: "Mobile No.",
    },
    {
      dataIndex: "bank_name",
      title: "Bank Name",
      // render: (text, record) => {
      //   return <>{record?.bankDetails?.bankName}</>;
      // },
    },
    {
      dataIndex: "application_num",
      title: "Application Number",
      render: (text) => {
        return <>{text || "-"}</>;
      },
    },
    {
      dataIndex: "disbursement_amount",
      title: "Disb. Amount",
      render: (text) => {
        return <>{text?.cur() || "-"}</>;
      },
    },
    {
      dataIndex: "disbursement_date",
      title: "Disb. Date",
      render: (text, record) => {
        if (record?.disbursement_date) {
          let date = new Date(text).dateToDDMMYYYY(new Date(text));
          return date;
        } else {
          return "-";
        }
      },
    },
  ];

  const [payoutPopupfields, setPayoutPopupfields] = useState({});
  const [payoutdata, setPayoutdata] = useState({});
  const [payoutfields, setPayoutfields] = useState([
    {
      name: "payoutType",
      label: "Confirm payout type",
      type: "select",
      options: _enum.PAYOUT_TYPES,
      placeholder: "select any",
      customcolspan: "12",
      required: true,
    },
  ]);
  // ROW SELECTION
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [assignDept, setAssignDept] = useState({});
  const [notSelected, setNotSelected] = useState(true);

  const onPageChange = (pageData) => {
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const openModal = () => {
    setOpenCanvas(true);
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };

  const handleCustName = (e) => {
    const stringReg = _enum?.REGEX?.onlystring;
    if (stringReg.test(e.target.value)) setErrorMessage("");
    else
      setErrorMessage({
        name: "customer_name",
        message: "Special characters are not allowed",
      });
  };
  const handleMobileNo = (e) => {
    const numberReg = _enum?.REGEX?.number;
    const fieldValue = e.target.value;
    if (numberReg.test(fieldValue) && fieldValue.length === 10)
      setErrorMessage("");
    else
      setErrorMessage({
        name: "mobile_num",
        message: "Please enter a valid Mobile Number",
      });
  };
  const handleLoanAmount = (e) => {
    const numberReg = _enum?.REGEX?.number;
    const fieldValue = e.target.value;
    if (numberReg.test(fieldValue)) setErrorMessage("");
    else
      setErrorMessage({
        name: "loan_amount",
        message: "Please enter a valid Loan Amount",
      });
  };
  const handleDisbAmount = (e) => {
    const numberReg = _enum?.REGEX?.number;
    const fieldValue = e.target.value;
    if (numberReg.test(fieldValue)) setErrorMessage("");
    else
      setErrorMessage({
        name: "disbursement_amount",
        message: "Please enter a valid Disbursement Amount",
      });
  };

  const handleApplicationStatus = (val) => {
    console.log("value: ", val[0].key);
    let value = val[0].key;
    setApplstatus(value);

    let disbAmntOuter = document.querySelector(
      "[name='appinput-disbursement_amount']"
    );

    let disbAmntInput = document.querySelector(
      "[name='appinput-disbursement_amount'] input"
    );

    let disbDateOuter = document.querySelector(
      "[name='appinput-disbursement_date']"
    );

    let disbDateInput = document.querySelector(
      "[name='appinput-disbursement_date'] input"
    );

    if (value !== _enum?.LOAN_STATUS_MAP?.disbursed) {
      disbDateOuter.classList.add("d-none");
      disbAmntOuter.classList.add("d-none");
      // disbAmntInput.required = false;
      // disbDateInput.required = false;
    } else {
      disbDateOuter.classList.remove("d-none");
      disbAmntOuter.classList.remove("d-none");
      // disbAmntInput.required = true;
      // disbDateInput.required = true;
    }
  };

  const getApplications = (params = {}, reset) => {
    let payload = {};
    if (reset) {
      payload = {
        ...search,
        ...params,
      };
    } else {
      payload = {
        ...search,
        ...params,
      };
    }
    props.apis
      .apiGetOfflineLeads(payload)
      .then(({ data, total }) => {
        console.log({ apiGetOfflineLeads: data });
        setTableData(data);
        setIsFetching(false);
        setRowcount(total);
      })
      .catch((err) => console.error(err));
  };

  const viewApplication = (record) => {
    setEditmode(false);
    setViewmode(true);
    // GET OFFLINE LEADS BY ID
    // UPDATE OFFLINE LEADS BY ID
    getOfflineLead(record);
  };

  const editApplication = (record) => {
    if (record?.isEnabled === false) {
      swal2.fire({
        title: "Disbursed leads cannot be edited",
        icon: "error",
        iconColor: "#FF4D4D",
        timer: 4000,
      });
    } else {
      setEditmode(true);
      setViewmode(false);
      setLeadId(record.leadId);
      // UPDATE OFFLINE LEADS BY ID
      getOfflineLead(record);
    }
  };

  const getOfflineLead = (record) => {
    let payload = {
      leadId: record.leadId,
    };
    props.apis
      .apiGetOfflineLeadsById(payload, { leadId: record.leadId })
      .then((response) => {
        console.log({ apiGetOfflineLeadsById: response });
        let filteredData = Object.only(
          response.data,
          modalFields.map((v) => v.name)
        );
        console.log({ filteredData });
        setPopupdata(filteredData);
        setEditData(response.data);
        openModal();

        var disbamntField = modalFields.find(
          (o) => o.name === "disbursement_amount"
        );
        var disbdateField = modalFields.find(
          (o) => o.name === "disbursement_date"
        );
        if (response.data?.loan_status === _enum?.LOAN_STATUS_MAP?.disbursed) {
          if (disbamntField.outerclass.includes("d-none")) {
            disbamntField.outerclass = disbamntField.outerclass
              .replace("d-none", "")
              .trim();
          }
          if (disbdateField.outerclass.includes("d-none")) {
            disbdateField.outerclass = disbdateField.outerclass
              .replace("d-none", "")
              .trim();
          }
          setModalFields([...modalFields]);
        } else {
          disbamntField.outerclass = "d-none";
          disbdateField.outerclass = "d-none";
        }
      });
  };

  const deleteApplication = (record) => {
    console.log({ record });
    // DELETE OFFLINE LEADS BY LEADS
    if (record?.loan_status === _enum.LOAN_STATUS_MAP.disbursed) {
      swal2.fire({
        title: "Disbursed leads cannot be deleted",
        icon: "error",
        iconColor: "#FF4D4D",
        timer: 4000,
      });
    } else {
      swal2
        .fire({
          title: "Are you sure you want to delete this application?",
          width: 500,
          icon: "warning",
          iconColor: "#234C9B",
          showCancelButton: true,
          confirmButtonColor: "#234C9B",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        })
        .then((result) => {
          if (result.isConfirmed) {
            props.apis
              .apiDeleteOfflineLeads({
                leadId: record.leadId,
              })
              .then((res) => {
                console.log({ apiDeleteOfflineLeads: res });
                swal2.fire({
                  title: "Applicant deleted successfully !!",
                  icon: "success",
                  iconColor: "#234C9B",
                  timer: 4000,
                });
                getApplications();
              });
          }
        });
    }
  };

  const getBankNames = () => {
    let payload = {
      page: 1,
    };
    props.apis.apiGetBanknames(payload).then(({ data, count }) => {
      let bankNameField = modalFields.find((o) => o.name === "bank_name");
      bankNameField.options = data.options("bankName");
      setModalFields([...modalFields]);
    });
  };

  const getProductTypes = () => {
    props.apis.apiGetProductTypes().then((res) => {
      let productType = modalFields.find((o) => o.name == "product_type");
      productType.options = res.options("productType");
      setModalFields([...modalFields]);
    });
  };

  const handleSubmit = (e, data, form) => {
    e.preventDefault();
    console.log("formdata: ", data);
    let formdata = data;
    let cansubmit = true;
    let addPayload = {
      ...Object.except(formdata, [
        "loan_amount",
        "product_type",
        "application_date",
      ]),
    };

    addPayload.loan_amount = Number(formdata.loan_amount);
    addPayload.product_type = String(formdata.product_type);
    // addPayload.channel_type = "Offline";
    // addPayload.login_status = _enum?.LOAN_STATUS_MAP?.applied;
    // addPayload.loan_status = _enum?.LOAN_STATUS_MAP?.disbursed;
    addPayload.disbursement_amount = Number(formdata.disbursement_amount);
    addPayload.disbursement_date = new Date(
      formdata.disbursement_date
    ).toISOString();

    // if (formdata?.application_date) {
    //   addPayload.application_date = new Date(
    //     formdata?.application_date
    //   ).toISOString();
    // } else {
    //   cansubmit = false;
    //   toast.error("Required fields cannot be empty", toastConfig);
    // }

    // if (data.loan_status !== _enum?.LOAN_STATUS_MAP?.disbursed) {
    //   addPayload.disbursement_amount = null;
    //   addPayload.disbursement_date = null;
    // } else if (
    //   (data.loan_status === _enum?.LOAN_STATUS_MAP?.disbursed &&
    //     addPayload.disbursement_amount === "") ||
    //   addPayload.disbursement_date === ""
    // ) {
    //   cansubmit = false;
    //   toast.error("Required fields cannot be empty", toastConfig);
    // } else {
    //   addPayload.disbursement_amount = Number(formdata.disbursement_amount);
    //   addPayload.disbursement_date = new Date(
    //     formdata.disbursement_date
    //   ).toISOString();
    //   cansubmit = true;
    // }

    console.log({ addPayload, cansubmit });
    if (cansubmit) {
      if (editmode) {
        props.apis
          .apiUpdateOfflineLeads(addPayload, { leadId: leadId })
          .then((response) => {
            console.log({ apiUpdateOfflineLeads: response });
            let filteredData = Object.only(
              response,
              modalFields.map((v) => v.name)
            );
            console.log({ filteredData });
            setPopupdata(filteredData);
            setEditData(response.data);
            closeModal();
            swal2.fire({
              title: "Applicant updated successfully !!",
              icon: "success",
              iconColor: "#234C9B",
              timer: 4000,
            });
            getApplications();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        props.apis
          .apiAddOfflineLeads(addPayload)
          .then((res) => {
            console.log({ apiAddOfflineLeads: res });
            closeModal();
            getApplications();
            swal2.fire({
              title: "Applications submitted for payout successfully.",
              icon: "success",
              iconColor: "#234C9B",
              timer: 4000,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const onSearch = (data, reset) => {
    console.log("onSearch on leads", data, reset);
    if (Object.keys(data).length === 0) {
      setSearch({
        ...data,
        ...search,
      });
      getApplications();
    } else {
      setSearch({
        ...search,
      });
      getApplications(data, reset);
    }
  };

  const addModalFields = (values) => {
    setEditmode(false);
    setViewmode(false);

    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);

    setPopupfields(fields);
    setPopupdata(formdata);
    setOpenCanvas(true);
  };

  const onModalClose = () => {
    setPopupfields({});
    setViewmode(false);
    setEditmode(false);
    setEditData({});
    closeModal();
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    // getCheckboxProps: (record) => {
    //   // use this flag for checkmark and edit actions "isEnabled"
    //   const bool = record?.isEnabled;
    //   if (
    //     record?.isEnabled &&
    //     record?.loan_status === _enum?.LOAN_STATUS_MAP?.disbursed
    //   ) {
    //     return {
    //       disabled: false,
    //     };
    //   } else {
    //     return {
    //       disabled: true,
    //     };
    //   }
    // },
  };

  const handleSendPayout = () => {
    openPayoutModal();
  };

  // ----------------------------------------------------------------
  // HANDLE CHANGE PASSWORD MODAL
  const openPayoutModal = () => {
    payoutModalRef.current.click();
  };

  const closePayoutModal = () => {
    document.querySelector("#sendPayoutModal #bsmodalclose").click();
    setPayoutdata({});
    setPayoutPopupfields({});
  };

  const addChangepasswordData = () => {
    let fields = (payoutfields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    setPayoutdata(formdata);
    setPayoutPopupfields(fields);
    openPayoutModal();
  };

  const handlePayoutSubmit = (e, data) => {
    let payload = {
      leadIds: [...selectedRowKeys],
      payoutType: data?.payoutType,
      leadCategory: "OFFLINE",
    };
    console.log({ handlePayoutSubmit: payload });
    props.apis.apiSendConnectorLeadsForPayment(payload).then((res) => {
      console.log({ apiSendConnectorLeadsForPayment: res });
      if (res) {
        swal2.fire({
          title: "leads send for payment successfully",
          icon: "success",
          iconColor: "#234C9B",
          timer: 4000,
        });
        setSelectedRowKeys([]);
        closePayoutModal();
        getApplications();
      }
    });
  };

  // ----------------------------------------------------------------
  const bulkUploadModalRef = useRef(0);
  const [popupBulkUploadFields, setPopupBulkUploadFields] = useState({});
  const [popupBulkUploadData, setPopupBulkUploadData] = useState({});
  const [bulkUploadModalFields, setBulkUploadModalFields] = useState([
    {
      label: "File Upload (Max limit 10,000)",
      name: "file",
      type: "filearea",
      accept: ".xlsx, .xls",
      customcolspan: "12",
      required: true,
    },
  ]);

  const downloadTemplateLeads = (e) => {
    props.apis.apiDownloadOfflineLeadsTemplate().then((res) => {
      console.log({ res });
      if (res) {
        let url = `${props.apis.api().api_base}/offlineLeads/template`;
        var downloadURL = url;
        var link = document.createElement("a");
        link.setAttribute(
          "download",
          `offline_leads_template_${new Date().toLocaleDateString()}.xlsx`
        );
        link.href = downloadURL;
        link.click();
      }
    });
  };

  const downloadBulkLeads = (values) => {
    // let leadIds = tableData?.map((v) => JSON.parse(v.leadId));
    // let payload = {};
    // payload.leads = leadIds;
    // console.log({ downloadBulkLeads: payload });
    // props.apis.apiBulkDownloadLead(payload).then((res) => {
    //   console.log({ res });
    //   // if (res) {
    //   //   let url = `${props.apis.api().api_base}/leads/bulk-download`;
    //   //   var downloadURL = url;
    //   //   var link = document.createElement("a");
    //   //   link.setAttribute(
    //   //     "download",
    //   //     `leads_${new Date().toLocaleDateString()}.xlsx`
    //   //   );
    //   //   link.href = downloadURL;
    //   //   // link.target = "_blank";
    //   //   link.click();
    //   // }
    // });
  };

  // HANDLE BULK UPLOAD
  const openBulkUploadmodal = () => {
    bulkUploadModalRef.current.click();
  };

  const closeBulkUploadmodal = () => {
    document.querySelector("#bulkUploadModal #bsmodalclose").click();
    setPopupBulkUploadData({});
    setPopupBulkUploadFields({});
  };

  const addBulkUploadModalFields = () => {
    let fields = (bulkUploadModalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    setPopupBulkUploadFields(fields);
    setPopupBulkUploadData(formdata);
    openBulkUploadmodal();
  };

  const handleBulkUploadSubmit = (e, data, form) => {
    let payload = {
      file: data?.file,
    };
    console.log({ payload });

    props.apis.apiBulkUploadOfflineLead(payload).then((res) => {
      console.log({ apiBulkUploadOfflineLead: res });
      if (res) {
        swal2.fire({
          title: "Bulk Uploaded Successfully.",
          text: `Records Uploaded : ${res?.count || 0}`,
          icon: "success",
          iconColor: "#234C9B",
          timer: 4000,
        });
        closeBulkUploadmodal();
        getApplications();
      }
    });

    swal2.fire({
      title: "Bulk Uploaded Successfully.",
      //   text: `Records Uploaded : ${res?.count || 0}`,
      icon: "success",
      iconColor: "#234C9B",
      timer: 4000,
    });
    closeBulkUploadmodal();
  };

  // ----------------------------------------------------------------

  const getBreadCums = () => {
    let pageId = props?.urlparams?.params?.leadname;
    if (pageId) {
      let pagetitle = pageId;
      return pagetitle;
    } else {
      return " ";
    }
  };

  const getBackLink = () => {
    return "/app/businesstobusiness?activetab=offlineleads";
  };

  useEffect(() => {
    selectedRowKeys.length > 0 ? setNotSelected(false) : setNotSelected(true);
  }, [selectedRowKeys]);

  useEffect(() => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    setPopupfields(fields);
  }, [editData]);

  useEffect(() => {
    getBankNames();
    getProductTypes();
    console.log({ editmode, viewmode });

    if (editmode === false && viewmode === false) {
      let rname = props?.urlparams?.params?.leadname;
      console.log(rname);
      setRouteName(rname);
      let productType = modalFields.find((o) => o.name == "product_type");
      productType.defaultValue = rname;
      setModalFields([...modalFields]);
    } else {
      let productType = modalFields.find((o) => o.name == "product_type");
      productType.defaultValue = editData?.product_type;
      setModalFields([...modalFields]);
      console.log("not add modal");
    }
  }, [openCanvas]);

  useEffect(() => {
    getApplications();
  }, []);

  props.$setNode({
    props,
    search,
    editData,
  });
  return (
    <>
      <MainPagetitle
        mainTitle={
          adminPersona
            ? "B2B Leads | Offline Leads"
            : "My Leads | Offline Leads"
        }
        back={getBreadCums()}
        backLink={getBackLink()}
        pageIcon={imagepaths.AdminLeadsIcon}
      />
      <div className="container-fluid pb-5">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="tbl-caption-container">
                  <OfflineSearch
                    onSearch={(...arg) => onSearch(...arg)}
                    addModalFields={addModalFields}
                  />
                </div>
                <div className="header_btn_box transparent_btns mb-3 px-3">
                  <button
                    className="btn btn-sm btn-outline-secondary me-2"
                    onClick={(...args) => downloadTemplateLeads(...args)}
                  >
                    <i className="fa fa-download me-2"></i>
                    <span>Download Template</span>
                  </button>
                  <button
                    className="btn btn-sm btn-outline-secondary me-2"
                    onClick={(e) => addBulkUploadModalFields(e)}
                    ref={bulkUploadModalRef}
                    data-bs-toggle="modal"
                    data-bs-target="#bulkUploadModal"
                  >
                    <i className="fa fa-upload me-2"></i>
                    <span>Bulk Upload</span>
                  </button>
                  <button
                    className="btn btn-sm btn-outline-secondary me-2"
                    onClick={(...args) => downloadBulkLeads(...args)}
                    // disabled={disableBulkDownload}
                  >
                    <i className="fa fa-download me-2"></i>
                    <span>Bulk Download</span>
                  </button>
                </div>
                {!!tableData.length && (
                  <AppTable
                    data={tableData}
                    columns={columns}
                    onChange={(...arg) => onPageChange(...arg)}
                    total={rowcount}
                    reorder={true}
                    editAction={(...args) => editApplication(...args)}
                    viewAction={(...args) => viewApplication(...args)}
                    editable={adminPersona ? false : true}
                    deletable={adminPersona ? false : true}
                    deleteAction={(...args) => deleteApplication(...args)}
                    loading={isFetching}
                    rowSelection={adminPersona ? "" : rowSelection}
                    checkbox={adminPersona ? false : true}
                  ></AppTable>
                )}
                {tableData.length == 0 && <div className="empty_layout"></div>}
              </div>
            </div>
          </div>
        </div>
        {!notSelected && (
          <div className="row bottom_container">
            <div className="col-12 text-end">
              <button
                className="btn btn-primary m-3"
                onClick={(e) => handleSendPayout(e)}
              >
                Send for Payout
              </button>
            </div>
          </div>
        )}
        {/* below button hidden on UI */}
        <button
          className="btn btn-sm btn-outline-secondary d-none"
          onClick={(e) => addChangepasswordData(e)}
          ref={payoutModalRef}
          data-bs-toggle="modal"
          data-bs-target="#sendPayoutModal"
        >
          <span>Send Payout</span>
        </button>
      </div>

      <OfflineModal
        id="applicationCanvas"
        title={`${
          editmode
            ? "Edit Offline Leads"
            : viewmode
            ? "View Offline Leads"
            : "Add Leads"
        }`}
        show={openCanvas}
        onClose={() => onModalClose()}
        data={popupdata}
        editdata={editData}
        fields={popupfields}
        viewmode={viewmode}
        editmode={editmode}
        errorMessage={errorMessage}
        // noValidate={true}
        onSubmit={(...args) => handleSubmit(...args)}
        submitText={`${editmode ? "Update" : "Save & Submit"}`}
        disableallfields={viewmode ? true : false}
      />

      <SendPayoutModal
        id="sendPayoutModal"
        title=""
        submitText="Submit"
        data={payoutdata}
        fields={payoutPopupfields}
        onSubmit={(...args) => handlePayoutSubmit(...args)}
        modaltype="centeredmodal"
        modalSize="default"
        onClose={() => closePayoutModal()}
      />

      <BulkUploadModal
        id="bulkUploadModal"
        modalSize="modal-lg"
        title="Bulk Upload"
        submitText="Upload"
        data={popupBulkUploadData}
        viewmode={false}
        fields={popupBulkUploadFields}
        onSubmit={(...args) => handleBulkUploadSubmit(...args)}
        modaltype="centeredmodal"
        onClose={() => closeBulkUploadmodal()}
      />
    </>
  );
};

export default constore(OfflineLeads);
