import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import Eligibitlity from "./Eligibitlity";
import ZypeSubmit from "./ZypeSubmit";

const ZypeSteps = (props) => {
  const toastConfig = {
    autoClose: 3000,
    closeButton: false,
    hideProgressBar: true,
  };
  const [checkdata, setCheckdata] = useState({});

  const handleCustomerCheck = (e) => {
    e.preventDefault();
    let payload = {
      ...e.target.getData(),
    };
    console.log({ payload });
    setCheckdata(payload);

    // handleStep(1);

    props.apis.apiZypeCustomerEligibilityCheck(payload).then((res) => {
      console.log({ res });

      if (res?.status === "APPLICATION_ALREADY_EXIST") {
        toast.error(
          "Application already exists from the same partner",
          toastConfig
        );
      } else {
        toast.success("Application eligible", toastConfig);
        handleStep(1);
      }
    });
  };

  const handleZypeSubmit = (e) => {
    e.preventDefault();
    props?.handleSubmit(e);
  };

  const handleStep = (step) => {
    props.setGoSteps(step);
  };

  return (
    <>
      <ToastContainer />
      <div className="form-wizard">
        {props.goSteps === 0 && (
          <>
            <Eligibitlity
              onHandleStep={(...args) => handleStep(...args)}
              onhandleCustomerCheck={(...args) => handleCustomerCheck(...args)}
              onClose={props.onClose}
              editData={props.editData}
            />
          </>
        )}
        {props.goSteps === 1 && (
          <>
            <ZypeSubmit
              onHandleStep={(...args) => handleStep(...args)}
              onhandleZypeSubmit={(...args) => handleZypeSubmit(...args)}
              onClose={props.onClose}
              checkdata={checkdata}
              editData={props.editData}
            />
          </>
        )}
      </div>
    </>
  );
};

export default constore(ZypeSteps);
