import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";

const RegisteredUsers = (props) => {
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [tableData, setTableData] = useState();
  const [columns, setColumns] = useState([
    {
      dataIndex: "customerName",
      title: "Name",
      render: (text, record) => {
        return <span className="table-primary-text">{text}</span>;
      },
    },
    {
      dataIndex: "mobileNumber",
      title: "Mobile No.",
    },
    {
      dataIndex: "emailAddress",
      title: "Email ID",
    },
    {
      dataIndex: "createdAt",
      title: "Registration Date",
      render: (text, record) => {
        let dt = new Date(text);
        let date = new Date(dt).dateToDDMMYYYY(dt);
        let time = dt.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
        return <span>{`${date} ${time}`}</span>;
      },
    },
    {
      dataIndex: "status",
      title: "Status",
      render: (text, record) => {
        let mappedStatus =
          _enum.STATUS_COLOR_MAP[record?.status?.toLowerCase()] || "";

        let dropdowItems;
        return (
          <Dropdown
            className={Object.className({
              "status-dropdown": true,
              "no-arrow pe-none": true,
            })}
          >
            <Dropdown.Toggle as="div" className={`${mappedStatus}`}>
              <span>{record?.status?.toTitleCase() || "-"}</span>
              <i class="fa-solid fa-chevron-down ms-2"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="status-drop-menu">
              {dropdowItems?.map((item) => {
                return (
                  <Dropdown.Item
                    onClick={() => handleAction(record.leadId, item.key)}
                  >
                    {item.label}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ]);
  const [rowcount, setRowcount] = useState(0);
  const [isFetching, setIsFetching] = useState(false);

  const viewUser = (record) => {
    console.log({ record });
    props.navigate(`/app/registereduserview?customerId=${record?.customerId}`);
  };

  const deleteUser = (record) => {
    console.log({ record });
  };

  const getUsers = (params = {}, reset) => {
    let payload = {};
    if (reset) {
      let defaultSearchData = Object.only(search, ["page", "limit"]);

      setSearch(defaultSearchData);
      payload = {
        ...defaultSearchData,
        ...params,
      };
      console.log({ defaultSearchData: payload });
    } else {
      payload = {
        ...search,
        ...params,
      };
    }
    props.apis.apiGetRegisteredUsers(payload).then(({ data, total }) => {
      console.log({ data });
      setTableData(data);
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-xl-12">
            <AppTable
              data={tableData}
              columns={columns}
              onChange={(...arg) => onPageChange(...arg)}
              total={rowcount}
              reorder={true}
              viewAction={(...arg) => viewUser(...arg)}
              editable={false}
              deleteAction={(...arg) => deleteUser(...arg)}
              checkbox={true}
              loading={isFetching}
            ></AppTable>
          </div>
        </div>
      </div>
    </>
  );
};

export default constore(RegisteredUsers);
