import OfflineLeads from "../../../jsx/components/Dashboard/OfflineLeads/OfflineLeads";

const OfflineLeadsWrapper = () => {
  return <OfflineLeads />;
};

export const allowedRoles = [
  _enum.ROUTE_PERSONAS.admin,
  _enum.ROUTE_PERSONAS.connector,
  _enum.ROUTE_PERSONAS.manager,
];

export default constore(OfflineLeadsWrapper);
