export default {

  apiHeartBeat() {
    return this.request(this.api().HEARTBEAT).exec()
      .mapresults((response) => {
        return response;
      }).log().get();
  },

  apiGetUser() {
    return this.request(this.api().GET_USER).exec()
      .mapresults((response) => {
        return response;
      }).log().get();
  },

  apiLogin(data) {
    return this.request(this.api().LOGIN, data)
      .exec().log().mapresults((response) => {
        return new Promise((resolve, reject) => {
          this.request(this.api().GET_USER, {}, {
            authorization: "Bearer " + response.access_token,
          }).exec().get().then((userresponse) => {
            // console.log("userresponse", userresponse);
            let retdata = {
              auth: response.access_token,
              logged: {
                ...Object.except(data, ["password"]),
                ...response
              },
              user: {
                ...userresponse,
                refresh: response.refresh_token
              }
            };
            setTimeout(() => {
              this.apiRefreshToken();
            }, retdata.logged.access_expires_in);
            resolve(retdata);
            return retdata;
          })
        });
      }).get();
  },

  apiLogout() {
    return this.request(this.api().LOGOUT).exec()
      .mapresults((response) => {
        return response;
      }).log().get();
  },

  apiOTPLogin(data) {
    console.log("apiOTPLogin", data);
    return this.request(this.api().OTP_LOGIN)
      .urltransform({
        mobile: data.mobile
      })
      .exec().log().mapresults((res) => {
        return res.data
      }).get();
  },

  apiPasswordGenerate(data) {
    console.log("apiPasswordGenerate", data);
    return this.request(this.api().GENERATE_TEMPORARY_PASSWORD)
      .urltransform({
        email: data.email
      })
      .exec().log().mapresults((res) => {
        return res.data
      }).get();
  },

  apiPasswordReset(data) {
    console.log("apiPasswordGenerate", data);
    return this.request(this.api().PASSWORD_RESET, data)
      // .urltransform({
      //   email: data.email
      // })
      .exec().log().mapresults((res) => {
        return res
      }).get();
  },

  apiOTPLoginVerify(data) {
    console.log({ apiOTPLoginVerify: data });
    return this.request(this.api().OTP_LOGIN_VERIFY, data)
      .exec().log().mapresults((response) => {
        return new Promise((resolve, reject) => {
          this.request(this.api().GET_USER, {}, {
            authorization: "Bearer " + response.access_token,
          }).exec().get().then((userresponse) => {
            console.log("userresponse", userresponse);
            let retdata = {
              auth: response.access_token,
              logged: {
                ...Object.except(data, ["password"]),
                ...response.data
              },
              user: {
                ...userresponse,
                refresh: response.refresh_token
              }
            };
            resolve(retdata);
            return retdata;
          })
        });
      }).get();
  },

  apiRefreshToken() {
    // console.log("apiRefreshToken", app, this.props.$store, window.app.props.$store.user);
    let refreshTokenAttemp = localStorage.getItem("refreshTokenAttemp");
    refreshTokenAttemp = refreshTokenAttemp || 1;
    localStorage.setItem("refreshTokenAttemp", (+refreshTokenAttemp) + 1);
    return this.request(this.api().REFRESH_TOKEN).headers({
      authorization: "Bearer " + app.props.$store.user.refresh
    }).exec().log().mapresults((response) => {
      let { logged = {} } = app.props.$store;
      let refreshdata = response;
      app.props.store('auth', response.access_token);
      app.props.store('logged', { ...logged, ...refreshdata });
      app.props.store('loggedIn', new Date().getTime());
      app.props.store('user.refresh', response.refresh_token);
      localStorage.setItem("refreshTokenAttemp", 0);
      // console.log({ refreshdata, response });
      // setTimeout(() => {
      //   this.apiRefreshToken();
      // }, refreshdata.access_expires_in);
    }).get();
  },

  // FILE UPLOAD

  apiFileUploader(data, urldata = mixins.params()) {
    return this.request(this.api().FILE_UPLOAD, data)
      .urltransform(urldata)
      .multipart()
      .exec()
      .log()
      .get();
  },
  apiFileDownloader(urldata = mixins.params()) {
    return this.request(this.api().FILE_DOWNLOAD)
      .urltransform(urldata)
      .multipart()
      .exec()
      .log()
      .get();
  },
  apiGetFile(data, urldata = mixins.params()) {
    return this.request(this.api().GET_FILE_METADATA, data)
      .urltransform({ ...data, ...urldata })
      .geturl();
  },
  apiFileRemover(urldata = mixins.params()) {
    return this.request(this.api().DELETE_FILE)
      .urltransform(urldata)
      .multipart()
      .exec()
      .log()
      .get();
  },
  // -----------------------  CUSTOMER API'S  -----------------------

  apiKycSendOTP(data) {
    return this.request(this.api().KYC_SEND_OTP, data)
      .exec().log().mapresults((res) => {
        return res
      }).get();
  },

  apiKycVerifyOTP(data) {
    console.log("apiKycVerifyOTP", data);
    return this.request(this.api().KYC_VERIFY_OTP, data)
      .exec().log().mapresults((res) => {
        return res
      }).get();
  },

  apiGetCustomerPanDetails(data) {
    // console.log("apiGetCustomerPanDetails", urldata);
    return this.request(this.api().GET_CUSTOMER_PAN_DETAILS, data)
      .exec().log().mapresults((res) => {
        return res
      }).get();
  },

  apiVerifyCustomerPanDetails(data) {
    console.log("apiVerifyCustomerPanDetails", data);
    return this.request(this.api().VERIFY_CUSTOMER_PAN, data)
      .exec().log().mapresults((res) => {
        return res
      }).get();
  },

  apiGetCustomerAadharDetails(data) {
    console.log("apiGetCustomerAadharDetails", data);
    return this.request(this.api().GET_CUSTOMER_AADHAR_DETAILS, data)
      .exec().log().mapresults((res) => {
        return res
      }).get();
  },

  apiVerifyCustomerAadharDetails(data) {
    // console.log("apiVerifyCustomerAadharDetails", data);
    return this.request(this.api().VERIFY_CUSTOMER_AADHAR, data)
      .exec().log().mapresults((res) => {
        return res
      }).get();
  },

  // VALUE ADDED SERVICE - WALLET
  apiVasRecharge(data) {
    return this.request(this.api().VAS_RECHARGE, data)
      .exec().log().mapresults((res) => {
        return res
      }).get();
  },

  apiGetUserBalance() {
    return this.request(this.api().GET_USER_BALANCE)
      .exec()
      .log()
      .get();
  },

  apiGetPastTransaction(data) {
    return this.request(this.api().GET_PAST_TRANSACTION, data)
      .exec().log().mapresults((res) => {
        return res
      }).get();
  },

  // VALUE ADDED SERVICE - WALLET
  apiGetVASPanDetails(data) {
    return this.request(this.api().VAS_GET_PAN_DETAILS, data)
      .exec().log().mapresults((res) => {
        return res
      }).get();
  },
  apiGetVASAadharInfo(data) {
    return this.request(this.api().VAS_GET_AADHAR_INFO, data)
      .exec().log().mapresults((res) => {
        return res
      }).get();
  },
  apiGetVASAadharVerify(data) {
    return this.request(this.api().VAS_AADHAR_VERIFY, data)
      .exec().log().mapresults((res) => {
        return res
      }).get();
  },

  // REQUEST
  apiConnectorRequestRecharge(data) {
    return this.request(this.api().CONNECTOR_REQUEST_RECHARGE, data)
      .exec().log().mapresults((res) => {
        return res
      }).get();
  },

  apiGetRequestRecharge(data) {
    return this.request(this.api().GET_REQUEST_RECHARGE, data)
      .exec().log()
      .mapresults((response) => {
        return {
          data: response?.data,
          total: response?.totalCount
        }
      }).get();
  },

  apiGetRequestRechargeById(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().GET_REQUEST_RECHARGE_BY_ID,)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res
      })
      .get();
  },

  apiUpdateRequestRecharge(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    let payload = {
      rechargeStatus: data?.rechargeStatus,
      remarks: data?.remarks,
    }
    payload = Object.filter(payload, (v) => v);

    return this.request(this.api().UPDATE_REQUEST_RECHARGE_BY_ID, payload)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiSendRequestRechargeOtp(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().SEND_REQUEST_RECHARGE_OTP,)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res
      })
      .get();
  },

  apiVerifyRequestRecharge(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().VERIFY_REQUEST_RECHARGE_OTP, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiRejectRequestRecharge(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().REJECT_REQUEST_RECHARGE_OTP, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  // SERVICE UTILIZATION

  apiGetServiceUtilization(data) {
    return this.request(this.api().GET_SERVICE_UTILIZATION, data)
      .exec().log()
      .get();
  },

  // MASTER SUBSCRIPTION

  apiGetMasterSubscriptions(data) {
    return this.request(this.api().GET_MASTER_SUBSCRIPTION, data)
      .exec().log()
      .get();
  },
};
